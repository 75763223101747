import { TypesHelper } from 'SRC/utils'
import countriesArr from '../../../../../public/data/countriesArr.json'
import translations from '../../../../../public/data/translations.json'

export const getParsedFilterValues = ({ filterValues, pageMetaTags, pageUrlItems, possibleValues, locale = 'ME' }) => {
  const parsedObject = {}
  const skipProps = ['category', 'userType', 'username', 'formStyle', 'pageNumber']
  if (!TypesHelper.isEmptyObject(filterValues)) {
    for (let property in filterValues) {
      if (filterValues.hasOwnProperty(property) && !skipProps.includes(property)) {
        try {
          parsedObject[property] = JSON.parse(filterValues[property])
        } catch (error) {
          if (typeof filterValues[property] !== 'undefined') {
            parsedObject[property] = filterValues[property]
          }
        }
      }
    }
  }

  if (Array.isArray(pageUrlItems) && pageUrlItems.length) {
    for (let item of pageUrlItems) {
      const key = Object.keys(item)[0]

      if (key && key.startsWith(`${translations.usluga[locale]}-`)) {
        parsedObject.service = item[key].id
        parsedObject.serviceText = item[key].text
        parsedObject.serviceBc = { seo: item[key].seo, text: item[key].text }
        continue;
      }
      if (key && key.startsWith(`${translations['tip-usluge'][locale]}-`)) {
        parsedObject.serviceType = item[key].id
        parsedObject.serviceTypeText = item[key].text
        parsedObject.serviceTypeBc = { seo: item[key].seo, text: item[key].text }
        continue;
      }
      if (key && key.startsWith(`${translations.grad[locale]}-`)) {
        parsedObject.cities = [item[key].id]
        parsedObject.citiesText = [item[key].text]

        parsedObject.city = item[key].id
        parsedObject.cityText = item[key].text
        parsedObject.cityBc = { seo: item[key].seo, text: item[key].text }
        continue;
      }
      if (key && key.startsWith(`${translations.lokacija[locale]}-`)) {
        parsedObject.location = item[key].id
        parsedObject.locationText = item[key].text
        parsedObject.locationBc = { seo: item[key].seo, text: item[key].text }
        continue;
      }
    }

    const findModels = pageUrlItems.find(item => {
      const key = Object.keys(item)[0]

      return key && key.startsWith(`${translations.model[locale]}-`)
    })

    const findBrands = pageUrlItems.find(item => {
      const key = Object.keys(item)[0]

      return key && key.startsWith(`${translations.proizvodjac[locale]}-`)
    })

    if (findModels) {
      const modelValue = findModels[Object.keys(findModels)[0]].id
      parsedObject.products = [modelValue]

      if (findBrands) {
        const brandValue = findBrands[Object.keys(findBrands)[0]].id
        const brandText = findBrands[Object.keys(findBrands)[0]].text
        const brandSeo = findBrands[Object.keys(findBrands)[0]].seo

        const modelText = findModels[Object.keys(findModels)[0]].text
        const modelSeo = findModels[Object.keys(findModels)[0]].seo

        parsedObject.brands = [brandValue]
        parsedObject.productsText = [`${brandText} ${modelText}`]

        parsedObject.brandBc = { seo: brandSeo, text: brandText }
        parsedObject.modelBc = { seo: modelSeo, text: modelText }
      }
    } else {
      if (findBrands) {
        const brandValue = findBrands[Object.keys(findBrands)[0]].id
        const brandText = findBrands[Object.keys(findBrands)[0]].text
        const brandSeo = findBrands[Object.keys(findBrands)[0]].seo

        parsedObject.brands = [brandValue]
        parsedObject.brandsText = [brandText]

        parsedObject.brandBc = { seo: brandSeo, text: brandText }
      }
    }
  }

  if (Array.isArray(possibleValues) && possibleValues.length) {
    for (let value of possibleValues) {
      if (Array.isArray(pageMetaTags?.urlSpecs)) {
        for (let spec of pageMetaTags.urlSpecs) {
          const findSeoIndex = spec.seo.findIndex(seo => seo === value)

          if (findSeoIndex !== -1) {
            const filterSpec = { id: spec.id, value: spec.options[findSeoIndex], titleText: spec.title[findSeoIndex] }

            if (!Array.isArray(parsedObject.specifications)) parsedObject.specifications = [filterSpec]
            else parsedObject.specifications.push(filterSpec)

            continue
          }
        }

        if (value && value.startsWith(`${translations.drzava[locale]}-`) && Array.isArray(countriesArr) && value !== 'drzava-crna-gora') {
          const country = countriesArr.find(row => row[0] === value)
          if (country && country[1]) parsedObject.country = country[1]
        }
      }
    }
  }

  return parsedObject
}
