import { CommonApi } from 'SRC/modules/common/api'
import { setFormFields } from '../setFormFields'
import { setFormLoading } from '../setFormLoading'
import { setCurrentSubCategory, setSpecifications } from 'SRC/modules/categories/actions'
import { fetchBrands } from 'SRC/modules/ads/brands/actions'

export const fetchFormFields = (seo) => async dispatch => {
  dispatch(setFormLoading(true))
  const api = new CommonApi()
  const formCategory = await api.getForm(seo)

  if (formCategory) {
    const fields = { category: { id: formCategory.id, seo: formCategory.seo, title: formCategory.title } }

    const specificationFields = formCategory && formCategory.fields
      ? formCategory.fields.filter(field => field.type === 'specification')
      : null

    fields.specification = specificationFields
    dispatch(setFormFields(fields))

    const category = Object.keys(formCategory).filter(key => key !== 'fields').reduce((acc, curr) => {
      acc[curr] = formCategory[curr]
      return acc
    }, {})

    if (category.requiredAdOptions === 'Brand' || category.requiredAdOptions === 'BrandModel') {
      await dispatch(fetchBrands(category.id))
    }

    dispatch(setCurrentSubCategory(category))
    if (specificationFields) {
      dispatch(setSpecifications(specificationFields.map(specField => ({
        ...specField,
        weightAdd: specField.position
      }))))
    }
  }

  dispatch(setFormLoading(false))
}
