import initialState from '../../../redux/initialState'
import actionTypes from '../../../redux/actionTypes'

export const regionsReducer = (state = initialState.geoObjects.regions, action) => {
  switch (action.type) {
    case actionTypes.SET_REGIONS:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_REGIONS_BY_COUNTRY:
      return {
        ...state,
        byCountry: {
          ...state.byCountry,
          [action.payload.country]: action.payload.regions
        }
      }
    case actionTypes.SET_REGIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}
