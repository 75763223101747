import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from 'SRC/utils'
import { Label } from '../Label'
import { DropdownItem } from '../DropdownItem'
import { DropdownGroup } from '../DropdownGroup'
import { SelectAll } from '../SelectAll'
import { Search } from '../../Search'
import { Title } from '../../Title'

const getStringValue = val => val ? val.toString() : ''

export const Dropdown = ({
  isDisabled,
	isSearchable,
	options,
	labelDefault,
	id,
	checkedItems,
	title,
	onChange,
	needColon,
	onMouseEnter,
	isRequired,
	isOptional,
  hasSelectAll
}) => {
	const [isOptionsVisible, setIsOptionsVisible] = React.useState(false)
	const [showTextSearch, setShowTextSearch] = React.useState(false)
	const [filter, setFilter] = React.useState('')

	const optionsRef = React.useRef()
	const dropdownRef = React.useRef()
	const textSearchRef = React.useRef()

  const checkedItemsValues = Array.isArray(checkedItems) ? checkedItems.map(item => getStringValue(item)) : []

  React.useEffect(() => {
		document.addEventListener('mousedown', toggleOptionsVisibility)

		return () => { document.removeEventListener('mousedown', toggleOptionsVisibility) }
	}, [isDisabled])

  React.useEffect(() => {
		if (!isOptionsVisible) setFilter('')
	}, [isOptionsVisible])

  const toggleOptionsVisibility = e => {
    let optionsVisible = false

    if (isDisabled) {
			setIsOptionsVisible(optionsVisible)
			setShowTextSearch(false)
			setFilter('')

      return
    }

    if (dropdownRef.current && dropdownRef.current.contains(e.target)) optionsVisible = !isOptionsVisible
    if (optionsRef.current && optionsRef.current.contains(e.target)) optionsVisible = true
    if (textSearchRef.current && textSearchRef.current.contains(e.target)) optionsVisible = true

    const showTextSearch = isSearchable && optionsVisible

		setIsOptionsVisible(optionsVisible)
		setShowTextSearch(showTextSearch)
  }

  const findItem = item => {
    const itemLabel = normalizeString(getStringValue(item.label).toLowerCase())
    return itemLabel.indexOf(filter.toLowerCase().normalize()) > -1
  }

  const getFilteredSubGroups = subGroups => {
    if (!subGroups.length) return []
    console.log('getSubGroups subGroups', subGroups)

    return subGroups.reduce((acc, curr) => {
      const subGroupOptions = Array.isArray(curr.options) ? curr.options.filter(findItem) : []

      if (subGroupOptions.length) acc.push({ ...curr, options: subGroupOptions })

      return acc
    }, [])
  }

  const getFilteredItems = () => {
    console.log('getSubGroups items', options)

    return options.reduce((acc, curr) => {
      if (!curr.isGrouped) {
        if (findItem(curr)) acc.push(curr)
        return acc
      }
      
      const groupOptions = Array.isArray(curr.options) ? curr.options.filter(findItem) : []
      const groupSubGroups = Array.isArray(curr.subGroups) ? getFilteredSubGroups(curr.subGroups) : []

      if (groupOptions.length || groupSubGroups.length) {
        acc.push({ ...curr, options: groupOptions, subGroups: groupSubGroups })
      }

      return acc
    }, [])
  }

  const getItems = () => {
    if (!Array.isArray(options) || !options) return []

    if (filter) return getFilteredItems()

    return options
  }

  const onSelectDropdownChange = (e) => {
    const value = e.currentTarget.dataset.value.toString()

    console.log('onSelectDropdownChange checkedItemsValues', checkedItemsValues)
    console.log('onSelectDropdownChange value', value)

    let newItems = [...checkedItemsValues]

    if (checkedItemsValues.includes(value)) {
      newItems = newItems.filter(item => item !== value)
    } else {
      newItems.push(value)
    }

    onChange(newItems)
  }

  const items = getItems()

  return ( 
    <React.Fragment>
      <Title title={title} needColon={needColon} isRequired={isRequired} />

      <div
        className={`select-dropdown-picker ${isDisabled ? 'disabled' : ''}`}
        ref={dropdownRef}
        key={`multiSelect-${id}`}
        onMouseEnter={onMouseEnter}
      >
        {showTextSearch
          ? <Search ref={textSearchRef} setFilter={setFilter} />
          : <Label labelDefault={labelDefault} checkedItems={checkedItemsValues} options={options} />
        }
      </div>

      {isOptionsVisible && options.length && !isDisabled ? (
        <div className='select-dropdown-container select-dropdown-multiple' ref={optionsRef}>
          <ul className='select-dropdown-items'>
            <SelectAll
              show={!isDisabled && hasSelectAll && Array.isArray(options) && options.every(option => !option.isGrouped)}
              checkedItems={checkedItemsValues}
              options={options}
              onChange={onChange}
            />
            
            {items.map((item, index) => item.isGrouped
              ? (
                <DropdownGroup
                  key={item.id}
                  checkedItems={checkedItemsValues}
                  filter={filter}
                  group={item}
                  hasSelectAll={hasSelectAll}
                  onChange={onChange}
                  onItemChange={onSelectDropdownChange}
                />
              ) : (
                <DropdownItem
                  key={`${item.id}-${item.label}`}
                  checkedItems={checkedItemsValues}
                  option={item}
                  onChange={onSelectDropdownChange}
                />
              )) 
            }
          </ul>
        </div>
      ) : null}
    </React.Fragment>
  )
}

Dropdown.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string.isRequired,
  labelDefault: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    isGrouped: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      value: PropTypes.any
    }))
  })).isRequired,
  checkedItems: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSearchable: PropTypes.bool.isRequired,
  hasSelectAll: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool,
  needColon: PropTypes.bool.isRequired,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onChange: PropTypes.func.isRequired
}

Dropdown.displayName = 'MultiSelectDropdown'

Dropdown.defaultProps = {
  title: '',
  options: [],
  checkedItems: [],
  isSearchable: false,
  isDisabled: false,
  needColon: true,
  hasSelectAll: false,
  isRequired: false
}
