import actionTypes from 'SRC/modules/redux/actionTypes'
import initialState from 'SRC/modules/redux/initialState'

export const groupsReducer = (state = initialState.groups, action) => {
  switch (action.type) {
    case actionTypes.SET_GROUPS:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: action.payload
      }
    case actionTypes.SET_IS_GROUP_PAGE:
      return {
        ...state,
        isGroupPage: action.payload
      }
    default:
      return state
  }
}
