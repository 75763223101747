import PropTypes from 'prop-types'
import React from 'react'
import { branch, renderNothing, renderComponent } from 'recompose'
import { Item } from '../Item/index'

export const List = ({items, onAddressChange}) =>
  [
    <span className='vise-lokacija' key='counter'>
      <i className='ico-vise-lokacija ico' />
      <p>+{items.length}</p>
    </span>,
    <div className='sidebar-vise-lokacija-dropdown' key='list'>
      {
        items.map(address => <Item onClick={onAddressChange} address={address} key={address.id} />)
      }
    </div>
  ]

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number.isRequired})).isRequired,
  onAddressChange: PropTypes.func
}

List.defaultProps = {
  items: []
}

export default branch(
  ({items}) => items.length,
  renderComponent(List),
  renderNothing
)(List)
