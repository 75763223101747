import PropTypes from 'prop-types'
import React from 'react'
import { RegularTextInput } from './RegularTextInput'
import { MaskedTextInput } from './MaskedTextInput'

export const TextInput = ({ mask, ...rest }) => {
	if (!mask) return <RegularTextInput {...rest} />
	return <MaskedTextInput mask={mask} {...rest} />
}

TextInput.propTypes = {
  mask: PropTypes.object
}
