import React from 'react'
import PropTypes from 'prop-types'

export const Navigation = React.forwardRef((props, ref) => (
  <div className='kategorije-pretraga-mega-menu-list' ref={ref}>
    <ul>
      {
        props.items && Array.isArray(props.items)
          ? props.items.map((item, index) => [
            <li key={item.id} className={index === props.currentIndex ? 'active' : ''}>
              <a href='javascript:void(0)' data-id={item.id} onClick={props.onHeaderClick}>
                {item.title}
              </a>
            </li>,
            '\n'
          ])
          : null
      }
    </ul>
  </div>
))

Navigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  onHeaderClick: PropTypes.func,
  currentIndex: PropTypes.number
}

Navigation.defaultProps = {
  items: []
}
