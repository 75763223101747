import { TypesHelper } from 'SRC/utils'

export class StorageHelper {
  constructor ({persistent}) {
    if (StorageHelper.supportsHtml5Storage()) {
      this.storage = persistent ? window.localStorage : window.sessionStorage
    } else {
      this.storage = null
    }
  }

  static supportsHtml5Storage () {
    try {
      return 'localStorage' in window && window['localStorage'] !== null
    } catch (e) {
      return false
    }
  }

  set = (key, item) => {
    if (!this.storage) throw new Error('Storage is not supported')

    let value = item
    if (typeof item === 'object') value = JSON.stringify(item)
    this.storage.setItem(key, value)
  }

  get = (key) => {
    if (!this.storage) throw new Error('Storage is not supported')

    const item = this.storage.getItem(key)
    if (item && TypesHelper.isJsonString(item)) return JSON.parse(item)
    return item
  }

  remove = (key) => {
    if (!this.storage) throw new Error('Storage is not supported')
    this.storage.removeItem(key)
  }
}
