import { createSelector } from 'reselect'
import initialState from 'SRC/modules/redux/initialState'

const defaultParams = initialState.ads.pagination.params

export const getPaginationParams = createSelector(
  (state) => state && state.ads && state.ads.pagination && state.ads.pagination.params
    ? state.ads.pagination.params
    : defaultParams,
  (params) => params
)
