import { CommonApi } from '../../../common/api'
import { setPageMetaTags } from '../setPageMetaTags'
import { setPageMetaTagsSingle } from '../setPageMetaTagsSingle'
import { getCurrentLocale } from 'SRC/modules/common/functions'

export const fetchPageMetaTags = (fields = {}, locale = getCurrentLocale()) => {
  const api = new CommonApi()

  return async (dispatch, getState) => {
    if (fields.category || fields.group) {
      const pageMetaTagsResponse = await api.getPageMetaTags(fields, locale)
      if (pageMetaTagsResponse) dispatch(setPageMetaTagsSingle(pageMetaTagsResponse))
      else dispatch(setPageMetaTagsSingle(null))
    } else {
      const pageMetaTags = getState().common.pageMetaTags

      if (!Array.isArray(pageMetaTags) || !pageMetaTags.length) {
        const pageMetaTagsResponse = await api.getPageMetaTags({}, locale)

        if (Array.isArray(pageMetaTagsResponse)) dispatch(setPageMetaTags(pageMetaTagsResponse))
        else dispatch(setPageMetaTags([]))
      }
    }
  }
}
