import PropTypes from 'prop-types'
import React from 'react'

export const ItemSpan = ({title, iconClass, iconSvg, count, onClick, width}) => (
  <span className='sidebar__menu-span' onClick={onClick}>
    {iconSvg ? <img src={iconSvg} width={width || 20} /> : <i className={`${iconClass || ''} ico`} />}
    <p>{title} {count ? <span>[ {count} ]</span> : null}</p>
  </span>
)

ItemSpan.propTypes = {
  count: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  iconSvg: PropTypes.string,
  width: PropTypes.number
}
