import PropTypes from 'prop-types'
import React from 'react'

export const PageHeader = ({children}) => (
  <div className='page-header'>
    <h1>{children}</h1>
  </div>
)

PageHeader.propTypes = {
  children: PropTypes.any
}
