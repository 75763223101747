import { setEditAd } from '../setEditAd'
import { AdsDetailApi } from '../../../detail/api'

export const fetchEditAd = params => async dispatch => {
  const api = new AdsDetailApi()
  const {ad, error} = await api.getAd(params)
  if (!error && ad) {
    dispatch(setEditAd(ad))
  } else {
    dispatch(setEditAd(null))
  }
}
