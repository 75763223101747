import PropTypes from 'prop-types'
import React from 'react'
import { UserAds } from '../UserAds'
import { NearAds } from '../NearAds'

export const Container = ({username, seo}) =>
  <div className='sidebar-user-ostalo'>
    <UserAds username={username} seo={seo} />
    <NearAds />
  </div>

Container.displayName = 'Filters'

Container.propTypes = {
  seo: PropTypes.string,
  username: PropTypes.string
}
