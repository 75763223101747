import { CategoriesApi } from '../../api'
import { setCurrentSubCategory } from '../setCurrentSubCategory'
import { setCategoryBySeo } from '../setCategoryBySeo'
import { getCategoryBySeo } from '../../selectors'

export const fetchCategory = (options, withFilters = true) => {
  return async (dispatch, getState) => {
    const state = getState()
    let category = getCategoryBySeo(options.seo)(state)

    if (!category || (category && category.isWithFilters !== withFilters)) {
      const api = new CategoriesApi()
      const result = withFilters
        ? await api.fetchCategoryBySeoWithFilters(options)
        : await api.fetchCategoryBySeo(options)
      
      category = {
        id: result.id,
        title: result.title,
        seo: result.seo,
        group: result.group,
        parent: result.parent,
        icon: result.icon,
        iconSvg: result.iconSvg,
        weight: result.weight,
        requiredAdOptions: result.requiredAdOptions,
        fillerText: result.fillerText,
        compatibilityCount: result.compatibilityCount,
        condition: result.condition,
        specsShown: result.specsShown,
        swappPresence: result.swappPresence,
        hasShortForm: result.hasShortForm,
        allowPriceOnRequest: result.allowPriceOnRequest,
        compatibilityCategories: result.compatibilityCategories,
        compatibilityUniversality: result.compatibilityUniversality,
        compatibilityBrandsQuantity: result.compatibilityBrandsQuantity,
        compatibilityModelsQuantity: result.compatibilityModelsQuantity,
        compatibilityYears: result.compatibilityYears,
        registrationFormulas: result.registrationFormulas,
        isWithFilters: withFilters
      }

      if (withFilters) {
        category.filters = result.filters
        category.sortings = result.sortings
      }

      dispatch(setCategoryBySeo(options.seo, category))
    }

    if (Object.keys(category).length) {
      if (category.parent) {
        dispatch(setCurrentSubCategory(category))
      } else {
        dispatch(setCurrentSubCategory(null))
      }
    } else {
      dispatch(setCurrentSubCategory(null))
    }
  }
}
