import React from 'react'
import PropTypes from 'prop-types'
import { SubCategory } from '../../SubCategory'

export const Body = ({items, baseHref, baseAs}) => (
  <div className='kategorije-pretraga-mega-menu-content-item'>
    {
      items.map(item => [
        <SubCategory
          ico={item.icon}
          iconSvg={item.iconSvg}
          text={item.title}
          seo={item.seo}
          key={item.id}
          baseHref={baseHref}
          baseAs={baseAs}
        />,
        '\n'
      ])
    }
  </div>
)

Body.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    seo: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  })).isRequired,
  baseHref: PropTypes.string,
  baseAs: PropTypes.string
}

Body.defaultProps = {
  items: []
}
