import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from 'SRC/utils'
import { DropdownItem } from '../DropdownItem'

const getStringValue = val => val ? val.toString() : ''

const SelectAll = ({ selectLabels, allSelected, onClick }) => {
  if (!selectLabels) return null

  return (
    <span
      className={`select-all ${allSelected ? ' selected' : ''}`}
      onClick={onClick}
    >
      {allSelected ? selectLabels.unselect : selectLabels.select}
    </span>
  )
}

export const DropdownSubGroup = ({
  id,
  label,
  options,
  checkedItems,
  selectLabels,
  onChange,
  onItemChange
}) => {
  let allSelected = false
  const valuesOfSubGroupItems = Array.isArray(options) ? options.map(item => getStringValue(item.value)) : []

  if (Array.isArray(checkedItems) && checkedItems.length) {
    allSelected = valuesOfSubGroupItems.every(optionValue => checkedItems.includes(optionValue))
  }

  const toggleSelectAllSubGroupItems = () => {
    let newItems = [...checkedItems]

    if (allSelected) {
      newItems = checkedItems.filter(itemValue => !valuesOfSubGroupItems.includes(itemValue))
    } else {
      newItems = [...checkedItems, ...valuesOfSubGroupItems]
    }

    onChange(newItems)
  }

  return (
    <li key={`group-${id}`} className='select-dropdown-subgroup'>
      <header className='select-dropdown-subgroup-header'>
        <p>{label}</p>
        <SelectAll selectLabels={selectLabels} onClick={toggleSelectAllSubGroupItems} allSelected={allSelected} />
      </header>
      <ul className='select-dropdown-subgroup-items'>
        {options.map(option => (
          <DropdownItem
            key={`${option.id}-${option.label}`}
            option={option}
            checkedItems={checkedItems}
            onChange={onItemChange}
            {...option}
          />
        ))}
      </ul>
    </li>
  )
}

DropdownSubGroup.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  checkedItems: PropTypes.array.isRequired,
  selectLabels: PropTypes.shape({
    select: PropTypes.string,
    unselect: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  onItemChange: PropTypes.func.isRequired,
}

SelectAll.propTypes = {
  selectLabels: PropTypes.shape({
    select: PropTypes.string,
    unselect: PropTypes.string
  }),
  allSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
