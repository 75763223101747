import { capitalize } from 'SRC/utils/Utils'

export const getSortOptions = (categorySort = []) => {
  const sort = categorySort.slice().sort((a, b) => a.weight - b.weight)
  const options = []
  sort.forEach(item => {
    options.push({
      id: `${item.id}-asc`,
      label: `${capitalize(item.label)} - ${capitalize(item.asc)}`,
      value: JSON.stringify({
        specification: item.specification,
        order: 'asc'
      })
    })
    options.push({
      id: `${item.id}-desc`,
      label: `${capitalize(item.label)} - ${capitalize(item.desc)}`,
      value: JSON.stringify({
        specification: item.specification,
        order: 'desc'
      })
    })
  })
  return options
}
