import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from 'next-translate/withTranslation'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getAdsLoading } from 'SRC/modules/ads/list/selectors'
import { AdFilterEmpty, AdFilterShowDetailBtn } from 'SRC/ui/FormElementsNew'
import { AdditionalPreloader } from 'SRC/ui/Preloader'
import config from 'SRC/config/config.json'
import { getSpecificationVisibilityCondition } from 'SRC/modules/categories/functions'
import {
  Sort,
  CitiesFilter,
  ModelFilter,
  Compatibilities,
  Specification,
  Price,
  Condition,
  CategoriesFilter,
  CategoriesFilterMobile
} from '../../components'
import { getSpecFilterInputType, getSpecFilterValueProp } from '../../functions'
import { InputProcessingContext } from '../../context/inputProcessingContext'

class BasicForm extends React.Component {
  state = {
    inputProcessing: ''
  }

  getFiltersFields = () => {
    const { category } = this.props

    const fields = []

    const basicFiltersSorted = category && category.filters && category.filters.length
      ? category.filters
        .filter(categoryFilter => categoryFilter.rank === 'basic')
        .slice()
        .sort((a, b) => a.position - b.position)
      : []

    basicFiltersSorted.forEach(filter => {
      switch (filter.type) {
        case 'empty':
          fields.push({ type: filter.type })
          break
        case 'model':
        case 'compatibility':
          if (!fields.find(field => field.type === filter.type)) fields.push({ type: filter.type })
          break
        case 'property':
          if (filter.name === 'price') {
            fields.push({ type: 'price' })
          }
          if (filter.name === 'condition') {
            fields.push({ type: 'condition' })
          }
          break
        case 'specification':
          const inputType = getSpecFilterInputType(filter)

          let dependentRatio = null
          if (filter.specification.dependent && filter.specification.dependentType === 'same') {
            const findDependentFilter = basicFiltersSorted.find(f => {
              return f.specification && f.specification.id === filter.specification.dependent
            })

            if (findDependentFilter) {
              dependentRatio = findDependentFilter.specification.dependentRatio
            }
          }

          fields.push({
            type: 'specification',
            inputType,
            id: filter.specification.id,
            title: filter.specification.title,
            hint: filter.specification.hint,
            defaultValue: filter.specification.defaultValue,
            dependent: filter.specification.dependent,
            dependentValues: filter.specification.dependentValues,
            dependentRatio,
            dependentType: filter.specification.dependentType,
            visibilityCondition: getSpecificationVisibilityCondition(filter.specification),
            measure: filter.specification.measure,
            options: filter.specification.options.map(option => ({
              id: `option-${filter.specification.id}-${option}`,
              label: option,
              value: option
            })),
            allowedCharacters: filter.specification.allowedCharacters,
            placeholder: filter.specification.placeholder
          })
          break
        default: break
      }
    })

    return fields
  }

  setInputProcessing = inputId => this.setState({ inputProcessing: inputId })

  renderFields() {
    const { category } = this.props
    const fields = this.getFiltersFields()

    if (!Array.isArray(fields) || !fields.length) return null

    return fields.map(filter => {
      switch (filter.type) {
        case 'model': return <ModelFilter key={`model-filter-${filter.id}`} />
        case 'compatibility':
          if (category && category.compatibilityCategories && category.compatibilityCategories.length) {
            return <Compatibilities key={`comp-filter-${filter.id}`} />
          }
          return null
        case 'price':
          return <Price key={`price-filter-${filter.id}`} />
        case 'condition':
          return <Condition key={`condition-filter-${filter.id}`} />
        case 'specification':
          return <Specification key={`spec-filter-${filter.id}`} specification={filter} />
        case 'empty':
          return <AdFilterEmpty key={`empty-filter-${filter.id}`} />
        default: return null
      }
    })
  }

  render() {
    const {
      category,
      onSubmit,
      initialValues,
      onToggleFormStyle,
      adsLoading,
      baseAs,
      baseHrefQuery,
      isMobileDevice,
      i18n: { t }
    } = this.props

    const { inputProcessing } = this.state

    const fields = this.getFiltersFields()

    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        <Form className='polja-pretrage ads-filter'>
          {!isMobileDevice ? (
            <CategoriesFilter baseAs={baseAs} baseHrefQuery={baseHrefQuery} />
          ) : <CategoriesFilterMobile />}

          <InputProcessingContext.Provider value={[inputProcessing, this.setInputProcessing]}>
            {this.renderFields()}
          </InputProcessingContext.Provider>

          <CitiesFilter />
          <Sort />
          <div className='filter-buttons'>
            <AdFilterShowDetailBtn onClick={onToggleFormStyle} disabled={adsLoading} />
            <button className={`filter-buttons-search ${adsLoading ? 'disabled' : ''}`} disabled={adsLoading}>
              <span style={{ textTransform: 'uppercase' }}>{t('common:Pretraži')}</span> <i className='ico-pretraga-polja-lupa ico' />
            </button>
          </div>
        </Form>
      </Formik>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const category = getCurrentSubCategory(state)

  const initialValues = {}

  const existingFilterValues = ownProps.filterValues

  if (category) {
    /**
     * Brand, model, service, service type
     */
    initialValues.category = category.seo

    if (category.requiredAdOptions === 'BrandOnly') {
      initialValues.brands = existingFilterValues.brands || []
      initialValues.brandsText = existingFilterValues.brandsText || ''
      initialValues.brandBc = existingFilterValues.brandBc || {}
    }

    if (category.requiredAdOptions === 'BrandModel') {
      initialValues.brands = Array.isArray(existingFilterValues.brands)
        ? existingFilterValues.brands.map(item => `${item}`)
        : []

      initialValues.products = Array.isArray(existingFilterValues.products)
        ? existingFilterValues.products.map(item => `${item}`)
        : []

      initialValues.brandsText = existingFilterValues.brandsText || ''
      initialValues.productsText = existingFilterValues.productsText || ''
      initialValues.brandBc = existingFilterValues.brandBc || {}
      initialValues.modelBc = existingFilterValues.modelBc || {}
    }

    if (category.requiredAdOptions === 'Services') {
      initialValues.service = existingFilterValues.service || -1
      initialValues.serviceType = existingFilterValues.serviceType || -1

      initialValues.serviceText = existingFilterValues.serviceText || ''
      initialValues.serviceTypeText = existingFilterValues.serviceTypeText || ''

      initialValues.serviceBc = existingFilterValues.serviceBc || {}
      initialValues.serviceTypeBc = existingFilterValues.serviceTypeBc || {}
    }

    /**
     * Compatibility
     */
    if (category.compatibilityCategories && category.compatibilityCategories.length) {
      initialValues.compatibility = {
        category: (existingFilterValues.compatibility && existingFilterValues.compatibility.category) || -1,
        brand: (existingFilterValues.compatibility && existingFilterValues.compatibility.brand) || -1,
        product: (existingFilterValues.compatibility && existingFilterValues.compatibility.product) || -1,
        year: (existingFilterValues.compatibility && Number(existingFilterValues.compatibility.year)) || -1
      }

      initialValues.isUniversal = existingFilterValues.isUniversal || -1
    }

    /**
     * Specifications
     */
    if (category.filters && category.filters.length) {
      const specificationBasicFilters = category.filters.filter(categoryFilter => categoryFilter.rank === 'basic' &&
        categoryFilter.type === 'specification')

      if (specificationBasicFilters && specificationBasicFilters.length) {
        const initialSpecificationValues = {}

        let existingSpecificationValues = []

        if (Array.isArray(existingFilterValues.specifications)) {
          existingSpecificationValues = existingFilterValues.specifications.reduce((acc, curr) => {
            acc.push(curr)

            const specificationFilter = specificationBasicFilters.find(specFilter => specFilter.specification.id === curr.id)

            let dependents = []

            if (specificationFilter && specificationFilter.specification && specificationFilter.specification.dependent) {
              const depSpec = specificationFilter.specification
              if (depSpec.dependentType === 'show' && Array.isArray(depSpec.dependentValues) && depSpec.dependentValues.length) {
                dependents.push({
                  dependent: depSpec.dependent,
                  dependentValues: depSpec.dependentValues,
                  dependentRatio: depSpec.dependentRatio,
                  dependentType: depSpec.dependentType
                })
              }
            }

            if (dependents.length) {
              dependents.forEach(dep => {
                acc.push({
                  id: dep.dependent,
                  value: dep.dependentValues[0],
                  values: dep.dependentValues
                })
              })
            }

            return acc
          }, [])
        }

        specificationBasicFilters.forEach(specificationFilter => {
          const specId = specificationFilter.specification.id
          const inputType = getSpecFilterInputType(specificationFilter)
          const valueProp = getSpecFilterValueProp(inputType)

          let value = -1
          let valueFrom = -1
          let valueTo = -1

          if (Array.isArray(existingSpecificationValues) && existingSpecificationValues.length) {
            const findSpecification = existingSpecificationValues.find(spec => Number(spec.id) === Number(specId))

            if (inputType === 'inputRange') {
              if (findSpecification && findSpecification.range && Number(findSpecification.range.from) !== -1) {
                valueFrom = findSpecification.range.from
              }

              if (findSpecification && findSpecification.range && Number(findSpecification.range.to) !== -1) {
                valueTo = findSpecification.range.to
              }
            } else {
              if (findSpecification && findSpecification[valueProp] && findSpecification[valueProp] !== -1) {
                value = findSpecification[valueProp]
              }
            }
          }

          if (inputType === 'inputRange') {
            initialSpecificationValues[specId] = { rangeFrom: valueFrom, rangeTo: valueTo }
          } else {
            initialSpecificationValues[specId] = {
              [valueProp]: value
            }
          }
        })

        initialValues.specifications = initialSpecificationValues
      }
    }
  }

  /**
   * Country
   */
  initialValues.country = existingFilterValues.country || -1

  /**
   * Cities
   */
  initialValues.cities = existingFilterValues.cities || []
  initialValues.citiesText = existingFilterValues.citiesText || ''
  initialValues.cityBc  = existingFilterValues.cityBc || {}

  /**
   * Price
   */
  if (existingFilterValues && Array.isArray(existingFilterValues.price) && existingFilterValues.price.length) {
    initialValues.priceFrom = existingFilterValues.price[0] || -1
    initialValues.priceTo = existingFilterValues.price[1] || -1
  } else {
    initialValues.priceFrom = -1
    initialValues.priceTo = -1
  }

  initialValues.condition = existingFilterValues.condition || -1

  /**
   * Sort
   */
  if (existingFilterValues.sortBy) {
    initialValues.sortBy = typeof existingFilterValues.sortBy === 'object'
      ? JSON.stringify(existingFilterValues.sortBy)
      : existingFilterValues.sortBy
  } else {
    initialValues.sortBy = 'dateDesc'
  }

  return {
    category,
    adsLoading: getAdsLoading(state),
    initialValues,
    isMobileDevice: getIsMobileDevice(state)
  }
}

BasicForm.propTypes = {
  baseAs: PropTypes.string,
  baseHrefQuery: PropTypes.object,
  category: PropTypes.shape({
    fillerText: PropTypes.string,
    requiredAdOptions: PropTypes.string,
    compatibilityCategories: PropTypes.array,
    filters: PropTypes.array
  }),
  adsLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggleFormStyle: PropTypes.func.isRequired
}

BasicForm.defaultProps = {
  baseAs: '/',
  baseHrefQuery: {}
}

const enhance = compose(
  withTranslation,
  connect(mapStateToProps)
)

export default enhance(BasicForm)
