export const phoneMask = {
  mask: '80 000 0000[0]',
  definitions: {
    '8': /[1-9]/
  },
  placeholderChar: ''
}

export const phoneMaskNoSpace = {
  mask: '800000000[0]',
  definitions: {
    '8': /[1-9]/
  },
  placeholderChar: ''
}
