import PropTypes from 'prop-types'
import React from 'react'
import { connect as formikConnect } from 'formik'
import { CheckboxNew, AdFilterField } from 'SRC/ui/FormElementsNew'

class Group extends React.Component {
  getItems = () => {
    const { items, formik, groupCode } = this.props

    const checkedEquipment = formik.values.equipment[groupCode]

    if (checkedEquipment && checkedEquipment.length) {
      return items.map(item => ({
        ...item,
        isChecked: checkedEquipment.includes(item.id)
      }))
    }

    return items
  }

  onItemChange = e => {
    const { formik, groupCode } = this.props
    const value = Number(e.target.dataset.value)

    const currentEquipmentByGroup = [...formik.values.equipment[groupCode]]

    if (currentEquipmentByGroup.includes(value)) {
      formik.setFieldValue('equipment', {
        ...formik.values.equipment,
        [groupCode]: currentEquipmentByGroup.filter(item => item !== value)
      })
    } else {
      formik.setFieldValue('equipment', {
        ...formik.values.equipment,
        [groupCode]: [...currentEquipmentByGroup, value]
      })
    }
  }

  render () {
    const { title, groupCode } = this.props

    const items = this.getItems()

    return (
      items.length
        ? [
          <div className='oglas-form-header' key={`header-${groupCode}`}>
            <p>{title}</p>
          </div>,
          items.map(item => <AdFilterField key={item.id}>
            <CheckboxNew {...item} onChange={this.onItemChange} />
          </AdFilterField>)
        ]
        : null
    )
  }
}

Group.propTypes = {
  groupCode: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  formik: {
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }.isRequired
}

Group.defaultProps = {
  items: []
}

export default formikConnect(Group)
