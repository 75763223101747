import { LocationsApi } from '../../api'
import { setLocationsByCity, setLocationsLoading } from '../../actions'

export const fetchLocationsByCity = (cityId, categoryId = null) => {
  return async (dispatch) => {
    if (!cityId) {
      dispatch(setLocationsByCity({ city: cityId, locations: [] }))
    } else {
      dispatch(setLocationsLoading(true))
      const api = new LocationsApi()
      const locations = await api.getLocationsByCity(cityId, categoryId)
      dispatch(setLocationsByCity({ city: cityId, locations }))
      dispatch(setLocationsLoading(false))
    }
  }
}
