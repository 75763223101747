import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ErrorMessage } from 'formik'
import { IMaskInput } from 'react-imask'
import { AsYouType } from 'libphonenumber-js'
import { debounce } from 'throttle-debounce'
import { phoneMask, phoneMaskUSA } from 'Core/masks'
import { Wrapper } from '../Wrapper'

const getMask = dial => {
  if (dial.toString().length === 4 && dial.toString().startsWith('1')) {
    return phoneMaskUSA
  }

  return phoneMask
}

export const PhoneInput = ({ label, isFullWidth, dialClass, dial, inputClass, field: { name }, form, isRequired, isDisabled }) => {
  return <Wrapper isError={form.touched[name] && form.errors[name]} isFullWidth={isFullWidth}>
    <label>{label} {isRequired ? <span className='required'>*</span> : null}</label>
    <div className='form-telefon__container'>
      <span className={dialClass}>{`+${dial}`}</span>
      <IMaskInput
        {...getMask(dial)}
        disabled={isDisabled || form.isSubmitting}
        className={inputClass}
        type='text'
        name={name}
        onAccept={(value) => {
          if (value) form.setFieldTouched(name, true)
          else form.setFieldTouched(name, false)
          form.setFieldValue(name, value.replace(dial, ''))
        }}
        value={form.values[name]}
      />
    </div>
    <ErrorMessage name={name} render={msg => <span className='form-group__error-message'>{msg}</span>} />
  </Wrapper>
}

export const PhoneInputWithSelectDial = ({
  countries,
  label,
  isRequired,
  isDisabled,
  isFullWidth,
  showStar,
  isAddAdExistingUser,
  isAddAdNewUser,
  dialClass,
  inputClass,
  wrapperClass,
  field: { name },
  form,
  onMouseEnter,
  onFocus
}) => {
  let dial = form.values.dial ? `+${form.values.dial}` : ''
  if (isAddAdExistingUser) {
    dial = form.values.existingUser && form.values.existingUser.dial ? form.values.existingUser.dial : ''
  }

  if (isAddAdNewUser) {
    dial = form.values.newUser && form.values.newUser.dial ? form.values.newUser.dial : ''
  }

  const [selectedCountryId, setSelectedCountry] = useState(getSelectedCountryByDial(countries, dial))

  let labelDial = `+${dial.replace('+', '')}`

  let value = form.values

  name.split('.').forEach(namePart => { value = value ? value[namePart] : null })

  return (
    <Wrapper isError={form.touched[name] && form.errors[name]} isFullWidth={isFullWidth} className={wrapperClass}>
      <label>{label}: {isRequired && showStar ? <span className='required'>*</span> : null}</label>
      {/* <span className={dialClass}>{`+${form.values.dial}`}</span> */}
      <div className='select-wrap' style={{ border: '1px solid #dcdae5' }}>
        <label>{labelDial}</label>
        <select
          className='dial-select'
          onChange={e => {
            const country = e.target.value

            const parentChildCountryPair = country.split('_')

            if (parentChildCountryPair.length > 1) {
              const parentCountry = countries.find(c => Number(c.id) === Number(parentChildCountryPair[0]))

              if (parentCountry) {
                const findCountry = parentCountry.options.find(c => Number(c.id) === Number(parentChildCountryPair[1]))

                if (findCountry) {
                  setSelectedCountry(`${parentCountry.id}_${findCountry.id}`)
                  if (isAddAdNewUser) form.setFieldValue('newUser.dial', findCountry.dial)
                  else if (isAddAdExistingUser) form.setFieldValue('existingUser.dial', findCountry.dial)
                  else form.setFieldValue('dial', findCountry.dial)

                  form.setFieldValue(name, '')
                }
              }
            } else {
              const findCountry = countries.find(c => Number(c.id) === Number(country))

              if (findCountry) {
                setSelectedCountry(findCountry.id)
                if (isAddAdNewUser) form.setFieldValue('newUser.dial', findCountry.dial)
                else if (isAddAdExistingUser) form.setFieldValue('existingUser.dial', findCountry.dial)
                else form.setFieldValue('dial', findCountry.dial)

                form.setFieldValue(name, '')
              }

            }
          }}
          value={selectedCountryId}
        >
          {countries && countries.length ? countries.map(country => {
            if (country.isGrouped) {
              return <optgroup id={country.id} label={country.label}>
                {country.options && country.options.length
                  ? country.options.map(childCountry => (
                    <option
                      key={`country-${childCountry.id}`}
                      id={`${country.value}_${childCountry.value}`}
                      value={`${country.value}_${childCountry.value}`}
                    >
                      {childCountry.label}
                    </option>
                  ))
                  : null
                }
              </optgroup>
            } else {
              return <option key={country.id} value={country.value}>{country.label}</option>
            }
          }) : null}
        </select>
      </div>

      <input
        disabled={form.isSubmitting}
        className={inputClass}
        type='text'
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
        value={value}
        onChange={async e => {
          let phoneValue = e.target.value

          if (phoneValue.charAt(0) === '0') phoneValue = phoneValue.substring(1)
          const phoneNumber = `+${dial.replace('+', '')}${phoneValue.trim().replace(/ /g, '')}`

          const parsedPhoneNumber = new AsYouType().input(phoneNumber)
          const [_dial, ...rest] = parsedPhoneNumber.split(' ')
          const formattedPhone = rest.join(' ')
          await form.setFieldValue(name, formattedPhone)

          const fieldmeta = form.getFieldMeta(name)
          if (!fieldmeta || !fieldmeta.touched) form.setFieldTouched(name, true)
        }}
      />

      <ErrorMessage
        name={name}
        render={msg => (
          <span
            style={{ display: 'block', marginTop: 5, color: '#e91c23' }}
            className='form-group__error-message'
          >
            {msg}
          </span>
        )}
      />
    </Wrapper>
  )
}

const getSelectedCountryByDial = (countries, dial) => {
  if (Array.isArray(countries) && dial) {
    const country = countries.find(c => c.dial === dial)

    if (country) {
      return Array.isArray(country.options) && country.options.length ? `${country.id}_${country.options[0].id}` : country.id
    }

    return 1
  }
  return 1
}

PhoneInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  dial: PropTypes.number,
  inputClass: PropTypes.string.isRequired,
  dialClass: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool
}

PhoneInput.defaultProps = {
  inputClass: 'form-group__phone',
  dialClass: 'form-group__mask',
  isRequired: true,
  isDisabled: false,
  isFullWidth: false
}

PhoneInputWithSelectDial.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  countries: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  inputClass: PropTypes.string.isRequired,
  dialClass: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onFocus: PropTypes.func
}

PhoneInputWithSelectDial.defaultProps = {
  inputClass: 'form-group__phone',
  dialClass: 'form-group__mask',
  isRequired: true,
  isDisabled: false,
  isFullWidth: false
}
