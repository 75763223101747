import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getGroups, getCurrentGroup } from 'SRC/modules/groups/selectors'
import { Item } from '../Item'

const List = ({groups, currentGroup}) => {
  return (
    <div className='category-items'>
      {groups && Array.isArray(groups) ? groups.map(item => {
        return (
          <Item
            {...item}
            currentGroup={currentGroup}
            key={item.id}
          />
        )
      }) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    groups: getGroups(state),
    currentGroup: getCurrentGroup(state)
  }
}

List.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    icon: PropTypes.string,
    seo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  currentGroup: PropTypes.shape({
    categories: PropTypes.array,
    seo: PropTypes.string
  })
}

List.defaultProps = {
  groups: []
}

export default connect(mapStateToProps)(List)
