import PropTypes from 'prop-types'
import React from 'react'
import { UserContactsFree } from '../Free/index'
import { UserContactsBusiness } from '../Business/index'

export const Container = ({ user, adStatus }) =>
  user && user.type === 'business' ? (
    <UserContactsBusiness user={user} adStatus={adStatus} />
  ) : (
    <UserContactsFree user={user} adStatus={adStatus} />
  )

Container.propTypes = {
  user: PropTypes.shape({type: PropTypes.oneOf(['free', 'business']).isRequired}).isRequired,
  adStatus: PropTypes.string
}

Container.displayName = 'UserContacts'
