import React from 'react'
import { AdFormHeader } from 'SRC/ui/FormElementsNew'
import useTranslation from 'next-translate/useTranslation'
import { FirstRow } from '../FirstRow'
import { SecondRow } from '../SecondRow'

export const Container = () => {
  const { t } = useTranslation('common')

  return (
    <React.Fragment>
      <AdFormHeader key='header' title={`${t('Cijena')} / ${t('Stanje')} / ${t('Prodavac')} / ${t('Lokacija')}`} />
      <FirstRow key='first-row' />
      <SecondRow key='second-row' />
    </React.Fragment>
  )
}
