import { CitiesApi } from '../../api'
import { getCurrentLocale } from 'SRC/modules/common/functions'
import { getCitiesByCountryWithinRegions } from '../../selectors'
import { setCitiesLoading, setCitiesByCountryWithinRegions } from '../../actions'

import citiesMEWithinRegions from '../../../../../../public/data/citiesMEWithinRegions.json'

export const fetchCitiesByCountryWithinRegions = (country, locale = getCurrentLocale()) => {
  return async (dispatch, getState) => {
    if (!country) {
      dispatch(setCitiesByCountryWithinRegions([]))
    } else {
      dispatch(setCitiesLoading(true))
      const state = getState()

      let cities = getCitiesByCountryWithinRegions(state, country)[country]

      if (locale === 'ME' && Number(country) === 1) cities = citiesMEWithinRegions

      if (!cities || !Array.isArray(cities) || !cities.length) {
        const api = new CitiesApi()
        const result = await api.getCitiesByCountryWithinRegions(country, locale)
        dispatch(setCitiesByCountryWithinRegions(country, result))
      } else {
        dispatch(setCitiesByCountryWithinRegions(country, cities))
      }
      dispatch(setCitiesLoading(false))
    }
  }
}
