import { BaseApi } from 'Core/api/BaseApi'
import { getCurrentLocale } from 'SRC/modules/common/functions'

export class Api extends BaseApi {
  getCountries = async (locale = getCurrentLocale()) => {
    const defaultResult = []
    try {
      const {body} = await Api.post('/country/list', { locale })
      if (!body.error && body.countries && Array.isArray(body.countries)) {
        return body.countries.map(item => ({
          id: item.id,
          label: item.name,
          value: item.id,
          abr: item.abr,
          domain: item.domain,
          dial: item.dial,
          parent: item.parent
        }))
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }
}
