import initialState from '../../../redux/initialState'
import actionTypes from '../../../redux/actionTypes'

export const authReducer = (state = initialState.user.auth, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_AUTHORIZED_USER:
      return {
        ...state,
        isAuthorized: action.payload
      }
    case actionTypes.SET_AUTH_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case actionTypes.SET_AUTH_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          login: action.payload,
          loginInPopup: state.formErrors.loginInPopup
        }
      }
    case actionTypes.SET_AUTH_POPUP_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          login: state.formErrors.login,
          loginInPopup: action.payload
        }
      }
    case actionTypes.SET_AUTH_ERROR:
      return {...state, error: action.payload}
    default:
      return state
  }
}
