const replaceLetters = [['š', 's'], ['đ', 'dj'], ['č', 'c'], ['ć', 'c'], ['ž', 'z']]
const excludeWords = [
  'od', 'iz', 'sa', 'do', 'bez', 'blizu', 'blizini', 'kod', 'kraj', 'iznad', 'između', 'izmedju', 'oko', 'okolini',
  'pored', 'ispod', 'ispred', 'k', 'ka', 'prema', 'nasuprot', 'uz', 'niz', 'u', 'kroz', 'na', 'za',
  's', 'nad', 'pod', 'među', 'medju', 'po', 'o', 'pri'
]

const citiesAdditional = {
  'bar': ['baru', 'bara'],
  'budva': ['budvi', 'budve', 'budvu'],
  'herceg novi': ['herceg novom', 'herceg novog'],
  'kotor': ['kotoru', 'kotora'],
  'tivat': ['tivtu', 'tivta'],
  'ulcinj': ['ulcinju', 'ulcinja'],
  'cetinje': ['cetinju', 'cetinja'],
  'danilovgrad': ['danilovgradu', 'danilovgrada'],
  'niksic': ['niksicu', 'niksica'],
  'podgorica': ['podgoricu', 'podgorici', 'podgorice'],
  'andrijevica': ['andrijevicu', 'andrijevici', 'andrijevice'],
  'berane': ['beranama', 'berana'],
  'bijelo polje': ['bijelom polju', 'bijelog polja'],
  'gusinje': ['gusinju', 'gusinja'],
  'kolasin': ['kolasinu', 'kolasina'],
  'mojkovac': ['mojkovcu', 'mojkovca'],
  'petnjica': ['petnjici', 'petnjice', 'petnjicu'],
  'zabljak': ['zabljaku', 'zabljaka'],
  'plav': ['plavu', 'plava'],
  'pljevlja': ['pljevljima', 'pljevalja'],
  'pluzine': ['pluzinama', 'pluzina'],
  'rozaje': ['rozajama', 'rozaja'],
  'savnik': ['savnika', 'savniku']
}

let citiesKeys = []

let searchableArray = {}
let foundCity = null

const getSearchTerm = text => {
  let searchTerm = text.trim().toLowerCase()

  const words = []

  searchTerm.split(' ').filter(w => w).forEach(word => {
    const trimmedWord = word.trim()
    if (!excludeWords.includes(trimmedWord)) {
      words.push(trimmedWord)
    }
  })

  searchTerm = words.join(' ')

  replaceLetters.forEach(letter => {
    searchTerm = searchTerm.replaceAll(letter[0], letter[1])
  })

  return searchTerm
}

export const getSearchableArray = (categories, options = {}) => {
  console.log('options', options)
  searchableArray = { categories: [] }
  foundCity = null
  citiesKeys = Array.isArray(citiesKeys) && citiesKeys.length ? citiesKeys : []

  if (Array.isArray(options.cities) && options.cities.length) {
    searchableArray.cities = {}

    options.cities.forEach(city => {
      const key = getSearchTerm(city.label)

      citiesKeys.push(key)

      searchableArray.cities[key] = city

      if (Array.isArray(citiesAdditional[key])) {
        citiesAdditional[key].forEach(cityKey => {
          searchableArray.cities[cityKey] = city
          citiesKeys.push(cityKey)
        })
      }
    })
  }

  return searchableArray
}

export const searchCities = (searchableArray, text) => {
  if (text.length < 3) return null

  foundCity = null

  let searchTerm = getSearchTerm(text)

  if (searchableArray.cities && Array.isArray(citiesKeys)) {
    for (let cityKey of citiesKeys) {
      let findIndex = searchTerm.indexOf(cityKey)

      if (findIndex !== -1) {
        foundCity = searchableArray.cities[cityKey]
      }
    }
  }

  return foundCity
}
