import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import config from 'SRC/config/config.json'
import { useOnScreen } from 'SRC/modules/common/hooks'
import { imagesCDN } from 'src/core/constants'
import { getUsersWithRandomOrder, getUserProAddon } from 'SRC/modules/layout/Sidebar/BusinessUsersSmsAds/functions/functions'
import { AdditionalPreloader } from 'SRC/ui/Preloader'
import { getUserTypes } from 'SRC/modules/common/selectors'
import { getUserType } from 'SRC/modules/users/list/selectors'
import { getSidebarUsers, getSidebarUsersLoading } from 'SRC/modules/users/listSidebar/selectors'
import { Header } from 'SRC/modules/layout/Sidebar/shared/components'
import { Item } from '../Item'
import { Footer } from '../Footer'

const userTypesSmsAdsConfig = config.sidebar.userTypesSmsAds

let usersRenewalTimer = null

const Items = ({ processing, users }) => {
  if (processing) return <AdditionalPreloader />

  if (!Array.isArray(users) || !users.length) return <div className='sidebar-items'><h3>Nema korisnika</h3></div>

  return (
    <div className='sidebar-items'>
      {users.map(user => <Item key={user.id} user={user} />)}
    </div>
  )
}

const List = ({
  listId,
  sidebarUsers,
  usersLoading,
  userType,
  userTypes,
  allUserTypes,
  title,
  info
}) => {
  const ref = React.useRef()
  const isVisible = useOnScreen(ref)

  const getUserTypePairs = () => {
    if (userType && userTypesSmsAdsConfig.display[userType.seo]) {
      return userTypesSmsAdsConfig.display[userType.seo].filter(item => item.userTypes)
    }

    return userTypesSmsAdsConfig.display.default.filter(item => item.userTypes)
  }

  const [users, setUsers] = React.useState([])
  const [processing, setProcessing] = React.useState(true)

  React.useEffect(() => {
    if (!usersLoading) {
      loadUsersIntoState()

      usersRenewalTimer = setInterval(() => {
        loadUsersIntoState()
      }, userTypesSmsAdsConfig.usersListRenewalInterval)
    }
  }, [usersLoading])

  const loadUsersIntoState = () => {
    if (!sidebarUsers || !Object.keys(sidebarUsers).length) return
    const userTypePairs = getUserTypePairs()

    const usersPair = Array.isArray(userTypePairs) ? userTypePairs.find(up => up.key === listId) : null

    if (!usersPair) return

    const userTypesPair = Array.isArray(usersPair.userTypes) && Array.isArray(allUserTypes)
      ? usersPair.userTypes.reduce((acc, curr) => {
        
        acc.push(allUserTypes.find(ut => ut.seo === curr))
        return acc
      }, [])
      : []

    setProcessing(true)
    let newUsers = []

    if (!Array.isArray(userTypesPair) || !userTypesPair.length) return

    for (const type of userTypesPair) {
      if (!type || !type.id) continue

      const usersByType = sidebarUsers && Array.isArray(sidebarUsers[type.id]) ? sidebarUsers[type.id] : null

      if (!Array.isArray(usersByType)) continue

      const usersWithUrl = usersByType.map(user => ({
        ...user,
        url: {
          href: {
            pathname: 'user',
            query: {
              userType: type.seo,
              username: user.username
            }
          },
          as: `/${type.seo}/${user.username}`
        }
      }))

      if (usersWithUrl.length) {
        const premiumUsers = filterPremiumUsers(usersWithUrl)
        newUsers.push(...premiumUsers)
      }
    }

    newUsers = getUsersWithRandomOrder(newUsers, userTypesSmsAdsConfig.usersCountPerPair)

    setUsers(newUsers)
    setProcessing(false)
  }

  const getUserTypeBySeo = seo => {
    const userType = userTypes.filter(userType => userType.seo === seo)

    if (userType.length) return userType[0]

    return null
  }

  const filterPremiumUsers = users => {
    return users.filter(user => {
      const proAddon = getUserProAddon(user)
      return proAddon && proAddon.name !== userTypesSmsAdsConfig.proAddonName
    })
  }

  const getFooterUserTypes = () => {
    if (Array.isArray(allUserTypes) && Array.isArray(userTypes)) {
      return userTypes.reduce((acc, curr) => {
        acc.push(allUserTypes.find(ut => ut.seo === curr))

        return acc
      }, [])
    }

    return []
  }

  return (
    <div className='izdvojeno-iz-ponude' ref={ref}>
      <Header title={title} />

      <React.Fragment>
        <div className='sidebar-content'>
          <div className='sidebar-info'>
            <p>{info}</p>
            <i className='ico-telefonska-podrska ico' />
          </div>

          <Items processing={processing} users={users} />
  
          <Footer userTypes={getFooterUserTypes()} />
        </div>
      </React.Fragment>
    </div>
  )
}

const mapStateToProps = (state) => {
  const userTypes = getUserTypes(state)

  return {
    allUserTypes: userTypes.filter(type => type.seo !== 'korisnik'),
    userType: getUserType(state),
    sidebarUsers: getSidebarUsers(state),
    usersLoading: getSidebarUsersLoading(state)
  }
}

List.displayName = 'BusinessUsersList'

List.propTypes = {
  listId: PropTypes.string.isRequired,
  userTypes: PropTypes.array.isRequired,
  allUserTypes: PropTypes.array.isRequired,
  userType: PropTypes.shape({ seo: PropTypes.string }),
  sidebarUsers: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  usersLoading: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(List)
