import PropTypes from 'prop-types'
import React from 'react'
import { PageHeader } from 'SRC/ui/PageHeader'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { connect } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import { getPaginationParams } from 'SRC/modules/ads/pagination/selectors'
import { getCurrentSubCategory, getCategories } from 'SRC/modules/categories/selectors'
import { fetchCategories } from 'SRC/modules/categories/actions'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { UserAdsCategoryFilterMobile } from '../UserAdsCategoryFilterMobile'
import { Form } from '../../Form'

export const UserAdsFilter = ({
  info,
  paginationParams,
  filterValues,
  formStyle,
  category,
  categories,
  fetchCategories,
  isMobileDevice
}) => {
  const { t } = useTranslation('common')

  React.useEffect(() => {
    if (!Array.isArray(categories) || !categories.length) fetchCategories({})
  }, [])

  const isRenderAvailable = info && info.type && paginationParams
  const baseAs = isRenderAvailable ? `/${info.type.seo}/${info.seo || info.username}/` : `/`
  const baseHrefQuery = isRenderAvailable ? { username: info.seo || info.username } : {}

  return isRenderAvailable
    ? (
      <div className='kategorije clearfix'>
        <PageHeader>{info.name}</PageHeader>
        <div className='pretraga-oglasa-prodavnice-header clearfix'>
          <p className='heading'>{info.name}</p>
          <p className='broj-prodavnica'>{t('Broj oglasa')} <span>{paginationParams.totalCount}</span></p>
        </div>
        {!isMobileDevice ? (
          <div className={`kategorije-pretraga ${category ? `kategorije-pretrage-mobilni` : ``}`}>
            <Form
              baseAs={baseAs}
              baseHrefQuery={baseHrefQuery}
              currentPage={paginationParams.currentPage}
              filterValues={filterValues}
              formStyle={formStyle}
              currentSubcategory={category}
            />
          </div>
        ) : (
          <div className='kategorije-pretraga kategorije-pretrage-mobilni'>
            <UserAdsCategoryFilterMobile baseAs={baseAs} baseHrefQuery={baseHrefQuery} filterValues={filterValues} />
          </div>
        )}
      </div>
    ) : null
}

const mapStateToProps = state => ({
  info: getInfo(state),
  paginationParams: getPaginationParams(state),
  category: getCurrentSubCategory(state),
  categories: getCategories(state),
  isMobileDevice: getIsMobileDevice(state)
})

UserAdsFilter.propTypes = {
  info: PropTypes.shape({
    type: PropTypes.shape({
      seo: PropTypes.string.isRequired,
      namePlu: PropTypes.string.isRequired
    }).isRequired,
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  paginationParams: PropTypes.shape({
    totalCount: PropTypes.number,
    currentPage: PropTypes.number
  }).isRequired,
  filterValues: PropTypes.object,
  isMobileDevice: PropTypes.bool.isRequired,
  formStyle: PropTypes.string,
  category: PropTypes.object
}

export default connect(mapStateToProps, { fetchCategories })(UserAdsFilter)
