export const extractSimilarWords = categories => {
  let similarWords = []

  const categoriesWithSimilarWords = categories.filter(c => c.similarWords && c.similarWords.length)

  for (let category of categoriesWithSimilarWords) {
    const categorySimilarWords = Array.isArray(category.similarWords)
      ? category.similarWords
      : JSON.parse(category.similarWords)
    categorySimilarWords.push(category.title)

    similarWords = similarWords.concat(categorySimilarWords.map(word => ([word, category.id])))
  }

  return similarWords
}

export const getSpecificationVisibilityCondition = specification => {
  const visibilityCondition = {
    show: null,
    hide: null
  }

  let specDependent = specification ? specification.dependent : null

  if (specDependent) {
    if (['hide', 'show'].includes(specification.dependentType) && Array.isArray(specification.dependentValues)) {
      if (!visibilityCondition[specification.dependentType]) {
        visibilityCondition[specification.dependentType] = {
          [specDependent]: specification.dependentValues
        }
      } else {
        visibilityCondition[specification.dependentType][specDependent] = specification.dependentValues
      }
    }
  }

  return visibilityCondition
}
