import Cookies from 'universal-cookie'

/** @TODO add index file for class, add tests **/
export class CookiesHelper {
  constructor () {
    this._cookies = new Cookies()
  }

  set = (name, value, options = {}) => {
    this._cookies.set(name, value, {
      path: '/',
      secure: false,
      domain: process.env.NEXT_PUBLIC_NAME === 'development' ? 'localhost' : '.autodiler.me',
      ...options
    })
  }

  get = (key, options = {}) => {
    return this._cookies.get(key, options)
  }

  remove = (name, options = {}) => {
    this._cookies.remove(name, {
      path: '/',
      secure: false,
      domain: process.env.NEXT_PUBLIC_NAME === 'development' ? 'localhost' : '.autodiler.me',
      ...options
    })
  }
}
