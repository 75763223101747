import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from 'SRC/utils'

import { DropdownItem } from '../DropdownItem'

const getGroupOptions = (options, filter) => {
  if (filter) {
    return options.filter(option => {
      const optionLabel = normalizeString(option.label.toLowerCase())
      return optionLabel.indexOf(filter.toLowerCase().normalize()) > -1
    })
  }

  return options
}

// rest value, onSelectDropdownChange, scrollToSelectedItem
export const DropdownGroup = ({ group, groupIndex, filter, ...restProps }) => {
  const groupOptions = group && Array.isArray(group.options) ? getGroupOptions(group.options, filter) : []

  return (
    <li className='select-dropdown-group'>
      <label className='select-dropdown-group-label'>{group.label}</label>

      <ul className='select-dropdown-group-items'>
        {groupOptions.map((option, index) => {
          const isFirstOption = groupIndex === 0 && index === 0

          return (
            <DropdownItem
              key={`${option.id}-${option.label}`}
              item={option}
              isFirstItem={isFirstOption}
              {...restProps}
            />
          )
        })}
      </ul>
    </li>
  )
}

DropdownGroup.propTypes = {
  group: PropTypes.shape({
    options: PropTypes.array,
    label: PropTypes.string
  }).isRequired,
  groupIndex: PropTypes.number.isRequired,
  filter: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSelectDropdownChange: PropTypes.func.isRequired
}
