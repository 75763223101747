import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Item } from '../Item'
import { headerTopLinks } from '../data/headerTopLinks'

export const Common = ({ router }) => {
  const { t } = useTranslation('common')

  return (
    <ul className='header-links__list'>
      {headerTopLinks.map(link => {
        return [
          <Item {...link} key={link.id} isActive={link.link && router && router.pathname === link.link.href}>
            {t(link.text)}
          </Item>,
          '\n'
        ]
      })}
    </ul>
  )
}

Common.propTypes = {
  router: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
}
