import initialState from '../../../redux/initialState'
import actionTypes from '../../../redux/actionTypes'

export const citiesReducer = (state = initialState.geoObjects.cities, action) => {
  switch (action.type) {
    case actionTypes.SET_CITIES:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_CITIES_BY_COUNTRY:
      return {
        ...state,
        byCountry: {
          ...state.byCountry,
          [action.payload.country]: action.payload.cities
        }
      }
    case actionTypes.SET_CITIES_BY_COUNTRY_WITHIN_REGIONS:
      return {
        ...state,
        byCountryWithinRegions: {
          ...state.byCountryWithinRegions,
          [action.payload.country]: action.payload.cities
        }
      }
    case actionTypes.SET_CITIES_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}
