import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'
import { getCurrentLocale } from 'SRC/modules/common/functions'

export class Api extends BaseApi {
  fetchEquipmentByCategory = async (category, locale = getCurrentLocale()) => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(category)) {
      try {
        const {body} = await Api.post(`/equipment/listByCategory`, {category, locale})
        if (!body.error && body.equipment && Array.isArray(body.equipment)) {
          return body.equipment
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }
}
