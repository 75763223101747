import PropTypes from 'prop-types'
import React from 'react'
import { formatLocationForTableView } from '../../../functions/functions'
import { adsListViews } from 'Core/constants'

export const Place = ({location, style}) => (
  <div className='oglasi-mesto'>
    <p>
      <i className='ico-lokacija ico' />
      {style === adsListViews.TABLE ? formatLocationForTableView(location) : <span>{location}</span>}
    </p>
  </div>
)

Place.propTypes = {
  location: PropTypes.string,
  style: PropTypes.oneOf([
    adsListViews.TABLE,
    adsListViews.LIST
  ]).isRequired
}
