import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const listReducer = (state = initialState.discountCard.partners.list, action) => {
  switch (action.type) {
    case actionTypes.SET_PARTNERS:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_PARTNERS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case actionTypes.SET_PARTNERS_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      }
    case actionTypes.SET_PARTNERS_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    default:
      return state
  }
}
