import initialState from '../../../redux/initialState'
import actionTypes from '../../../redux/actionTypes'

export const countriesReducer = (state = initialState.geoObjects.countries, action) => {
  switch (action.type) {
    case actionTypes.SET_COUNTRIES:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_COUNTRIES_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}
