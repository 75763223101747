import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getCitiesByCountry } from 'SRC/modules/geoObjects/cities/selectors'
import config from 'SRC/config/config.json'

import translations from '../../../../../public/data/translations.json'

const getSortedCities = cities => {
	return cities.slice().sort((c1, c2) => {
		if (c1.label < c2.label) return -1
		if (c1.label > c2.label) return 1
		return 0
	})
}

const QuickSearch = ({ currentSubcategory, cities }) => {
  const { t, lang } = useTranslation('common')
	
	const locale = lang?.toUpperCase() || 'ME'

	if (!currentSubcategory || !Array.isArray(cities) || !cities.length) return null

	return (
		<div className='oglasi-opis'>
			<div className='oglasi-info-heading'>
				<p>{t('Brza pretraga')}</p>
			</div>
			<section className='quick-search'>
				{getSortedCities(cities).map(city => (
					<Link
						key={`${currentSubcategory.seo}_${city.seo}`}
						href={{
							pathname: '/search',
							query: {
								category: currentSubcategory.seo,
								locale: lang,
								'0': [`${translations.grad[locale]}-${city.seo}`]
							}
						}}
						as={`/${translations.oglasi[locale]}/${currentSubcategory.seo}/${translations.grad[locale]}-${city.seo}`}
					>
						<a>
							{currentSubcategory.title} {city.label}
						</a>
					</Link>
				))}
			</section>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		currentSubcategory: getCurrentSubCategory(state),
		cities: getCitiesByCountry(state, config.globalOptions.MontenegroId)
	}
}

QuickSearch.propTypes = {
	currentSubcategory: PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
		seo: PropTypes.string
	}),
	cities: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		label: PropTypes.string,
		seo: PropTypes.string
	})),
	page: PropTypes.oneOf(['ad'])
}

export default connect(mapStateToProps)(QuickSearch)
