import { AdsDetailApi } from '../../api'
import { setRegistrationFormulas } from '../setRegistrationFormulas'

export const fetchRegistrationFormulas = () => async (dispatch, getState) => {
  const registrationFormulas = getState().ads.detail.registrationFormulas

  console.log('registrationFormulas state', registrationFormulas)

  if (!Array.isArray(registrationFormulas) || !registrationFormulas.length) {
    const api = new AdsDetailApi()
    const formulas = await api.getRegistrationFormulas()
    dispatch(setRegistrationFormulas(formulas))
  }
}
