import { CommonApi } from '../../api'
import { setFormLoading } from '../setFormLoading'
import { setFormResult } from '../setFormResult'

export const submitForm = (fields, type = 'add') => async dispatch => {
  dispatch(setFormLoading(true))
  const api = new CommonApi()
  let result
  if (type === 'add') {
    result = await api.submitAddForm(fields)
  } else {
    result = await api.submitUpdateForm(fields)
  }

  if (result) {
    dispatch(setFormResult(result))
  }

  dispatch(setFormLoading(false))
}
