import PropTypes from 'prop-types'
import React from 'react'

export const PageContent = ({children, additionalClass}) => (
  <div className={`registracija-content ${additionalClass || ``}`}>
    <div className='registracija-content-form'>{children}</div>
  </div>
)

PageContent.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.any
}
