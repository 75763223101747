import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ErrorMessage } from 'formik'

export const PhoneInputWithDial = ({
  label,
  isRequired,
  isDisabled,
  isFullWidth,
  showStar,
  isAddAdExistingUser,
  isAddAdNewUser,
  dialClass,
  inputClass,
  wrapperClass,
  field: { name },
  form,
  onChange,
  onMouseEnter,
  onFocus
}) => {
  let dial = form.values.dial ? `+${form.values.dial}` : ''
  if (isAddAdExistingUser) {
    dial = form.values.existingUser && form.values.existingUser.dial ? form.values.existingUser.dial : ''
  }

  if (isAddAdNewUser) {
    dial = form.values.newUser && form.values.newUser.dial ? form.values.newUser.dial : ''
  }

  let labelDial = `+${dial.replace('+', '')}`

  let value = form.values

  name.split('.').forEach(namePart => { value = value ? value[namePart] : null })

  return (
    <div
      style={{ display: 'flex', flexWrap: 'wrap' }}
      className={`form-group ${form.touched[name] && form.errors[name] ? `form-group_error` : ``}`}
    >
      <label style={{ width: '100%' }}>
        {label}: {isRequired && showStar ? <span className='required'>*</span> : null}
      </label>

      <div className='dial-label'>
        <label>{labelDial}</label>
      </div>

      <input
        disabled={form.isSubmitting}
        className={inputClass}
        style={{ width: '75%' }}
        type='text'
        name={name}
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
        value={value}
        onChange={e => {
          const value = e.target.value
          onChange && onChange(value)
        }}
      />

      <ErrorMessage
        name={name}
        render={msg => (
          <span
            style={{ width: '100%', display: 'block', marginTop: 5, color: '#e91c23' }}
            className='form-group__error-message'
          >
            {msg}
          </span>
        )}
      />
    </div>
  )
}

PhoneInputWithDial.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  inputClass: PropTypes.string.isRequired,
  dialClass: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  showStar: PropTypes.bool,
  onChange: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onFocus: PropTypes.func
}

PhoneInputWithDial.defaultProps = {
  inputClass: 'form-group__phone',
  dialClass: 'form-group__mask',
  wrapperClass: 'form-group',
  isRequired: true,
  isDisabled: false,
  isFullWidth: false,
  isAddAdExistingUser: false,
  isAddAdNewUser: false,
  showStar: true
}
