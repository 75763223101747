import { combineReducers } from 'redux'
import { listReducer } from './list/reducer'
import { detailReducer } from './detail/reducer'
import { paginationReducer } from './pagination/reducer'
import { brandsReducer } from './brands/reducer'
import { equipmentReducer } from './equipment/reducer'
import { productsReducer } from './products/reducer'
import { addReducer } from './add/reducer'
import { listingsReducer } from './lisitings/reducer'
import { serviceReducer } from './services/reducer'
import { serviceTypesReducer } from './serviceTypes/reducer'
import { editReducer } from './edit/reducer'
import { sharedReducer } from './shared/reducer'

export const adsReducer = combineReducers({
  add: addReducer,
  list: listReducer,
  detail: detailReducer,
  pagination: paginationReducer,
  brands: brandsReducer,
  equipment: equipmentReducer,
  products: productsReducer,
  listings: listingsReducer,
  services: serviceReducer,
  serviceTypes: serviceTypesReducer,
  edit: editReducer,
  shared: sharedReducer
})
