import { BaseApi } from 'Core/api/BaseApi'
import { adsTypes } from 'Core/constants'
import { TypesHelper } from 'SRC/utils'

export class Api extends BaseApi {
  getAds = async ({page = 1, limit = 36, type, category, group, ...restOptions}) => {
    const defaultResult = {paidAds: [], paidCount: 0, freeAds: [], freeCount: 0}
    try {
      if (!TypesHelper.isObjectHasKey(adsTypes, type)) {
        return defaultResult
      } else {
        const options = {type, page, limit}
        if (category) {
          options.category = category
        }
        if (group) {
          options.group = group
        }
        const {body} = await Api.post('/ads/list', {...options, ...restOptions})
        const result = {
          paidAds: Array.isArray(body.paidAds) ? body.paidAds : [],
          paidCount: body.paidCount ? Number(body.paidCount) : 0,
          freeAds: Array.isArray(body.freeAds) ? body.freeAds : [],
          freeCount: body.freeCount ? Number(body.freeCount) : 0
        }
        if (body.userCount) result.totalCount = body.userCount
        return body.error ? defaultResult : result
      }
    } catch (e) {
      return defaultResult
    }
  }
}
