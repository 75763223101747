import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Item } from '../Item'

export const Column = ({ className, title, items, children, id }) => {

  const { t } = useTranslation('common')

  return (
    <div className={className}>
      <span className='footer-column-title'>{t(`footer_column${id}_title`)}</span>
      <ul key={id}>
        {items.map((item) => !item.hide ? [
          <Item key={item.id} {...item} title={t(`footer_column${id}_${item.id}_title`)} />,
          '\n'
        ] : null)}
      </ul>
      {children}
    </div>
  )
}



Column.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string
}

Column.defaultProps = {
  items: []
}
