import { SharedApi } from '../../api'
import { setGarageCategories } from '../setGarageCategories'

export const fetchGarageCategories = () => async (dispatch, getState) => {
  const currentGarageCategories = getState().garage.shared.categories
  if (currentGarageCategories && currentGarageCategories.length) {
    dispatch(setGarageCategories(currentGarageCategories))
  } else {
    const api = new SharedApi()
    const categories = await api.fetchGarageCategories()
    if (categories && categories.length) {
      const garageCategories = [{
        id: 'garaza-parent',
        title: 'Garaža',
        children: categories
      }]
      dispatch(setGarageCategories(garageCategories))
    }
  }
}
