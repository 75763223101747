import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const AddressVerification = ({ isVerified, needShowText }) => {
  const { t } = useTranslation('common')

  return (
    <div className={
      `sidebar-user-verifikacija-item user-card__verification-address ${isVerified ? 'is-verifikovana' : ''}`
    }>
      <i className='ico-user-verifikacija-lokacije ico' />
      {needShowText ? <p>{t('verifikovana lokacija')}</p> : null}
    </div>
  )
}

AddressVerification.propTypes = {
  isVerified: PropTypes.bool.isRequired,
  needShowText: PropTypes.bool.isRequired
}

AddressVerification.defaultProps = {
  isVerified: false,
  needShowText: true
}
