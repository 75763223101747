import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const Success = ({ onClose }) => {
  const { t } = useTranslation('common')

  return (
    <div className='report-block'>
      <div className='report-content'>
        <div className='report-title'>{t('ad-report-success-title')}</div>
        <br />
        <div className='report-description'>
          {t('ad-report-success-description')}
        </div>
      </div>
      <br />
      <div className='report-buttons'>
        <button className='report-button__submit' onClick={onClose}>{t('U redu')}</button>
      </div>
    </div>
  )
}


Success.propTypes = {
  onClose: PropTypes.func.isRequired
}
