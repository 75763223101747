import React from 'react'
import ReactSVG from 'react-svg'
import useTranslation from 'next-translate/useTranslation'
import config from 'SRC/config/config.json'

export const Apps = () => {
  const { t } = useTranslation('common')

  return (
    <section className='footer-apps'>
      <span className='footer-column-title'>
        {t('Preuzmi aplikacije')}
      </span>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <a
          href='https://play.google.com/store/apps/details?id=me.autodiler'
          target='_blank'
          rel='noreferrer'
          aria-label='Google Play'
        >
          <ReactSVG
            src={'/icons/google-play.svg'}
            beforeInjection={svg => {
              svg.classList.add('footer-apps-svg')
            }}
            wrapper='span'
          />
        </a>

        <a
          href='https://apps.apple.com/us/app/autodiler/id844615689'
          target='_blank'
          rel='noreferrer'
          aria-label='App Store'
        >
          <ReactSVG
            src={'/icons/apple-app-store.svg'}
            beforeInjection={svg => {
              svg.classList.add('footer-apps-svg')
            }}
            wrapper='span'
          />
        </a>

        <a
          href='https://appgallery.huawei.com/app/C102884519'
          target='_blank'
          rel='noreferrer'
          aria-label='Huawei AppGallery'
        >
          <ReactSVG
            src={'/icons/appgallery.svg'}
            beforeInjection={svg => {
              svg.classList.add('footer-apps-svg')
            }}
            wrapper='span'
          />
        </a>
      </div>
    </section>
  )
}

