import PropTypes from 'prop-types'
import React from 'react'
import { debounce } from 'throttle-debounce'
import { Field } from 'redux-form'
import { getChar, getRegularExpression } from 'SRC/utils'

export class Textarea extends React.PureComponent {
  onKeyPress = e => {
    const chr = getChar(e)

    if (e.ctrlKey || e.altKey || chr == null) return
    if (Array.isArray(this.props.allowedCharacters) && this.props.allowedCharacters.length) {
      const regexp = getRegularExpression(this.props.allowedCharacters)
      const isValid = regexp ? regexp.test(chr) : true
      if (!isValid) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  onPaste = e => {
    if (Array.isArray(this.props.allowedCharacters) && this.props.allowedCharacters.length) {
      const regexp = getRegularExpression(this.props.allowedCharacters)
      const text = e.clipboardData.getData('Text')
      const chars = text.split('')
      for (const char of chars) {
        const isValid = regexp ? regexp.test(char) : true
        if (!isValid) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
    }
  }

  shouldComponentUpdate = nextProps => {
    return nextProps.textContent !== this.props.textContent ||
      nextProps.label !== this.props.label
  }

  onChange = debounce(500, e => {
    const { onAccept } = this.props

    if (Array.isArray(this.props.allowedCharacters) && this.props.allowedCharacters.length) {
      const regexp = getRegularExpression(this.props.allowedCharacters)
      const text = e.target.value
      const chars = text.split('')

      let isValid = true

      for (const char of chars) {
        isValid = regexp ? regexp.test(char) : true
        if (!isValid) {
          return e.preventDefault()
        }
      }

      isValid && onAccept(text)
    }
  })

  render () {
    const { id, disabled, label, placeholder, textContent, maxLength, isRequired } = this.props


    console.log('ta label', label)

    return (
      <React.Fragment>
        {label ? <label htmlFor={id}>{label}: {isRequired ? <span className='required'>*</span> : null}</label> : null}
        <textarea
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          maxLength={Number(maxLength) > 0 ? maxLength : null}
          onKeyPress={this.onKeyPress}
          onPaste={this.onPaste}
          onChange={this.onChange}
        >
          {textContent}
        </textarea>
      </React.Fragment>
    )
  }
}

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  textContent: PropTypes.string,
  maxLength: PropTypes.number,
  allowedCharacters: PropTypes.array,
  isRequired: PropTypes.bool,
  onAccept: PropTypes.func
}

Textarea.defaultProps = {
  maxLength: 0,
  allowedCharacters: [],
  // wrapperClass: 'info-polje info-ime',
  isRequired: false
}
