import { createSelector } from 'reselect'
import { getCategories } from 'SRC/modules/categories/selectors'
import { getPaidAds } from '../getPaidAds'

export const getAds = createSelector(
  (state) => state && state.ads && state.ads.list && state.ads.list.elements ? state.ads.list.elements : [],
  getPaidAds,
  getCategories,
  (ads, paidAds, categories) => ads.map(ad => ({ ...ad, isPaid: paidAds.includes(ad.id) }))
)
