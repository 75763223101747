import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from 'next-translate/withTranslation'
import StpdBannerComponent from 'stpd-ad-component'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { userStatuses } from 'Core/constants'
import { getUserTypes } from 'SRC/modules/common/selectors'
import { isUserOnline } from 'SRC/utils'
import { getCurrentAd } from 'SRC/modules/ads/detail/selectors'
import { SimilarAds } from 'SRC/modules/ads/detail/components/Tab/SimilarAds'
import { QuickSearch } from 'SRC/modules/categories/components'
import { FloatingBanner } from 'SRC/modules/common/components/FloatingBanner'
import { SocServices, Header, UserCard, UserContacts } from '../../../shared/components'
import config from 'SRC/config/config.json'

const Banner = () => {
  return (
    <div style={{ width: 300, height: 250 }}>
      <StpdBannerComponent
        refreshOnUrlChange={true}
        adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.sidebar.adDetail.adUnit}`}
        size={config.banners.sidebar.adDetail.sizes}
        divId={config.banners.sidebar.adDetail.slotId}
      />
    </div>
  )
}

const MobileBottomBanner = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <div style={{ width: windowWidth <= 727 ? 320 : 728, margin: 'auto' }}>
      <StpdBannerComponent
        refreshOnUrlChange={true}
        adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.sidebar.adDetailMobileBottom.adUnit}`}
        size={config.banners.sidebar.adDetailMobileBottom.sizes}
        divId={config.banners.sidebar.adDetailMobileBottom.slotId}
      />
    </div>
  )
}

export class Container extends React.Component {
  getUserTypeSeo = () => {
    return this.props.ad && this.props.ad.user && this.props.ad.user.type
      ? this.props.ad.user.type.seo : ''
  }

  getSidebarTitle = () => {
    let title = ''

    if (this.props.ad?.user?.type?.seo && Array.isArray(this.props.userTypes)) {
      const searchedUserType = this.props.userTypes.filter(item => item.seo === this.props.ad.user.type.seo)
      if (searchedUserType.length) title = searchedUserType[0].name
    }

    return title
  }

  isBusinessUser = () => {
    let isBusiness = false
    const seo = this.getUserTypeSeo()

    const businessUserTypes = [...config.userTypes.businessUsersSeos, config.userTypes.serviceProviderSeo]
    if (businessUserTypes.includes(seo)) isBusiness = true

    console.log('isBusiness', isBusiness)
    return isBusiness
  }

  getUserCardInfo = () => {
    const isBusinessUser = this.isBusinessUser()
    const { ad } = this.props
    const mainAddress = ad && ad.user && this.getMainAddress(ad.user.userAddresses)
    return ad && ad.user
      ? {
        name: ad.user.name,
        login: ad.user.username,
        status: isUserOnline(ad.user.active) ? userStatuses.online : userStatuses.offline,
        isEmailVerified: Boolean(ad.user.emailVerified),
        isPhoneVerified: true,
        isAddressVerified: false,
        gender: ad.user.gender,
        type: isBusinessUser ? 'business' : 'free',
        image: isBusinessUser ? ad.user.logo : null,
        addressCount: isBusinessUser ? ad.user.userAddresses.length : 0,
        city: isBusinessUser ? mainAddress && mainAddress.city ? mainAddress.city.name : null : null,
        isPro: false
      }
      : null
  }

  getMainAddress = addresses => {
    if (Array.isArray(addresses)) {
      const searchedAddress = addresses.filter(address => address.main)
      if (searchedAddress.length) {
        return searchedAddress[0]
      } else {
        return null
      }
    }
  }

  getUserContactsInfo = () => {
    return this.props.ad && this.props.ad.user
      ? {
        type: this.isBusinessUser() ? 'business' : 'free',
        addresses: this.props.ad.user.userAddresses,
        phones: this.props.ad.user.phones,
        login: this.props.ad.user.username,
        seo: this.getUserTypeSeo()
      }
      : null
  }

  renderBanner = () => {
    const { isMobileDevice } = this.props

    if (!isMobileDevice) return <Banner />

    return <MobileBottomBanner />
  }

  render() {
    const { ad, isMobileDevice, i18n: { t } } = this.props

    const userCardInfo = this.getUserCardInfo()
    const userContactsInfo = this.getUserContactsInfo()
    const title = this.getSidebarTitle()

    return (
      <React.Fragment>
        <aside className='sidebar'>
          <div className='sidebar-prodavac'>
            {title && <Header title={title} />}
            <div className={`sidebar-content ${this.isBusinessUser() ? `sidebar-user-prodavnica` : `sidebar-user-free`}`}>
              <div className='sidebar-user'>
                {userCardInfo && <UserCard user={userCardInfo} />}
                {userContactsInfo && <UserContacts user={userContactsInfo} adStatus={ad ? ad.status : null} />}
              </div>
            </div>
          </div>
          {this.renderBanner()}
          <SocServices />
          {!isMobileDevice ? <FloatingBanner /> : null}
        </aside>

        {isMobileDevice ? (
          <>
            <div className='oglasi-tab-header' style={{ width: '100%', marginTop: '.5rem' }}>
              <ul>
                <li className='active'>
                  <a href='javascript:void(0)'><i className='ico-tab-slicni-oglasi ico' />{t('common:Slični oglasi')}</a>
                </li>
              </ul>
            </div>
            <SimilarAds />

            <div style={{ marginTop: 3 }}>
              <QuickSearch page='ad' />
            </div>
          </>
        ) : null}
      </React.Fragment>
    )
  }
}

Container.displayName = 'AdDetailSidebar'

Container.propTypes = {
  ad: PropTypes.shape({
    status: PropTypes.string.isRequired,
    user: PropTypes.shape({
      type: PropTypes.shape({
        id: PropTypes.number,
        seo: PropTypes.string
      }).isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      gender: PropTypes.string,
      phones: PropTypes.array,
      userAddresses: PropTypes.array,
      logo: PropTypes.object,
      emailVerified: PropTypes.bool,
      active: PropTypes.string
    })
  }),
  userTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    name: PropTypes.string
  })),
  isMobileDevice: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  ad: getCurrentAd(state),
  userTypes: getUserTypes(state),
  isMobileDevice: getIsMobileDevice(state)
})

const enhance = compose(
  withTranslation,
  connect(mapStateToProps)
)

export default enhance(Container)
