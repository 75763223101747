export class TypesHelper {
  static isString (value) {
    return typeof value === 'string'
  }

  static isJsonString (str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  static isNotEmptyString (value) {
    return typeof value === 'string' && value.length > 0
  }

  static isNotEmptyArray (value) {
    return Array.isArray(value) && value.length
  }

  static isEmptyObject (obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) { return false }
    }

    return JSON.stringify(obj) === JSON.stringify({})
  }

  static isObjectHasKey (obj, key) {
    try {
      return obj.hasOwnProperty(key)
    } catch (e) {
      return false
    }
  }

  static isPositiveNumber (value) {
    return Number.isInteger(value) && Boolean(Math.sign(value))
  }
}
