import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const userDetailReducer = (state = initialState.user.detail, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_DETAIL_INFO:
      return {...state, info: action.payload}
    default:
      return state
  }
}
