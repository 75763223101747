import React, { useEffect } from 'react'
import Link from 'next/link'
import { CookiesHelper } from 'SRC/core/cookiesHelper/CookiesHelper'
import Image from 'next/image'
import { getCurrentLocale } from 'SRC/modules/common/functions'
import setLanguage from 'next-translate/setLanguage'

const languages = [
  { name: 'Crnogorski', code: 'me', flag: 'flag-mne.png' },
  { name: 'English', code: 'en', flag: 'flag-en.png' },
  { name: 'Shqip', code: 'al', flag: 'flag-al.png' },
  { name: 'Русский', code: 'ru', flag: 'flag-rus.png' },
  { name: 'Українська', code: 'ua', flag: 'flag-ua.png' },
  { name: 'Deutsch', code: 'de', flag: 'flag-de.png' },
  { name: 'Türkçe', code: 'tr', flag: 'flag-tr.png' }
]

const locale = getCurrentLocale()

export const Languages = () => {
  const lang = locale ? locale.toLowerCase() : 'me'

  useEffect(() => {
    setLanguage(lang)
  }, [])

  const cookies = new CookiesHelper()

  const changeLaguage = async (language) => {
    await setLanguage(language)
    window.location.href = language === 'me' ? `/` : `/${language}`
  }

  const currentLanguage = languages.find(language => language.code === lang)

  return Number(process.env.NEXT_PUBLIC_SHOW_LANGUAGES) ? (
    <ul className='lang-list'>
      {currentLanguage ? (
        <li className='lang-list_item active'>
          <a href='javascript:void(0)'>
            <Image src={`/icons/${currentLanguage.flag}`} alt={currentLanguage.name} width={25} height={17} />
          </a>
          <p className='lang-list_item-text'>{currentLanguage.code.toUpperCase()}</p>
        </li>
      ) : null}

      {languages.filter(language => language.code !== lang).map((language) => {
        return (
          <li
            key={`lang_${language.code}`}
            className='lang-list_item'
            onClick={async () => await changeLaguage(language.code)}
          >
            <a href='javascript:void(0)'>
              <Image src={`/icons/${language.flag}`} alt={language.name} width={25} height={17} />
            </a>
            <p className='lang-list_item-text'>{language.code.toUpperCase()}</p>
          </li>
        )
      })}
    </ul>
  ) : null
}
