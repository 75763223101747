import { createSelector } from 'reselect'
import { getCountries } from '../getCountries'

export const getGroupedCountries = createSelector(
	getCountries,
	countries => {
		const children = countries.filter(country => country.parent).map(country => ({
			id: `${country.id}`,
			label: country.label,
			value: `+${country.dial}`,
			dial: `+${country.dial}`,
			parent: country.parent
		}))

		return countries.filter(country => !country.parent).map(country => {
			const options = children.filter(childCountry => childCountry.parent === country.id)

			return {
				id: `${country.id}`,
				label: country.label,
				dial:`+${country.dial}`,
				isGrouped: !!options.length,
				options
			}
		})
	}
)