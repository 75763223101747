import { createSelector } from 'reselect'

export const getAddonsSortedByGroup = createSelector(
  (state) => state && state.user && state.user.profile && state.user.profile.addons &&
  Array.isArray(state.user.profile.addons.elements)
    ? state.user.profile.addons.elements : [],
  addons => {
    return addons.reduce((acc, curr) => {
      if (curr.group) {
        if (!acc[curr.group.id]) {
          acc[curr.group.id] = {
            label: curr.group.name,
            weight: curr.group.weight,
            elements: []
          }
        }
        acc[curr.group.id].elements.push(curr)
      }
      return acc
    }, {})
  }
)
