import React from 'react'
import PropTypes from 'prop-types'

export const TermsOfServiceDiscountCard = ({ forApp }) => {
  return <div className='novosti-text'>
    <h1 style={{ textAlign: 'center', fontSize: '1.4em' }}>
      USLOVI KORIŠĆENJA POPUST KARTICE I PARTNERSKOG PROGRAMA:
    </h1>
    <p style={{ textAlign: 'center' }}><strong>Član 1.</strong></p>
    <p>WebLab. d.o.o. je pokretač programa lojalnosti Popust Kartice za sve registrovane korisnike u
      AutoDiler aplikaciji, koja ostaje u vlasništvu firme. WebLab d.o.o. zadržava pravo da u bilo kom
      trenutku i bez prethodne najave povuče Popust Karticu iz opticaja i da promijeni ili u potpunosti
      ukine elemente programa Popust Kartice.</p>
    <p>Korisnik Popust Kartice može postati svaka punoljetna osoba
      koja firmi WebLab d.o.o. putem Zahtjeva za dobijanje Popust Kartice stavi na raspolaganje obavezne
      podatke navedene u Zahtjevu za Popust Karticu.</p>
    <p>Partner programa Popust Kartice može postati svako pravno
      lice koje firmi WebLab d.o.o.putem popunjavanjem Pristupne forme stavi na raspolaganje obavezen podatke
      navedene u Zahtjevu za partnersto.
    </p>

    <p style={{ textAlign: 'center' }}><strong>Član 2.</strong></p>
    <p>Podaci korisnika navedeni u Zahtjevu za Popust Karticu koriste se isključivo za informisanje korisnika
      o različitim popustima i akcijama Popust Kartice i njenih partnera, i to samo onih korisnika koji u
      Zahtjevu za Popust Karticu nijesu naveli da ne žele da primaju informacije takvog tipa. Lični podaci o
      korisnicima Kartice ne razmjenjuju se sa drugim korisnicima, u skladu sa Zakonom o zaštiti podataka o
      ličnosti.</p>
    <p>Podaci Partnera navedeni u Zahtjevu za Partnerstvo koriste se isključivo za informisanje korisnika o
      Partneru, njegovim ponudama, proizvodima, kontakt podacima i sl. Partner je saglasan da se svi njegovi
      podaci objave na AutoDiler sajtu i ostalim sajtovima koji Podržavaju program Popust Kartice.</p>

    <p style={{ textAlign: 'center' }}><strong>Član 3.</strong></p>
    <p>Popust Kartica nije prenosiva i može je koristiti isključivo registrovani korisnik.</p>

    <p style={{ textAlign: 'center' }}><strong>Član 4.</strong></p>
    <p>Korisnik ima pravo da u svako doba pisanim putem izmijeni podatke koje je prethodno dostavio Popust
      Kartici. Korisnik takođe ima pravo da pisanim putem ili pozivom na broj našeg operatera 067 733 941
      otkaže korišćenje Popust Kartice. Važenje Popust Kartice prestaje od trenutka kada izdavač primi
      obavještenje o otkazu. Korisnik povodom otkazivanja karitce ne može od izdavača tražiti povrat novca.</p>

    <p style={{ textAlign: 'center' }}><strong>Član 5.</strong></p>
    <p>WebLab d.o.o. zadržava pravo da u svakom trenutku provjeri identitet osobe koja traži otkazivanje Popust Kartice.</p>

    <p style={{ textAlign: 'center' }}><strong>Član 6.</strong></p>
    <p>WebLab d.o.o. može, prema sopstvenoj procjeni i bez prethodne najave, isključiti iz Popust Kartica programa
      bilo kojeg korisnika, kako korisnika Popust Kartice, tako i Partnera programa Popust Kartice. Firma WebLab
      d.o.o. je dužna da korisnika obavijesti o isključenju, ali nije obavezna da korisniku obrazloži svoju odluku. </p>

    <p style={{ textAlign: 'center' }}><strong>Član 7.</strong></p>
    <p>Svako neovlašćeno kopiranje i umnožavanje Popust Kartice će biti sankcionisano u skladu sa zakonskim
      propisima. Korisnici slanjem zahtjeva za Popust Karticu, kao i slanjem zahtjeva za Partnerstvo bezuslovno
      prihvataju ova pravila.</p>

    <p style={{ textAlign: 'center' }}><strong>Član 8. </strong></p>
    <p>Partner je u obavezi da Korisniku Popust Kartice omogući popust na navedene usluge ili proizvode, kako
      je mu je navedeno u sekciji PONUDA na njegovom partnerskom profilu u sklopu Popust Kartice.</p>

    <p style={{ textAlign: 'center' }}><strong>Član 9.</strong></p>
    <p>Ukoliko Partner programa ima popust koji traje samo do određenog datuma, dužan je da to istakne na
      njegovoj stranici PONUDE, kako korisnika Popust Kartice ne bi doveo u zabludu.</p>

    <p style={{ textAlign: 'center' }}><strong>Član 10.</strong></p>
    <p>Korisnik koji ne dobije popust kod Partnera našeg programa, a ta usluga je navedena na njegovoj
      stranici PONUDE može prijaviti Partnera (nudioca popusta) na email <a href='mailto:info@popustkartica.com'>
      info@popustkartica.com</a>
    </p>

    <p style={{ textAlign: 'center' }}><strong>Član 11.</strong></p>
    <p>O svakoj izmjeni pravila, kao i promjeni osnivača i partnera programa, Popust Kartica će korisnike
      obavijestiti na odgovarajući način. Aktuelna pravila su dostupna na ovoj internet
      stranici <a href='https://www.autodiler.me/uslovi-koriscenja-popust-kartice'>
      www.autodiler.me/uslovi-koriscenja-popust-kartice.</a></p>
  </div>
}

TermsOfServiceDiscountCard.propTypes = {
  forApp: PropTypes.bool
}
