import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { formatDateForTableView } from '../../../functions/functions'

export const Time = ({time}) => {
  const { t } = useTranslation('common')

  return (
    <div className='oglasi-vreme'>
      {formatDateForTableView(time, t)}
    </div>
  )
}

Time.propTypes = {
  time: PropTypes.string
}
