import PropTypes from 'prop-types'
import React from 'react'
import { userStatuses } from 'Core/constants'

export const Status = ({status}) =>
  <span className={`user-online-status ${status === userStatuses.online ? 'online' : 'offline'}`} />

Status.propTypes = {
  status: PropTypes.string.isRequired
}

Status.defaultProps = {
  status: userStatuses.offline
}
