export const serviceLinks = [
  {
    id: 1,
    link: '#',
    icoClass: 'ico-sifra-login',
    text: 'Zaboravljena šifra (lozinka)?'
  },
  {
    id: 2,
    link: '#',
    icoClass: 'ico-top-meni-prijavi-se',
    text: 'Zaboravljeno korisničko ime?'
  },
  {
    id: 3,
    link: '#',
    icoClass: 'ico-top-meni-kontakt ico',
    text: 'Potrebna pomoć? Pozovi 067/733 941'
  }
]
