export const rationalNumbers = {
  mask: Number,
  scale: 2,
  signed: true,
  radix: ',',
  thousandsSeparator: ' ',
  mapToRadix: ['.'],
  normalizeZeros: true,
  unmask: 'typed'
}
