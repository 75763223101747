import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Image from 'next/image'
import config from 'SRC/config/config.json'
import { getIsTransitioning } from 'SRC/modules/common/selectors'

const Logo = ({ isMobileDevice, isTransitioning }) => isTransitioning ? (
  <div style={{ cursor: 'not-allowed' }}>
    <div className={'logo-autodiler-img'}>
      <img src={!isMobileDevice ? '/img/autodiler-logo.png' : '/img/logo-mobile.svg'} alt='Logo' />
    </div>
  </div>
) : (
  <Link href='/'>
    <a>
      <div className={'logo-autodiler-img'}>
        <img src={!isMobileDevice ? '/img/autodiler-logo.png' : '/img/logo-mobile.svg'} alt='Logo' />
      </div>
    </a>
  </Link>
)

Logo.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  isTransitioning: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isTransitioning: getIsTransitioning(state)
})

export default connect(mapStateToProps)(Logo)