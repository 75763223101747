import { AuthApi } from '../../api'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'
import { StorageHelper } from 'Core/storageHelper/StorageHelper'
import { setAuthPopupFormErrors } from '../setAuthPopupFormErrors'
import { setAuthFormErrors } from '../setAuthFormErrors'
import { setAuthLoading } from '../setAuthLoading'
import { setIsAuthorized } from '../setIsAuthorized'
import { hidePopupLogin } from 'SRC/modules/common/actions'
import Router from 'next/router'

export const login = (credentials, isPopup = false) => {
  return async (dispatch) => {
    isPopup ? dispatch(setAuthPopupFormErrors('')) : dispatch(setAuthFormErrors(''))
    dispatch(setAuthLoading(true))
    try {
      const api = new AuthApi()
      const {body, error} = await api.login(credentials)
      if (!error && body.token && body.user.id) {
        const cookie = new CookiesHelper()
        cookie.set('token', body.token, { maxAge: 315360000 })
        cookie.set('userId', body.user.id, { maxAge: 315360000 })
        dispatch(hidePopupLogin())
        dispatch(setIsAuthorized(true))

        // Set savedAdsSynced and favouriteProfilesSynced to false in localStorage
        const storage = new StorageHelper({ persistent: true })
        storage.set('savedAdsSynced', false)
        storage.set('favouriteProfilesSynced', false)

        Router.push({
          pathname: '/profile',
          query: {
            page: 'oglasi',
            status: 'Published'
          }
        }, '/profil/oglasi')
      } else {
        dispatch(setIsAuthorized(false))
        isPopup
          ? dispatch(setAuthPopupFormErrors(`${error}`))
          : dispatch(setAuthFormErrors(`${error}`))
      }
      dispatch(setAuthLoading(false))
    } catch (error) {
      dispatch(setAuthLoading(false))
      dispatch(setIsAuthorized(false))
    }
  }
}
