import PropTypes from 'prop-types'
import Link from 'next/link'
import React from 'react'

export const AsLink = ({icoClass, iconSvg, link: {href, as}, children}) =>
  <Link href={href} as={as}><a>
    {icoClass ? <i className={icoClass + ' ico'} /> : null}
    {iconSvg ? <img src={iconSvg} alt='icon' width={18} style={{ height: 21 }} /> : null}
    &nbsp;
    {children}
  </a></Link>

export const HrefLink = ({icoClass, iconSvg, link: {href}, children}) =>
  <Link href={href}><a>
    {icoClass ? <i className={icoClass + ' ico'} /> : null}
    {iconSvg ? <img src={iconSvg} alt='icon' width={18} style={{ height: 21 }} /> : null}
    &nbsp;
    {children}
  </a></Link>

export const A = ({icoClass, iconSvg, children, link}) => link.href && link.targetBlank
  ? <a href={link.href} target='blank'>
    {icoClass ? <i className={icoClass + ' ico'} /> : null}
    {iconSvg ? <img src={iconSvg} alt='icon' width={18} style={{ height: 21 }} /> : null}
    &nbsp;
    {children}
  </a>
  : <a href='javascript:void(0)'>
    {icoClass ? <i className={icoClass + ' ico'} /> : null}
    {iconSvg ? <img src={iconSvg} alt='icon' width={18} style={{ height: 21 }} /> : null}
    &nbsp;
    {children}
  </a>

export const Item = ({icoClass, link, children, isActive, iconSvg}) => (
  <li className={isActive ? 'active' : ''}>
    {
      link.href !== '#' && link.href !== '' && !link.targetBlank
        ? link.as
          ? <AsLink icoClass={icoClass} iconSvg={iconSvg} link={link}>{children}</AsLink>
          : <HrefLink link={link} icoClass={icoClass} iconSvg={iconSvg}>{children}</HrefLink>
        : <A link={link} icoClass={icoClass} iconSvg={iconSvg}>{children}</A>
    }
  </li>
)

Item.propTypes = {
  children: PropTypes.any,
  icoClass: PropTypes.string,
  iconSvg: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string,
    as: PropTypes.string.isRequired,
    targetBlank: PropTypes.bool
  }).isRequired,
  isActive: PropTypes.bool.isRequired
}

Item.defaultProps = {
  isActive: true
}

AsLink.propTypes = {
  children: PropTypes.any,
  icoClass: PropTypes.string,
  iconSvg: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    as: PropTypes.string.isRequired
  }).isRequired
}

HrefLink.propTypes = {
  children: PropTypes.any,
  icoClass: PropTypes.string,
  iconSvg: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired
  }).isRequired
}

A.propTypes = {
  children: PropTypes.any,
  icoClass: PropTypes.string,
  iconSvg: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    targetBlank: PropTypes.bool
  }).isRequired
}
