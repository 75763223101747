import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from 'SRC/utils'

export const DropdownItem = ({ value, item, isFirstItem = false, onSelectDropdownChange, scrollToSelectedItem = false }) => {
  const isChecked = item.value === value

  const ref = React.useRef()

  React.useEffect(() => {
    if (isChecked && ref && ref.current && ref.current.parentNode && scrollToSelectedItem) {
      console.log('ref.current.parentNode', ref.current.parentNode.parentNode.parentNode)
      const closestParent = ref.current.closest('.select-dropdown-items') || ref.current.closest('.select-dropdown-items-mobile')
      console.log('closest', closestParent)
      closestParent && closestParent.scrollTo({ top: ref.current.offsetTop - 40, left: 0, behavior: 'smooth' })
    }
  }, [])

  return (
    <li className='select-dropdown-item' ref={ref}>
      <input
        id={item.id}
        type='checkbox'
        onChange={!item.isDisabled ? onSelectDropdownChange : null}
        data-value={item.value}
        checked={isChecked}
      />

      <label className='select-dropdown-item-label' htmlFor={item.id}>{item.label}</label>
    </li>
  )
}

DropdownItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  isFirstItem: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onSelectDropdownChange: PropTypes.func.isRequired
}

DropdownItem.defaultProps = {
  scrollToSelectedItem: false
}
