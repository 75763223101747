import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'
import { getCurrentLocale } from 'SRC/modules/common/functions'

export class Api extends BaseApi {
  fetchGroups = async (locale = getCurrentLocale()) => {
    const defaultResult = []
    try {
      const { body } = await Api.post('/group/list', { locale })
      if (!body.error && body.groups && Array.isArray(body.groups)) {
        return body.groups
      } else {
        return defaultResult
      }
    } catch (error) {
      console.log('GROUP LIST error', error)
      return defaultResult
    }
  }

  fetchCategories = async (locale = getCurrentLocale()) => {
    console.log('fetchCategories locale', locale)
    const defaultResult = []
    try {
      const { body } = await Api.post('/category/list', { locale })
      if (!body.error && body.categories && Array.isArray(body.categories)) {
        return body.categories
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }

  fetchCategoryBySeoWithFilters = async (options, locale = getCurrentLocale()) => {
    const defaultResult = {}
    try {
      if (TypesHelper.isNotEmptyString(options.seo)) {
        const { body } = await Api.post('/category/getBySeoWithFilters', { ...options, locale })
        if (!body.error && body.category) {
          return body.category
        } else {
          return defaultResult
        }
      } else {
        return defaultResult
      }
    } catch (e) {
      return defaultResult
    }
  }

  fetchCategoryBySeo = async (options) => {
    const defaultResult = {}
    try {
      if (TypesHelper.isNotEmptyString(options.seo)) {
        const { body } = await Api.post('/category/getBySeo', options)
        if (!body.error && body.category) {
          return body.category
        } else {
          return defaultResult
        }
      } else {
        return defaultResult
      }
    } catch (e) {
      return defaultResult
    }
  }

  fetchSpecificationsByCategory = async (category, locale = getCurrentLocale()) => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(category)) {
      try {
        const { body } = await Api.post('/specification/listByCategory', { category, locale })
        if (!body.error && body.categoryspecifications && Array.isArray(body.categoryspecifications)) {
          return body.categoryspecifications
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }

  fetchCategoryV2 = async (seo, locale = getCurrentLocale()) => {
    const defaultResult = null

    return fetch(`https://autodiler.me/category/${locale?.toLowerCase()}?seo=${seo}`)
      .then(response => response.json())
      .then(body => {
        if (!body.error && Array.isArray(body.categories) && Array.isArray(body.categories) && typeof body.group !== 'undefined' && typeof body.category !== 'undefined') {
          return body
        }

        return defaultResult
      })
      .catch(error => defaultResult)
  }
}