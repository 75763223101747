import { getCurrentLocale } from 'SRC/modules/common/functions'

import { setServices } from '../setServices'
import { ServicesApi } from '../../api'

export const fetchServices = (locale = getCurrentLocale()) => async (dispatch, getState) => {
  let services = getState().ads.services.elements

  if (locale !== 'ME') services = []

  if (!Array.isArray(services) || !services.length) {
    const api = new ServicesApi()
    services = await api.fetchServices(locale)
  }

  dispatch(setServices(services))
}
