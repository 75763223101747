import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from 'next-translate/withTranslation'
import { connect as formikConnect } from 'formik'
import { adPriceTypes } from 'Core/constants'
import { adFiltersLabels } from 'src/core/constants'
import { SelectDropdown, AdFilterField, AdFilterEmpty } from 'SRC/ui/FormElementsNew'
import { getUserTypes } from 'SRC/modules/common/selectors'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getIsSelfConainedCategory } from 'SRC/modules/categories/functions'
import { Price } from '../../Price'
import { Condition } from '../../Condition'

export class FirstRow extends React.Component {
  getPriceTypeField = () => {
    const { formik, i18n: { t } } = this.props

    return {
      id: 'price-type',
      title: t('common:Vrsta cijene'),
      options: [
        {
          id: `price-type-${adPriceTypes.fixed.id}`,
          label: t(`common:${adPriceTypes.fixed.title}`),
          value: adPriceTypes.fixed.id
        },
        {
          id: `price-type-${adPriceTypes.negotiated.id}`,
          label: t(`common:${adPriceTypes.negotiated.title}`),
          value: adPriceTypes.negotiated.id
        },
        {
          id: `price-type-${adPriceTypes.urgent.id}`,
          label: t(`common:${adPriceTypes.urgent.title}`),
          value: adPriceTypes.urgent.id
        }
      ],
      onChange: this.onPriceTypeChange,
      value: formik.values.priceType
    }
  }

  onPriceTypeChange = value => {
    const { formik } = this.props
    const priceType = Number(value)
    formik.setFieldValue('priceType', priceType)
  }

  getUserTypeField = () => {
    const { userTypes, formik, i18n: { t } } = this.props

    return {
      id: 'userType',
      title: t('common:Prodavac'),
      options: userTypes.map(type => ({
        id: type.id,
        label: t(`common:${type.name}`),
        value: type.id
      })),
      onChange: this.onUserTypeChange,
      value: formik.values.userType
    }
  }

  onUserTypeChange = value => {
    const { formik } = this.props
    const userType = Number(value)
    formik.setFieldValue('userType', userType)
  }

  render () {
    const { currentCategory } = this.props

    const priceTypeField = this.getPriceTypeField()
    const userTypeField = this.getUserTypeField()

    return (
      <div className='ads-filter__row'>
        <Price key='price' />

        <AdFilterField>
          <SelectDropdown {...priceTypeField} />
        </AdFilterField>

        <AdFilterField>
          <SelectDropdown {...userTypeField} />
        </AdFilterField>
        {
          currentCategory && currentCategory.condition ? <Condition /> : <AdFilterEmpty />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userTypes: getUserTypes(state),
  currentCategory: getCurrentSubCategory(state)
})

FirstRow.propTypes = {
  userTypes: PropTypes.array.isRequired,
  currentCategory: PropTypes.shape({
    condition: PropTypes.bool.isRequired
  }),
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

FirstRow.defaultProps = {
  userTypes: [],
  currentCategory: null
}

const enhance = compose(
  connect(mapStateToProps),
  formikConnect,
  withTranslation
)

export default enhance(FirstRow)
