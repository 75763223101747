import PropTypes from 'prop-types'
import React from 'react'

export const SelectedAddress = ({address}) => [
  <i className='ico-lokacija ico' key='icon' />,
  <p key='address'>
    {address && address.country && address.country.name} - {address && address.city && address.city.name}, {address && address.location && address.location.name}
  </p>,
  <p key='street'>
    {address.street}
  </p>
]

SelectedAddress.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    location: PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  })
}
