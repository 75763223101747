import { ProfileApi } from '../../api'
import { setPriceTypes } from '../setPriceTypes'

export const fetchPriceTypes = (userId, token) => {
  return async (dispatch, getState) => {
    let priceTypes = getState().user.profile.priceTypes

    if (!Array.isArray(priceTypes) //|| !priceTypes.length
    ) {
      const api = new ProfileApi()
      priceTypes = await api.getPriceTypes(userId, token)
  
      if (Array.isArray(priceTypes)) dispatch(setPriceTypes(priceTypes))
    }
  }
}
