import { BaseApi } from 'Core/api/BaseApi'

export class Api extends BaseApi {
  login = async credentials => {
    try {
      const {body} = await Api.post('/auth/login', credentials)
      return body.error ? {error: body.error} : {body}
    } catch (error) {
      return {error: error.message}
    }
  }
}
