import React from 'react'
import Image from 'next/image'

export const SecureShopping = () => (
  <div className='novosti-text'>
		<p>Par savjeta kako bi prepoznali prevaru i sigurno trgovali na našim portalima AutoDiler.me i Oglasi.me</p>

		<p>U poslednje vrijeme administratori naših portala primili su veći broj prijava koji se odnose na zloupotrebu naših
      portala od strane lažnih &quot;kupaca&quot; prevaranata, kojima je jedini cilj da dodju do podataka vaše kreditne ili
      debitne bankovne kartice.
    </p>

    <p><strong>Primjer prevare u nastavku:</strong></p>

    <p>Naime, korisnicima našeg portala stižu poruke - najčešće putem Vibera/Whatsapp-a sa inostranih brojeva u kojima se navodno
      zainteresovani &quot;kupci&quot; interesuju za artikal koji prodajete, mogućnost dostave i ostalo.
    </p>

    <p>Za sada sve izgleda normalno i jedino može da se nasluti da je riječ o prevari jeste inostrani brojevi koji se koriste za slanje
      ovakvih poruka kao i lose poznavanje našeg jezika.
    </p>

    <p>Nakon ovih prvih poruka &quot;kupac&quot; prevarant šalje link do lažnih stranica poznatih kurirskih službi
      (obično lažna DHL stranica) sa jasnim instrukcijama da vi kao prodavac teba da unesete broj vaše kartice kako
      bi oni mogli da vam posalju novac.
    </p>

    <p>Ukoliko dobijete ovakvu poruku treba da vam bude 100% jasno da se radi o prevari i da istog trenutka prekinete
      komunikaciju sa korisnikom. Vi kao prodavac nikada ne treba da unosite vaš broj kartice - za prijem novca kupcu
      je sasvim dovoljno da posaljte vaš broj žiro računa ili IBAN.
    </p>

    <br />

    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
      <Image
        src="/img/prevara-na-internetu-dhl-1.jpg"
        alt="Prevara na internetu"
        width={256}
        height={546}
      />

      <Image
        src="/img/prevara-na-internetu-dhl-2.jpg"
        alt="Prevara na internetu"
        width={256}
        height={546}
      />

      <Image
        src="/img/prevara-na-internetu-dhl-3.jpg"
        alt="Prevara na internetu"
        width={256}
        height={546}
      />
    </div>

    <br />

    <p><strong>Par savjeta za bezbjednu trgovinu:</strong></p>

    <p style={{ marginTop: 0, marginLeft: 5 }}>
      • Prilikom kupovine nemojte slati novac unaprjed već koristite kurirsku službu, plaćajte pouzećem.
    </p>
    <p style={{ marginTop: 0, marginLeft: 5 }}>
      • Nikada nemojte davati svoje lične podatke (jmbg, broj lične karte...) ili brojeve vaše kartice,
      ništa od ovih informacija nije potrebno kako bi vam kupac platio za proizvod koji prodajete.
    </p>
    <p style={{ marginTop: 0, marginLeft: 5 }}>
      • Odustanite od kupovine ukoliko prodavac izbjegava lični kontakt, plaćanje preko računa banke ili pouzećem.
    </p>
    <p style={{ marginTop: 0, marginLeft: 5 }}>
      • Ukoliko je cijena nerealno niska, dobro provjerite predmet prodaje ili prodavca.
    </p>
    <p style={{ marginTop: 0, marginLeft: 5 }}>
      • Ako vam kupac pošalje link do neke stranice da unesete vaše podatke ne pratite taj link vjerovatno
      se radi o nekom vidu prevare i prikupljanja vaših ličnih podataka.
    </p>

    <p>Napominjemo da naš portal nema nikakvu vezu sa naplatom artikala, i da se prodaja vrši ISKLJUČIVO direktnim kontaktom kupca
      i prodavca.
    </p>

    <p>Ukoliko se dogodi prevara, pokušaj prevare ili jednostavno želite provjeriti da li komunicirate sa potencijalnim
      prevarantom, preporučujemo da prije preduzimanja bilo kakvih daljih akcija kontaktirate Sektor za podršku korisnicima
      gdje ćete dobiti korisne savjete i daljnje instrukcije.
    </p>

    <p>Ovim putem Vas pozivamo da pratite gore navedene savjete i da ukoliko se dogodi prevara, pokušaj prevare ili jednostavno
      želite provjeriti da li komunicirate sa potencijalnim prevarantom pozovete jedan od administratorskih telefona 067/312-555,
      067/313-555 ili 067/733-941 kako bi vam pomogli.
    </p>
  </div>
)
