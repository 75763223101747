import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import { withRouter } from 'next/router'
import Link from 'next/link'
import ReactSVG from 'react-svg'

import translations from '../../../../../../public/data/translations.json'

const ItemContent = ({ text, icon }) => (
	<React.Fragment>
		<ReactSVG src={icon} beforeInjection={svg => {
				svg.setAttribute('style', 'width: 30px; margin-top: 5px; height: 30px;')
			}} wrapper='span' />

			<p>{text}</p>
	</React.Fragment>
)

const renderItem = ({text, icon, isActive}) => {
	if (!isActive) {
		return (
			<a className='filter-nav-mobile-item'>
				<ItemContent text={text} icon={icon} />
			</a>
		)
	}

	return (
		<a className='filter-nav-mobile-item active' href='javascript:void(0)'>
			<ItemContent text={text} icon={icon} />
		</a>
	)
}

const renderTextSearchItem = ({ isTextSearchActive, textFilterText, textFilterIcon, textSearchDisabled, locale }) => {
	if (isTextSearchActive) return renderItem({ text: textFilterText, icon: textFilterIcon, isActive: true })

	if (textSearchDisabled) {
		return (
			<a className='filter-nav-mobile-item' href='javascript:void(0)'>
				<ItemContent text={textFilterText} icon={textFilterIcon} />
			</a>
		) 
	}

	return (
		<Link
			key='pretraga-po-tekstu'
			href={{ pathname: '/mobileAdFilter', query: { page: translations.tekstualnaPretraga[locale] } }}
			as={`/${translations.pretragaOglasa[locale]}/${translations.tekstualnaPretraga[locale]}`}
		>
			{renderItem({
				text: textFilterText,
				icon: textFilterIcon,
				isActive: false
			})}
		</Link>
	)
}

const NavigationMobile = ({ router, textSearchDisabled }) => {
  const { t, lang } = useTranslation('common')

	const locale = lang?.toUpperCase() || 'ME'

	const isTextSearchActive = router.asPath === `/${translations.pretragaOglasa[locale]}/${translations.tekstualnaPretraga[locale]}`

	const textFilterText = t('Pretraga po tekstu')
	const textFilterIcon = '/icons/pretraga-po-tekstu.svg'
	const categoryFilterText = t('Pretraga po filterima')
	const categoryFilterIcon = '/icons/pretraga-po-filterima.svg'

  return (
		<div className='filter-nav-mobile'>
			{renderTextSearchItem({ isTextSearchActive, textFilterText, textFilterIcon, textSearchDisabled, locale })}

			{!isTextSearchActive ?
				renderItem({
					text: categoryFilterText,
					icon: categoryFilterIcon,
					isActive: true
				}) : (
					<Link
						key='pretraga-po-filterima'
						href={{ pathname: '/mobileAdFilter', query: { page: 'category-filter' } }}
						as={`/${translations.pretragaOglasa[locale]}`}
					>
						{renderItem({
							text: categoryFilterText,
							icon: categoryFilterIcon,
							isActive: false
						})}
					</Link>
				)
			}
		</div>
	)
}

NavigationMobile.propTypes = {
  router: PropTypes.shape({
    asPath: PropTypes.string.isRequired
  }),
	textSearchDisabled: PropTypes.bool
}

export default withRouter(NavigationMobile)
