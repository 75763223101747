import { createSelector } from 'reselect'
import { getSpecifications } from 'SRC/modules/categories/selectors'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'

export const getSortedSpecifications = createSelector(
  getSpecifications,
  getIsMobileDevice,
  (specs, isMobileDevice) => {
    const weightProp = isMobileDevice ? 'weightDetailsApp' : 'weightDetails'

    return specs.filter(item => Boolean(item[weightProp])).sort((a, b) => a[weightProp] - b[weightProp])
  } 
)
