import PropTypes from 'prop-types'
import React from 'react'

export const Item = ({id, link, title}) => (
  <li key={id}>
    <a href={link} target='_blank'>{title}</a>
  </li>
)

Item.propTypes = {
  id: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string
}
