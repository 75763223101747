import PropTypes from 'prop-types'
import React from 'react'

export const Item = ({id, link, title, targetBlank}) => (
  <li key={id}>
    <a href={link} target={targetBlank ? '_blank' : '_self'}>
      {title}
    </a>
  </li>
)

Item.propTypes = {
  id: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string,
  targetBlank: PropTypes.bool
}
