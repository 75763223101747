export const conditions = {
  NEW: {
    id: 1,
    label: 'Novo',
    value: 'new'
  },
  USED: {
    id: 2,
    label: 'Polovno',
    value: 'used'
  }
}

conditions[Symbol.iterator] = function * () {
  yield conditions.NEW
  yield conditions.USED
}
