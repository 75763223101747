import PropTypes from 'prop-types'
import React from 'react'

export const Checkbox = ({ id, title, isChecked, onChange }) => {
  return (
    <React.Fragment>
      <input id={id} data-value={id} type='checkbox' checked={isChecked} onChange={onChange} />
      <label className='inline-label' htmlFor={id}>{title}</label>
    </React.Fragment>
  )
}

Checkbox.propTypes = {
  id: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}
