import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const paginationReducer = (state = initialState.ads.pagination, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGINATION_PARAMS:
      return {
        ...state,
        params: action.payload
      }
    default:
      return state
  }
}
