import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { withRouter } from 'next/router'

const ForNonAuthUsers = ({togglePopupVisibility, router: { pathname }}) => {
  const { t } = useTranslation('common')

  return (
    <div className='header-links__no-auth-user'>
      <ul className='header-links__list'>
        <li className={`main-nav-actions last-in-nav ${pathname === '/profile' ? 'active' : ''}`}>
          <Link href={'/profile'} as={'/profil'}>
            <a className='header-links__register'>
              <i className='ico-top-meni-prijavi-se ico' />
              {t('Prijavi se')}
            </a>
          </Link>
        </li>
      </ul>
    </div>
  )
}

ForNonAuthUsers.propTypes = {
  togglePopupVisibility: PropTypes.func,
  router: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
}

export default withRouter(ForNonAuthUsers)
