export const getUserPhone = phone => {
  return `Tel: ${formatPhone(phone)}`
}

export const formatPhone = phone => {
  const matches = phone.match(/^\+?(\d{3})(\d{2})(\d{3})(\d+)$/)
  const result = {}
  if (matches && matches.length > 1) {
    result.countryCode = matches[1]
    result.operatorCode = matches[2]
    result.middle = matches[3]
    result.rest = matches[4]
  }
  return `+${result.countryCode} ${result.operatorCode} ${result.middle} ${result.rest}`
}

const getArrayRandomOrder = (elements, length) => {
  let newArray = []
  for (let i = 0; i < length; i++) {
    let randomIndex = Math.floor(Math.random() * elements.length)
    let randomElement = elements[randomIndex]
    while (newArray.includes(randomElement)) {
      randomIndex = Math.floor(Math.random() * elements.length)
      randomElement = elements[randomIndex]
    }
    newArray.push(randomElement)
    if (newArray.length >= length) break
  }
  return newArray
}

export const getUsersWithRandomOrder = (users, usersLength) => {
  let usersWithRandomOrder = []
  let randomIds = []
  const ids = Array.from(new Set(users.map(user => user.id)))

  if (ids.length >= usersLength) randomIds = getArrayRandomOrder(ids, usersLength)
  else randomIds = getArrayRandomOrder(ids, ids.length)

  usersWithRandomOrder = randomIds.map(userId => users.find(user => user.id === userId))

  return usersWithRandomOrder
}

export const getUserProAddon = user => {
  if (user.activeAddons.length) {
    for (let addon of user.activeAddons) {
      if (addon.action.label === 'pro') return addon
    }
    return null
  } else {
    return null
  }
}
