
import React from 'react'
import { connect } from 'react-redux'
import { useFormikContext, ErrorMessage } from 'formik'

import { getCountries, getGroupedCountries } from 'SRC/modules/geoObjects/countries/selectors'

import { getCitiesByCountryWithinRegions, getCitiesLoading } from 'SRC/modules/geoObjects/cities/selectors'
import { fetchCitiesByCountryWithinRegions } from 'SRC/modules/geoObjects/cities/actions'

import { fetchLocationsByCity } from 'SRC/modules/geoObjects/locations/actions'
import { getLocationsByCity, getLocationsLoading } from 'SRC/modules/geoObjects/locations/selectors'

import { SelectDropdown, TextInputNew, Wrapper } from 'SRC/ui/FormElementsNew'

import useTranslation from 'next-translate/useTranslation'

const Address = ({
	countries,
	allCountries,
	regionsWithCities,
	regionsWithCitiesLoading,
	locations,
	locationsLoading,
	fetchCitiesByCountryWithinRegions,
	fetchLocationsByCity
}) => {
	const [selectedCityRegion, setSelectedCityRegion] = React.useState(-1)

  const { t } = useTranslation('common')

	const formik = useFormikContext()

	const selectedCountry = formik.values.address.country
	const selectedCity = formik.values.address.city

	React.useEffect(() => {
		const { initialValues } = formik

		if (initialValues && initialValues.address.region && initialValues.address.city) {
			setSelectedCityRegion(JSON.stringify({ city: initialValues.address.city, region: initialValues.address.region }))

			if (Number(initialValues.address.city) !== -1) fetchLocationsByCity(Number(initialValues.address.city))
		}

		if (Number(initialValues.address.country) !== -1) fetchCitiesByCountryWithinRegions(Number(initialValues.address.country))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		if (Number(selectedCity) === -1) setSelectedCityRegion(-1)
	}, [selectedCity])

	const countryError = formik.touched.country && formik.errors.country
	const countryOptions = Array.isArray(countries)
		? countries.map(country => ({
			id: `${country.id}`,
			label: country.label,
			value: `${country.id}`,
			isGrouped: country.isGrouped,
			options: Array.isArray(country.options)
				? country.options.map(childCountry => ({
					id: `${childCountry.id}`,
					label: childCountry.label,
					value: `${childCountry.id}`
				}))
				: []
		}))
		: []

	const cityError = formik.touched.city && formik.errors.city
	const cityOptions = regionsWithCities && Array.isArray(regionsWithCities[selectedCountry])
		? regionsWithCities[selectedCountry].map(region => ({
			id: `${region.id}`,
			label: region.label,
			value: `${region.id}`,
			isGrouped: true,
			options: Array.isArray(region.cities)
				? region.cities.map(city => ({
					id: `${city.id}`,
					label: city.label,
					value: JSON.stringify({
						city: `${city.id}`,
						region: `${region.id}`
					})
				}))
				: []
		}))
		: []

	const cityDisabled = !formik.values.address.country || Number(formik.values.address.country) === -1 ||
		!cityOptions.length || regionsWithCitiesLoading

	const locationError = formik.touched.location && formik.errors.location
	const locationOptions = locations && formik.values.address.city && Number(formik.values.address.city) !== -1 &&
		Array.isArray(locations[formik.values.address.city])
		? locations[formik.values.address.city].map(location => ({
			id: `${location.id}`,
			label: location.label,
			value: `${location.value}`
		}))
		: []

	const locationsDisabled = !formik.values.address.city || Number(formik.values.address.city) === -1 ||
		!locationOptions.length || locationsLoading

	return (
		<div className='form-lokacija'>
			<Wrapper isError={!!countryError}>
				<SelectDropdown
					id='country'
					title={t('Država')}
					isDisabled={false}
					isSearchable
					isRequired
					value={formik.values.address.country}
					options={countryOptions}
					onChange={async value => {
						try {
							if (Number(value) !== -1) fetchCitiesByCountryWithinRegions(Number(value))

							await formik.setFieldValue('address.country', value)

							if (!formik.touched.address?.country) {
								formik.setFieldTouched('address.country', true)
							}

							formik.setFieldValue('address.city', -1)
							formik.setFieldValue('address.location', -1)
						} catch (err) {
							await formik.setFieldValue('address.country', -1)

							if (!formik.touched.address?.country) {
								formik.setFieldTouched(`address.country`, true)
							}

							formik.setFieldValue('address.city', -1)
							formik.setFieldValue('address.location', -1)
						}
					}}
				/>
				<ErrorMessage name='address.country' render={msg => <span className='form-group__error-message'>{msg}</span>} />
			</Wrapper>

			<Wrapper isError={!!cityError}>
				<SelectDropdown
					id='city'
					title={t('Grad')}
					isDisabled={cityDisabled}
					isSearchable
					isRequired
					value={selectedCityRegion}
					options={cityOptions}
					onChange={async value => {
						let parsedValue = -1

						try {
							parsedValue = JSON.parse(value)

							if (parsedValue === -1) parsedValue = { city: -1, region: -1 }

							if (Number(parsedValue.city) === -1) setSelectedCityRegion(-1)
							else setSelectedCityRegion(value)

							await formik.setFieldValue('address.city', parsedValue.city)
							await formik.setFieldValue('address.location', -1)
							formik.setFieldValue('address.region', parsedValue.region)

							if (!formik.touched.city) formik.setFieldTouched('address.city', true)

							if (Number(parsedValue.city) !== -1) fetchLocationsByCity(Number(parsedValue.city))
						} catch (err) {
							formik.setFieldValue('address.country', -1)
							formik.setFieldValue('address.city', -1)
							formik.setFieldValue('address.location', -1)
							formik.setFieldValue('address.region', -1)
							setSelectedCityRegion(-1)
						}
					}}
				/>
				<ErrorMessage name='address.city' render={msg => <span className='form-group__error-message'>{msg}</span>} />
			</Wrapper>

			<Wrapper isError={!!locationError}>
				<SelectDropdown
					id='location'
					title={t('Lokacija')}
					isDisabled={locationsDisabled}
					isSearchable
					isRequired
					value={formik.values.address.location || -1}
					options={locationOptions}
					onChange={async (location) => {
						await formik.setFieldValue('address.location', location)

						if (!formik.touched.location) formik.setFieldTouched('address.location', true)
					}}
				/>
				<ErrorMessage name='address.location' render={msg => <span className='form-group__error-message'>{msg}</span>} />
			</Wrapper>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		countries: getGroupedCountries(state),
		allCountries: getCountries(state),
		regionsWithCities: getCitiesByCountryWithinRegions(state),
		regionsWithCitiesLoading: getCitiesLoading(state),
		locations: getLocationsByCity(state),
		locationsLoading: getLocationsLoading(state)
	}
}

Address.defaultProps = {
	locations: {}
}

export default connect(mapStateToProps, {
	fetchCitiesByCountryWithinRegions,
	fetchLocationsByCity
})(Address)
