import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { hidePopupLogin } from 'SRC/modules/common/actions'
import {
  PopupLoginRegLink,
  PopupLoginSocServicesLogin,
  PopupLoginServiceLinksList
} from '../../../components'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { LoginInPopupForm } from 'SRC/modules/users/Auth/components'
import { getPopupLoginVisibility } from 'SRC/modules/common/selectors'

export class PopupLogin extends React.Component {
  componentDidMount () {
    document.addEventListener('mousedown', this.closePopup)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.closePopup)
  }

  closePopup = (e) => {
    const {wrapper} = this
    if (wrapper) {
      if (!wrapper.contains(e.target) && this.props.isPopupLoginBlockOpened) {
        this.props.hidePopupLogin()
      }
    }
  }

  render () {
    return (
      !this.props.isAuthorized
        ? <div
          className='prijavi-se-popup'
          style={{display: this.props.isPopupLoginBlockOpened ? 'block' : 'none'}}
          ref={wrapper => (this.wrapper = wrapper)}
        >
          <div className='prijavi-se-popup-main'>
            <LoginInPopupForm />
            <div className='prijavi-se-popup-ili'>
              <p>ili</p>
            </div>
            <PopupLoginSocServicesLogin />
          </div>
          <PopupLoginRegLink />
          <PopupLoginServiceLinksList />
        </div>
        : null
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthorized: getIsAuthorized(state),
  isPopupLoginBlockOpened: getPopupLoginVisibility(state)
})

PopupLogin.propTypes = {
  hidePopupLogin: PropTypes.func,
  isAuthorized: PropTypes.bool.isRequired,
  isPopupLoginBlockOpened: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, {hidePopupLogin})(PopupLogin)
