import initialState from '../../redux/initialState'
import actionTypes from '../../redux/actionTypes'

export const registrationReducer = (state = initialState.user.registration, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_PARENT_USERTYPE:
      return {
        ...state,
        currentParentUserType: action.payload
      }
    case actionTypes.SET_CURRENT_USERTYPE:
      return {
        ...state,
        currentUserType: action.payload
      }
    case actionTypes.SET_REGISTRATION_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case actionTypes.SET_REGISTRATION_SMS_WAS_SENT:
      return {
        ...state,
        smsCodeWasSent: action.payload
      }
    case actionTypes.SET_ACTIVE_TIP:
      return {
        ...state,
        activeTip: action.payload
      }
    case actionTypes.SET_REGISTRATION_LOGO:
      return {
        ...state,
        logo: action.payload
      }
    case actionTypes.SET_REGISTRATION_TEMP_LOGO:
      return {
        ...state,
        tempLogo: action.payload
      }
    default:
      return state
  }
}
