import React from 'react'
import PropTypes from 'prop-types'
import { Phone } from '../../Phone'
import { Buttons } from '../../Buttons'
import { Filters } from '../../Filters'
import { BusinessAddresses } from '../Addresses'

export const Container = ({ user, adStatus }) => {
  const visiblePhones = user.phones.filter(phone => {
    if (phone.hasOwnProperty('visible')) return phone.visible
    return true
  })
  return [
    <div className='sidebar-user-contact' key='phones'>
      {!adStatus || adStatus !== 'Sold' ? visiblePhones.map(phone => <Phone phone={phone} key={phone.phone} />) : null}
    </div>,
    <BusinessAddresses key='addresses' addresses={user.addresses} />,
    <Buttons key='buttons' />,
    <Filters key='filters' username={user.login} seo={user.seo} />
  ]
}

Container.propTypes = {
  adStatus: PropTypes.string,
  user: PropTypes.shape({
    phones: PropTypes.arrayOf(PropTypes.shape({phone: PropTypes.string.isRequired})).isRequired,
    addresses: PropTypes.array
  }).isRequired
}

Container.displayName = 'UserContactsBusiness'
