import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { GroupsList, SubGroupsList } from 'SRC/modules/groups/components'
import { CategoryMenu } from 'SRC/modules/categories/components'
import { getPaginationParams } from 'SRC/modules/ads/pagination/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { SecureShoppingBox } from 'SRC/modules/common/components/SecureShoppingBox'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { ElasticSearchInput } from '../ElasticSearch'
import config from 'SRC/config/config.json'
import { Form } from '../../Form'

export const AdsHeader = ({
  currentGroup,
  currentSubcategory,
  formStyle,
  paginationParams,
  filterValues,
  isAdPage
}) => {
  const showDefaultForm = currentGroup && !currentSubcategory

  const showSearch = (currentGroup && config.search.showTextSearchAndCategoryMenuOn.includes(currentGroup.seo))

  return (
    <div className='kategorije'>
      <GroupsList />

      <SubGroupsList className='kategorije-sub' />

      {!isAdPage ? (
        <div className={`kategorije-pretraga`}>
          {showSearch ? (
            <React.Fragment>
              <section className='category-search'>
                <div className='pretraga-dropdown'>
                  <CategoryMenu showDropdown={false} />
                </div>

                <ElasticSearchInput />
              </section>
            </React.Fragment>
          ) : null}

          <Form
            currentPage={paginationParams.currentPage}
            filterValues={filterValues}
            formStyle={showDefaultForm ? 'default' : (formStyle || 'basic')}
            currentSubcategory={currentSubcategory}
            currentGroup={currentGroup}
          />
        </div>
      ) : null}

      {/* <SecureShoppingBox /> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentGroup: getCurrentGroup(state),
    currentSubcategory: getCurrentSubCategory(state),
    paginationParams: getPaginationParams(state)
  }
}

AdsHeader.propTypes = {
  currentGroup: PropTypes.shape({
    categories: PropTypes.array,
    seo: PropTypes.string
  }),
  isAdPage: PropTypes.bool.isRequired,
  currentSubcategory: PropTypes.object,
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number
  }).isRequired,
  filterValues: PropTypes.object,
  formStyle: PropTypes.string
}

AdsHeader.defaultProps = {
  isAdPage: false
}

export default connect(mapStateToProps)(AdsHeader)
