import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from 'SRC/utils'
import useTranslation from 'next-translate/useTranslation'
import { DropdownItem } from '../DropdownItem'
import { DropdownSubGroup } from '../DropdownSubGroup'

const getStringValue = val => val ? val.toString() : ''

const getGroupOptions = (options, filter) => {
  if (!Array.isArray(options)) return []

  return options
}

const getSubGroups = (group, filter) => {
  if (!Array.isArray(group.subGroups)) return []

  return group.subGroups
}

const SelectAll = ({ show, allSelected, onClick }) => {
  const { t } = useTranslation('common')

  if (!show) return null

  return (
    <span className={`select-all ${allSelected ? ' selected' : ''}`}
      onClick={onClick}
    >
      {allSelected ? t('OČISTI') : t('SVE')}
    </span>
  )
}

export const DropdownGroup = ({ group, filter, checkedItems, hasSelectAll, onChange, onItemChange, ...restProps }) => {
  let allSelected = false
  const valuesOfGroupItems = Array.isArray(group.options) ? group.options.map(item => getStringValue(item.value)) : []

  if (Array.isArray(checkedItems) && checkedItems.length) {
    allSelected = valuesOfGroupItems.every(optionValue => checkedItems.includes(optionValue))
  }

  const toggleSelectAllGroupItems = () => {
    let newItems = [...checkedItems]

    if (allSelected) {
      newItems = checkedItems.filter(itemValue => !valuesOfGroupItems.includes(itemValue))
    } else {
      newItems = [...checkedItems, ...valuesOfGroupItems]
    }

    onChange(newItems)
  }

  const subGroups = getSubGroups(group, filter)
  const groupOptions = getGroupOptions(group.options, filter)

  return (
    <li key={`group-${group.id}`} className='select-dropdown-group'>
      <label className='select-dropdown-group-label'>
        {group.label}

        <SelectAll show={hasSelectAll} onClick={toggleSelectAllGroupItems} allSelected={allSelected} />
      </label>
      <ul className='select-dropdown-group-items'>
        {subGroups.map(subGroup => (
          <DropdownSubGroup
            key={subGroup.id}
            checkedItems={checkedItems}
            onChange={onChange}
            onItemChange={onItemChange}
            {...subGroup}
          />
        ))}
        {groupOptions.map((option, index) => (
          <DropdownItem
            key={`${option.id}-${option.label}`}
            option={option}
            checkedItems={checkedItems}
            onChange={onItemChange}
            {...restProps}
          />
        ))}
      </ul>
    </li>
  )
}

DropdownGroup.propTypes = {
  group: PropTypes.shape({
    options: PropTypes.array,
    label: PropTypes.string
  }).isRequired,
  checkedItems: PropTypes.array.isRequired,
  filter: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hasSelectAll: PropTypes.bool,
  onItemChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

SelectAll.propTypes = {
  show: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
