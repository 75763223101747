import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from 'SRC/utils'

import useTranslation from 'next-translate/useTranslation'

import { DropdownGroup } from '../DropdownGroup'
import { DropdownItem } from '../DropdownItem'
import { Label } from '../Label'
import { Title } from '../../Title'
import { Search } from '../../Search'

export const Dropdown = ({
	isDisabled,
	isSearchable,
	options,
	labelDefault,
	id,
	value,
	title,
	onChange,
	needColon,
	onMouseEnter,
	isRequired,
	isOptional
}) => {
	const [isOptionsVisible, setIsOptionsVisible] = React.useState(false)
	const [showTextSearch, setShowTextSearch] = React.useState(false)
	const [filter, setFilter] = React.useState('')

	const optionsRef = React.useRef()
	const dropdownRef = React.useRef()
	const textSearchRef = React.useRef()

  const { t } = useTranslation('common')

	React.useEffect(() => {
		document.addEventListener('mousedown', toggleOptionsVisibility)

		return () => { document.removeEventListener('mousedown', toggleOptionsVisibility) }
	}, [isDisabled])

  const toggleOptionsVisibility = e => {
    let optionsVisible = false

    if (isDisabled) {
			setIsOptionsVisible(optionsVisible)
			setShowTextSearch(false)
			setFilter('')

      return
    }

    if (dropdownRef.current && dropdownRef.current.contains(e.target)) optionsVisible = !isOptionsVisible
    if (optionsRef.current && optionsRef.current.contains(e.target)) optionsVisible = true
    if (textSearchRef.current && textSearchRef.current.contains(e.target)) optionsVisible = true

    const showTextSearch = isSearchable && optionsVisible

		setIsOptionsVisible(optionsVisible)
		setShowTextSearch(showTextSearch)
  }

  const getItems = () => {
    let items = [...options]

    if (isOptional) {
      items.unshift({
        id: `${id}-izaberi`,
        value: '-1',
        label: labelDefault || t('Izaberi')
      })
    }

    if (filter) {
      items = items.filter(item => {
        if (item.isGrouped) {
          return item.options.find(childItem => {
            const childItemLabel = normalizeString(childItem.label.toLowerCase())
            return childItemLabel.indexOf(filter.toLowerCase().normalize()) > -1
          })
        }

        const itemLabel = normalizeString(item.label.toLowerCase())
        return itemLabel.indexOf(filter.toLowerCase().normalize()) > -1
      })
    }

    return items
  }

  const onSelectDropdownChange = e => {
    const value = e.currentTarget.dataset.value

    onChange(value)

		setIsOptionsVisible(false)
		setShowTextSearch(false)
		setFilter('')
  }

	const items = getItems()

	return (
		<React.Fragment>
			<Title title={title} needColon={needColon} isRequired={isRequired} />

			<div
				className={`select-dropdown-picker ${isDisabled ? 'disabled' : ''}`}
				ref={dropdownRef}
				key={`singleselect-${id}`}
				onMouseEnter={onMouseEnter}
			>
				{showTextSearch
					? <Search ref={textSearchRef} setFilter={setFilter} />
					: <Label labelDefault={labelDefault} value={value} options={options} />
				}
			</div>

			{isOptionsVisible && options.length && !isDisabled ? (
				<div className='select-dropdown-container select-dropdown-single' ref={optionsRef}>
					<ul className='select-dropdown-items'>
						{items.map((item, index) => item.isGrouped
							? (
								<DropdownGroup
									key={item.id}
									group={item}
									groupIndex={index}
									filter={filter}
									value={value}
									onSelectDropdownChange={onSelectDropdownChange}
									scrollToSelectedItem
								/>
							) : (
								<DropdownItem
									key={`${item.id}-${item.label}`}
									value={value}
									item={item}
									isFirstItem={index === 0}
									onSelectDropdownChange={onSelectDropdownChange}
									scrollToSelectedItem
								/>
							))
						}
					</ul>
				</div>
			) : null}
		</React.Fragment>
	)
}

Dropdown.displayName = 'SingleSelectDropdown'

Dropdown.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string.isRequired,
  labelDefault: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    isGrouped: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      value: PropTypes.any
    }))
  })).isRequired,
  value: PropTypes.any,
  isDisabled: PropTypes.bool.isRequired,
  needColon: PropTypes.bool.isRequired,
  isSearchable: PropTypes.bool.isRequired,
  isOptional: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onChange: PropTypes.func.isRequired
}

Dropdown.defaultProps = {
  title: '',
  options: [],
  isSearchable: false,
  isOptional: true,
  needColon: true,
  isDisabled: false,
  isRequired: false
}
