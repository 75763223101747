import actionTypes from 'SRC/modules/redux/actionTypes'
import initialState from 'SRC/modules/redux/initialState'

export const equipmentReducer = (state = initialState.ads.equipment, action) => {
  switch (action.type) {
    case actionTypes.SET_EQUIPMENT:
      return {
        ...state,
        elements: action.payload
      }
    default:
      return state
  }
}
