export class AuthError extends Error {
  constructor (status, message = '') {
    super()
    this.name = 'AuthError'
    this.status = status
    this.message = message || this.getMessageByStatus(this.status)
  }

  getMessageByStatus = (status) => {
    switch (status) {
      case 401:
        return 'Pogrešni podaci za logovanje. Molimo pokušajte ponovo.'
      case 403:
        return 'Vaš nalog je blokiran zbog kršenja uslova korišćenja. Ukoliko imate pitanja kontaktirajte nas.'
      default:
        return 'Authorization error'
    }
  }
}
