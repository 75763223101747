import React from 'react'
import config from 'SRC/config/config.json'

export const itemsBeforeUserTypes = config.burgerMenu.items.beforeUserTypes.map(item => {
  return {
    id: item.key,
    link: item.link || '',
    as: item.as || '',
    text: item.text || '',
    icoClass: item.icoClass || '',
    iconSvg: item.iconSvg || '',
    className: item.className || '',
    pages: item.pages
  }
})

export const itemsAfterUserTypes = config.burgerMenu.items.afterUserTypes.map(item => {
  return {
    id: item.key,
    link: item.link || '',
    text: item.text || '',
    as: item.as || '',
    icoClass: item.icoClass || '',
    iconSvg: item.iconSvg || '',
    className: item.className || '',
    pages: item.pages
  }
})

export const itemsBottom = config.burgerMenu.items.bottom.map(item => {
  return {
    id: item.key,
    link: item.link || '',
    text: item.text || '',
    as: item.as || '',
    icoClass: item.icoClass || '',
    rightText: item.rightText || '',
    rightBg: item.rightBg || '',
    iconSvg: item.iconSvg || '',
    className: item.className || '',
    pages: item.pages
  }
})
