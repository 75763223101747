import React from 'react'
import Link from 'next/link'
import config from 'SRC/config/config.json'
import useTranslation from 'next-translate/useTranslation'

export const AddAdBlock = () => {
  const { t } = useTranslation('common')

  return (
    <div className='prodajete-tehniku'>
      <span className='footer-column-title'>{t('footer_addAd_header')}</span>
      <div className='postavi-oglas'>
        <p>{t('footer_addAd_text')}</p>
        <Link href={'/dodaj'}>
          <a className='dodaj-oglas-btn'>
            <i className='ico-footer-dodaj-oglas ico' />
            {t('footer_addAd_btnText')}
          </a>
        </Link>
      </div>
    </div>
  )
}
