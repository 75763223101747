import PropTypes from 'prop-types'
import React from 'react'
import { getChar, getRegularExpression } from 'SRC/utils'
import Imask from 'imask'
import { debounce } from 'throttle-debounce'
import { Hint } from '../Hint'

export const MaskedTextInput = ({
	id,
	name,
	title,
	isRequired,
	value,
	hint,
	measure,
	allowedCharacters,
	className,
	placeholder,
	mask,
	isDisabled,
	onChange,
	preventUpdate,
	resetPreventUpdate,
	onMouseEnter,
	onFocus
}) => {
  const [maskedValue, setMaskedValue] = React.useState('')

  const inputRef = React.useRef()

  const masked = Imask.createMask(mask)

  React.useEffect(() => {
    if (Number(value) === -1) return setMaskedValue('')
    let updatedV = value

    masked.resolve(`${value}`)
    setMaskedValue(masked.value)
    if (inputRef && inputRef.current) inputRef.current.value = maskedValue
  }, [value, maskedValue, inputRef.current?.value])

  const isRational = () => Array.isArray(allowedCharacters) && allowedCharacters.includes('rational')

  const onAcceptRational = e => {
    const text = e.target.value

    const endsWithDotOrZero = text.endsWith('.') || text.endsWith('.0')
    const dotRepeated = text.indexOf('.') !== text.lastIndexOf('.')

    if (!endsWithDotOrZero || dotRepeated || text.indexOf(',') !== -1) {
      masked.resolve(e.target.value)
      onChange(masked.unmaskedValue)
    }
  }

  const onAccept = e => {
    masked.resolve(e.target.value)

    onChange(masked.unmaskedValue)
  }

  const onKeyPress = e => {
    const chr = getChar(e)
    if (e.ctrlKey || e.altKey || chr == null) return

    if (Array.isArray(allowedCharacters) && allowedCharacters.length) {
      const regexp = getRegularExpression(allowedCharacters)
      const isValid = regexp ? regexp.test(chr) : true
      if (!isValid) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

	return (
		<React.Fragment>
			<label htmlFor={id} className='inline-label'>
				{`${title}:`} {isRequired ? <span className='required'>*</span> : null}
			</label>

			{hint ? <Hint hint={hint} /> : null}

      <input
        id={id}
        name={name}
        key={id}
        ref={inputRef}
        defaultValue={maskedValue}
        type='text'
        disabled={isDisabled}
        style={{ opacity: isDisabled ? 0.4 : 1 }}
        placeholder={placeholder}
        className={className}
        onKeyPress={onKeyPress}
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
        onChange={isRational() ? onAcceptRational : onAccept}
      />
			{measure ? <span className='input-addon'>{measure}</span> : null}
		</React.Fragment>
	)
}

MaskedTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string,
  measure: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  allowedCharacters: PropTypes.array,
  mask: PropTypes.object,
  preventUpdate: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  value: PropTypes.any.isRequired,
  resetPreventUpdate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func
}

MaskedTextInput.defaultProps = {
  allowedCharacters: [],
  isDisabled: false,
  className: ''
}
