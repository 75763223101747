import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { prepareFilterValues } from 'SRC/modules/ads/filter/functions'
import { CategoriesFilter } from 'SRC/modules/ads/filter/components'

import translations from '../../../../../../public/data/translations.json'


export const UserAdsCategoryFilterMobile = ({
  baseAs,
  baseHrefQuery,
  userInfo,
  currentSubcategory,
  filterValues
}) => {
  const { t, lang } = useTranslation('common')

  const locale = lang?.toUpperCase() || 'ME'

  return (
    <React.Fragment>
      <CategoriesFilter baseAs={baseAs} baseHrefQuery={baseHrefQuery} />

      {currentSubcategory && userInfo ? (
        <Link
          href={{
            pathname: '/mobileAdFilter',
            query: {
              page: 'category-filter',
              username: userInfo.username,
              adUserType: userInfo.type ? userInfo.type.seo : 'korisnik',
              category: currentSubcategory.seo,
              ...prepareFilterValues(filterValues)
            }
          }}
          as={{
            pathname: `/${translations.pretragaOglasa[locale]}/${currentSubcategory.seo}`,
            query: {
              username: userInfo.username,
              adUserType: userInfo.type ? userInfo.type.seo : 'korisnik',
              ...prepareFilterValues(filterValues)
            }
          }}
        >
          <a className='filter-btn'>
            {t('Filtriraj za detalje')}
          </a>
        </Link>
      ) : null}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  userInfo: getInfo(state),
  currentSubcategory: getCurrentSubCategory(state)
})

UserAdsCategoryFilterMobile.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  baseAs: PropTypes.string,
  baseHrefQuery: PropTypes.object,
  currentSubcategory: PropTypes.shape({
    seo: PropTypes.string
  }),
  filterValues: PropTypes.object
}

UserAdsCategoryFilterMobile.defaultProps = {
  filterValues: {}
}

export default connect(mapStateToProps)(UserAdsCategoryFilterMobile)
