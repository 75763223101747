import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'

export const ReportTextarea = ({classname, name, placeholder}) =>
  <div className={classname}>
    <Field name={name} placeholder={placeholder} rows='10' component='textarea' />
  </div>

ReportTextarea.propTypes = {
  classname: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
}
