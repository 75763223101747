import PropTypes from 'prop-types'
import React from 'react'
import { genders } from 'Core/constants'

export const Logo = ({gender, packageName}) =>
  <div className='sidebar-user-img'>
    {packageName === 'free' && !gender ? <i className={`ico-registracija-korisnik ico`} />
      : <i className={`ico-user-avatar-${gender === genders.male.code ? 'musko' : 'zensko'} ico`} />}
  </div>

Logo.propTypes = {
  gender: PropTypes.string,
  packageName: PropTypes.string
}
