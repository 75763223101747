import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'
// import { globalOptions } from 'SRC/core/constants/globalOptions'

export class Api extends BaseApi {
  static ResponseCodes = {
    REPORT_SUCCESSFULLY_ADDED: 0
  }
  getAd = async params => {
    const defaultResult = {}
    if (params && TypesHelper.isNotEmptyString(params.seo)) {
      try {
        const {body} = await Api.post(`/ads/getBySeo`, params)
        return body
      } catch (error) {
        return defaultResult
      }
    } else {
      return defaultResult
    }
  }

  getAdsReportTypes = async () => {
    const defaultResponse = []
    try {
      const {body} = await Api.post('/adsReportType/list')
      return body && body.adsReportTypes ? body.adsReportTypes : defaultResponse
    } catch (e) {
      return defaultResponse
    }
  }

  addReport = async params => {
    const response = {status: null, error: null}
    try {
      const {body} = await Api.post('/adsReport/add', params)
      if (body && !body.error && body.status === Api.ResponseCodes.REPORT_SUCCESSFULLY_ADDED) {
        response.status = body.status
      } else {
        if (body && body.error) {
          response.error = body.error
        } else {
          response.error = 'Unknown error'
        }
      }
    } catch (e) {
      response.error = e
    }
    return response
  }

  getSimilarAds = async adId => {
    const defaultResult = []
    const params = { adId, similarAdsTotalNumber: 8 }
    try {
      const {body} = await Api.post(`/ads/listSimilar`, params)
      return body.error ? defaultResult : body.similarAds
    } catch (error) {
      return defaultResult
    }
  }

  getRegistrationFormulas = async () => {
    try {
      const {body} = await Api.post(`/registrationFormula/list`)
      return body.error ? [] : body.registrationFormulas
    } catch (error) {
      return []
    }
  }

  getRegistrationFormulaDetails = async formulaId => {
    try {
      const {body} = await Api.post(`/registrationFormula/getById`, { registrationFormulaId: formulaId })
      return body.error ? {} : body.registrationFormula
    } catch (error) {
      return {}
    }
  }

  getRegistrationPremiumClasses = async () => {
    try {
      const {body} = await Api.post(`/registrationPremiumClass/list`)
      return body.error ? [] : body.registrationPremiumClasses
    } catch (error) {
      return []
    }
  }

  calculateRegistrationCost = async params => {
    try {
      const {body} = await Api.post(`/registrationCost/calculate`, params)
      console.log('params', params)
      console.log('body', body)
      return body.error || body.registrationCost === null ? 0 : body.registrationCost
    } catch (error) {
      return 0
    }
  }
}
