import config from 'SRC/config/config.json'
import { getSpecificationVisibilityCondition } from 'SRC/modules/categories/functions'

export const getConditionTrusty = (value, checkValues = []) => {
  let isConditionTrusty = false
  if (value) {
    if (Array.isArray(value)) {
      isConditionTrusty = value.some(val => checkValues.includes(val))
    } else {
      isConditionTrusty = checkValues.includes(value)
    }
  } else {
    isConditionTrusty = checkValues.includes('')
  }
  return isConditionTrusty
}

export const getIsVisible = visibilityConditionValues => {
  let isVisible = true
  if (!visibilityConditionValues.show && !visibilityConditionValues.hide) {
    isVisible = true
  }

  if (visibilityConditionValues.show && !visibilityConditionValues.hide) {
    isVisible = Object.values(visibilityConditionValues.show).some(v => v)
  }

  if (!visibilityConditionValues.show && visibilityConditionValues.hide) {
    isVisible = Object.values(visibilityConditionValues.hide).every(v => !v)
  }

  if (visibilityConditionValues.show && visibilityConditionValues.hide) {
    isVisible = Object.values(visibilityConditionValues.show).some(v => v) ||
      Object.values(visibilityConditionValues.hide).every(v => !v)
  }

  return isVisible
}

export const getSpecsWithUpdatedVisibility = ({
	categorySpecification,
	categorySpecifications,
	initialSpecificationValues,
	specificationValue,
	formType
}) => {
	const currentSpecification = categorySpecification.specification

	const dependentSpecifications = Array.isArray(categorySpecifications)
		? categorySpecifications.filter(item => Boolean(item.specification.dependent === currentSpecification.id))
		: []

	if (dependentSpecifications.length) {
		dependentSpecifications.forEach(depSpec => {
			const dependentSpecification = depSpec.specification
			const visibilityCondition = getSpecificationVisibilityCondition(dependentSpecification)
			const dependentSpecIndex = initialSpecificationValues.findIndex(spec => spec.id === dependentSpecification.id)

			const visibilityConditionValues = {
				hide: visibilityCondition.hide
					? Object.keys(visibilityCondition.hide).reduce((acc, curr) => {
						let specValue

						if (Number(curr) === currentSpecification.id) {
							specValue = specificationValue
						} else {
							specValue = initialSpecificationValues[dependentSpecIndex].value
						}

						const isConditionTrusty = getConditionTrusty(specValue, visibilityCondition.hide[curr])
						acc[curr] = isConditionTrusty

						return acc
					}, {})
					: null,
				show: visibilityCondition.show
					? Object.keys(visibilityCondition.show).reduce((acc, curr) => {
						let specValue

						if (Number(curr) === currentSpecification.id) {
							specValue = specificationValue
						} else {
							specValue = initialSpecificationValues[dependentSpecIndex].value
						}

						const isConditionTrusty = getConditionTrusty(specValue, visibilityCondition.show[curr])
						acc[curr] = isConditionTrusty

						return acc
					}, {})
					: null
			}

			const isVisible = getIsVisible(visibilityConditionValues)

			let visibility

			if (formType === 'addBasic' && !depSpec.required) {
				// Hide specifications that are not required in Basic Form
				const specTitle = dependentSpecification.title

				const basicFormSpecificationsExceptions = config.adForm.addAdForm.basicFormSpecificationsExceptions
				visibility = false
				if (Array.isArray(basicFormSpecificationsExceptions)) {
					visibility = basicFormSpecificationsExceptions.includes(specTitle)
				} else {
					visibility = false
				}
			} else {
				visibility = isVisible
			}

			const currentVisibility = !initialSpecificationValues[dependentSpecIndex].isHidden

			if (currentVisibility !== visibility) {
				if (!visibility) {
					console.log(`visibilityCondition hide specification ${dependentSpecification.id}/${dependentSpecification.title} depending on ${categorySpecification.specification.id}/${categorySpecification.specification.title}`)

					initialSpecificationValues[dependentSpecIndex] = {
						id: dependentSpecification.id,
						isHidden: true
					}
				} else {
					// console.log(`visibilityCondition unhide specification ${dependentSpecification.id}/${dependentSpecification.title} depending on ${categorySpecification.specification.id}/${categorySpecification.specification.title}`)

					// Unhide specification
					initialSpecificationValues[dependentSpecIndex] = {
						id: dependentSpecification.id,
						isHidden: false
					}
				}
			}
		})
	}

	return initialSpecificationValues
}

export const getInitialSpecificationValues = ({ categorySpecifications = [], adsSpecifications, formType = '', t }) => {
	const initialSpecificationValues = []

	categorySpecifications.forEach(categorySpec => {
		const initialFormSpecification = { id: categorySpec.specification.id }

		let actualSpecificationValue
		let isHidden = false

		/**
		 * Find ad specification
		 */
		if (Array.isArray(adsSpecifications)) {
			const adSpecification = adsSpecifications.find(adSpec => adSpec.specification.id === categorySpec.specification.id)

			if (adSpecification && typeof adSpecification.value !== 'undefined') {
				actualSpecificationValue = adSpecification.value
			}
		}

		if (!adsSpecifications) {
			// Check if specification has default value
			if (typeof categorySpec.specification.defaultValue !== 'undefined' && categorySpec.specification.defaultValue !== null) {
				actualSpecificationValue = categorySpec.specification.defaultValue
			}

			if (formType === 'addAdBasic' && !categorySpec.required) {
				// Hide specifications that are not required in Basic Form
				const specTitle = categorySpec.specification.title
				const basicFormSpecificationsExceptions = config.adForm.addAdForm.basicFormSpecificationsExceptions

				isHidden = true

				if (Array.isArray(basicFormSpecificationsExceptions)) {
					isHidden = !basicFormSpecificationsExceptions.map(item => t(item)).includes(specTitle)
				} else {
					isHidden = true
				}
			}
		}

		if (typeof actualSpecificationValue !== 'undefined') initialFormSpecification.value = actualSpecificationValue
		initialFormSpecification.isHidden = isHidden

		initialSpecificationValues.push(initialFormSpecification)
	})

	initialSpecificationValues.forEach(formSpec => {
		const categorySpecification = categorySpecifications.find(spec => spec.specification.id === formSpec.id)

		if (categorySpecification && categorySpecifications) {
			getSpecsWithUpdatedVisibility({
				categorySpecifications,
				categorySpecification,
				initialSpecificationValues,
				specificationValue: formSpec.value,
				formType
			})
		}
	})

	return initialSpecificationValues
}
