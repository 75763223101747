import React from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

export const AboutDiscountCard = ({ forApp }) => {
  const { t } = useTranslation('common')

  return <div className='novosti-text'>
    <h2>{t('Šta je Popust Kartica')}?</h2>

    {forApp ? (
      <>
        <p>{t('aboutDiscountCardText1')}</p>
        <Image src={'/icons/Popust_Kartica_Temp.svg'} alt='Popust kartica' />
      </>
    ) : (
      <div className='clearfix'>
        <p>
          {/* <img style={{ float: 'right', marginTop: '-10px' }} src='/icons/Popust_Kartica_Temp.svg'
            alt='Popust kartica' width={280} />
          Popust Kartica je program lojalnosti u koji je uključeno više partnera i svaki od njih vas nagrađuje
          popustima. U Popust Kartica programu ste uvijek na dobitku, jer svakog dana imate mogućnost da ostvarujete
          brojne uštede kod partnera programa. */}
          <div style={{ float: 'right', marginTop: '-10px' }}>
            <Image src={'/icons/Popust_Kartica_Temp.svg'} alt='Popust kartica' width={280} height={170} />
          </div>
          <p>{t('aboutDiscountCardText1')}</p>
        </p>
      </div>
    )}

    <h2>{t('Kako mogu da dobijem Popust Karticu')}?</h2>

    <p style={{ marginTop: 2 }}>{t('Popust Karticu možete dobiti brzo, lako i jednostavno')}!</p>
    <p>{t('Potrebno je da popunite online formular')} <a href='/popust-kartica/aktivacija'>
      <strong>{t('ZAHTJEV ZA POPUST KARTICU')}</strong></a> {t('ili pozovete broj')} <strong>067/733-941.</strong></p>
    <p>{t('Prilikom provjere zahtjeva kontaktiraće vas naš operater')}.</p>
  </div>
}

AboutDiscountCard.propTypes = {
  forApp: PropTypes.bool
}
