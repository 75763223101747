import React from 'react'
import withTranslation from 'next-translate/withTranslation'
import Link from 'next/link'
import moment from 'moment'
import { CookiesHelper } from 'SRC/core/cookiesHelper/CookiesHelper'
import config from 'SRC/config/config.json'

class CookieConsent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      accepted: true
    }
    this.cookies = new CookiesHelper()
  }

  componentDidMount() {
    const isCookiesAccepted = this.cookies.get('cookies_accepted')
    this.setState({ accepted: isCookiesAccepted })
  }

  acceptCookies = () => {
    const nextYear = moment(new Date()).add(1, 'year')
    this.cookies.set('cookies_accepted', true, { expires: new Date(nextYear) })
    this.setState({ accepted: true })
  }

  render() {
    const { i18n: { t } } = this.props

    return !this.state.accepted ? (
      <section className='cookie-consent'>
        <p>{config.siteName} {t('common:cookie-consent-text')}&nbsp;
          <Link
            href={{ pathname: '/static', query: { url: '/uslovi-koriscenja' } }}
            as='/uslovi-koriscenja'
          >
            <a>{t('common:uslove korišćenja')}</a>
          </Link>
          &nbsp;{t('common:i')} <Link href={{ pathname: '/static', query: { url: '/politika-privatnosti' } }} as='/politika-privatnosti'>
            <a>{t('common:politiku privatnosti')}</a>
          </Link>.
        </p>
        <button onClick={this.acceptCookies}>{t('common:Prihvati i zatvori')}</button>
      </section>
    ) : null
  }
}

export default withTranslation(CookieConsent)
