import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import { compose } from 'recompose'
import StpdBannerComponent from 'stpd-ad-component'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import config from 'SRC/config/config.json'
import { TopLinks } from '../Links'
import { LangVersionsList, Logo } from '../'
import { Menu } from '../Menu'
import { BurgerMenu } from '../BurgerMenu'
import { Languages } from '../../components'

const Header = ({ isMobileDevice, router }) => {
  return (
    <header style={{ position: 'relative' }}>
      {!isMobileDevice ? <TopLinks /> : null}
      <div className='brand-header'>
        <div className='logo-autodiler'>
          <Logo isMobileDevice={isMobileDevice} />
        </div>

        {isMobileDevice ? <Languages /> : null}

        {isMobileDevice ? <BurgerMenu asPath={router ? router.asPath : null} /> : null}

        {!isMobileDevice ? (
          <div className='banner-728-90-wrapper'>
            <div style={{ width: 728, height: 90 }}>
              <StpdBannerComponent
                refreshOnUrlChange={true}
                adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.header.adUnit}`}
                size={config.banners.header.sizes}
                divId={config.banners.header.slotId}
              />
            </div>
          </div>
        ) : null}
      </div>
      {!isMobileDevice ? <Menu /> : null}
    </header>
  )

}

const mapStateToProps = state => {
  return {
    isMobileDevice: getIsMobileDevice(state)
  }
}

Header.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps)
)

export default enhance(Header)
