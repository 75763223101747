import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import {
  LinksCommon,
  LinksForAuthUsers,
  LinksForNonAuthUsers,
  PopupLogin,
  Languages
} from '../../../components'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { togglePopupLoginVisibility } from 'SRC/modules/common/actions'
import { getPopupLoginVisibility } from 'SRC/modules/common/selectors'
import { compose } from 'recompose'
import { withRouter } from 'next/router'

export class TopLinks extends React.Component {
  togglePopupVisibility = () => {
    this.props.togglePopupVisibility(this.props.isPopupLoginBlockOpened)
  }

  render () {
    return (
      <div className={`top-nav ${this.props.isAuthorized ? ` signed-in` : ``}`}>
        <LinksCommon router={this.props.router} />
        {
          this.props.isAuthorized
            ? <LinksForAuthUsers />
            : <LinksForNonAuthUsers togglePopupVisibility={this.togglePopupVisibility} />
        }

        <Languages />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthorized: getIsAuthorized(state),
  isPopupLoginBlockOpened: getPopupLoginVisibility(state)
})

const mapDispatchToProps = (dispatch) => ({
  togglePopupVisibility: (state) => {
    dispatch(togglePopupLoginVisibility(state))
  }
})

TopLinks.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isPopupLoginBlockOpened: PropTypes.bool.isRequired,
  togglePopupVisibility: PropTypes.func,
  router: PropTypes.shape()
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TopLinks)
