import React from 'react'
import { Item } from '../Item'
import { serviceLinks } from '../data/serviceLinks'

export const List = () => (
  <div className='prijavi-se-popup-registracija-footer'>
    {
      serviceLinks.map(link => [
        <Item {...link} key={link.id}>
          {link.text}
        </Item>,
        '\n'
      ])
    }
  </div>
)
