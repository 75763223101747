import PropTypes from 'prop-types'
import React from 'react'
import withTranslation from 'next-translate/withTranslation'
import { List } from '../List'
import { Navigation } from '../Navigation'
import { getCurrentSubCategory, getActiveCategories } from '../../../selectors/index'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { smoothScroll } from 'src/utils/index'

export class Menu extends React.Component {
  constructor (props) {
    super(props)
    this.categoryList = React.createRef()
    this.categoryNavigation = React.createRef()
    this.state = {
      currentIndex: 0,
      headersOffset: [],
      scroll: 0
    }
  }

  scrollToCategory = (e) => {
    const sourceId = e.target.dataset.id
    if (sourceId && this.categoryList && this.categoryList.current) {
      const targetNode = this.categoryList.current.querySelectorAll([`[data-header="${sourceId}"]`])[0]
      smoothScroll(this.categoryList.current, targetNode.offsetTop)
    }
  }

  handleScroll = (e) => {
    const scrollBorder = e.target.scrollTop
    const currentIndex = this.state.headersOffset.filter(
      this.offsetFilter.bind(null, scrollBorder)
    ).length - 1
    this.setState({
      ...this.state,
      scroll: e.target.scrollTop,
      currentIndex
    })
  }

  offsetFilter = (offset, item) => {
    return offset >= item
  }

  // componentDidMount () {
  //   if (this.categoryList && this.categoryList.current) {
  //     this.categoryList.current.addEventListener('scroll', this.handleScroll)
  //     const headersOffset = Array.from(
  //       this.categoryList.current.querySelectorAll('.kategorije-pretraga-mega-menu-content-header')
  //     ).map(item => item.offsetTop)

  //     this.setState({
  //       ...this.state,
  //       headersOffset
  //     })
  //   }
  // };

  // componentWillUnmount () {
  //   if (this.categoryList && this.categoryList.current) {
  //     this.categoryList.current.removeEventListener('scroll', this.handleScroll)
  //   }
  // };

  shouldComponentUpdate (nextProps, nextState) {
    return this.state.currentIndex !== nextState.currentIndex || this.props.currentSubcategory !== nextProps.currentSubcategory ||
      (!this.props.categories.length && nextProps.categories.length)
  }

  render () {
    const {
      currentSubcategory,
      baseAs,
      baseHref,
      categories,
      showDropdown,
      needScroll,
      hasGetParamsInBaseHref,
      i18n: { t }
    } = this.props

    return (
      <div className='sve-kategorije-btn'>
        <a className='categories-dropdown__button' href='javascript:void(0)'>
          {currentSubcategory && currentSubcategory.title ? currentSubcategory.title : t('common:Sve kategorije')}
          {showDropdown ? <i className='ads-header-ico ico-pretraga-text-search-dropdown-strelica ico' /> : null}
        </a>
        {showDropdown ? (
          <div className='kategorije-pretraga-mega-menu'>
            <Navigation
              items={categories}
              onHeaderClick={this.scrollToCategory}
              currentIndex={this.state.currentIndex}
              ref={this.categoryNavigation}
            />
            <List
              items={categories}
              ref={this.categoryList}
              baseHref={baseHref}
              baseAs={baseAs}
              hasGetParamsInBaseHref={hasGetParamsInBaseHref}
              needScroll={needScroll}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  categories: getActiveCategories(state),
  currentSubcategory: getCurrentSubCategory(state)
})

Menu.propTypes = {
  categories: PropTypes.array.isRequired,
  currentSubcategory: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  baseHref: PropTypes.string,
  baseAs: PropTypes.string,
  hasGetParamsInBaseHref: PropTypes.bool,
  needScroll: PropTypes.bool,
  showDropdown: PropTypes.bool
}

Menu.defaultProps = {
  categories: [],
  showDropdown: true
}

const enhance = compose(
  connect(mapStateToProps),
  withTranslation
)

export default enhance(Menu)
