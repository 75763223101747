import React from 'react'
import { TypesHelper } from 'SRC/utils'
import PropTypes from 'prop-types'
import { isJsonString } from 'src/utils/Utils'
import useTranslation from 'next-translate/useTranslation'
import config from 'SRC/config/config.json'

const renderCompatibilitiesForSingleBrand = ({ brand, product }, count) => {
  return [
    `${brand ? brand.name : ''} ${product ? ` - ${product.model}` : ''}`,
    count > 1 ? ` (+${count - 1})` : ''
  ]
}

const renderCompatibilitiesForMultipleBrands = (compatibilityBrands, restBrands) => {
  return [
    compatibilityBrands.slice(0, 5).map(brand => {
      if (brand.logo) {
        return <img src={`${config.mediaCDN}${brand.logo}`} alt={brand.name} width={20}
          style={{ marginRight: 2 }} />
      } else {
        restBrands++
        return null
      }
    }),
    restBrands ? ` +${restBrands}` : ''
  ]
}

const renderCompatibilities = (value, t) => {
  const count = value.compatibilitiesCount
  const countBrands = value.compatibilityBrandsCount
  const compatibilities = value.compatibilities
  const compatibilityBrands = value.compatibilityBrands

  const categoryText = compatibilities[0].category.compatibilityTitle
    ? compatibilities[0].category.compatibilityTitle
    : compatibilities[0].category.title
  let yearsText = ''
  let restBrands = countBrands <= 5 ? 0 : countBrands - 5

  if (count > 1) {
    yearsText = t('Za više godišta')
  } else {
    if (count === 1) {
      const years = compatibilities[0].years && compatibilities[0].years.length
        ? compatibilities[0].years.map(y => Number(y)) : null
      if (years) {
        const yMin = Math.min(...years)
        const yMax = Math.max(...years)

        yearsText = yMin === yMax ? yMin : `${yMin} - ${yMax}`
      }
    }
  }

  return <ul>
    <li>
      <b>{t('Za')}:</b> {categoryText}
    </li>
    <li style={{ maxHeight: 20 }}>
      {countBrands > 1
        ? renderCompatibilitiesForMultipleBrands(compatibilityBrands, restBrands)
        : countBrands === 1
          ? renderCompatibilitiesForSingleBrand({
            brand: Array.isArray(compatibilityBrands) && compatibilityBrands.length ? compatibilityBrands[0] : null,
            product: compatibilities[0].product || null
          }, count)
          : null}
    </li>
    <li>{yearsText}</li>
  </ul>
}

const renderServiceAndServiceType = (service, serviceType) => {
  return <ul>
    <li>{service.name}</li>
    <li>{serviceType.name}</li>
  </ul>
}

export const GridView = ({
  specs, compatibilities, compatibilityBrands, compatibilitiesCount,
  compatibilityBrandsCount, service, serviceType
}) => {
  const { t } = useTranslation('common')
  const hasSpecs = TypesHelper.isNotEmptyArray(specs)

  return (
    <div className={`oglasi-item-description ${hasSpecs || compatibilities.length || (service && serviceType)
      ? `` : `oglasi-item-description_no-specs`}`}>
      {
        hasSpecs
          ? <ul>
            {
              specs.map((item, index) => {
                if (item) {
                  let itemValue = item.value
                  if (isJsonString(item.value) && Array.isArray(JSON.parse(item.value))) {
                    itemValue = JSON.parse(item.value).join(', ')
                  }
                  if (item.measure) itemValue += ` ${item.measure}`

                  return [
                    <li key={index}>
                      <span className='oglasi-item-description_spec-title' title={item.title}>{item.title}</span>
                      <span className='oglasi-item-description_spec-value' title={itemValue}>
                        :&nbsp;{itemValue}
                      </span>
                    </li>,
                    '\n'
                  ]
                } else {
                  return ''
                }
              })
            }
          </ul>
          : compatibilities && compatibilities.length
            ? renderCompatibilities({
              compatibilities,
              compatibilityBrands,
              compatibilitiesCount,
              compatibilityBrandsCount
            }, t)
            : service && serviceType ? renderServiceAndServiceType(service, serviceType) : ''
      }
    </div>
  )
}

GridView.propTypes = {
  specs: PropTypes.arrayOf(PropTypes.object).isRequired,
  compatibilities: PropTypes.arrayOf(PropTypes.object),
  compatibilityBrands: PropTypes.array,
  compatibilitiesCount: PropTypes.number,
  compatibilityBrandsCount: PropTypes.number,
  service: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  serviceType: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
}

GridView.defaultProps = {
  specs: []
}
