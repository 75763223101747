import PropTypes from 'prop-types'
import React from 'react'
import { UserCardFree } from '../Free'
import { UserCardBusiness } from '../Business'

export const Container = ({user}) => (
  user && user.type === 'business' ? <UserCardBusiness user={user} /> : <UserCardFree user={user} />
)

Container.propTypes = {
  user: PropTypes.shape({type: PropTypes.oneOf(['free', 'business']).isRequired}).isRequired
}

Container.displayName = 'UserCard'
