import PropTypes from 'prop-types'
import React from 'react'

export const Address = ({country, city, location}) =>
  <div className='sidebar-user-contact-content sidebar-user-contact-lokacija'>
    <i className='ico-lokacija ico' />
    <p>{country}</p>
    <p>{city} / {location}</p>
  </div>

Address.propTypes = {
  country: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
}
