import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { compose } from 'recompose'
import useTranslation from 'next-translate/useTranslation'
import { AdsBrandsApi } from 'SRC/modules/ads/brands/api'
import { AdsProductsApi } from 'SRC/modules/ads/products/api'
import { AdFilterField, SelectDropdown } from 'SRC/ui/FormElementsNew'
import { adFiltersLabels } from 'SRC/core/constants'

const brandsApi = new AdsBrandsApi()
const productsApi = new AdsProductsApi()

export const BrandsProducts = ({ category }) => {
  const [brands, setBrands] = useState([])
  const [isBrandsLoading, setIsBrandsLoading] = useState(false)
  const [productsByBrand, setProductsByBrand] = useState({})
  const [isProductsLoading, setIsProductsLoading] = useState(false)

  const { t } = useTranslation('common')

  const formik = useFormikContext()

  const getBrands = async () => {
    setIsBrandsLoading(true)

    const result = await brandsApi.getBrandsByCategory(category.id)

    setBrands(result.map(brand => ({
      id: brand.id,
      label: brand.name,
      seo: brand.seo,
      value: brand.id,
      stared: brand.stared
    })))

    setIsBrandsLoading(false)
  }

  useEffect(() => {
    if (!category) return

    getBrands()
  }, [category?.id])

  useEffect(() => {
    if (!formik?.values?.brands?.length) {
      formik.setFieldValue('products', [])
      formik.setFieldValue('productsText', [])
      formik.setFieldValue('modelBc', {})

      return
    }

    updateProducts(formik.values.brands)

  }, [formik?.values?.brands?.length])

  const getBrandsField = () => {
    const isDisabled = !category || isBrandsLoading || !brands.length

    return {
      id: 'brands',
      isDisabled,
      title: t('Proizvođači'),
      options: getBrandsOptions(),
      checkedItems: formik.values.brands,
      onChange: onBrandsChange,
      isSearchable: true,
      isMultiple: true
    }
  }

  const getBrandsOptions = () => {
    const staredBrands = brands.filter(brand => brand.stared)

    if (Array.isArray(staredBrands) && staredBrands.length) {
      return [
        {
          id: 'stared-brands',
          label: t('Popularni'),
          isGrouped: true,
          options: staredBrands.map(brand => ({
            ...brand,
            id: `stared-brands-${brand.id}`,
            key: `stared-brands-${brand.id}`,
            value: `${brand.id}`
          }))
        },
        {
          id: 'all-brands',
          label: t('Svi proizvođači'),
          isGrouped: true,
          options: brands.map(brand => ({
            ...brand,
            id: `all-brands-${brand.id}`,
            key: `stared-brands-${brand.id}`,
            value: `${brand.id}`
          }))
        }
      ]
    }

    return brands.map(brand => ({
      ...brand,
      id: `all-brands-${brand.id}`,
      key: `stared-brands-${brand.id}`,
      value: `${brand.id}`
    }))
  }

  const onBrandsChange = async selectedBrands => {
    if (Array.isArray(selectedBrands) && selectedBrands.length > 0) {
      formik.setFieldValue('brands', selectedBrands)

      const brandsText = [];
      const brandsBcs = [];

      (selectedBrands || []).map(brandId => {
        const findBrand = brands.find(br => br.id === Number(brandId));

        brandsText.push(findBrand?.label || '')
        brandsBcs.push({ seo: findBrand.seo, text: findBrand?.label || '' })
      })

      formik.setFieldValue('brandsText', brandsText)

      if (brandsBcs.length === 1) formik.setFieldValue('brandBc', brandsBcs[0])
      else formik.setFieldValue('brandBc', {})
    } else {
      formik.setFieldValue('brands', [])
      formik.setFieldValue('products', [])
      formik.setFieldValue('brandsText', [])
      formik.setFieldValue('brandBc', {})
      formik.setFieldValue('productsText', [])
    }
  }

  /**
   * Products
   */
  const getProductsField = () => {
    const isDisabled = !formik.values.brands || !formik.values.brands.length || isProductsLoading

    return {
      id: 'products',
      isDisabled,
      title: t('Modeli'),
      options: getProductsOptions(),
      checkedItems: formik.values.products,
      onChange: onProductsChange,
      isSearchable: true,
      hasSelectAll: true,
      isMultiple: true
    }
  }

  const updateProducts = async selectedBrands => {
    const _productsByBrand = { ...productsByBrand }

    if (category) {
      setIsProductsLoading(true)

      for (const brand of selectedBrands) {
        if (!productsByBrand[brand]) {
          const products = await productsApi.fetchProductByBrandAndCategory({
            brand: Number(brand),
            category: category.id
          })

          _productsByBrand[brand] = products?.length ? products : []
        }
      }
    }

    let productsNew = []
    let productsText = []
    let productsBcs = []

    if (formik.values.products) {
      for (const brand of selectedBrands) {
        const brandProducts = _productsByBrand[brand]

        const diff = Array.isArray(brandProducts) ? brandProducts.filter(p => {
          return formik.values.products.includes(`${p.id}`)
        }) : []

        const diffIds = diff.map(p => `${p.id}`);

        if (diffIds) productsNew.push(...diffIds);

        const findBrand = brands.find(br => br.id === Number(brand));

        (diff || []).forEach(product => {
          if (findBrand) {
            productsText.push(`${findBrand.label} ${product.model}`)
            productsBcs.push({ seo: product.seo, text: product.model })
          }
        })
      }
    }

    if (productsNew.length) {
      formik.setFieldValue('products', productsNew)
      formik.setFieldValue('productsText', productsText)

      if (productsBcs.length === 1) formik.setFieldValue('modelBc', productsBcs[0])
      else formik.setFieldValue('modelBc', {})
    } else {
      formik.setFieldValue('products', [])
      formik.setFieldValue('productsText', [])
      formik.setFieldValue('modelBc', {})
    }

    setProductsByBrand(_productsByBrand)
    setIsProductsLoading(false)
  }

  const getProductsOptions = () => {
    if (formik.values.brands && formik.values.brands.length) {
      return formik.values.brands.map(brandId => {
        const products = productsByBrand[brandId]

        const brand = brands.find(brand => brand.id === Number(brandId));

        const productsOptions = Array.isArray(products) ? products.filter(p => !p.series).map(product => ({
          id: `${brandId}_${product.id}`,
          key: `${brandId}_${product.id}`,
          value: `${product.id}`,
          label: product.model
        })) : []

        const productsBySeries = {}
        let subGroups = []

        if (Array.isArray(products)) {
          products.filter(p => p.series).forEach(product => {
            const productItem = {
              id: `${brandId}_${product.id}`,
              key: `${brandId}_${product.id}`,
              value: `${product.id}`,
              label: product.model
            }

            if (!productsBySeries[product.series]) {
              productsBySeries[product.series] = {
                id: `subgroup-${product.series}`,
                label: product.series,
                selectLabels: {
                  select: t('Izaberi seriju'),
                  unselect: t('Izbriši seriju')
                },
                options: []
              }
            }

            productsBySeries[product.series].options.push(productItem)
          })

          if (Object.keys(productsBySeries).length) {
            subGroups = Object.keys(productsBySeries).reduce((acc, curr) => {
              acc.push(productsBySeries[curr])

              return acc
            }, [])
          }
        }

        return {
          id: brandId,
          label: brand ? brand.label : '',
          isGrouped: true,
          subGroups,
          options: productsOptions
        }
      })
    }
    return []
  }

  const onProductsChange = selectedProducts => {
    if (Array.isArray(selectedProducts) && selectedProducts.length) {
      formik.setFieldValue('products', selectedProducts)

      const productsText = [];
      const productsBcs = [];

      (formik.values.brands || []).forEach(brandId => {
        (selectedProducts || []).forEach(productId => {
          const findProduct = (productsByBrand[brandId] || []).find(pr => pr.id === Number(productId));
          const findBrand = brands.find(br => br.id === Number(brandId));

          if (findBrand && findProduct) {
            productsText.push(`${findBrand.label} ${findProduct.model}`)
            productsBcs.push({ seo: findProduct.seo, text: findProduct.model })
          }
        })
      })

      formik.setFieldValue('productsText', productsText)

      if (productsBcs.length === 1) formik.setFieldValue('modelBc', productsBcs[0])
      else formik.setFieldValue('modelBc', {})
    } else {
      formik.setFieldValue('products', [])
      formik.setFieldValue('productsText', [])
      formik.setFieldValue('modelBc', {})
    }
  }

  const brandsField = getBrandsField()
  const productsField = getProductsField()

  return (
    <React.Fragment>
      <AdFilterField key='brands-filter'>
        <SelectDropdown {...brandsField} />
      </AdFilterField>

      {category && category.requiredAdOptions === 'BrandModel'
        ? <AdFilterField key='products-filter'>
          <SelectDropdown {...productsField} />
        </AdFilterField>
        : null}
    </React.Fragment>
  )
}

BrandsProducts.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    requiredAdOptions: PropTypes.oneOf(['Brand', 'BrandModel', 'Services', null])
  }).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}
