import actionTypes from 'SRC/modules/redux/actionTypes'

export const setPopupLoginVisibility = (payload) => ({
  type: actionTypes.SET_POPUP_LOGIN_BLOCK_VISIBILITY,
  payload
})

export const togglePopupLoginVisibility = (state) => setPopupLoginVisibility(!state)
export const showPopupLogin = () => setPopupLoginVisibility(true)
export const hidePopupLogin = () => setPopupLoginVisibility(false)
