import { getCurrentLocale } from 'SRC/modules/common/functions'

import { AdsEquipmentApi } from '../../api'
import { setEquipment } from '../setEquipment'

export const fetchEquipment = (category, locale = getCurrentLocale()) => {
  return async (dispatch) => {
    try {
      const api = new AdsEquipmentApi()
      const equipment = await api.fetchEquipmentByCategory(category, locale)
      dispatch(setEquipment(equipment))
    } catch (error) {
      dispatch(setEquipment([]))
    }
  }
}
