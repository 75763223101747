import React from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'src/utils/Utils'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'


export const Footer = ({ userTypes }) => {
  const { t } = useTranslation('common')

  return (
    <div className='sidebar-item-footer'>
      <div className='sidebar-footer-bg'>
        {userTypes.filter(userType => userType).map(userType => (
          <Link
            key={userType.seoPlu}
            href={{
              pathname: 'users',
              query: {
                userType: userType.seo
              }
            }}
            as={`/${userType.seoPlu}`}
          >
            <a key={userType.id}>
              {t(capitalize(userType.namePlu))}
            </a>
          </Link>
        ))}
      </div>
    </div>
  )
}

Footer.propTypes = {
  userTypes: PropTypes.array.isRequired
}
