import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import Router from 'next/router'
import { useOnScreen } from 'SRC/modules/common/hooks'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'
import { updateFavouriteProfiles, fetchFavouriteProfiles } from 'src/modules/users/Profile/actions'
import { getFavouriteProfiles } from 'src/modules/users/Profile/selectors'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { getUserTypes } from 'src/modules/common/selectors'

const cookies = new CookiesHelper()

const getProfileType = ({ userInfo, userTypes }) => {
  if (!userInfo || !Array.isArray(userTypes)) return null

  return typeof userInfo.type === 'number'
    ? userInfo.type
    : userTypes.find(t => t.seo === userInfo.type).id
}

const SaveProfile = ({
  userInfo,
  userTypes,
  style,
  isAuthorized,
  favouriteProfiles,
  fetchFavouriteProfiles,
  updateFavouriteProfiles
}) => {
  const { t } = useTranslation('common')
  const [fetch, setFetch] = React.useState(true)
  const [isFavourite, setIsFavourite] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const profileType = getProfileType({ userInfo, userTypes })

  const ref = React.useRef()
  const isVisible = useOnScreen(ref)

  const getInitialProfiles = () => {
    const userId = cookies.get('userId')
    const token = cookies.get('token')

    if (!userInfo || !userId || !token) return

    fetchFavouriteProfiles(userId, token, profileType, 1, {}, 1000)
  }

  const handleClick = async () => {
    if (!isAuthorized) return Router.push('/profile', '/profil')

    const userId = cookies.get('userId')
    const token = cookies.get('token')

    setIsLoading(true)

    const add = !isFavourite
    await updateFavouriteProfiles(userInfo.id, userId, token, add, profileType)

    setIsLoading(false)
  }

  React.useEffect(() => {
    const isThisProfileFavourite = !!favouriteProfiles.elements.find(favProfile => favProfile.username === userInfo.username)
    setIsFavourite(isThisProfileFavourite)

  }, [favouriteProfiles.count, userInfo.username])

  React.useEffect(() => {
    if (!isAuthorized) return

    if (isVisible && fetch) {
      setFetch(false)
      getInitialProfiles()
    }
  }, [isVisible])

  return (
    <div className='sidebar-user-akcije-item' style={style} ref={ref}>
      {!isLoading ? <a href='javascript:void (0)' onClick={handleClick}
        className={isFavourite ? 'active' : ''}>
        <i className='ico-user-sacuvaj-korisnika ico' />
        <p>{isFavourite ? t('Sačuvan profil') : t('Sačuvaj profil')}</p>
      </a> : <div style={{ width: 52, height: 52, margin: 'auto' }} className='preloader__icon' />}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const userTypes = getUserTypes(state)

  const profileType = getProfileType({ userInfo: ownProps.userInfo, userTypes })

  return {
    userTypes,
    favouriteProfiles: getFavouriteProfiles(state, profileType),
    isAuthorized: getIsAuthorized(state)
  }
}

SaveProfile.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired
  }),
  updateFavouriteProfiles: PropTypes.func.isRequired,
  fetchFavouriteProfiles: PropTypes.func.isRequired,
  userTypes: PropTypes.array.isRequired,
  favouriteProfiles: PropTypes.object.isRequired,
  style: PropTypes.object,
  isAuthorized: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, { updateFavouriteProfiles, fetchFavouriteProfiles })(SaveProfile)
