import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { Services } from '../Services'
import { BrandsProducts } from '../BrandsProducts'

const Container = ({ category, ...restProps }) => {
  if (category) {
    if (category.requiredAdOptions === 'Services') {
      return <Services {...restProps} category={category} />
    }

    if (['BrandOnly', 'BrandModel'].includes(category.requiredAdOptions)) {
      return <BrandsProducts {...restProps} category={category} />
    }
  }

  return null
}

const mapStateToProps = state => {
  return {
    category: getCurrentSubCategory(state)
  }
}

Container.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    requiredAdOptions: PropTypes.string
  }).isRequired
}

export default connect(mapStateToProps)(Container)
