import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Router from 'next/router'
import withTranslation from 'next-translate/withTranslation'
import { SelectDropdown, AdFilterField } from 'SRC/ui/FormElementsNew'
import {
  getCurrentSubCategory,
  getGroupCategories,
  getActiveCategories
} from 'SRC/modules/categories/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'

class CategoriesFilter extends React.Component {
  /**
   * Categories by current group
   */
  prepareCategoriesByCurrentGroup = () => {
    const { categories, currentGroup } = this.props

    if (Array.isArray(categories)) {
      const groupCategories = currentGroup ? categories.filter(category => category.group === currentGroup.id) : []

      return groupCategories.map(category => ({
        id: `cat-${category.id}`,
        label: category.title,
        value: `${category.seo}`
      }))
    }

    return []
  }

  getCategoryValue = () => {
    const { currentSubCategory } = this.props
    let value = '-1'

    if (currentSubCategory) {
      value = `${currentSubCategory.seo}`
    }

    return value
  }

  onCategoryByCurrentGroupChange = selectedCategory => {
    const { currentSubCategory, currentGroup, baseHref, baseHrefQuery, baseAs } = this.props

    const href = {
      pathname: baseHref ? baseHref.pathname : '/category',
      query: { ...baseHrefQuery }
    }

    let as = baseAs

    if (Number(selectedCategory) === -1) {
      if (!currentGroup || !currentSubCategory) return null

      if (currentSubCategory) {
        href.query.category = currentSubCategory.seo
        as += currentSubCategory.seo
      }

      return Router.push(href, as)
    }

    href.query.category = selectedCategory
    as += selectedCategory

    console.log('onCategoryByCurrentGroupChange href', href)
    console.log('onCategoryByCurrentGroupChange as', as)

    Router.push(href, as)
  }

  /**
   * End Categories by current group
   */

  /**
   * Active Categories
   */
  prepareActiveCategories = () => {
    const { activeCategories } = this.props

    return Array.isArray(activeCategories) ? activeCategories.map(parentCategory => ({
      id: parentCategory.id,
      label: parentCategory.title,
      isGrouped: true,
      options: parentCategory.children.map(childCategory => ({
        id: childCategory.id,
        label: childCategory.title,
        value: childCategory.seo
      }))
    })) : []
  }

  onActiveCategoryChange = selectedCategory => {
    const { baseAs, baseHrefQuery } = this.props

    let as = baseAs
    let href = {
      pathname: '/user',
      query: {
        ...baseHrefQuery
      }
    }

    console.log('onActiveCategoryChange as', as)
    console.log('onActiveCategoryChange href', href)

    if (Number(selectedCategory) === -1) {
      return Router.push(href, as)
    }

    href = {
      pathname: '/user',
      query: {
        ...baseHrefQuery,
        category: selectedCategory
      }
    }

    as = `${baseAs}${selectedCategory}`

    Router.push(href, as)
  }
  /**
   * End Active Categories
   */

  render () {
    const { activeCategories, baseHrefQuery, i18n: { t } } = this.props

    if (baseHrefQuery.username) {
      const preparedActiveCategories = this.prepareActiveCategories()

      console.log('preparedActiveCategories', preparedActiveCategories)

      return (
        <AdFilterField key='active-categories-filter'>
          <SelectDropdown
            id='active-categories'
            title={t('common:Kategorije')}
            isDisabled={!Array.isArray(activeCategories) || !activeCategories.length}
            isSearchable
            value={this.getCategoryValue()}
            options={preparedActiveCategories}
            onChange={this.onActiveCategoryChange}
          />
        </AdFilterField>
      )
    }

    const categoriesByCurrentGroup = this.prepareCategoriesByCurrentGroup()

    if (Array.isArray(categoriesByCurrentGroup) && categoriesByCurrentGroup.length > 1) {
      return (
        <AdFilterField key='categories-filter'>
          <SelectDropdown
            title={t('common:Kategorije')}
            value={this.getCategoryValue()}
            isSearchable
            options={categoriesByCurrentGroup}
            onChange={this.onCategoryByCurrentGroupChange}
          />
        </AdFilterField>

      )
    }

    return null
  }
}

const mapStateToProps = state => {
  return {
    currentSubCategory: getCurrentSubCategory(state),
    categories: getGroupCategories(state),
    currentGroup: getCurrentGroup(state),
    activeCategories: getActiveCategories(state)
  }
}

CategoriesFilter.propTypes = {
  currentSubCategory: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string
  }).isRequired,
  currentGroup: PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    parentCategory: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      seo: PropTypes.string
    }))
  }).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    seo: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      seo: PropTypes.string
    }))
  })).isRequired,
  activeCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    parentCategory: PropTypes.number,
    title: PropTypes.string,
    seo: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      seo: PropTypes.string
    }))
  })),
  baseAs: PropTypes.string,
  baseHrefQuery: PropTypes.object,
  baseHref: PropTypes.object
}

CategoriesFilter.defaultProps = {
  baseAs: '/',
  baseHrefQuery: {},
  baseHref: {},
  categories: []
}

const enhance = compose(
  connect(mapStateToProps),
  withTranslation
)

export default enhance(CategoriesFilter)
