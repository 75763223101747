export const formatPriceEnding = (price, formatZeroSum = true) => {
  const defaultResult = formatZeroSum ? 'Besplatno' : '0 Eura'
  const numberPrice = Number(price)
  if (numberPrice) {
    const stringPrice = numberPrice.toString()
    if (stringPrice) {
      if (stringPrice.length >= 2) {
        const lastDigit = stringPrice.slice(-1)
        const lastTwoDigit = stringPrice.slice(-2)
        return (lastTwoDigit === '11' || lastDigit !== '1') ? `${stringPrice} Eura` : `${stringPrice} Euro`
      } else {
        const lastDigit = stringPrice.slice(-1)
        return lastDigit === '1' ? `${stringPrice} Euro` : `${stringPrice} Eura`
      }
    }
    return defaultResult
  }
  return defaultResult
}
