import React from 'react'
import { Item } from '../Item'
import { regions } from '../data/regions'

export const List = () => (
  <div className='footer-regions'>
    <span className='footer-column-title'>Auto diler u regionu</span>
    <ul>
      {
        regions.map(region => [
          <Item key={region.id}{...region} />,
          '\n'
        ])
      }
    </ul>
  </div>
)
