import PropTypes from 'prop-types'
import Link from 'next/link'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import ReactSVG from 'react-svg'
import translations from '../../../../../../../public/data/translations.json'

export const FilterItems = ({
	category,
  isTextSearchActive,
	isFilterSearchActive,
  is404,
  closeIcon
}) => {
  const { t, lang } = useTranslation('common')
	const locale = lang?.toUpperCase() || 'ME'

  const itemClassName = 'bm-item bm-item-half'

	const filterSearchHref = { pathname: '/mobileAdFilter', query: { page: 'category-filter' } }
	let filterSearchAs = `/${translations.pretragaOglasa[locale]}`

	if (category) {
		filterSearchHref.query.category = category.seo
		filterSearchAs += `/${category.seo}`
	}

  return (
		<React.Fragment>
			<li className={`${itemClassName}${isTextSearchActive && !is404 ? ' active' : ''}`}>
				<Link
					href={{ pathname: '/mobileAdFilter', query: { page: translations.tekstualnaPretraga[locale] } }}
					as={`/${translations.pretragaOglasa[locale]}/${translations.tekstualnaPretraga[locale]}`}
				>
					<a onClick={closeIcon}>
						<ReactSVG
							src={'/icons/pretraga-po-tekstu.svg'}
							beforeInjection={svg => {
								svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 45px;')
							}} wrapper='span'
						/>
						<p className='bm-item__text'>{t('Pretraga po tekstu')}</p>
					</a>
				</Link>
			</li>

			<li className={`${itemClassName}${isFilterSearchActive && !is404 ? ' active' : ''}`}>
				<Link
					href={filterSearchHref}
					as={filterSearchAs}
				>
					<a onClick={closeIcon}>
						<ReactSVG
							src={'/icons/pretraga-po-filterima.svg'}
							beforeInjection={svg => {
								svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 45px;')
							}} wrapper='span'
						/>
						<p className='bm-item__text'>{t('Pretraga po filterima')}</p>
					</a>
				</Link>
			</li>
		</React.Fragment>
  )
}

FilterItems.propTypes = {
  isActive: PropTypes.bool,
  is404: PropTypes.bool.isRequired,
  closeIcon: PropTypes.func.isRequired
}

FilterItems.defaultProps = {
  is404: false
}
