import PropTypes from 'prop-types'
import React from 'react'

export const Address = ({city, addressCount}) =>
  <ul>
    <li>{city} {addressCount > 1 ? `(+${Number(addressCount) - 1})` : null}</li>
  </ul>

Address.propTypes = {
  addressCount: PropTypes.number.isRequired,
  city: PropTypes.string
}

Address.defaultProps = {
  addressCount: 0
}
