import config from 'SRC/config/config.json'

export const getTitleForSearchPage = ({
  categoryTagTitle,
  defaultTitle,
  citiesText,
  cityText,
  locationText,
  brandsText,
  productsText,
  serviceText,
  serviceTypeText,
  specifications,
  urlSpecs
}) => {
  const titleParts = []
  let title

  let brandsProductsPart = ''

  if (serviceText) titleParts.push(serviceText)
  if (serviceTypeText) titleParts.push(serviceTypeText)

  if (Array.isArray(productsText) && productsText.length <= 2) {
    brandsProductsPart = productsText.join(', ') + ' | '
  } else {
    if (Array.isArray(brandsText) && brandsText.length <= 2) {
      brandsProductsPart = brandsText.join(', ') + ' | '
    }
  }

  if (Array.isArray(specifications) && specifications.every(spec => typeof spec.titleText !== 'undefined')) {
    titleParts.push(specifications.map(spec => spec.titleText).join(', '))
  } else {
    if (Array.isArray(specifications) && Array.isArray(urlSpecs)) {
      urlSpecs.forEach((urlSpec) => {
        const findSpec = specifications.find(spec => Number(spec.id) === Number(urlSpec.id))
  
        if (findSpec) {
          const findSpecIndex = (urlSpec.options || []).findIndex(option => option === findSpec.value)
          if (findSpecIndex !== -1) titleParts.push(urlSpec.title[findSpecIndex])
        }
      })
    }
  }

  if (Array.isArray(citiesText) && citiesText.length === 1 && !cityText) {
    titleParts.push(citiesText.join(' '))
  }

  if (cityText) titleParts.push(cityText)
  if (locationText) titleParts.push(locationText)

  titleParts.push(categoryTagTitle || defaultTitle || '')

  title = titleParts.join(' - ')

  return `${brandsProductsPart}${title || config.siteName}`
}
