import React from 'react'
import config from 'SRC/config/config.json'
import useTranslation from 'next-translate/useTranslation'

export const PhoneTip = () => {
  const { t } = useTranslation('common')

  return <div className='info-polje info-login'>
    <p className='heading'>{t('phone_number')}</p>
    <p>{t('login_phone_tooltip')}</p>
  </div>
}
