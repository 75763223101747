import PropTypes from 'prop-types'
import { MenuSubList } from '../'
import React from 'react'
import { connect } from 'react-redux'
import StpdBannerComponent from 'stpd-ad-component'
import { getGroups } from 'SRC/modules/groups/selectors'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import config from 'SRC/config/config.json'

import translations from '../../../../../../../public/data/translations.json'

export const TransportSubMenu = ({ items }) => {
  const { t, lang } = useTranslation('common')

  const locale = lang?.toUpperCase() || 'ME'

  const list1 = Array.isArray(items) && items.length ? items.slice(0, items.length / 2) : []
  const list2 = Array.isArray(items) && items.length ? items.slice(items.length / 2) : []

  return (
    <div className='kategorije-mega-menu'>
      <div className='kategorije-menu'>
        {list1.length ? <MenuSubList items={list1} /> : []}
        {list2.length ? <MenuSubList items={list2} /> : []}
        <hr />
        <Link href={'/categories'} as={`${translations.kategorije[locale]}`}>
          <a className='pogledaj-sve-btn'>
            <i className='ico-gavni-meni-pogledaj-sve-kategorije ico' />
            {t('Pogledaj sve kategorije i podkategorije')}
          </a>
        </Link>
      </div>
      <div className='kategorije-img'>
        <div style={{ width: 300, height: 250 }}>
          <StpdBannerComponent
            refreshOnUrlChange={true}
            adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.groupsSubMenu.adUnit}`}
            size={config.banners.groupsSubMenu.sizes}
            divId={config.banners.groupsSubMenu.slotId}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  items: getGroups(state)
})

TransportSubMenu.propTypes = {
  items: PropTypes.array.isRequired
}

TransportSubMenu.defaultProps = {
  items: []
}

export default connect(mapStateToProps)(TransportSubMenu)
