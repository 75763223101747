import PropTypes from 'prop-types'
import React from 'react'
import { Wrapper } from '../Wrapper'
import { ErrorMessage } from 'formik'

export class MultiSelect extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isOptionsVisible: false
    }

    this.labelRef = React.createRef()
    this.multiselectRef = React.createRef()
    this.optionsRef = React.createRef()
    this.firstOptionRef = React.createRef()
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.toggleOptionsVisibility)
    this.multiselectRef.current.addEventListener('keyup', this.onKeyUp)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.toggleOptionsVisibility)
    this.multiselectRef.current.removeEventListener('keyup', this.onKeyUp)
  }

  onKeyUp = event => {
    let isOptionsVisible = false
    if (event.keyCode === 40) { // arrow down
      isOptionsVisible = true
    } else if (event.keyCode === 37) { // arrow up
      isOptionsVisible = false
    }

    this.setState({
      isOptionsVisible
    }, () => {
      setTimeout(() => {
        if (this.firstOptionRef.current) this.firstOptionRef.current.focus()
      }, 100)
    })
  }

  toggleOptionsVisibility = (e) => {
    let isOptionsVisible = false
    if (this.labelRef.current && this.labelRef.current.contains(e.target)) {
      isOptionsVisible = !this.state.isOptionsVisible
    }

    if (this.multiselectRef.current && this.multiselectRef.current.contains(e.target)) {
      isOptionsVisible = !this.state.isOptionsVisible
    }

    if (this.optionsRef.current && this.optionsRef.current.contains(e.target)) {
      isOptionsVisible = true
    }

    this.setState({
      isOptionsVisible
    })
  }

  getLabelText = () => {
    let text
    const selected = this.getSelected()
    if (Array.isArray(selected) && selected.length) {
      text = `Odabrano: ${selected.length}`
    } else {
      text = 'Izaberi'
    }
    return text
  }

  onOptionClick = e => {
    const { push, remove, form, name } = this.props
    const clickedValue = e.currentTarget.dataset.value
    let selected = this.getSelected()
    if (selected.includes(clickedValue)) {
      const index = selected.findIndex(v => v === clickedValue)
      remove(index)
    } else {
      push(clickedValue)
    }
    setTimeout(() => {
      selected = this.getSelected()
      if (!selected.length) {
        form.setFieldTouched(name, true, false)
        form.setFieldError(name, 'Obavezno polje')
      } else {
        form.setFieldError(name, null)
      }
    }, 100)
  }

  addCheckedOptionToState = async value => {
  }

  removeCheckedOptionFromState = async value => {
  }

  getSelected = () => {
    const { form, name } = this.props
    const path = name.split('.')
    if (path.length > 1) {
      let values = form.values[path[0]]
      for (let i = 1; i < path.length; i++) values = values[path[i]]
      return values || []
    } else {
      return form.values[name] || []
    }
  }

  renderDropdownItem = (option, index) => {
    const selectedValues = this.getSelected()
    return <li key={option.id ? option.id : option} className='option'>
      <input id={option.id} type='checkbox' onChange={!option.isDisabled ? this.onOptionClick : null}
        data-value={option.value} checked={selectedValues.includes(option.value)}
        ref={index === 0 ? this.firstOptionRef : null} />

      <label htmlFor={option.id}>
        {option.label}
      </label>
    </li>
  }

  render () {
    const { id, name, isRequired, label, disabled, options, form } = this.props
    const { isOptionsVisible } = this.state
    return <Wrapper isError={form.touched[name] && form.errors[name]}>
      <label htmlFor={id} ref={this.labelRef}>
        {label}{isRequired ? <span className='required'>*</span> : null}
      </label>
      <div className='multiselect' ref={this.multiselectRef} tabIndex={0}>
        <label ref={this.labelRef} className={form.touched[name] && form.errors[name] ? 'no-border' : ''}>
          {this.getLabelText()}
        </label>
      </div>
      {isOptionsVisible && !disabled && !form.isSubmitting && options.length
        ? <div className='multiselect__select-dropdown' ref={this.optionsRef}>
          <ul>
            {options.map((option, index) => this.renderDropdownItem(option, index))}
          </ul>
        </div>
        : null}
      <ErrorMessage name={name} render={msg => <span className='form-group__error-message'>{msg}</span>} />
    </Wrapper>
  }
}

MultiSelect.propTypes = {
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    isGrouped: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired
    }))
  })).isRequired,
  label: PropTypes.string,
  form: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired
}

MultiSelect.defaultProps = {
  disabled: false,
  options: [],
  isRequired: false,
  isError: false,
  isBlocked: false,
  labelDefault: 'Izaberi',
  needColon: true,
  checked: [],
  resetOptions: false
}
