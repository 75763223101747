import PropTypes from 'prop-types'
import React from 'react'

export const NewsPageContent = ({children, title, showHeadingTitle = true}) => (
  <React.Fragment>
    <div className='novosti-header'><p>{title}</p></div>
    <div className='novosti-content'>
      <div className='novosti-heading'><p className='novosti-main-heading'>{showHeadingTitle ? title : ''}</p></div>
      {children}
    </div>
  </React.Fragment>
)

NewsPageContent.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  showHeadingTitle: PropTypes.string
}
