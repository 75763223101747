export const userTypes = [
	{
		"name": "Korisnik",
		"weight": 1,
		"icon": "ico-registracija-korisnik ico",
		"description": "Običnan, nekomercijalan, besplatnan profil za sva fizička lica sa 3 besplatna oglasa.",
		"seo": "korisnik",
		"seoPlu": null,
		"namePlu": "korisnika",
		"visibleGarage": true,
		"id": 1,
		"createdAt": "2020-10-27T13:09:10.000Z",
		"updatedAt": "2020-10-27T13:09:10.000Z"
	},
	{
		"name": "Prodavnica",
		"weight": 2,
		"icon": "ico-registracija-prodavnica ico",
		"description": "Profil namjenjen za sva pravna i fizička lica koja se bave trgovinom novih i polovnih djelova i opreme.",
		"seo": "prodavnica",
		"seoPlu": "prodavnice",
		"namePlu": "prodavnice",
		"visibleGarage": true,
		"id": 2,
		"createdAt": "2020-10-27T13:09:10.000Z",
		"updatedAt": "2020-10-27T13:09:10.000Z"
	},
	{
		"name": "Auto plac",
		"weight": 3,
		"icon": "ico-registracija-autoplac ico",
		"description": "Profil namjenjen za auto placeve i korisnike koji imaju više od 2 polovna vozila za prodaju.",
		"seo": "auto-plac",
		"seoPlu": "auto-placevi",
		"namePlu": "auto placevi",
		"visibleGarage": false,
		"id": 3,
		"createdAt": "2020-10-27T13:09:10.000Z",
		"updatedAt": "2020-10-27T13:09:10.000Z"
	},
	{
		"name": "Zastupnici",
		"weight": 4,
		"icon": "ico-registracija-autosalon ico",
		"description": "Profil namjenjen za auto salone i ovlašćenje prodavce novih i polovnih vozila.",
		"seo": "auto-salon",
		"seoPlu": "auto-saloni",
		"namePlu": "zastupnici",
		"visibleGarage": false,
		"id": 4,
		"createdAt": "2020-10-27T13:09:10.000Z",
		"updatedAt": "2020-10-27T13:09:10.000Z"
	},
	{
		"name": "Auto otpad",
		"weight": 5,
		"icon": "ico-registracija-autootpad ico",
		"description": "Profil namjenjen za auto otpade koji se bave trgovinom polovnih djelova i opreme.",
		"seo": "auto-otpad",
		"seoPlu": "auto-otpadi",
		"namePlu": "auto otpadi",
		"visibleGarage": true,
		"id": 5,
		"createdAt": "2020-10-27T13:09:10.000Z",
		"updatedAt": "2020-10-27T13:09:10.000Z"
	},
	{
		"name": "Servisi i usluge",
		"weight": 6,
		"icon": "ico-registracija-adresar ico",
		"description": "Profil namjenjen za prezentovanje usluga za sva pravna i fizička lica.",
		"seo": "adresar",
		"seoPlu": "adresari",
		"namePlu": "servisi i usluge",
		"visibleGarage": true,
		"id": 6,
		"createdAt": "2020-10-27T13:09:10.000Z",
		"updatedAt": "2020-10-27T13:09:10.000Z"
	}
]