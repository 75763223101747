import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const HideDetailBtn = ({onClick}) => {
  const { t } = useTranslation('common')

  return (
    <button className='filter-buttons-toggle-detail' onClick={e => { e.preventDefault(); onClick() }}>
      {t('OSNOVNA PRETRAGA')} <i className='ico-pretraga-polja-lupa ico' />
    </button>
  )
}

HideDetailBtn.propTypes = {
  onClick: PropTypes.func.isRequired
}
