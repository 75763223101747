import actionTypes from 'SRC/modules/redux/actionTypes'
import initialState from 'SRC/modules/redux/initialState'

export const commonReducer = (state = initialState.common, action) => {
  switch (action.type) {
    case actionTypes.SET_POPUP_LOGIN_BLOCK_VISIBILITY:
      return { ...state, isPopupLoginBlockOpened: action.payload }
    case actionTypes.SET_404:
      return { ...state, is404: action.payload }
    case actionTypes.SET_APP_LOADING:
      return { ...state, transitions: { ...state.transitions, isLoading: action.payload } }
    case actionTypes.SET_APP_INTERNAL_TRANSITION_FUNC:
      return { ...state, transitions: { ...state.transitions, internalTransitionFunc: action.payload } }
    case actionTypes.SET_IS_TRANSITIONING:
      return { ...state, transitions: { ...state.transitions, isTransitioning: action.payload } }
    case actionTypes.SET_USER_TYPES:
      return { ...state, userTypes: action.payload }
    case actionTypes.SET_FORM_FIELDS:
      return { ...state, form: { ...state.form, fields: action.payload } }
    case actionTypes.SET_FORM_RESULT:
      return { ...state, form: { ...state.form, result: action.payload } }
    case actionTypes.SET_FORM_LOADING:
      return { ...state, form: { ...state.form, isLoading: action.payload } }
    case actionTypes.SET_FORM_ERROR:
      return { ...state, form: { ...state.form, error: action.payload } }
    case actionTypes.SET_SMS_CODE_VERIFIED:
      return { ...state, form: { ...state.form, smsCodeVerified: action.payload } }
    case actionTypes.SET_SMS_WAS_SENT:
      return { ...state, form: { ...state.form, wasSmsSent: action.payload } }
    case actionTypes.SET_PAGE_META_TAGS:
      return { ...state, pageMetaTags: action.payload }
    case actionTypes.SET_PAGE_META_TAGS_SINGLE:
      return { ...state, pageMetaTagsSingle: action.payload }
    case actionTypes.SET_IS_MOBILE_DEVICE:
      return { ...state, isMobileDevice: action.payload }
    default:
      return state
  }
}
