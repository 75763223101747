import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const listReducer = (state = initialState.ads.list, action) => {
  switch (action.type) {
    case actionTypes.SET_ADS:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_PAID_ADS:
      const newPaidAds = action.payload.filter(paidAdId => !state.paid.includes(paidAdId))
      return {
        ...state,
        paid: [...state.paid, ...newPaidAds]
      }
    case actionTypes.SET_PAID_ADS_REQUESTED:
      return {
        ...state,
        paidRequested: action.payload
      }
    case actionTypes.SET_ADS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case actionTypes.UPDATE_AD_BY_ID:
      return {
        ...state,
        elements: state.elements.map(ad => ad.id === action.payload.id ? {...ad, ...action.payload.fields} : ad)
      }
    case actionTypes.REMOVE_AD_FROM_PAID:
      return {
        ...state,
        paid: state.paid.filter(item => item !== action.payload)
      }
    case actionTypes.SET_SAVED_FILTERS_BUTTON_ENABLED:
      return {
        ...state,
        isSavedAdsButtonEnabled: action.payload
      }
    default:
      return state
  }
}
