import { connect } from 'react-redux'
import { getIsAuthorized } from '../../selectors'
// import { LoginPage } from '../LoginPage'
import { LoginPasswordless } from '../LoginPasswordless'
import { compose, renderComponent, branch, lifecycle } from 'recompose'
import Router from 'next/router'

export const withAuth = WrappedComponent => {
  return compose(
    connect(state => ({ isAuthorized: getIsAuthorized(state) })),
    lifecycle({
      componentDidUpdate (prevProps) {
        // if (!this.props.isAuthorized) {
        //   Router.push({pathname: '/index'}, {pathname: '/'}).then(() => window.scrollTo(0, 0))
        // }
        // if (!prevProps.isAuthorized && this.props.isAuthorized) {
        //   Router.reload(Router.route)
        // }
      }
    }),
    branch(
      props => props.isAuthorized,
      renderComponent(WrappedComponent),
      renderComponent(LoginPasswordless)
    )
  )(WrappedComponent)
}
