import { BaseApi } from 'Core/api/BaseApi'
import jwt from 'jsonwebtoken'

export class Api extends BaseApi {
  static statusCodes = {
    SUCCESSFULLY_SENT: 0,
    NUMBER_ALREADY_USED: 1,
    FORCE_SENT: 2,
    TOO_SHORT_PERIOD: -1
  }

  static responseTypes = {
    ERROR: 'error',
    OK: 'ok'
  }

  static registrationDefaultError = 'Registration error. Try later'

  recaptcha = async () => {
    return new Promise((resolve, reject) => {
      grecaptcha.enterprise.ready(async function () {
        let token = null

        try {
          token = await grecaptcha.enterprise.execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, { action: 'request_sms_code' })
        } catch (err) {
          console.log('grecaptcha.enterprise.execute error', err)
        }

        if (!token) return resolve({ success: false, error: 'Došlo je do greške' })

        console.log('token generated', token)
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token, action: 'request_sms_code' })
        }

        fetch(`${process.env.NEXT_PUBLIC_API_HOST}/v2/recaptcha`, options)
          .then(response => response.json())
          .then(response => {
            console.log('backend response', response)
            if (response.error) return resolve({ success: false, error: response.error })

            if (response.score && response.score > 0.6) return resolve({ success: true })

            return resolve({ success: false, error: null, score: response.score })
          })
          .catch(err => {
            console.log('recaptcha err', err)
            return resolve({ success: false, error: 'Došlo je do greške' })
          })
      })
    })
  }

  sendSmsCode = async (phone, isForced = false) => {
    const defaultResult = { status: Api.responseTypes.ERROR }
    if (!phone) return defaultResult

    try {
      const tokenSecretAuthSms = process.env.NEXT_PUBLIC_AUTH_SMS_TOKEN_SECRET
      const authtoken = jwt.sign({ platform: 'autodiler' }, tokenSecretAuthSms, { expiresIn: '3s' })

      const { body } = await Api.post('/auth/sms', { phone, forceSendSms: isForced, authtoken })
      const statusCode = body.status
      const isBusinessUser = body.isBusinessUser
      const name = body.name
      const username = body.username
      const error = body.error

      if (error) return { status: Api.responseTypes.ERROR, error }

      return { status: Api.responseTypes.OK, statusCode, name, username, isBusinessUser }
    } catch (error) {
      return { status: Api.responseTypes.ERROR, error: `${error.message || error}` }
    }
  }

  sendSmsCodeWithRecaptcha = async (phone, isForced = false, recaptchaCheck = true) => {
    const defaultResult = { status: Api.responseTypes.ERROR }
    if (!phone) return defaultResult

    try {
      if (recaptchaCheck) {
        const recaptchaCheck = await this.recaptcha()
  
        if (!recaptchaCheck.success) {
          if (recaptchaCheck.score) return { status: Api.responseTypes.OK, score: recaptchaCheck.score }
  
          return { status: Api.responseTypes.ERROR, error: recaptchaCheck.error || 'Došlo je do greške' }
        }
      }

      const tokenSecretAuthSms = process.env.NEXT_PUBLIC_AUTH_SMS_TOKEN_SECRET
      const authtoken = jwt.sign({ platform: 'autodiler' }, tokenSecretAuthSms, { expiresIn: '3s' })

      const { body } = await Api.post('/auth/sms', { phone, forceSendSms: isForced, authtoken })
      const statusCode = body.status
      const isBusinessUser = body.isBusinessUser
      const name = body.name
      const username = body.username
      const error = body.error

      if (error) return { status: Api.responseTypes.ERROR, error }

      return { status: Api.responseTypes.OK, statusCode, name, username, isBusinessUser }
    } catch (error) {
      return { status: Api.responseTypes.ERROR, error: `${error.message || error}` }
    }
  }

  verifySmsCode = async (phone, code, country) => {
    const defaultResult = { status: Api.responseTypes.ERROR }
    try {
      const fields = { phone, code, country }
      const { body } = await Api.post('/auth/verifySmsCode', fields)
      return body
    } catch (error) {
      return { ...defaultResult, error: `${error.message || error}` }
    }
  }
}
