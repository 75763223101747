import PropTypes from 'prop-types'
import Link from 'next/link'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import ReactSVG from 'react-svg'

export const VehicleRegistrationItem = ({
  isActive,
  is404,
  closeIcon
}) => {
  const { t } = useTranslation('common')
  const activeClass = !is404 && isActive ? `active` : ``

  const itemClassName = `bm-item bm-item-full${activeClass ? ` ${activeClass}` : ''}`

  return (
    <li className={itemClassName}>
			<Link
				href={{ pathname: '/formRequest', query: { url: '/forma/registracija-vozila' } }}
				as={'/forma/registracija-vozila'}
			>
				<a onClick={closeIcon}>
					<ReactSVG
						src={'/icons/kalkulator_registracije_vozila_meni.svg'}
						beforeInjection={svg => {
							svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 45px;')
						}} wrapper='span'
					/>
					<p className='bm-item__text'>{t('Kalkulator registracije vozila')}</p>
				</a>
			</Link>
    </li>
  )
}

VehicleRegistrationItem.propTypes = {
  isActive: PropTypes.bool,
  is404: PropTypes.bool.isRequired,
  closeIcon: PropTypes.func.isRequired
}

VehicleRegistrationItem.defaultProps = {
  is404: false
}
