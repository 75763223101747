import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { Hint } from '../Hint'

export class Checkbox extends React.PureComponent {
  renderCheckbox = ({ id, hint, label, field: { name, value, onChange, onBlur } }) => {
    return <div className='dodatna-oprema-checkbox'>
      <input
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        checked={Array.isArray(value) && value.includes(id)}
        type='checkbox'
        value={id}
      />
      <label htmlFor={id}>{label}</label>
      {
        hint ? <Hint hint={hint} /> : null
      }
    </div>
  }

  render () {
    return <Field
      name={this.props.name}
      id={this.props.id}
      component={this.renderCheckbox}
      label={this.props.label}
      hint={this.props.hint}
    />
  }
}

Checkbox.propTypes = {
  hint: PropTypes.string,
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onInput: PropTypes.func
}
