import { CookiesHelper } from 'SRC/core/cookiesHelper/CookiesHelper'

export const getCurrentLocale = () => {
	let locale = 'ME'

	if (typeof window !== 'undefined') {
		if (window.next?.router?.locale) {
			locale = window.next.router.locale.toUpperCase()
		} else {
			const cookies = new CookiesHelper()
			const pathnameParts = window.location.pathname.split('/')

			if (['ME', 'EN', 'DE', 'AL', 'TR', 'UA', 'RU'].includes(pathnameParts[1].toUpperCase())) {
				locale = pathnameParts[1].toUpperCase()
			} else {
				locale = 'ME'
			}
		}
	}

	return locale
}
