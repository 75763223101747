import React from 'react'
import useTranslation from 'next-translate/useTranslation'


export const Header = () => {

  const { t } = useTranslation('common')

  return (
    <div className='sidebar-header'>
      <p>{t('sidebar_social_header')}</p>
    </div>
  )
}
