import React from 'react'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export const Copyright = () => {
  const { t } = useTranslation('common')

  return (
    <div className='footer-bottom'>
      <Trans
        i18nKey='common:footer_copyright'
        components={{
          p: <p />,
          a: <a target='_blank' href='https://weblab.group/' rel="noreferrer"/>,
          c: <>&copy;</>
        }}
        defaultTrans='<p>AutoDiler.me je dio <a>WebLab Grupe</a> Copyright <c/> 2023. Sva prava zadržana.<p>'
      />
      <div className='web-dizajn'>
        <p>{t('web dizajn')} <a href='https://www.genije.net' target='_blank' rel="noreferrer">Genije.NET</a></p>
      </div>
    </div>
  )
}

