import PropTypes from 'prop-types'
import React from 'react'
import { SubItem } from '../SubItem'

export const SubList = (props) => (
  <ul className='kategorije-menu-list'>
    {props.items.map(item => [
      <SubItem {...item} key={item.id}>{item.title}</SubItem>,
      '\n'
    ])}
  </ul>
)

SubList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired
}

SubList.defaultProps = {
  items: []
}
