import React from 'react'
import PropTypes from 'prop-types'
import { imagesCDN } from 'SRC/core/constants'
import { capitalize } from 'SRC/utils/Utils'
import Link from 'next/link'

export const Item = ({ user }) => {
  const getUserBanner = () => {
    if (user.banner && user.banner.fd) {
      return `${imagesCDN}${user.banner.fd}`
    }

    return 'https://placehold.it/274x76'
  }

  return (
    <div className='sidebar-item' key={user.id}>
      <Link
        href={user.url.href}
        as={user.url.as}
      >
        <a>
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'relative'
            }}
          >
            <img src={getUserBanner()} alt={user.username} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
        </a>
      </Link>
    </div>
  )
}

Item.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    banner: PropTypes.object
  }).isRequired
}
