import PropTypes from 'prop-types'
import React from 'react'
import { swapOptions } from 'Core/constants'
import useTranslation from 'next-translate/useTranslation'
import { SelectDropdown, AdFilterField } from 'SRC/ui/FormElementsNew'
import { useFormikContext } from 'formik'

export const Swapp = () => {
  const formik = useFormikContext()
  const { t } = useTranslation('common')

  const getSwappField = () => {
    return {
      id: 'swapp',
      title: t('Zamjena'),
      options: swapOptions.map(option => ({ ...option, label: t(option.title) })),
      value: formik.values.swapp,
      onChange: onSwapChange
    }
  }

  const onSwapChange = async value => {
    const swappValue = isNaN(value) ? value : Number(value)

    formik.setFieldValue('swapp', swappValue)
    formik.setFieldValue('swapps', {
      category: -1,
      brand: -1,
      product: -1,
      specification1: -1,
      specificationValue1: -1,
      specification2: -1,
      specificationValue2: -1
    })
  }

  const swappField = getSwappField()

  return (
    <AdFilterField className='polja-pretrage-item_swapps'>
      <SelectDropdown {...swappField} />
    </AdFilterField>
  )

}

Swapp.propTypes = {
  formik: {
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }.isRequired
}
