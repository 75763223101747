import React from 'react'
import { TypesHelper } from 'SRC/utils'
import PropTypes from 'prop-types'

const renderSpecWithIcons = spec => {
  return <div className='oglasi-item-tekst-icons__item'>
    <i className={spec.icon} />
    <p>{spec.value} {spec.measure ? spec.measure : ''}</p>
  </div>
}

export const GridViewWithIcons = ({ specs }) => {
  const hasSpecs = TypesHelper.isNotEmptyArray(specs)
  return (
    <div className={`oglasi-item-tekst-icons`}>
      {hasSpecs ? specs.map(spec => renderSpecWithIcons(spec)) : <h1>n</h1>}
    </div>
  )
}

GridViewWithIcons.propTypes = {
  specs: PropTypes.arrayOf(PropTypes.object).isRequired
}

GridViewWithIcons.defaultProps = {
  specs: []
}
