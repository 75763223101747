import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import PropTypes from 'prop-types'
import { Dropdown } from '../Dropdown'
import { DropdownMobile } from '../DropdownMobile'

const popupStyles = {
  modalContainer: {
    overflowY: 'hidden'
  },
  modal: {
    width: '85%',
    maxWidth: '85%',
    height: '90vh',
    borderRadius: 10
  }
}

const Container = ({
	isMobileDevice,
	...restProps
}) => {
	return !isMobileDevice ? <Dropdown {...restProps} /> : <DropdownMobile {...restProps} />
}

Container.displayName = 'SingleSelectContainer'

Container.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
	return {
    isMobileDevice: getIsMobileDevice(state)
	}
}

export default connect(mapStateToProps)(Container)
