import { BaseApi } from 'Core/api/BaseApi'

export class Api extends BaseApi {
  fetchGarageCategories = async () => {
    const defaultResult = []
    try {
      const url = '/garage/listCategories'
      const {body} = await Api.post(url)
      if (!body.error && body.categories && Array.isArray(body.categories)) {
        return body.categories
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }
}
