import actionTypes from 'SRC/modules/redux/actionTypes'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'

import { AdApi } from 'SRC/modules/ads/shared/api'
import { setError } from 'SRC/modules/ads/add/actions'
import { setPhotos } from 'SRC/modules/ads/shared/actions'
import { setNotifications } from 'SRC/modules/users/Profile/actions'

import { notificationTypes } from 'SRC/modules/users/Profile/constants'

import config from 'SRC/config/config.json'

export const createAd = payload => {
	return async (dispatch, getState) => {
		console.log('createAd payload', payload)

		const api = new AdApi()
		const response = await api.createAd(payload)

		console.log('createAd response', response)

		const { userStatus, adStatus, user, ad } = response

		let errorMessage = ''
		const notifications = [
			{
				type: notificationTypes.USER_INFO,
				info: user
			}
		]

		switch (userStatus) {
			case AdApi.UserStatusCodes.WRONG_EMAIL:
				errorMessage = 'Email koji ste unijeli je već u upotrebi.'
				break
			case AdApi.UserStatusCodes.WRONG_SMS_CODE_OR_WRONG_CREDENTIALS:
				errorMessage = 'Pogrešan SMS kod.'
				break
			case AdApi.UserStatusCodes.USER_WAS_CREATED:
			case AdApi.UserStatusCodes.INCOMPLETE_USER_WAS_FOUND:
			case AdApi.UserStatusCodes.EXISTED_USER_WAS_FOUND:
				if (user && user.id && user.token) {
					const cookie = new CookiesHelper()

					cookie.set('userId', user.id, { maxAge: 315360000 })
					cookie.set('token', user.token, { maxAge: 315360000 })
				}

				break
			default: break
		}

		switch (adStatus) {
			case AdApi.AdStatusCodes.AD_HAS_BEEN_ADDED:
				if (ad) {
					if (ad.listing === config.adForm.addAdForm.packageTypes.FREE_PACKAGE_ID) {
						notifications.push({
							type: notificationTypes.USER_ADDED_FREE_AD,
							info: ad
						})
					} else {
						notifications.push({
							type: notificationTypes.USER_ADDED_PAID_AD,
							info: {
								ad,
								user: {
									...user,
									phones: Array.isArray(user.phones)
										? user.phones.map(phoneNumber => ({ phone: phoneNumber }))
										: []
								}
							}
						})
					}

					if (userStatus === AdApi.UserStatusCodes.USER_WAS_CREATED) {
						notifications.push({
							type: notificationTypes.USER_MUST_CREATE_PASSWORD,
							info: user
						})
					}

					dispatch(setNotifications(notifications))
				}

				break
			case AdApi.AdStatusCodes.PACKAGE_EXPIRED:
				errorMessage = config.adForm.addAdForm.errors.AD_PACKAGE_EXPIRED
				break
			case AdApi.AdStatusCodes.AD_LIMIT_IN_PACKAGE_EXCEEDED:
				errorMessage = config.adForm.addAdForm.errors.AD_PACKAGE_LIMIT_EXCEEDED
				break
			case AdApi.AdStatusCodes.AD_LIMIT_IN_CATEGORY_EXCEEDED:
				errorMessage = config.adForm.addAdForm.errors.AD_PACKAGE_CATEGORY_LIMIT_EXCEEDED
				break
			default: break
		}

		if (errorMessage) {
			console.log('errorMessage', errorMessage)
			dispatch(setError(errorMessage))
			dispatch(setNotifications([]))
			// dispatch(setPhotos([]))
			return false
		}

		if (response.error) {
			console.log('error', response.error)
			if (response.error === 'Wrong SMS Code.') dispatch(setError('SMS kod nije ispravan.')) 
			else dispatch(setError('Došlo je do greške pri kreiranju oglasa.'))
	
			dispatch(setNotifications([]))
			// dispatch(setPhotos([]))
			return false
		}

		return true
	}
}
