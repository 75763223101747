import PropTypes from 'prop-types'
import React from 'react'

export const Hint = ({hint}) => (
  <span className='checkbox-tooltip'>
    <i className='ico-info-popup ico' />
    <div className='cb-tooltip-content'>{hint}</div>
  </span>
)

Hint.propTypes = {
  hint: PropTypes.string.isRequired
}
