import { BaseApi } from 'Core/api/BaseApi'
import { AuthError } from 'Core/errors'
import config from 'SRC/config/config.json'

export class Api extends BaseApi {
  static AdStatusCodes = {
    AD_HAS_BEEN_ADDED: 0,
    PACKAGE_EXPIRED: 1,
    AD_LIMIT_IN_PACKAGE_EXCEEDED: 2,
    AD_LIMIT_IN_CATEGORY_EXCEEDED: 3
  }

  static UserStatusCodes = {
    WRONG_EMAIL: -2,
    WRONG_SMS_CODE_OR_WRONG_CREDENTIALS: -1,
    USER_WAS_CREATED: 0,
    INCOMPLETE_USER_WAS_FOUND: 1,
    EXISTED_USER_WAS_FOUND: 2
  }

  static MarkAsSoldResponseStatuses = {
    SUCCESS: 0,
    FAILED: -1
  }

  static TogglePauseResponseStatuses = {
    SUCCESS: 0,
    FAILED: -1,
    AD_LIMIT_EXCEEDED: -2
  }

  static RefreshResponseStatuses = {
    SUCCESS: 0,
    WRONG_AD_STATUS: -1,
    REFRESH_PERIOD_NOT_EXPIRED: -2,
    REFRESH_LIMIT_WAS_EXCEEDED: -3
  }

  static setAutoRefreshResponseStatuses = {
    SUCCESS: 0,
    WRONG_AD_STATUS: -1,
    REFRESH_LIMIT_WAS_EXCEEDED: -3,
    AUTO_REFRESH_ALREADY_SET: -4,
    NO_AUTO_REFRESH_ADDON: -5
  }

  static clearAutoRefreshResponseStatuses = {
    SUCCESS: 0,
    NO_AUTO_REFRESH_SET: -1
  }

  static PromoteResponseStatuses = {
    SUCCESS: 0,
    WRONG_AD_STATUS: -1,
    ALREADY_PROMOTED: -2
  }

  createAd = async fields => {
    try {
      const {body} = await Api.post(`/ads/add`, fields, false)

      return body
    } catch (err) {
      return { error: err }
    }
  }

  getPackageList = async () => {
    const defaultResult = []
    try {
      const {body} = await Api.post(`/listing/list`)
      if (!body.error && body.listings && Array.isArray(body.listings)) {
        return body.listings
      } else {
        return defaultResult
      }
    } catch (e) {
      return defaultResult
    }
  }

  updateAd = async fields => {
    try {
      const {body} = await Api.post(`/ads/update`, fields, false)
      const {error, ad} = body
      if (error || !ad) {
        return {status: Api.responseTypes.ERROR, error}
      } else {
        return {status: Api.responseTypes.OK, data: ad}
      }
    } catch (e) {
      const result = {status: Api.responseTypes.ERROR}
      if (e instanceof AuthError) {
        result.statusCode = e.status
        result.error = e.message
      } else {
        result.error = `${e}`
      }
      return result
    }
  }

  markAdAsSold = async fields => {
    try {
      const {body} = await Api.post(`/ads/markSold`, fields)
      if (body && body.status === Api.MarkAsSoldResponseStatuses.SUCCESS) {
        return {status: Api.responseTypes.OK}
      } else {
        return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
      }
    } catch (e) {
      return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
    }
  }

  togglePause = async fields => {
    try {
      const {body} = await Api.post(`/ads/togglePaused`, fields)
      if (body && body.status === Api.TogglePauseResponseStatuses.SUCCESS) {
        return {status: Api.responseTypes.OK}
      } else if (body && body.status === Api.TogglePauseResponseStatuses.AD_LIMIT_EXCEEDED) {
        return {
          status: Api.TogglePauseResponseStatuses.AD_LIMIT_EXCEEDED,
          message: config.adForm.addAdForm.errors.AD_PACKAGE_CATEGORY_LIMIT_EXCEEDED
        }
      } else {
        return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
      }
    } catch (e) {
      return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
    }
  }

  refresh = async fields => {
    try {
      const {body} = await Api.post(`/ads/refresh`, fields)
      if (body && body.status === Api.TogglePauseResponseStatuses.SUCCESS) {
        return {status: Api.responseTypes.OK}
      } else {
        return {status: Api.responseTypes.ERROR, errorCode: body.status, message: Api.defaultErrorMessage}
      }
    } catch (e) {
      return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
    }
  }

  setAutoRefresh = async fields => {
    try {
      const {body} = await Api.post(`/ads/autoRefreshSet`, fields)
      if (body && Number.isInteger(body.status)) return {status: body.status}
      return {status: Api.responseTypes.ERROR}
    } catch (e) {
      return {status: Api.responseTypes.ERROR}
    }
  }

  clearAutoRefresh = async fields => {
    try {
      const {body} = await Api.post(`/ads/autoRefreshClear`, fields)
      if (body && Number.isInteger(body.status)) return {status: body.status}
      return {status: Api.responseTypes.ERROR}
    } catch (e) {
      return {status: Api.responseTypes.ERROR}
    }
  }

  promote = async fields => {
    try {
      const {body} = await Api.post(`/promoRequest/add`, fields)
      if (body && body.status === Api.PromoteResponseStatuses.SUCCESS) {
        return {status: Api.responseTypes.OK}
      } else {
        return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
      }
    } catch (e) {
      return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
    }
  }

  promoteForNonAuthorizedUser = async fields => {
    try {
      const {body} = await Api.post(`/promoRequest/addNotLoggedIn`, fields)
      console.log('promoteForNonAuthorizedUser body', body)
      if (body && body.status === Api.PromoteResponseStatuses.SUCCESS) {
        return {status: Api.responseTypes.OK}
      } else {
        return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
      }
    } catch (e) {
      return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
    }
  }

  addSavedSearch = async fields => {
    try {
      const {body} = await Api.post(`/savedSearch/add`, fields)
      if (body && body.status === Api.TogglePauseResponseStatuses.SUCCESS) {
        return {status: Api.responseTypes.OK}
      } else {
        return {
          status: Api.responseTypes.ERROR,
          message: body.error ? body.error : Api.defaultErrorMessage
        }
      }
    } catch (e) {
      return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
    }
  }

  uplodAdImages = async fields => {
    try {
      const {body} = await Api.post(`/ads/uploadImage`, fields, false)

      if (body && body.images) {
        return { status: Api.responseTypes.OK, images: body.images }
      } else {
        return { status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage }
      }
    } catch (e) {
      return {status: Api.responseTypes.ERROR, message: Api.defaultErrorMessage}
    }
  }
}
