import { BaseApi } from 'Core/api/BaseApi'
import { AuthError } from 'Core/errors'

export class Api extends BaseApi {
  static UpdateProfileStatusCodes = {
    UPDATE_SUCCESSFULLY: 0,
    PHONE_VERIFICATION_FAILED: 1,
    PHONE_ALREADY_EXIST: 2,
    EMAIL_IS_NOT_UNIQUE: 3
  }

  getUserTypes = async (locale) => {
    const defaultResult = []
    try {
      const {body, status} = await Api.post('/usertype/list', { locale })
      if (body && !body.error && body.userTypes && Array.isArray(body.userTypes)) {
        return { userTypes: body.userTypes, status }
      } else {
        return { status }
      }
    } catch (error) {
      return defaultResult
    }
  }

  getUserInfo = async ({ userId, token, countActiveAds, locale }) => {
    if (userId && token) {
      try {
        const {body} = await Api.post('/user/getById', {userId, token, id: userId, countActiveAds, getPendingAddons: true, locale})
        if (!body.error && body.user) {
          let activeAdsCount = 0
          if (body.activeAdsCount) {
            activeAdsCount = Number(body.activeAdsCount)
          }
          return {info: body.user, publishAdsCount: activeAdsCount}
        } else {
          return {info: null}
        }
      } catch (e) {
        if (e instanceof AuthError) {
          return {error: e.message}
        }
        return {info: null}
      }
    }
    return {info: null}
  }

  getAddons = async (userId, token, locale) => {
    const defaultResult = []
    try {
      const {body} = await Api.post('/addons/list', {userId, token, locale})
      if (!body.error && body.addons && Array.isArray(body.addons)) {
        return body.addons
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }

  addAddonRequest = async (userId, token, addon, locale) => {
    try {
      const {body} = await Api.post('/addonsRequest/add', {userId, token, addon, locale})
      if (body.status === 0) return {status: Api.responseTypes.OK}
      if (body.status === -1) {
        return {
          status: Api.responseTypes.ERROR,
          error: 'Zahtjev za aktivaciju ovog dodatka je već poslat.'
        }
      }
    } catch (e) {
      const result = {
        error: Api.responseTypes.ERROR
      }
      if (e instanceof AuthError) {
        result.statusCode = 401
        result.error = 'Token expired'
      } else {
        result.error = `${e}`
      }
      return result
    }
  }

  updateProfile = async (data, needStringify = false, locale) => {
    try {
      const {body} = await Api.post('/user/update', data, needStringify)
      console.log(body)
      const error = body.error
      if (error) return {status: Api.responseTypes.ERROR, error}

      return {status: Api.responseTypes.OK, data: {user: body.user, updateStatus: body.status, token: body.token}}
    } catch (error) {
      const result = {
        status: Api.responseTypes.ERROR
      }
      if (error instanceof AuthError) {
        result.statusCode = 401
        result.error = 'Token expired'
      } else {
        result.error = `${error.message || error}`
      }

      return result
    }
  }

  getFavouriteAds = async (userId, token, status, page, limit, locale) => {
    if (userId && token) {
      try {
        const {body} = await Api.post('/ads/listFavourites', {userId, token, status, page, limit, locale})
        if (!body.error && body.ads) {
          return {favouriteAds: body.ads}
        } else {
          return {favouriteAds: []}
        }
      } catch (e) {
        if (e instanceof AuthError) {
          return {error: e.message}
        }
        return {favouriteAds: []}
      }
    }
    return {favouriteAds: []}
  }

  updateFavouriteAds = async (userId, token, adId, add = true, locale) => {
    if (userId && token && adId) {
      try {
        const data = {userId, token, adId, locale}
        const {body} = add
          ? await Api.post('/ads/addToFavourites', data)
          : await Api.post('/ads/removeFromFavourites', data)
        const error = body.error
        if (error) {
          return {status: Api.responseTypes.ERROR, error}
        } else {
          return {status: body.status}
        }
      } catch (e) {
        const result = {
          status: Api.responseTypes.ERROR
        }
        if (e instanceof AuthError) {
          result.statusCode = 401
          result.error = 'Token expired'
        } else {
          result.error = `${e}`
        }
        return result
      }
    }
  }

  addFavouriteProfile = async (userId, token, id, locale) => {
    if (userId && token && id) {
      try {
        const data = {userId, token, id, locale}
        const {body} = await Api.post('/user/addToFavourites', data)
        const error = body.error
        if (error) {
          return {status: Api.responseTypes.ERROR, error}
        } else {
          return {status: body.status}
        }
      } catch (e) {
        const result = {
          status: Api.responseTypes.ERROR
        }
        if (e instanceof AuthError) {
          result.statusCode = 401
          result.error = 'Token expired'
        } else {
          result.error = `${e}`
        }
        return result
      }
    }
  }

  removeFavouriteProfile = async (userId, token, id, locale) => {
    if (userId && token && id) {
      try {
        const data = {userId, token, id, locale}
        const {body} = await Api.post('/user/removeFromFavourites', data)
        if (body.error) {
          return {status: Api.responseTypes.ERROR, error: body.error}
        } else {
          return {status: body.status}
        }
      } catch (e) {
        const result = {
          status: Api.responseTypes.ERROR
        }
        if (e instanceof AuthError) {
          result.statusCode = 401
          result.error = 'Token expired'
        } else {
          result.error = `${e}`
        }
        return result
      }
    }
  }

  getFavouriteProfiles = async (userId, token, type, page, limit, locale) => {
    if (userId && token) {
      try {
        const data = {userId, token, type, page, limit, locale}
        const {body} = await Api.post('/user/listFavourites', data)
        if (!body.error && body.favouriteUsers && body.count) {
          return {favouriteUsers: body.favouriteUsers, count: body.count}
        } else {
          return {favouriteUsers: []}
        }
      } catch (e) {
        if (e instanceof AuthError) {
          return {error: e.message}
        }
        return {favouriteUsers: []}
      }
    }
    return {favouriteUsers: []}
  }

  getSavedFilters = async (userId, token, page, limit, locale) => {
    if (userId && token) {
      try {
        const {body} = await Api.post('/savedSearch/list', {userId, token, page, limit, locale})
        if (!body.error && body.savedSearches && body.count) {
          return {savedFilters: body.savedSearches, count: body.count}
        } else {
          return {savedFilters: []}
        }
      } catch (e) {
        if (e instanceof AuthError) {
          return {error: e.message}
        }
        return {savedFilters: []}
      }
    }
    return {savedFilters: []}
  }

  removeSavedFilter = async (userId, token, savedSearchId, locale) => {
    try {
      const {body} = await Api.post('/savedSearch/remove', {userId, token, savedSearchId, locale})
      if (body.error) {
        return {status: Api.responseTypes.ERROR, error: body.error}
      }

      return {status: Api.responseTypes.OK}
    } catch (e) {
      const result = {
        error: Api.responseTypes.ERROR
      }
      if (e instanceof AuthError) {
        result.statusCode = 401
        result.error = 'Token expired'
      } else {
        result.error = `${e}`
      }
      return result
    }
  }

  getDiscountCard = async (userId, token, locale) => {
    const defaultResult = null
    try {
      const {body} = await Api.post('/discountcard/get', {userId, token, locale})
      if (!body.error && body.card) {
        return body.card
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }

  getDiscountCardCode = async (userId, token, locale) => {
    const defaultResult = ''
    try {
      const {body} = await Api.post('/discountcard/generateCode', {userId, token, user: userId, locale})
      if (!body.error && body.code) {
        return body.code
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }

  getPriceTypes = async (userId, token, locale) => {
    const defaultResult = []

    try {
      const {body} = await Api.post('/priceType/list', {userId, token, user: userId, locale})
      if (!body.error && Array.isArray(body.priceTypes) && body.priceTypes.length) {
        return body.priceTypes
      } else {
        return defaultResult
      }
    } catch (error) {
      return defaultResult
    }
  }
}