import React from 'react'
import { connect } from 'react-redux'
import { getIsMobileDevice, getIsTransitioning } from 'SRC/modules/common/selectors'
import Link from 'next/link'
import PropTypes from 'prop-types'

const Item = ({
  id,
  icon,
  seo,
  title,
  currentGroup,
  isMobileDevice,
  isTransitioning
}) => {
  let flexGrow = 3

  if (title.length <= 13) flexGrow = 3
  else if (title.length <= 16) flexGrow = 4
  else flexGrow = 5

  const isActive = currentGroup && seo === currentGroup.seo

  if (!isTransitioning || isActive) {
    return (
      <div
        className={`kategorije-item ${isActive ? 'active' : ''}`}
        style={!isMobileDevice ? { flexGrow } : {}}
      >
        <Link href={`/category?category=${seo}`} as={`/${seo}`}>
          <a>
            <div className='kategorije-item-icon'>
              <i className={icon} />
            </div>
            <div className='kategorije-item-text'>
              <p>{title}</p>
            </div>
          </a>
        </Link>
      </div>
    )
  }

  return (
    <div
      className={`kategorije-item disabled`}
      style={!isMobileDevice ? { flexGrow } : {}}
    >
      <a href='javascript:void(0)' style={{ opacity: .4, cursor: 'not-allowed' }}>
        <div className='kategorije-item-icon'>
          <i className={icon} />
        </div>
        <div className='kategorije-item-text'>
          <p>{title}</p>
        </div>
      </a>
    </div>
  )
}

const mapStateToProps = state => ({
  isMobileDevice: getIsMobileDevice(state),
  isTransitioning: getIsTransitioning(state)
})

Item.propTypes = {
  id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  seo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  currentGroup: PropTypes.object,
  isMobileDevice: PropTypes.bool.isRequired,
  isTransitioning: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Item)
