import React from 'react'
import PropTypes from 'prop-types'
import config from 'SRC/config/config.json'

export const Email = ({ baseUrl, asPath, adLink }) => {
  const link = baseUrl ? baseUrl + '/' + adLink : adLink

  return (
    <div className='kontakt-icon kontakt-email'>
      <a
        rel='noreferrer'
        target='_blank'
        href={adLink
          ? `mailto:?subject=${link}&body=${config.sidebar.share.email.body}`
          : `mailto:?subject=${baseUrl}${asPath}&body=${config.sidebar.share.email.body}`
        }
        aria-label='Email share'
      >
        <i className='ico-share-email ico' />
      </a>
    </div>
  )
}

Email.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  asPath: PropTypes.string.isRequred,
  adLink: PropTypes.string.isRequred
}
