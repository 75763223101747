import PropTypes from 'prop-types'
import React from 'react'

import useTranslation from 'next-translate/useTranslation'

import { useFormikContext, ErrorMessage } from 'formik'
import { TextInputNew, SelectDropdown, Wrapper } from 'SRC/ui/FormElementsNew'

import config from 'SRC/config/config.json'

const messengerOptions = [
	{
		id: 'viber',
		label: 'Viber',
		value: 'viber'
	},
	{
		id: 'whatsapp',
		label: 'WhatsApp',
		value: 'whatsapp'
	}
]

export const Contact = (props) => {
	const formik = useFormikContext()

  const { t } = useTranslation('common')

	const getCheckedMessengers = () => {
		const checkedMessengers = []
		if (formik.values.phoneUpdate?.viber) checkedMessengers.push('viber')
		if (formik.values.phoneUpdate?.whatsapp) checkedMessengers.push('whatsapp')
		return checkedMessengers
	}

	const { contact } = props

	return (
		<div className='form-contact'>
			<Wrapper>
				<TextInputNew
					id='contact-phone'
					isDisabled
					isRequired
					title={t('Telefon')}
					value={contact?.phone ? `+${contact.phone}` : ''}
				/>
				</Wrapper>

				<Wrapper>
					<SelectDropdown
						id='viber-whatsapp'
						title={t('login_update_form_viberWhatsApp')}
						isMultiple
						checkedItems={getCheckedMessengers()}
						options={messengerOptions}
						onChange={async selectedOptions => {
							if (Array.isArray(selectedOptions)) {
								if (selectedOptions.includes('viber')) formik.setFieldValue('phoneUpdate.viber', true)
								else formik.setFieldValue('phoneUpdate.viber', false)

								if (selectedOptions.includes('whatsapp')) formik.setFieldValue('phoneUpdate.whatsapp', true)
								else formik.setFieldValue('phoneUpdate.whatsapp', false)
							}
						}}
					/>
				</Wrapper>
		</div>
	)
}

Contact.propTypes = {
	contact: PropTypes.shape({
		phone: PropTypes.string,
		viber: PropTypes.bool,
		whatsapp: PropTypes.bool
	}).isRequired
}
