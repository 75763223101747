import React from 'react'
import { SocialLinks } from '../SocialLinks'
import config from 'SRC/config/config.json'

let itemCounter = 1
export const columns = config.footer.menu.map((column, index) => ({
  id: index + 1,
  apps: Boolean(column.apps),
  title: column.title,
  className: column.className,
  items: column.items.map((item) => ({
    id: itemCounter++,
    link: item.link,
    title: item.title,
    targetBlank: item.targetBlank,
    hide: item.hide
  })),
  child: column.child === 'social' ? <SocialLinks /> : null
}))
