import React from 'react'
import config from 'SRC/config/config.json'
import Trans from 'next-translate/Trans'

export const Weblab = () => (
  <div className='footer-regions'>
    <Trans
        i18nKey='common:footer_weblab'
        components={{
          span: <span className='footer-column-title' />,
          p: <p />
        }}
        defaultTrans={`
          <span>WEBLAB D.O.O.</span>
          <p>Jovana Tomaševića 1, Bar, Crna Gora PIB: 03007448 | </p>
          <p>Telefon: +382 (0) 30 550 099 | info@autodiler.me</p>
        `}
      />
  </div>
)
