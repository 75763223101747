import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'

export const Label = ({ options, labelDefault, value }) => {
  const { t } = useTranslation('common')

  let label = labelDefault || t('Izaberi')

  if (Array.isArray(options)) {
    options.forEach(option => {
      if (option.isGrouped) {
        option.options.forEach(childItem => {
          if (childItem.value === value) label = childItem.label
        })
      } else {
        if (option.value === value) label = option.label
      }
    })
  }

  if (label.length > 25) label = `${label.substr(0, 25).trim()}...`

  return <label className={`${label !== t('Izaberi') ? 'select-dropdown-selected' : ''}`}>{label}</label>
}

Label.propTypes = {
  labelDefault: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    isGrouped: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      value: PropTypes.any
    }))
  })).isRequired,
  value: PropTypes.any
}
