import { createSelector } from 'reselect'
import { getFlattenCategories } from '../../../../categories/selectors/getFlattenCategories'

export const getFavouriteExpiredAds = createSelector(
  state => state && state.user && state.user.profile && state.user.profile.favouriteAds
    && state.user.profile.favouriteAds.Expired.elements.length ?
    state.user.profile.favouriteAds.Expired.elements : [], getFlattenCategories,
 	(favouriteAds, categories) => favouriteAds.map(ad => {
    return {
      ...ad,
      isPaid: ad.listingStatus === 'Active',
      category: categories.find(cat => cat.id === ad.category)
    }
  })
)
