import React from 'react'
import { TransportSubMenu } from '../TransportSubMenu'
import config from 'SRC/config/config.json'

export const itemsBeforeUserTypes = config.menu.items.beforeUserTypes.map(item => {
  const child = item.child === 'TransportSubMenu' ? () => (<TransportSubMenu />) : null
  return {
    id: item.key,
    link: item.link ? item.link : '',
    as: item.as ? item.as : '',
    text: item.text ? item.text : '',
    ariaLabel: item.ariaLabel ? item.ariaLabel : '',
    icoClass: item.icoClass ? item.icoClass : '',
    className: item.className ? item.className : '',
    pages: item.pages,
    child
  }
})

export const itemsAfterUserTypes = config.menu.items.afterUserTypes.map(item => {
  const child = item.child === 'TransportSubMenu' ? () => (<TransportSubMenu />) : null
  return {
    id: item.key,
    link: item.link ? item.link : '',
    text: item.text ? item.text : '',
    ariaLabel: item.ariaLabel ? item.ariaLabel : '',
    as: item.as ? item.as : '',
    icoClass: item.icoClass ? item.icoClass : '',
    className: item.className ? item.className : '',
    pages: item.pages,
    child
  }
})
