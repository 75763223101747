import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export const UserAds = ({ username, seo }) => {
  const { t } = useTranslation('common')

  return (
    <div className='sidebar-user-ostalo-item'>
      {username && seo ? (
        <Link href={`/user?userType=${seo}&username=${username}`} as={`/${seo}/${username}`}>
          <a>
            <i key='icon' className='ico-user-svi-oglasi-prodavca ico' />
            <p key='text'>{t('Pogledaj sve oglase prodavca')}</p>
          </a>
        </Link>
      ) : (
        <a href='javascript:void (0)'>
          <i key='icon' className='ico-user-svi-oglasi-prodavca ico' />
          <p key='text'>{t('Pogledaj sve oglase prodavca')}</p>
        </a>
      )}
    </div>
  )
}


UserAds.propTypes = {
  seo: PropTypes.string,
  username: PropTypes.string
}
