import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const detailReducer = (state = initialState.discountCard.partners.detail, action) => {
  switch (action.type) {
    case actionTypes.SET_PARTNER:
      return action.payload
    default:
      return state
  }
}
