import { getCurrentLocale } from 'SRC/modules/common/functions'

import { setSpecifications } from '../setSpecifications'
import { CategoriesApi } from '../../api'

export const fetchSpecifications = (category, locale = getCurrentLocale()) => {
  return async dispatch => {
    const api = new CategoriesApi()
    const specifications = await api.fetchSpecificationsByCategory(category, locale)
    dispatch(setSpecifications(specifications))
  }
}
