import PropTypes from 'prop-types'
import React from 'react'
import { SelectDropdown } from 'SRC/ui/FormElementsNew'

export class SelectRange extends React.Component {
  onFromSelected = value => {
    const { onValueFromChange, valueTo, valueFrom } = this.props

    const needToClearValue = Number(value) === -1

    const updateValueCondition =
      value <= valueTo || // Update if value from is equal or less than value to
      Number(valueTo) === -1 || // Force update if value to === -1
      needToClearValue // Force update if the value must be cleared

    if (updateValueCondition) {
      onValueFromChange(needToClearValue ? -1 : value)
    } else {
      const oldValue = valueFrom
      onValueFromChange(oldValue)
    }
  }

  onToSelected = value => {
    const { onValueToChange, valueFrom, valueTo } = this.props

    const needToClearValue = Number(value) === -1

    const updateValueCondition =
      value >= valueFrom || // Update if value from is equal or greater than value to
      Number(valueFrom) === -1 || // Force update if value from === -1
      needToClearValue // Force update if the value must be cleared

    if (updateValueCondition) {
      onValueToChange(needToClearValue ? -1 : value)
    } else {
      const oldValue = valueTo
      onValueToChange(oldValue)
    }
  }

  render () {
    const { id, title, options, valueFrom, valueTo, show } = this.props
    return show ? (
      <React.Fragment>
        <div className='select-range-item'>
          <SelectDropdown
            id={`select-from-${id}`}
            title={title}
            options={options}
            value={valueFrom}
            onChange={this.onFromSelected}
            isSearchable
          />
        </div>

        <div className='select-range-item'>
          <SelectDropdown
            id={`select-to-${id}`}
            needColon={false}
            options={options}
            value={valueTo}
            onChange={this.onToSelected}
            isSearchable
          />
        </div>
      </React.Fragment>
    ) : null
  }
}

SelectRange.propTypes = {
  id: PropTypes.any.isRequired,
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  valueFrom: PropTypes.any.isRequired,
  valueTo: PropTypes.any.isRequired,
  show: PropTypes.bool,
  onValueFromChange: PropTypes.func.isRequired,
  onValueToChange: PropTypes.func.isRequired
}

SelectRange.defaultProps = {
  show: true
}
