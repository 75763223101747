import initialState from 'src/modules/redux/initialState'
import actionTypes from 'src/modules/redux/actionTypes'

export const listingsReducer = (state = initialState.ads.listings, action) => {
  switch (action.type) {
    case actionTypes.SET_ADS_LISTINGS:
      return action.payload
    default:
      return state
  }
}
