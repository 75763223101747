import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const listReducer = (state = initialState.garage.list, action) => {
  switch (action.type) {
    case actionTypes.SET_GARAGE_ITEMS:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_GARAGE_ITEMS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case actionTypes.SET_GARAGE_ITEMS_PAGE:
      return {
        ...state,
        page: action.payload
      }
    default:
      return state
  }
}
