import initialState from '../../../redux/initialState'
import actionTypes from '../../../redux/actionTypes'

export const locationsReducer = (state = initialState.geoObjects.locations, action) => {
  switch (action.type) {
    case actionTypes.SET_LOCATIONS:
      return {
        ...state,
        elements: action.payload
      }
    case actionTypes.SET_LOCATIONS_BY_CITY:
      return {
        ...state,
        byCity: {
          ...state.byCity,
          [action.payload.city]: action.payload.locations
        }
      }
    case actionTypes.SET_LOCATIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}
