import { getCurrentLocale } from 'SRC/modules/common/functions'

import { CategoriesApi } from 'SRC/modules/categories/api'
import { setGroups } from '../setGroups'

import groupsME from '../../../../../public/data/groupsME.json'

export const fetchGroups = ({ locale }) => {
  return async (dispatch, getState) => {
    let groups = getState().groups.elements

    if (typeof locale === 'undefined') locale = getCurrentLocale() || 'ME'

    if (locale === 'ME') groups = groupsME

    if (!Array.isArray(groups) || !groups.length) {
      const api = new CategoriesApi()
      groups = await api.fetchGroups(locale)
    }

    dispatch(setGroups(groups))
  }
}
