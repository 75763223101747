import { setSavedFilters, setSavedFiltersLoading } from '../../actions'
import { setProfilePaginationParams } from '../../pagination/actions'
import { ProfileApi } from '../../api'
import { globalOptions } from 'SRC/core/constants'

export const fetchSavedFilters = (userId, token, page, paginationParams = {}) => {
	return async (dispatch) => {
    dispatch(setSavedFiltersLoading(true))
    const api = new ProfileApi()
    const {savedFilters, count} = await api.getSavedFilters(userId, token, page, globalOptions.savedFiltersCountPerPage)
    dispatch(setSavedFilters(savedFilters))
    const pagination = {...paginationParams, currentPage: page, count}
    dispatch(setProfilePaginationParams(pagination))
    dispatch(setSavedFiltersLoading(false))
  }
}