import React from 'react'
import PropTypes from 'prop-types'

export const Viber = ({ baseUrl, asPath, adLink }) => {
  const link = baseUrl ? baseUrl + '/' + adLink : adLink

  return (
    <div className='kontakt-icon kontakt-viber'>
      <a id='viber_share'
        rel='noreferrer'
        href={adLink ? 'viber://forward?text=' + encodeURIComponent(link)
          : 'viber://forward?text=' + encodeURIComponent(baseUrl + asPath)}
        aria-label='Viber share'
      >
        <i className='ico-share-viber ico' />
      </a>
    </div>
  )
}

Viber.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  asPath: PropTypes.string.isRequred,
  adLink: PropTypes.string.isRequred
}
