import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'

export class Api extends BaseApi {
  fetchServiceTypesByServiceAndCategory = async ({service, category}) => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(service) && TypesHelper.isPositiveNumber(category)) {
      try {
        const {body} = await Api.post('/serviceType/listByServiceAndCategory', {service, category})
        if (!body.error && Array.isArray(body.serviceTypes)) {
          return body.serviceTypes
        } else {
          return defaultResult
        }
      } catch (e) {
        return defaultResult
      }
    }
    return defaultResult
  }

  fetchServiceTypesByService = async service => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(service)) {
      try {
        const {body} = await Api.post('/serviceType/listByService', {service})
        if (!body.error && Array.isArray(body.serviceTypes)) {
          return body.serviceTypes
        } else {
          return defaultResult
        }
      } catch (e) {
        return defaultResult
      }
    }
    return defaultResult
  }
}
