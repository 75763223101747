import PropTypes from 'prop-types'
import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { Wrapper } from '../Wrapper'

export class Select extends React.PureComponent {
  renderSelect = () => {
    const { id, label, labelDefault, name, disabled,
      needColon, options, isRequired, onChange, validate, selectClassName } = this.props
    return <Field name={name} validate={validate}>{({
      field,
      form: {
        touched,
        errors,
        isSubmitting
      }
    }) => {
      return <Wrapper isError={touched[field.name] && errors[field.name]}>
        <label htmlFor={id}>
          {label}{needColon ? `:` : null} {isRequired ? <span className='required'>*</span> : null}
        </label>

        <select
          name={field.name}
          value={field.value || ''}
          onChange={(e) => {
            field.onChange(e)
            if (onChange) onChange(e)
          }}
          onBlur={field.onBlur}
          id={id}
          disabled={disabled || isSubmitting}
          className={selectClassName}
        >
          <option value='' key='izaberi'>{labelDefault}</option>
          {
            options.map(option => option.isGrouped
              ? <optgroup key={option.id} label={option.label}>
                {
                  option.options.map(item =>
                    <option key={item.key ? item.key : (item.id ? `${item.id}-${item.value}` : item.value)}
                      value={item.value} disabled={item.disabled}>{item.label}</option>
                  )
                }
              </optgroup>
              : <option value={option.value} key={option.key ? option.key : (option.id ? `${option.id}-${option.value}` : option.value)}
                disabled={option.disabled}>{option.label}</option>)
          }
        </select>
        <ErrorMessage name={name} render={msg => <span className='form-group__error-message'>{msg}</span>} />
      </Wrapper>
    }}
    </Field>
  }

  render () {
    return this.renderSelect()
  }
}

Select.propTypes = {
  // disabled: PropTypes.bool.isRequired,
  // id: PropTypes.string.isRequired,
  // key: PropTypes.string,
  // name: PropTypes.string.isRequired,
  // options: PropTypes.arrayOf(PropTypes.shape({
  //   id: PropTypes.number.isRequired,
  //   value: PropTypes.any.isRequired,
  //   disabled: PropTypes.bool,
  //   label: PropTypes.string,
  //   isGrouped: PropTypes.bool,
  //   options: PropTypes.arrayOf(PropTypes.shape({
  //     value: PropTypes.any.isRequired,
  //     disabled: PropTypes.bool,
  //     label: PropTypes.string.isRequired,
  //     key: PropTypes.string
  //   }))
  // })).isRequired,
  // label: PropTypes.string,
  labelDefault: PropTypes.string.isRequired,
  // className: PropTypes.string,
  // isRequired: PropTypes.bool.isRequired,
  // onChange: PropTypes.func,
  // isBlocked: PropTypes.bool.isRequired,
  // onFocus: PropTypes.func,
  // onBlur: PropTypes.func,
  // onMouseEnter: PropTypes.func,
  // selectClassName: PropTypes.string,
  // validate: PropTypes.any,
  // needColon: PropTypes.bool.isRequired,
  // normalize: PropTypes.func
}

Select.defaultProps = {
  // disabled: false,
  // options: [],
  // isRequired: false,
  // isError: false,
  // isBlocked: false,
  labelDefault: 'Izaberi',
  // needColon: true
}
