/* eslint-disable react/no-did-update-set-state */
import PropTypes from 'prop-types'
import React from 'react'
import { Wrapper } from '../Wrapper'
import { ErrorMessage } from 'formik'
import { getChar, getRegularExpression } from 'SRC/utils'
import { Hint } from '../Hint'
import { IMaskInput } from 'react-imask'

const normalize = value => `${value}`.replace(/ /g, '')

export class TextInput extends React.Component {
  constructor (props) {
    super(props)

    this.input = React.createRef()
  }
  componentDidMount () {
    const { field } = this.props
    if (field && field.hasOwnProperty('value')) {
      if (this.input.current) {
        this.input.current.value = field.value
      }
    }
  }

  renderTextInput = () => {
    const {
      id,
      label,
      hint,
      measure,
      placeholder,
      isRequired,
      isFullWidth,
      onAccept,
      field: {
        name,
        onBlur,
        onChange
      },
      form: {
        touched,
        errors
      }
    } = this.props
    return <Wrapper isFullWidth={isFullWidth} isError={touched[name] && errors[name]}>
      <label htmlFor={id}>{label}: {isRequired ? <span className='required'>*</span> : null}</label>
      {hint ? <Hint hint={this.props.hint} /> : null}
      <input
        name={name}
        unmask
        onBlur={onBlur}
        id={id}
        key={id}
        type='text'
        onChange={onChange}
        placeholder={placeholder}
        onKeyPress={this.onKeyPress}
        ref={input => {
          if (input && input.element) {
            this.input.current = input.element
          }
        }}
      />
      {measure ? <span className='input-addon'>{measure}</span> : null}
      <ErrorMessage name={name} render={msg => <span className='form-group__error-message'>{msg}</span>} />
    </Wrapper>
  }

  renderMaskedInput = () => {
    const {
      id,
      label,
      hint,
      measure,
      placeholder,
      isRequired,
      isFullWidth,
      mask,
      onAccept,
      field: {
        name,
        onBlur,
        onChange
      },
      form: {
        touched,
        errors
      }
    } = this.props

    return <Wrapper isFullWidth={isFullWidth} isError={touched[name] && errors[name]}>
      <label htmlFor={id}>{label}: {isRequired ? <span className='required'>*</span> : null}</label>
      {hint ? <Hint hint={this.props.hint} /> : null}
      <IMaskInput
        {...mask}
        name={name}
        unmask
        onBlur={onBlur}
        id={id}
        key={id}
        type='text'
        placeholder={placeholder}
        onKeyPress={this.onKeyPress}
        onAccept={(value, field) => onAccept(normalize(value), field)}
        ref={input => {
          if (input && input.element) {
            this.input.current = input.element
          }
        }}
      />
      {measure ? <span className='input-addon'>{measure}</span> : null}
      <ErrorMessage name={name} render={msg => <span className='form-group__error-message'>{msg}</span>} />
    </Wrapper>
  }

  onKeyPress = e => {
    const chr = getChar(e)
    if (e.ctrlKey || e.altKey || chr == null) return
    if (Array.isArray(this.props.allowedCharacters) && this.props.allowedCharacters.length) {
      const regexp = getRegularExpression(this.props.allowedCharacters)
      const isValid = regexp ? regexp.test(chr) : true
      if (!isValid) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  render () {
    const { mask } = this.props

    return mask ? this.renderMaskedInput() : this.renderTextInput()
  }
}

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  // isRequired: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  measure: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func
  }),
  form: {
    touched: PropTypes.bool,
    errors: PropTypes.array
  },
  // type: PropTypes.string.isRequired,
  // isBlocked: PropTypes.bool.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  // className: PropTypes.string,
  // wrapperClass: PropTypes.string,
  allowedCharacters: PropTypes.array,
  // measure: PropTypes.string,
  mask: PropTypes.shape({
    mask: PropTypes.any.isRequired,
    maskChar: PropTypes.any,
    formatChars: PropTypes.object
  }),
  // onChange: PropTypes.func,
  onAccept: PropTypes.func
}

TextInput.defaultProps = {
  // isRequired: false,
  // isBlocked: false,
  // isFullWidth: false,
  // label: '',
  // type: 'text',
  // allowedCharacters: [],
  // isHtmlLabel: false,
  // needColon: true
}
