import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { connect } from 'react-redux'
import { getSimilarAds, getSimilarAdsLoading, getCurrentAd } from 'SRC/modules/ads/detail/selectors'
import { fetchSimilarAds } from 'SRC/modules/ads/detail/actions'
import { useOnScreen } from 'SRC/modules/common/hooks'
import { AdditionalPreloader } from 'SRC/ui/Preloader'
import { GridViewList } from 'SRC/modules/ads/list/components/GridView'
import { adsListViews } from 'Core/constants'

export const SimilarAds = ({ currentAd, similarAds, similarAdsLoading, fetchSimilarAds }) => {
  const { t } = useTranslation('common')
  const [fetch, setFetch] = React.useState(true)

  const ref = React.useRef()
  const isVisible = useOnScreen(ref)

  React.useEffect(() => {
    if (fetch && !similarAdsLoading && isVisible && currentAd) {
      setFetch(false)
      fetchSimilarAds(currentAd.id)
    }
  }, [isVisible])

  const adId = currentAd ? currentAd.id : null

  React.useEffect(() => setFetch(true), [adId])

  return (
    <div className='oglasi-tab-content-slicni-oglasi active' ref={ref}>
      {!similarAdsLoading
        ? (
          <GridViewList
            ads={similarAds}
            style={adsListViews.TABLE}
            noAdsMessage={t('common:Nema sličnih oglasa')}
          />
        ) : <AdditionalPreloader />
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    similarAds: getSimilarAds(state),
    similarAdsLoading: getSimilarAdsLoading(state),
    currentAd: getCurrentAd(state)
  }
}

SimilarAds.propTypes = {
  similarAds: PropTypes.array,
  similarAdsLoading: PropTypes.bool,
  currentAd: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  fetchSimilarAds: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { fetchSimilarAds })(SimilarAds)

