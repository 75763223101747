import config from 'SRC/config/config.json'

const getPriceTitle = ({ price, priceOnRequest, priceFrom, priceTo, t }) => {
  if (priceOnRequest) return 'Cijena na upit'

  if (priceFrom && priceTo) return `${priceFrom} ${t('do')} ${priceTo} €`
  if (priceFrom && !priceTo) return `${t('Već od')} ${priceFrom} €`
  if (!priceFrom && priceTo) return `${t('Do')} ${priceTo} €`

  if (price) return `Cijena ${price} €`
  return ''
}

export const getTitleForCategory = ({
  category,
  titleCompiled,
  price,
  priceOnRequest,
  priceFrom,
  priceTo,
  brand,
  product,
  service,
  serviceType,
  adsSpecifications,
  country,
  city,
  location,
  t
}) => {
  let title
  const titleParts = []
  const adTitle = titleCompiled || ''

  if (adTitle) {
    titleParts.push(adTitle)
  }

  const priceTitle = getPriceTitle({ price, priceOnRequest, priceFrom, priceTo, t })

  if (priceTitle) {
    titleParts.push(priceTitle)
  }

  let restPartOfTitle
  const arRestPartOfTitle = []
  if (country && country.name) {
    arRestPartOfTitle.push(country.name)
  }
  if (city && city.name) {
    arRestPartOfTitle.push(city.name)
  }
  if (location && location.name) {
    arRestPartOfTitle.push(location.name)
  }
  if (category && category.title) {
    arRestPartOfTitle.push(category.title)
  }
  restPartOfTitle = arRestPartOfTitle.join(' ')
  if (restPartOfTitle) {
    titleParts.push(restPartOfTitle)
  }

  title = titleParts.join(' - ')

  return title ? `${title} | ${config.siteName}` : config.siteName
}
