import {
  numberRegExp,
  letterRegExp,
  letterExtended,
  letterShortRegExp,
  rationalNumberRegExp
} from 'Core/validators'
import { TypesHelper } from './TypesHelper'
import { globalOptions } from '../core/constants'
import browserDetect from 'browser-detect'
import parsePhoneNumber from 'libphonenumber-js'

export const smoothScroll = (sourceNode, stopY) => {
  const MAX_SPEED = 20
  const startY = sourceNode.scrollTop
  const distance = stopY > startY ? stopY - startY : startY - stopY
  if (distance < 100) {
    sourceNode.scrollTop = stopY
    return
  }
  let speed = Math.round(distance / 100)
  if (speed >= MAX_SPEED) {
    speed = MAX_SPEED
  }
  const step = Math.round(distance / 25)
  let timer = 0
  if (stopY > startY) {
    for (let i = startY; i < stopY; i += step) {
      setTimeout(() => {
        if (sourceNode.scrollTop + step > stopY) {
          sourceNode.scrollTop = stopY
        } else {
          sourceNode.scrollTop += step
        }
      }, timer * speed)
      timer++
    }
  } else {
    for (let i = startY; i > stopY; i -= step) {
      setTimeout(() => {
        if (sourceNode.scrollTop - step < stopY) {
          sourceNode.scrollTop = stopY
        } else {
          sourceNode.scrollTop -= step
        }
      }, timer * speed)
      timer++
    }
  }
}

export const def = x => typeof x !== 'undefined'

export const isArray = x => Array.isArray(x)

export const flatten = ([x, ...xs]) => def(x)
  ? isArray(x) ? [...flatten(x), ...flatten(xs)] : [x, ...flatten(xs)]
  : []

export const flattenWithChildren = ([x, ...xs]) => {
  if (def(x)) {
    const {children, ...noChildren} = x
    return isArray(children)
      ? [noChildren, ...flattenWithChildren(children), ...flattenWithChildren(xs)]
      : [noChildren, ...flattenWithChildren(xs)]
  } else {
    return []
  }
}

export const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const getRoundedTimePassedSinceLastRefresh = (lastTimeRefreshed) => {
  if (lastTimeRefreshed) {
    const moment = require('moment')
    const timePassedSinceLastRefresh = moment.duration(
      moment(new Date()).diff(moment(lastTimeRefreshed))
    ).asSeconds()
    switch (timePassedSinceLastRefresh) {
      case (timePassedSinceLastRefresh <= 60):
        return `prije ${timePassedSinceLastRefresh} sekunde`
      case (timePassedSinceLastRefresh >= 60 && timePassedSinceLastRefresh <= 90):
        return `prije 1 minut`
      case (timePassedSinceLastRefresh > 90 && timePassedSinceLastRefresh < 3600):
        const minutes = Math.floor(timePassedSinceLastRefresh / 60 + (timePassedSinceLastRefresh % 60 > 30 ? 1 : 0))
        return `prije ${minutes} minuta`
      case (timePassedSinceLastRefresh >= 3600 && timePassedSinceLastRefresh < 5400):
        return `prije 1 sat`
      case (timePassedSinceLastRefresh >= 5400 && timePassedSinceLastRefresh < 86400):
        const hours = Math.floor(timePassedSinceLastRefresh / 3600 + (timePassedSinceLastRefresh % 3600 > 1800 ? 1 : 0))
        return `prije ${hours} sata`
      case (timePassedSinceLastRefresh >= 86400 && timePassedSinceLastRefresh < 129600):
        return `prije 1 dan`
      case (timePassedSinceLastRefresh >= 129600 && timePassedSinceLastRefresh < 2592000):
        const days = Math.floor(timePassedSinceLastRefresh / 86400 + (timePassedSinceLastRefresh % 86400 > 43200 ? 1 : 0))
        return `prije ${days} dana`
      case (timePassedSinceLastRefresh >= 2592000 && timePassedSinceLastRefresh < 3888000):
        return `prije mesec dana`
      default:
        return moment(lastTimeRefreshed).format('DD.MM.YY')
    }
  } else {
    return ''
  }
}

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, {type: mime})
}

export const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], {type: mime})
}

export const getRegularExpression = (items = []) => {
  const browser = browserDetect()
  if (Array.isArray(items) && items.length) {
    const arRegExp = []
    items.forEach(item => {
      switch (item) {
        case 'letters':
          if (browser.name === 'chrome') {
            arRegExp.push(letterRegExp)
          } else {
            arRegExp.push(letterShortRegExp)
          }
          arRegExp.push('\\s')
          break
        case 'lettersWithDots':
          if (browser.name === 'chrome') {
            arRegExp.push(letterRegExp)
          } else {
            arRegExp.push(letterShortRegExp)
          }
          arRegExp.push('\\s')
          arRegExp.push('[,.!?:()+%-]')
          break
        case 'lettersExtended':
          if (browser.name === 'chrome') {
            arRegExp.push(letterRegExp)
            arRegExp.push(letterExtended)
          } else {
            arRegExp.push(letterShortRegExp)
          }
          arRegExp.push('\\s')
          break
        case 'characters':
          arRegExp.push('[.,-/+]')
          break
        case 'numbers':
        case 'natural':
          arRegExp.push(numberRegExp)
          break
        case 'rational':
          arRegExp.push(rationalNumberRegExp)
          break
      }
    })
    if (arRegExp.length) {
      const flags = browser.name === 'chrome' ? 'iu' : 'i'
      const regex = new RegExp(`^(${arRegExp.join('|')})+$`, flags)
      return regex
    }
    return null
  }
  return null
}

export const getChar = event => {
  if (event.which == null) {
    if (event.keyCode < 32) return null
    return String.fromCharCode(event.keyCode)
  }

  if (event.which !== 0 && event.charCode !== 0) {
    if (event.which < 32) return null
    return String.fromCharCode(event.which)
  }

  return null
}

export const isObjectEquals = (a, b) => {
  let isEquals = true
  if (a && b && typeof a === 'object' && typeof b === 'object' && Object.keys(a).length === Object.keys(b).length) {
    for (let key in a) {
      if (a.hasOwnProperty(key)) {
        if (a[key] !== b[key]) {
          isEquals = false
          break
        }
      }
    }
  } else {
    isEquals = false
  }
  return isEquals
}

export const isObjectContainedAnotherObject = (targetObj, sourceObj) => {
  let isContained = true
  if (sourceObj && targetObj && typeof sourceObj === 'object' && typeof targetObj === 'object') {
    for (let key in targetObj) {
      if (targetObj.hasOwnProperty(key)) {
        if (sourceObj[key] !== targetObj[key]) {
          isContained = false
          break
        }
      }
    }
  } else {
    isContained = false
  }
  return isContained
}

export const capitalize = (word) => {
  if (TypesHelper.isNotEmptyString(word)) {
    return `${word.charAt(0).toUpperCase() + word.substr(1)}`
  } else {
    return ''
  }
}

export const deepCopy = object => {
  return JSON.parse(JSON.stringify(object))
}

export const isUserOnline = lastActivityTime => {
  let isOnline = false
  if (lastActivityTime) {
    const date = new Date(lastActivityTime)
    if (date) {
      const currentDate = new Date()
      const border = globalOptions.minutesCountForUserIsOnline * 60 * 1000
      isOnline = (currentDate.getTime() - date.getTime()) < border
    }
  }
  return isOnline
}

export const getFormattedAdPrice = price => {
  if (isNaN(Number(price))) return 0

  if (price >= 1000) return Number(`${price}`.split('.')[0])

  return Number(price).toFixed(2)
}

/** Moved to TypesHelper */
export const isJsonString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isBrowserSupported = browser => {
  console.log('browser', browser)
  switch (browser && browser.name) {
    case 'chrome': return true
    case 'edge': return true
    case 'firefox':
      if (browser.versionNumber >= 54) return true
      return false
    case 'ie':
      if (browser.versionNumber >= 11) return true
      return false
    default: return true
  }
}

export const normalizeString = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const getFormattedPhoneNumber = phone => {
  const phoneNumber = parsePhoneNumber(`+${phone}`)
  const formattedPhone = phoneNumber.formatInternational()
  return formattedPhone || `+${phone}`
}
