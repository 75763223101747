import { BaseApi } from 'Core/api/BaseApi'
import { TypesHelper } from 'SRC/utils'

export class Api extends BaseApi {
  getBrandsByCategory = async category => {
    const defaultResult = []
    if (TypesHelper.isPositiveNumber(category)) {
      try {
        const {body} = await Api.post(`/brand/listByCategory`, {category})
        if (!body.error && body.brands && Array.isArray(body.brands)) {
          return body.brands
        } else {
          return defaultResult
        }
      } catch (error) {
        return defaultResult
      }
    } else {
      return []
    }
  }
}
