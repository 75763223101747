import PropTypes from 'prop-types'
import React from 'react'
import { ErrorMessage } from 'formik'
import StarRatings from 'react-star-ratings'
import { Wrapper } from '../Wrapper'

export class Stars extends React.Component {
  changeRating = (newRating, name) => {
    const { form } = this.props
    console.log('e', newRating, name)
    form.setFieldValue(name, newRating)
  }

  render () {
    const { label, needColon, isRequired, form, field: { name, value } } = this.props
    const rating = value && !isNaN(value) ? Number(value) : 0
    return <Wrapper isError={form.touched[name] && form.errors[name]}>
      {label}{needColon ? `:` : null} {isRequired ? <span className='required'>*</span> : null}
      <StarRatings name={name} rating={rating} numberOfStars={5} starDimension={23} starSpacing={3}
        changeRating={this.changeRating} starRatedColor='#e6432f' />
      <ErrorMessage name={name} render={msg => <span className='form-group__error-message'>{msg}</span>} />
    </Wrapper>
  }
}

Stars.propTypes = {
  label: PropTypes.string.isRequired,
  needColon: PropTypes.bool,
  isRequired: PropTypes.bool,
  form: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }).isRequired
}
