import { fetchFavouriteProfiles } from '../../actions/'
import { ProfileApi } from '../../api'

export const updateFavouriteProfiles = (profileId, userId, token, add = true, type) => {
  return async (dispatch) => {
    const api = new ProfileApi()
    const result = add === true
      ? await api.addFavouriteProfile(userId, token, profileId)
      : await api.removeFavouriteProfile(userId, token, profileId)
    if (result) {
      if (result.status === 0) {
        dispatch(fetchFavouriteProfiles(userId, token, type, 1))
      }
      return result
    }
  }
}
