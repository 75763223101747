import { createSelector } from 'reselect'

export const getFavouriteProfiles = createSelector(
  (state, type) => state && state.user && state.user.profile && state.user.profile.favouriteProfiles &&
  state.user.profile.favouriteProfiles[type] ? state.user.profile.favouriteProfiles[type] : null,
  favouriteProfiles => favouriteProfiles ? ({
    elements: favouriteProfiles.elements,
    count: favouriteProfiles.count
  }) : ({
    elements: [],
    count: 0
  })
)
