import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'

export const ReportRadio = ({id, label, value}) =>
  <div className='report-row'>
    <Field id={id} component='input' type='radio' value={`${value}`} name='type' />
    <label htmlFor={id}>{label}</label>
    <em>*</em>
  </div>

ReportRadio.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string,
  value: PropTypes.number.isRequired
}
