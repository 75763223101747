import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const garageAddReducer = (state = initialState.garage.add, action) => {
  switch (action.type) {
    case actionTypes.SET_GARAGE_FORM_LOADING:
      return {...state, isLoading: action.payload}
    case actionTypes.SET_GARAGE_ADD_RESULT:
      return {...state, result: action.payload}
    case actionTypes.SET_GARAGE_ADD_FIELDS:
      return {...state, fields: action.payload}
    case actionTypes.SET_GARAGE_ADD_ERROR:
      return {...state, error: action.payload}
    default:
      return state
  }
}
