import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from 'SRC/utils'
import Modal from 'react-responsive-modal'

import useTranslation from 'next-translate/useTranslation'

import { DropdownGroup } from '../DropdownGroup'
import { DropdownItem } from '../DropdownItem'
import { Label } from '../Label'
import { Title } from '../../Title'
import { Search } from '../../Search'

const modalStyles = {
  modalContainer: {
    overflowY: 'hidden'
  },
  modal: {
    width: '90%',
    maxWidth: '90%',
    height: '90vh',
    borderRadius: 10,
		padding: '.25rem',
		borderRadius: 4,
		backgroundColor: '#eeeeee'
  }
}

export const DropdownMobile = ({
	isDisabled,
	options,
	labelDefault,
	id,
	value,
	title,
	onChange,
	needColon,
	isRequired,
	isOptional
}) => {
	const [isModalVisible, setIsModalVisible] = React.useState(false)
	const [filter, setFilter] = React.useState('')

  const { t } = useTranslation('common')

  const getItems = () => {
    let items = [...options]

    if (isOptional) {
      items.unshift({
        id: `${id}-izaberi`,
        value: '-1',
        label: labelDefault || t('Izaberi')
      })
    }

    if (filter) {
      items = items.filter(item => {
        if (item.isGrouped) {
          return item.options.find(childItem => {
            const childItemLabel = normalizeString(childItem.label.toLowerCase())
            return childItemLabel.indexOf(filter.toLowerCase().normalize()) > -1
          })
        }

        const itemLabel = normalizeString(item.label.toLowerCase())
        return itemLabel.indexOf(filter.toLowerCase().normalize()) > -1
      })
    }

    return items
  }

  const onSelectDropdownChange = e => {
    const value = e.currentTarget.dataset.value

		console.log('onSelectDropdownChange', value)

    onChange(value)

		setFilter('')
		setIsModalVisible(false)
  }

	const items = getItems()

	return (
		<React.Fragment>
			<Title title={title} needColon={needColon} isRequired={isRequired} />

			<div
				className={`select-dropdown-picker ${isDisabled ? 'disabled' : ''}`}
				key={`singleselect-${id}`}
				onClick={() => { setIsModalVisible(true) }}
			>
				<Label labelDefault={labelDefault} value={value} options={options} />
			</div>

			<Modal
				open={isModalVisible}
				onClose={() => { setFilter(''); setIsModalVisible(false) }}
				center
				showCloseIcon={false}
				closeOnOverlayClick
				onExited={() => { setFilter(''); setIsModalVisible(false) }}
				styles={modalStyles}
			>
				{!isDisabled ? (
					<div className='select-dropdown-container select-dropdown-single select-dropdown-mobile'>
						<Search setFilter={setFilter} isMobile />
						<ul className='select-dropdown-items-mobile'>
							{items.map((item, index) => item.isGrouped
								? (
									<DropdownGroup
										key={item.id}
										group={item}
										groupIndex={index}
										filter={filter}
										value={value}
										onSelectDropdownChange={onSelectDropdownChange}
										scrollToSelectedItem
									/>
								) : (
									<DropdownItem
										key={`${item.id}-${item.label}`}
										value={value}
										item={item}
										isFirstItem={index === 0}
										onSelectDropdownChange={onSelectDropdownChange}
										scrollToSelectedItem
									/>
								))
							}
						</ul>
					</div>
			) : null}
			</Modal>
		</React.Fragment>
	)
}

DropdownMobile.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string.isRequired,
  labelDefault: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    isGrouped: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      value: PropTypes.any
    }))
  })).isRequired,
  value: PropTypes.any,
  isDisabled: PropTypes.bool.isRequired,
  needColon: PropTypes.bool.isRequired,
  isOptional: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

DropdownMobile.defaultProps = {
  title: '',
  options: [],
  isOptional: true,
  needColon: true,
  isDisabled: false,
  isRequired: false
}
