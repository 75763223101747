import { globalOptions } from 'src/core/constants'
import { setFavouriteProfiles, setFavouriteProfilesLoading } from '../'
import { setProfilePaginationParams } from '../../pagination/actions'
import { ProfileApi } from '../../api'

export const fetchFavouriteProfiles = (userId, token, type = 0, page, paginationParams = {}, limitProfiles = null) => {
  return async (dispatch) => {
    dispatch(setFavouriteProfilesLoading(true))
    const api = new ProfileApi()
    const limit = limitProfiles || globalOptions.savedProfilesCountPerPage

    const {favouriteUsers, error, count} = await api.getFavouriteProfiles(userId, token, type, page, limit)
    if (!error) {
      dispatch(setFavouriteProfiles(favouriteUsers, count, type))
      const pagination = { ...paginationParams, countPerPage: limit, currentPage: page, count, type }
      dispatch(setProfilePaginationParams(pagination))
    }
    dispatch(setFavouriteProfilesLoading(false))
  }
}
