import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import { getFormattedAdPrice } from 'SRC/utils'

const renderPriceRange = (priceFrom, priceTo, t) => {
  console.log('priceFrom, priceTo', priceFrom, priceTo)

  if (priceFrom && priceTo) {
    return <div className='cena' key='priceRange'>
      <p>{`${getFormattedAdPrice(priceFrom)} ${t('do')} ${getFormattedAdPrice(priceTo)}`} &euro;</p>
    </div>
  } else if (priceFrom && !priceTo) {
    return <div className='cena' key='priceFrom'>
      <p>{`${t('Već od')} ${getFormattedAdPrice(priceFrom)}`} &euro;</p>
    </div>
  } else if (!priceFrom && priceTo) {
    return <div className='cena' key='priceTo'>
      <p>{`${t('Do')} ${getFormattedAdPrice(priceTo)}`} &euro;</p>
    </div>
  } else {
    return null
  }
}

const renderRetailPrice = (price, oldPrice) => {
  return <React.Fragment>
    <div className='cena'>
      <p>{getFormattedAdPrice(price)} &euro;</p>
    </div>
    {oldPrice ? <div className='stara-cena'>
      {<span className={'stara-cena'}>{getFormattedAdPrice(oldPrice)} &euro;</span>}
    </div> : null}
  </React.Fragment>
}

const renderTypeOfPrice = (price, typeOfPrice) => {
  if (price) {
    return (
      <div className={'oglasi-items-tekst-cena'}>
        <div className='cena' key='price'>
          <p>{typeOfPrice.format.replace('{price}', price)}</p>
        </div>
      </div>
    )
  }

  return null
}

export const Price = ({
  newPrice, oldPrice, promotion, type, priceType, priceWithDiscountCard,
  priceFrom, priceTo, priceOnRequest, typeOfPrice
}) => {
  const { t } = useTranslation('common')

  if (typeOfPrice) return renderTypeOfPrice(newPrice, typeOfPrice)

  return (
    <div className={'oglasi-items-' + type + '-cena'}>
      {!priceOnRequest
        ? priceFrom || priceTo
          ? renderPriceRange(priceFrom, priceTo, t)
          : renderRetailPrice(newPrice, oldPrice)
        : <div className='cena'>
          <p>{t('Cijena na upit')}</p>
        </div>}
      {
        priceWithDiscountCard && !priceOnRequest
          ? <div className='discount-card-price-small'>
            <span>
              <img src='/icons/Partneri-Ponude-red.svg' width={10} />&nbsp;
              {getFormattedAdPrice(priceWithDiscountCard)} &euro;
            </span>
            <p>{t('uz popust karticu')}</p>
          </div> : null
      }
      {promotion && !priceOnRequest ? <div className='cena-akcija'><p>{promotion}&euro;</p></div> : null}
      {
        priceType && !priceOnRequest ? <span className='vrsta-cene'>
          {priceType === 1 ? t('Fiksno') : priceType === 2 ? t('Po dogovoru') : t('Hitno')}
        </span> : null
      }
    </div>
  )
}

Price.propTypes = {
  newPrice: PropTypes.number.isRequired,
  oldPrice: PropTypes.number,
  priceType: PropTypes.number,
  priceFrom: PropTypes.number,
  priceTo: PropTypes.number,
  promotion: PropTypes.number,
  priceWithDiscountCard: PropTypes.number,
  priceOnRequest: PropTypes.bool,
  type: PropTypes.string.isRequired,
  typeOfPrice: PropTypes.object
}
