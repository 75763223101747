import React from 'react'
import PropTypes from 'prop-types'

export const Header = ({item}) => (
  <div key={item.id} className='kategorije-pretraga-mega-menu-content-header' data-header={item.id}>
    <p><span>{item.title}</span></p>
  </div>
)

Header.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
}
