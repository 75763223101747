import { combineReducers } from 'redux'
import { garageAddReducer } from './add/reducer'
import { listReducer } from './list/reducer'
import { editReducer } from './edit/reducer'
import { sharedReducer } from './shared/reducer'

export const garageReducer = combineReducers({
  add: garageAddReducer,
  list: listReducer,
  edit: editReducer,
  shared: sharedReducer
})
