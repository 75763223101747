import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { get404, getUserTypes } from 'SRC/modules/common/selectors'
import { capitalize } from 'SRC/utils/Utils'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import { compose } from 'recompose'
import { Item } from '../Item'
import LocaleContext from 'SRC/contexts/LocaleContext'
import { itemsBeforeUserTypes, itemsAfterUserTypes } from '../data/headerMenuItems'

export const getUserTypeItems = (userTypes, options = {}) => {
  const items = []

  userTypes.forEach(item => {
    if (item && item.seoPlu) {
      items.push({
        id: item.seo,
        link: {
          href: {
            pathname: '/users',
            query: {
              userType: item.seo
            }
          },
          as: `/${item.seoPlu}`
        },
        text: capitalize(item.namePlu),
        ariaLabel: item.ariaLabel,
        icoClass: options.withIcons ? item.icon : '',
        className: '',
        pages: ['/users', '/user', '/search'],
        query: { userType: item.seo },
        child: null
      })
    }
  })

  return items
}

export const Menu = ({ userTypes, is404, router }) => {
  const { t } = useTranslation('common')

  const { locale } = useContext(LocaleContext)

  console.log('Menu locale', locale)

  const headerMenuItems = [...itemsBeforeUserTypes]
  const userTypesItems = getUserTypeItems(userTypes)

  headerMenuItems.push(...userTypesItems)
  headerMenuItems.push(...itemsAfterUserTypes.filter(item => !(item.id === 'discountCard' && ['ru', 'ua', 'de', 'al', 'tr'].includes(locale))))

  return (
    <div className='main-menu'>
      <div className='row'>
        <ul className='main-menu__list'>
          {
            headerMenuItems.map(item => {
              return (
                <React.Fragment key={item.id}>
                  <Item {...item} is404={is404} router={router} text={t(item.text)} locale={locale}>
                    {item.child !== null ? item.child() : ''}
                  </Item>
                  <br />
                </React.Fragment>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

Menu.propTypes = {
  is404: PropTypes.bool,
  router: PropTypes.shape(),
  userTypes: PropTypes.arrayOf(PropTypes.shape())
}

Menu.defaultProps = {
  userTypes: []
}

export default compose(
  withRouter,
  connect((state) => ({ is404: get404(state), userTypes: getUserTypes(state) }))
)(Menu)
