
import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import { withRouter } from 'next/router'
import { compose } from 'recompose'
import useTranslation from 'next-translate/useTranslation'
import { get404, getUserTypes } from 'SRC/modules/common/selectors'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import PropTypes from 'prop-types'
import { slide as BurgerMenu } from 'react-burger-menu'
import config from 'SRC/config/config.json'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { itemsBeforeUserTypes, itemsAfterUserTypes, itemsBottom } from '../../Menu/data/burgerMenuItems'
import { getUserTypeItems } from '../../Menu/Menu'
import { ItemTop } from '../ItemTop'
import { ItemBottom } from '../ItemBottom'
import { Auth } from '../Auth'
import { VehicleRegistrationItem } from '../VehicleRegistrationItem'
import { FilterItems } from '../FilterItems'
import translations from '../../../../../../../public/data/translations.json'

const getIsItemActive = ({ item, asPath, locale }) => {
	let linkPathname = ''
	let itemAsReplaced = ''
	let asPathReplaced = ''

	if (!item || !item.link || !item.link.href || !item.link.as) return false

	if (typeof item.link.href === 'object') linkPathname = item.link.href.pathname.replace('/', '')
	else linkPathname = item.link.href.replace('/', '')

	itemAsReplaced = item.link.as.replace('/', '')
	asPathReplaced = asPath && asPath.replace('/', '')

	if (item.link.as === '/' || item.link.as === `/${translations.pretragaOglasa[locale]}` || item.link.as === `/${translations.pretragaOglasa[locale]}/${translations.tekstualnaPretraga[locale]}`) {
		return item.link.as === asPath
	}

	return (itemAsReplaced && asPathReplaced && asPathReplaced.startsWith(itemAsReplaced)) ||
		(linkPathname && asPathReplaced && linkPathname === asPathReplaced)
}

const Menu = ({ category, userTypes, is404, asPath }) => {
	const [isMenuOpen, setIsMenuOpen] = React.useState(false)
	const prevAsPath = React.useRef(asPath)
	const { lang } = useTranslation('common')

	const locale = lang?.toUpperCase() || 'ME'

	if (prevAsPath.current && prevAsPath.current !== asPath) {
		console.log('Menu asPath', asPath)
		console.log('Menu prevAsPath.current', prevAsPath.current)
		// setIsMenuOpen(false)
		prevAsPath.current = asPath
	}

	const handleStateChange = (state) => {
		console.log('Menu state', state)
    setIsMenuOpen(state.isOpen)
  }

	const closeIcon = () => setIsMenuOpen(false)

	const burgerMenuTopItems = [...itemsBeforeUserTypes]
  const userTypesItems = getUserTypeItems(userTypes || [], { withIcons: true })

  burgerMenuTopItems.push(...userTypesItems)
  burgerMenuTopItems.push(...itemsAfterUserTypes)

	return (
		<div className='burger-menu-wrapper'>
			<BurgerMenu
				isOpen={isMenuOpen}
				onStateChange={handleStateChange}
				right
				width='90%'
			>
				{burgerMenuTopItems.map(item => (
					<ItemTop
						key={item.id}
						{...item}
						is404={is404}
						isActive={getIsItemActive({ item, asPath, locale })}
						closeIcon={closeIcon}
					/>
				))}

				<FilterItems
					key='ad-filter'
					is404={is404}
					isTextSearchActive={asPath === `/${translations.pretragaOglasa[locale]}/${translations.tekstualnaPretraga[locale]}`}
					isFilterSearchActive={asPath.startsWith(`/${translations.pretragaOglasa[locale]}`) &&
						asPath !== `/${translations.pretragaOglasa[locale]}/${translations.tekstualnaPretraga[locale]}`
					}
					closeIcon={closeIcon}
					category={category}
				/>

				<VehicleRegistrationItem
					key={'vehicle-registration'}
					is404={is404}
					isActive={asPath === '/forma/registracija-vozila'}
					closeIcon={closeIcon}
				/>

				{Array.isArray(itemsBottom) ? itemsBottom.map(item => (
					<ItemBottom
						key={item.id}
						{...item}
						is404={is404}
						isActive={getIsItemActive({ item, asPath, locale })}
						closeIcon={closeIcon}
					/>
				)) : null}
				<Auth key='burger-menu-auth' is404={is404} closeIcon={closeIcon} asPath={asPath} />
			</BurgerMenu>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		userTypes: getUserTypes(state),
		is404: get404(state),
		isAuthorized: getIsAuthorized(state),
		category: getCurrentSubCategory(state)
	}
}

export default compose(
  withRouter,
	connect(mapStateToProps)
)(Menu)
