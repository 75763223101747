import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { adsListViews } from 'Core/constants'
import { Item } from '../Item'
import { Banner } from '../Banner'
import { Preloader } from 'SRC/ui/Preloader'

export const List = React.forwardRef((props, ref) => {
  const { ads, style, noAdsMessage, isLoading } = props

  return (
    <div
      className={`oglasi-content-text ${ads.length ? '' : 'oglasi-content-list_no-ads'}`}
      style={{ display: style === adsListViews.TABLE ? 'flex' : 'none' }}
      ref={ref}
    >
      {ads.length && style === adsListViews.TABLE ? ads.map(item => {
        if (item.banner) return <Banner key={item.key} slotId={item.slotId} />
        return <Item ad={item} isLoading={isLoading} key={item.id} />
      }) : isLoading
        ? <Preloader /> : (
          <div className='oglasi-content-text__no-ads-text'>
            {noAdsMessage}
          </div>
        )
      }
    </div>
  )
})

List.displayName = 'GridView'

List.propTypes = {
  ads: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.oneOf([
    adsListViews.LIST,
    adsListViews.TABLE
  ]),
  noAdsMessage: PropTypes.string,
  isLoading: PropTypes.bool
}

List.defaultProps = {
  ads: []
}
