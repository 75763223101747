import { createSelector } from 'reselect'

import { getCurrentAd } from 'SRC/modules/ads/detail/selectors'
import { getUserInfo } from 'SRC/modules/users/Profile/selectors'

export const getIsMyAd = createSelector(
  getCurrentAd,
  getUserInfo,
  (ad, user) => {
    return ad && ad.user && user && ad.user.id === user.id
  }
)
