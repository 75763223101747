import React from 'react'
import PropTypes from 'prop-types'

export const Header = ({title}) => (
  <div className='kategorije-pretraga-mega-menu-content-header'>
    <p><span>{title}</span></p>
  </div>
)

Header.propTypes = {
  title: PropTypes.string.isRequired
}
