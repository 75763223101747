import React from 'react'
import PropTypes from 'prop-types'

const getStringValue = val => val ? val.toString() : ''

export const SelectAll = ({ show, checkedItems, options, onChange }) => {
  if (!show) return null

  let allItemsAreSelected = false

  if (checkedItems.length) {
    const valuesOfItems = options.map(item => getStringValue(item.value))

    allItemsAreSelected = valuesOfItems.every(optionValue => checkedItems.includes(optionValue))
  }

  const toggleSelectAllItems = () => {
    const valuesOfItems = options.map(item => getStringValue(item.value))

    let newItems = [...checkedItems]

    if (allItemsAreSelected) {
      newItems = checkedItems.filter(itemValue => !valuesOfItems.includes(itemValue))
    } else {
      newItems = [...checkedItems, ...valuesOfItems]
    }

    onChange(newItems)
  }

  return (
    <label style={{ minHeight: 17 }}>
      <span
        className={`select-all ${allItemsAreSelected ? ' selected' : ''}`}
        onClick={toggleSelectAllItems}
      >
        {allItemsAreSelected ? 'OČISTI' : 'SVE'}
      </span>
    </label>
  )
}

SelectAll.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    isGrouped: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      value: PropTypes.any
    }))
  })).isRequired,
  show: PropTypes.bool.isRequired,
  checkedItems: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}
