import React from 'react'
import PropTypes from 'prop-types'
import { imagesCDN } from 'src/core/constants/imagesCDN'

export const Item = ({items, baseHref, baseAs, hasGetParamsInBaseHref, needScroll}) =>
  <div className='kategorije-pretraga-mega-menu-content-item'>
    {
      items.map((item) => [
        <div className='pretraga-item' key={item.id}>
          <a href={`${baseAs}${item.seo}`}>
            {item.iconSvg ? <img src={`${imagesCDN}${item.iconSvg}`} /> : <i className={item.icon} />}
            <p>{item.title}</p>
          </a>
        </div>,
        '\n'
      ])
    }
  </div>

Item.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    seo: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    icon: PropTypes.string,
    iconSvg: PropTypes.string,
    title: PropTypes.string.isRequired
  })).isRequired,
  baseHref: PropTypes.string.isRequired,
  baseAs: PropTypes.string.isRequired,
  hasGetParamsInBaseHref: PropTypes.bool.isRequired,
  needScroll: PropTypes.bool.isRequired
}

Item.defaultProps = {
  items: [],
  baseHref: '/category',
  baseAs: '/',
  hasGetParamsInBaseHref: false,
  needScroll: true
}
