import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const sharedReducer = (state = initialState.ads.shared, action) => {
  switch (action.type) {
    case actionTypes.SET_AD_PHOTOS:
      return {...state, photos: action.payload}
    case actionTypes.SET_AD_PHOTOS_UPLOADING:
      return {...state, photosUploading: action.payload}
    case actionTypes.SAVE_AD_PHOTO:
      const photos = [...state.photos]
      photos.push(action.payload)
      return {...state, photos}
    case actionTypes.DELETE_AD_PHOTO:
      const filteredPhotos = state.photos.filter(photo => photo.tmpId !== action.payload)
      return {...state, photos: filteredPhotos}
    case actionTypes.UPDATE_AD_PHOTO_BY_ID:
      if (state.photos.filter(photo => photo.id === action.payload.id).length) {
        const newPhotos = state.photos.map(item => item.id === action.payload.id ? action.payload.photo : item)
        return {...state, photos: newPhotos}
      }
      return state
    case actionTypes.UPDATE_AD_PHOTO_BY_TMP_ID:
      if (state.photos.filter(photo => photo.tmpId === action.payload.tmpId).length) {
        const newPhotos = state.photos.map(item => item.tmpId === action.payload.tmpId ? action.payload.photo : item)
        return {...state, photos: newPhotos}
      }
      return state
    case actionTypes.RESET_AD_PHOTOS:
      return {...state, resetPhotos: action.payload}
    default:
      return state
  }
}
