import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import config from 'SRC/config/config.json'

export const Banner = ({ slotId }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

	const slot = config.banners.adsList && config.banners.adsList.betweenAdsMobile
  const slotTablet = config.banners.adsList && config.banners.adsList.betweenAdsTablet

	useEffect(() => {
		if (windowWidth === 0) return

		const googletag = window.googletag || {}

		googletag.cmd = googletag.cmd || []

		googletag.cmd.push(() => {
			const adUnitCode = windowWidth <= 727 ? slot.adUnit : slotTablet.adUnit
			const sizes = windowWidth <= 727 ? slot.sizes : slotTablet.sizes

			const definedSlot = googletag.defineSlot(`/${config.banners.dfpNetworkId}/${adUnitCode}`, sizes, slotId).addService(googletag.pubads())

			googletag.pubads().disableInitialLoad()
			googletag.pubads().enableSingleRequest()
			googletag.pubads().collapseEmptyDivs()

			googletag.enableServices()

			googletag.display(slotId)

			window.stpd = window.stpd || { que: [] }
			stpd.que.push((function () {
				stpd.initialize(slotId)
			}))
		})
	}, [windowWidth])

	return (
		<div className='oglasi-item-tekst oglasi-item-tekst-banner'>
			<div id={slotId} style={{ width: windowWidth <= 727 ? 336 : 728, margin: 'auto' }} />
		</div>
	)
}

Banner.propTypes = {
	slotId: PropTypes.number.isRequired
}
