import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import Router from 'next/router'
import { useOnScreen } from 'SRC/modules/common/hooks'
import {
  getFavouritePublishedAds,
  getFavouriteExpiredAds,
  getFavouriteSoldAds
} from 'SRC/modules/users/Profile/selectors/getFavouriteAds'
import { updateFavouriteAds, fetchFavouriteAds } from 'SRC/modules/users/Profile/actions'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'

const cookies = new CookiesHelper()

const SaveAd = ({
  favouritePublishedAds,
  favouriteExpiredAds,
  favouriteSoldAds,
  isAuthorized,
  updateFavouriteAds,
  fetchFavouriteAds,
  adId,
  adStatus
}) => {
  const { t } = useTranslation('common')
  const [fetch, setFetch] = React.useState(true)
  const [isFavourite, setIsFavourite] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const ref = React.useRef()
  const isVisible = useOnScreen(ref)

  const getInitialSavedAds = () => {
    const userId = cookies.get('userId')
    const token = cookies.get('token')

    if (!userId || !token) return

    fetchFavouriteAds(userId, token, 'Published', 1, 100)
  }

  const handleClick = async () => {
    if (!isAuthorized) return Router.push('/profile', '/profil')

    const userId = cookies.get('userId')
    const token = cookies.get('token')

    setIsLoading(true)

    await updateFavouriteAds({
      userId,
      token,
      add: !isFavourite,
      status: adStatus
    })

    setIsLoading(false)
  }

  React.useEffect(() => {
    let favouriteAdsIds = []

    switch (adStatus) {
      case 'Published':
        favouriteAdsIds = favouritePublishedAds.map(ad => ad.id || ad.add)
        break
      case 'Expired':
        favouriteAdsIds = favouriteExpiredAds.map(ad => ad.id || ad.add)
        break
      case 'Sold':
        favouriteAdsIds = favouriteSoldAds.map(ad => ad.id || ad.add)
        break
      default: break
    }

    setIsFavourite(!!favouriteAdsIds.includes(adId))
  }, [adId, favouritePublishedAds.length, favouriteExpiredAds.length, favouriteSoldAds.length])

  React.useEffect(() => {
    if (!isAuthorized) return

    if (isVisible && fetch) {
      setFetch(false)
      getInitialSavedAds()
    }
  }, [isVisible])

  return (
    <div className='sidebar-user-akcije-item' ref={ref}>
      {!isLoading ? <a href='javascript:void (0)' onClick={handleClick}
        className={isFavourite ? 'active' : ''}>
        <i className='ico-user-sacuvaj-oglas ico' />
        <p>{isFavourite ? t('Sačuvan oglas') : t('Sačuvaj oglas')}</p>
      </a> : <div style={{ width: 52, height: 52, margin: 'auto' }} className='preloader__icon' />}
    </div>
  )
}

SaveAd.propTypes = {
  adId: PropTypes.number.isRequired,
  adStatus: PropTypes.string,
  favouritePublishedAds: PropTypes.array,
  favouriteExpiredAds: PropTypes.array,
  favouriteSoldAds: PropTypes.array,
  isAuthorized: PropTypes.bool.isRequired,
  fetchFavouriteAds: PropTypes.func.isRequired,
  updateFavouriteAds: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    favouritePublishedAds: getFavouritePublishedAds(state),
    favouriteExpiredAds: getFavouriteExpiredAds(state),
    favouriteSoldAds: getFavouriteSoldAds(state),
    isAuthorized: getIsAuthorized(state)
  }
}

export default connect(mapStateToProps, { updateFavouriteAds, fetchFavouriteAds })(SaveAd)
