import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from '../SingleSelect/Container'
import { MultiSelect } from '../MultiSelect/Container'

export const Container = ({ isMultiple, ...restProps }) => {
  return isMultiple ? <MultiSelect {...restProps} /> : <SingleSelect {...restProps} />
}

Container.displayName = 'SelectDropdownContainer'

Container.propTypes = {
  isMultiple: PropTypes.bool
}
