import { BaseApi } from 'Core/api/BaseApi'
import { getCurrentLocale } from 'SRC/modules/common/functions'

export class Api extends BaseApi {
  getForm = async (seo, locale = getCurrentLocale()) => {
    const defaultResult = null
    try {
      const { body } = await Api.post('/form/getFormBySeo', { seo, locale })
      return body.category ? body.category : defaultResult
    } catch (e) {
      return defaultResult
    }
  }

  submitAddForm = async (fields, locale = getCurrentLocale()) => {
    try {
      const { body } = await Api.post(`/form/add`, { ...fields, locale }, false)
      const error = body.error
      if (error) {
        return {
          status: Api.responseTypes.ERROR,
          error
        }
      } else {
        return {
          status: Api.responseTypes.OK,
          data: body
        }
      }
    } catch (e) {
      const result = {
        status: Api.responseTypes.ERROR
      }
      return result
    }
  }

  submitUpdateForm = async (fields, locale = getCurrentLocale()) => {
    try {
      const { body } = await Api.post(`/form/update`, { ...fields, locale }, false)
      const error = body.error
      if (error) {
        return {
          status: Api.responseTypes.ERROR,
          error
        }
      } else {
        return {
          status: Api.responseTypes.OK,
          data: body
        }
      }
    } catch (e) {
      const result = {
        status: Api.responseTypes.ERROR
      }
      return result
    }
  }

  checkSmsCode = async (phone, code, locale = getCurrentLocale()) => {
    const defaultResult = { status: Api.responseTypes.ERROR }
    try {
      const fields = { phone, code, locale }
      const { body } = await Api.post('/auth/justVerifySmsCode', fields)
      return body
    } catch (error) {
      return defaultResult
    }
  }

  getTransfer = async (paymentId, trackId, locale = getCurrentLocale()) => {
    const defaultResult = { status: Api.responseTypes.ERROR }
    try {
      const fields = { paymentId, trackId, locale }
      const { body } = await Api.post('/transfer/get', fields)
      return body
    } catch (error) {
      return defaultResult
    }
  }

  getPageMetaTags = (fields = {}, locale = getCurrentLocale()) => {
    const defaultResult = { status: Api.responseTypes.ERROR }

    let queryParams = ''
    if (fields.category && fields.group) queryParams = `?category=${fields.category}&group=${fields.group}`
    else if (fields.category) queryParams = `?category=${fields.category}`
    else if (fields.group) queryParams = `?group=${fields.group}`
    else queryParams = ``

    return fetch(`https://autodiler.me/pagemetatags/${locale?.toLowerCase()}${queryParams}`)
      .then(response => response.json())
      .then(body => body)
      .catch(error => defaultResult)
  }
}