import { createSelector } from 'reselect'
import { getFlattenCategories } from '../../../../categories/selectors/getFlattenCategories'

export const getFavouritePublishedAds = createSelector(
  state => state && state.user && state.user.profile && state.user.profile.favouriteAds &&
    state.user.profile.favouriteAds.Published.elements.length
    ? state.user.profile.favouriteAds.Published.elements : [], getFlattenCategories,
  (favouriteAds, categories) => favouriteAds.map(ad => ({
    ...ad,
    isPaid: ad.listingStatus === 'Active'
  }))
)
