import { ProfileApi } from '../../api'
import { setDiscountCardCode } from '../setDiscountCardCode'

export const generateDiscountCardCode = (userId, token) => {
  return async (dispatch) => {
    const api = new ProfileApi()
    const code = await api.getDiscountCardCode(userId, token)
    if (code) {
      dispatch(setDiscountCardCode(code))
    }
  }
}
