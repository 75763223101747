import React from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import ReactSVG from 'react-svg'
import config from 'SRC/config/config.json'

export const SocialLinks = () => {
  const { t } = useTranslation('common')

  return (
    <div className='footer-social'>
      {config.footer.socialLinks ? config.footer.socialLinks.map((item, index) => (
        <a
          key={`social_link_${index + 1}`}
          href={item.link.url}
          target={item.link.targetBlank ? '_blank' : '_self'}
          rel='noreferrer'
          aria-label={item.ariaLabel}
        >
          {item.className
            ? <i className={item.className} />
            : item.svgIcon
              ? (
                <ReactSVG
                  src={item.svgIcon}
                  beforeInjection={svg => {
                    svg.setAttribute('style', 'width: 37px; height: 100%; margin-right: 5px;')
                  }}
                  wrapper='span'
                />
              )
              : null
          }
        </a>
      )) : null}

      <Link href={'/dodaj'}>
        <a className='dodaj-oglas-btn dodaj-oglas-btn__mobile'>
          <i className='ico-footer-dodaj-oglas ico' />
          {t('footer_addAd_btnText')}
        </a>
      </Link>
    </div>
  )
}
