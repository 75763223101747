import { getSortedSpecifications } from '../getSortedSpecifications'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { createSelector } from 'reselect'

export const getSpecsIdForMainProps = createSelector(
  getSortedSpecifications,
  getIsMobileDevice,
  (specs, isMobileDevice) => {
    let iconsCount = !isMobileDevice ? 5 : 6
    const specsForMainProps = specs.length > 5 ? specs.slice(0, iconsCount) : specs

    return specsForMainProps.map(item => {
      if (item.specification && item.specification.id) {
        return item.specification.id
      } else {
        return null
      }
    }).filter(item => Boolean(item))
  }
)
