import React from 'react'
import { genders } from 'SRC/core/constants'
import { Radio } from 'SRC/ui/FormElementsNew'
import config from 'SRC/config/config.json'
import useTranslation from 'next-translate/useTranslation'

export const Gender = () => {
  const { t } = useTranslation('common')

  return (
    <div className='form-pol' key='field'>
      <label>
        {t('Pol')}
        <span className='required'>*</span>
        <span className='lead-label'>{t('gender_lead')}</span>
      </label>
      <Radio
        options={[
          {
            value: genders.male.code,
            className: genders.male.className,
            label: t('gender_male')
          },
          {
            value: genders.female.code,
            className: genders.female.className,
            label: t('gender_female')
          }
        ]}
        name='gender'
      />
    </div>
  )
}
