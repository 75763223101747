import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Header, SocServices } from '../../../shared/components'
import { UserfulInfoItem } from '../UsefulInfo'
import StpdBannerComponent from 'stpd-ad-component'
import config from 'SRC/config/config.json'

const usefulInfoItems = config.sidebar.addAd.usefulInfoItems.map((item, index) => ({
  id: index + 1,
  title: item.title,
  content: item.content,
  contentBold: item.contentBold,
  icon: item.icon,
  url: item.url
}))

export const Container = () => {
  const { t } = useTranslation('common')

  return (
    <aside className='sidebar'>
      <div className='sidebar-korisne-informacije'>
        <Header title={t('Korisne informacije')} />
        <div className='sidebar-content'>
          {usefulInfoItems.map(item => (<UserfulInfoItem key={item.id} item={item} />))}
        </div>
      </div>

      <div style={{ width: 300, height: 250 }}>
        <StpdBannerComponent
          refreshOnUrlChange={true}
          adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.sidebar.addAd.adUnit}`}
          size={config.banners.sidebar.addAd.sizes}
          divId={config.banners.sidebar.addAd.slotId}
        />
      </div>

      <SocServices />
    </aside>
  )
}
