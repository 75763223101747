import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { imagesCDN } from 'src/core/constants/imagesCDN'

export const SubCategory = ({seo, ico, iconSvg, text, baseHref, baseAs}) => (
  <div className='pretraga-item'>
    <Link href={`${baseHref}?category=${seo}`} as={`${baseAs}${seo}`}>
      <a>
        {iconSvg ? <img src={`${imagesCDN}${iconSvg}`} /> : <i className={ico} />}
        <p>{text}</p>
      </a>
    </Link>
  </div>
)

SubCategory.propTypes = {
  seo: PropTypes.string.isRequired,
  ico: PropTypes.string,
  iconSvg: PropTypes.string,
  text: PropTypes.string.isRequired,
  baseHref: PropTypes.string.isRequired,
  baseAs: PropTypes.string.isRequired
}

SubCategory.defaultProps = {
  ico: 'ico',
  baseHref: '/category',
  baseAs: '/'
}
