import { setFavouriteAds } from '../../actions/setFavouriteAds'
import { setFavouriteAdsLoading } from '../../actions/setFavouriteAdsLoading'
import { setFavouriteAdsFetchedInitially } from '../../actions/setFavouriteAdsFetchedInitially'
import { ProfileApi } from '../../api'

export const fetchFavouriteAds = (userId, token, status, page, limit) => {
  return async (dispatch) => {
    dispatch(setFavouriteAdsLoading(status, true))
    const api = new ProfileApi()

    const {favouriteAds} = await api.getFavouriteAds(userId, token, status, page, limit)

    dispatch(setFavouriteAds({ ads: favouriteAds, status }))
    dispatch(setFavouriteAdsLoading(status, false))
    dispatch(setFavouriteAdsFetchedInitially(true))
  }
}
