import React from 'react'
import config from 'SRC/config/config.json'
import useTranslation from 'next-translate/useTranslation'
import { Menu, Weblab, ProjectsList, AddAdBlock, Copyright, AppsMobile } from '../index'

export const Footer = () => {
  const { t } = useTranslation('common')

  return (
    <React.Fragment>
      <footer>
        <div className='footer-main clearfix'>
          <Menu />
          <div className='footer-prodaja'>
            <AddAdBlock />
            <ProjectsList />
            <Weblab />
          </div>

          <AppsMobile />
        </div>

        <p className='footer-text'>{t('footer_bottom')}</p>
        <Copyright />
      </footer>
    </React.Fragment>
  )
}

