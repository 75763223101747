import PropTypes from 'prop-types'
import React from 'react'
import { useFormikContext } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { adFiltersLabels } from 'src/core/constants'
import { AdFilterField, SelectDropdown } from 'SRC/ui/FormElementsNew'

const Condition = () => {
  const formik = useFormikContext()
  const { t } = useTranslation('common')

  const getConditionField = () => {
    return {
      id: 'condition',
      title: t('Stanje'),
      options: [
        {
          id: 'condition-new',
          label: t('Novo'),
          value: 'new'
        },
        {
          id: 'condition-used',
          label: t('Polovno'),
          value: 'used'
        }
      ],
      onChange: onConditionChange,
      value: formik.values.condition
    }
  }

  const onConditionChange = value => {
    if (Number(value) === -1) formik.setFieldValue('condition', -1)
    else formik.setFieldValue('condition', value)
  }

  return (
    <AdFilterField>
      <SelectDropdown {...getConditionField()} />
    </AdFilterField>
  )
}

Condition.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

export default Condition
