import PropTypes from 'prop-types'
import React from 'react'

export const AddPageContent = ({children, title, description, descriptionClass}) => (
  <div className='dodaj-oglas-content'>
    <p className='heading'>{title}</p>
    {description ? <p className={descriptionClass}>{description}</p> : null}
    {children}
  </div>
)

AddPageContent.propTypes = {
  children: PropTypes.any,
  description: PropTypes.string,
  descriptionClass: PropTypes.string,
  title: PropTypes.string
}
