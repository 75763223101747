import { combineReducers } from 'redux'
import { commonReducer } from 'SRC/modules/common/reducer'
import { userReducer } from 'SRC/modules/users/reducer'
import { adsReducer } from 'SRC/modules/ads/reducer'
import { geoObjectsReducer } from 'SRC/modules/geoObjects/reducer'
import { categoriesReducer } from 'SRC/modules/categories/reducer'
import { garageReducer } from 'SRC/modules/garage/reducer'
import { discountCardReducer } from 'SRC/modules/discountCard/reducer'
import { groupsReducer } from 'SRC/modules/groups/reducer'
import { reducer as formReducer } from 'redux-form'
import actionTypes from './actionTypes'

const appReducer = combineReducers({
  common: commonReducer,
  user: userReducer,
  form: formReducer,
  ads: adsReducer,
  geoObjects: geoObjectsReducer,
  categories: categoriesReducer,
  groups: groupsReducer,
  garage: garageReducer,
  discountCard: discountCardReducer
})

const rootReducer = (state, action) => {
  if (action.type === actionTypes.SET_APP_STATE) {
    return action.payload
  }

  return appReducer(state, action)
}

export default rootReducer
