import React from 'react'
import PropTypes from 'prop-types'

export const Title = ({ title, needColon, isRequired }) => {
  if (!title) return null

  return <label>{`${title}${needColon ? ':' : ''}`} {isRequired ? <span className='required'>*</span> : null}</label>
}

Title.propTypes = {
  isRequired: PropTypes.bool.isRequired,
  needColon: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
}
