import { globalOptions } from 'Core/constants'
import { TypesHelper, flatten } from 'SRC/utils'
import { CategoriesApi } from 'SRC/modules/categories/api'
import { setCurrentSubCategory, setGroupCategories } from 'SRC/modules/categories/actions'
import { setCurrentGroup } from 'SRC/modules/groups/actions'
import { getCurrentLocale } from 'SRC/modules/common/functions'

export const setCategoriesAndGroups = (categoryInUrl, locale = getCurrentLocale()) => {
  return async (dispatch, getState) => {
    const api = new CategoriesApi()

    const result = await api.fetchCategoryV2(categoryInUrl, locale)

    if (!result) {
      dispatch(setCurrentSubCategory(null))
      dispatch(setGroupCategories([]))
      dispatch(setCurrentGroup(null))
      return
    }

    dispatch(setGroupCategories(result.categories))
    dispatch(setCurrentSubCategory(result.category))
    dispatch(setCurrentGroup(result.group))
  }
}

