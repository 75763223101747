import PropTypes from 'prop-types'
import React from 'react'
import { MenuItem } from '../MenuItem'

export const Menu = ({items}) => (
  <div className='sidebar-user-ostalo sidebar-user-profile-items'>
    {
      items.map(item => {
        return item.id ? <MenuItem {...item} key={item.id} /> : null
      })
    }
  </div>
)

Menu.propTypes = {
  items: PropTypes.array.isRequired
}

Menu.defaultValues = {
  items: []
}
