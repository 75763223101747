import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { Wrapper } from '../Wrapper'

export class Radio extends React.PureComponent {
  renderRadio = ({
    field,
    form: { touched, errors },
    disabled,
    wrapperClassName,
    label,
    value,
    className,
    onFocus,
    onMouseEnter
  }) => {
    return <Wrapper isError={touched[field.name] && errors[field.name]} className={wrapperClassName}>
      <label>
        <input
          name={field.name}
          type='radio'
          value={value}
          className={className}
          disabled={disabled}
          checked={field.value === value}
          onChange={field.onChange}
          onFocus={onFocus}
          onMouseEnter={onMouseEnter}
          onBlur={field.onBlur}
        />
        <p>{label}</p>
      </label>
    </Wrapper>
  }

  render () {
    const { name, wrapperClassName, options, onMouseEnter, onFocus } = this.props

    return options.map((item, index) =>
      <Field
        {...item}
        key={item.value}
        id={`${name}_${index}`}
        name={name}
        component={this.renderRadio}
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
        wrapperClassName={wrapperClassName}
      />
    )
  }
}

Radio.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    className: PropTypes.string
  })),
  name: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func,
  onFocus: PropTypes.func,
  wrapperClassName: PropTypes.string
}
