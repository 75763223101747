import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import { AdFilterField, SelectDropdown } from 'SRC/ui/FormElementsNew'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors/getCurrentSubCategory'
import { getSortOptions } from '../../functions'

const Sort = ({ form, categorySort, field }) => {
  const { t } = useTranslation('common')

  const onSortChange = value => {
    form.setFieldValue('sortBy', value)
  }

  const defaultSort = [
    {
      id: 'dateDesc',
      value: 'dateDesc',
      label: `${t('OGLASI')} - ${t('Najnoviji')}`
    },
    {
      id: 'dateAsc',
      value: 'dateAsc',
      label: `${t('OGLASI')} - ${t('Najstariji')}`
    },
    {
      id: 'priceAsc',
      value: 'priceAsc',
      label: `${t('Cijena')} - ${t('Najmanja')}`
    },
    {
      id: 'priceDesc',
      value: 'priceDesc',
      label: `${t('Cijena')} - ${t('Najveća')}`
    }
  ]

  const options = [...defaultSort, ...getSortOptions(categorySort)]

  return (
    <AdFilterField>
      <SelectDropdown
        isOptional={false}
        id='sortBy'
        title={t('Sortiraj')}
        options={options}
        onChange={onSortChange}
        value={field.value}
      />
    </AdFilterField>
  )
}

const mapStateToProps = (state) => {
  const currentSubcategory = getCurrentSubCategory(state)

  return {
    categorySort: Array.isArray(currentSubcategory?.sortings) ? currentSubcategory.sortings : []
  }
}

Sort.propTypes = {
  categorySort: PropTypes.array.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func
  }).isRequired
}

Sort.defaultProps = {
  sort: []
}

export default connect(mapStateToProps)(Sort)
