import React from 'react'
import Link from 'next/link'
import { getAdThumbnailUrl } from 'SRC/modules/ads/shared/functions'

import {
  ItemImage,
  ItemGridViewDescription,
  // ItemGridViewDescriptionWithIcons,
  ItemPrice,
  ItemLocation
} from '../../../components/Item'
import PropTypes from 'prop-types'
import { AdditionalPreloader } from 'SRC/ui/Preloader'

const getAdSpecsSorted = specs => {
  return specs.slice().sort((spec1, spec2) => spec1.weightCatalog - spec2.weightCatalog).slice(0, 3)
}

export const Item = ({ ad, isLoading }) => {
  if (!ad.category) return null

  const img = getAdThumbnailUrl(ad)

  const priceWithDiscountCard = ad.discount ? (ad.price - ad.price * (ad.discount / 100)).toFixed(2) : null

  const adCategory = ad.categorySeo || ad.category

  return (
    <div className={`oglasi-item-tekst oglasi-item-tekst-${adCategory}`}>
      <ItemImage
        isLoading={isLoading}
        category={adCategory}
        seo={ad.seo}
        paid={ad.isPaid}
        className='oglasi-item-tekst-img'
        img={img}
        title={ad.titleCompiled}
      />

      {!isLoading ? (
        <Link href={`/ad?category=${adCategory}&ad=${ad.seo}`} as={`/${adCategory}/${ad.seo}`}>
          <a className='oglasi-item-heading'>
            <h3>{ad.titleCompiled || ''}</h3>
          </a>
        </Link>
      ) : (
        <div>
          <a className='oglasi-item-heading' style={{ cursor: 'not-allowed' }}>
            <h3>{ad.titleCompiled || ''}</h3>
          </a>
        </div>
      )}

      <ItemGridViewDescription
        specs={ad.specifications ? getAdSpecsSorted(ad.specifications) : []}
        compatibilities={Array.isArray(ad.compatibilities) ? ad.compatibilities : []}
        compatibilityBrands={Array.isArray(ad.compatibilityBrands) ? ad.compatibilityBrands : []}
        compatibilitiesCount={ad.compatibilitiesCount || 0}
        compatibilityBrandsCount={ad.compatibilityBrandsCount || 0}
        service={ad.service || null}
        serviceType={ad.serviceType || null}
      />
      {'\n'}
      <ItemPrice
        newPrice={ad.price}
        oldPrice={ad.oldPrice}
        priceFrom={ad.priceFrom}
        priceTo={ad.priceTo}
        promotion={ad.akcija}
        priceWithDiscountCard={priceWithDiscountCard}
        priceOnRequest={ad.priceOnRequest}
        typeOfPrice={ad.typeOfPrice}
        type='tekst'
      />
      <ItemLocation place={ad.city && ad.city.name} time={ad.activated} />
    </div>
  )
}

Item.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    titleCompiled: PropTypes.string.isRequired,
    isPaid: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    oldPrice: PropTypes.number,
    discount: PropTypes.number,
    priceFrom: PropTypes.number,
    priceTo: PropTypes.number,
    seo: PropTypes.string.isRequired,
    media: PropTypes.array,
    category: PropTypes.number.isRequired,
    categorySeo: PropTypes.string,
    specifications: PropTypes.array,
    priceOnRequest: PropTypes.bool,
    akcija: PropTypes.number,
    pro: PropTypes.bool,
    brand: PropTypes.object,
    product: PropTypes.object,
    city: PropTypes.object,
    activated: PropTypes.string,
    compatibilities: PropTypes.array,
    compatibilityBrands: PropTypes.array,
    compatibilitiesCount: PropTypes.number,
    compatibilityBrandsCount: PropTypes.number,
    status: PropTypes.string.isRequired,
    service: PropTypes.object,
    serviceType: PropTypes.object,
    typeOfPrice: PropTypes.object
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
}
