import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { PageHeader } from '../PageHeader'
import { Page } from '../Page'
import Link from 'next/link'

export const Page404 = () => {
  const { t } = useTranslation('common')

  return (
    <Page>
      <PageHeader>{t('404page_title')}</PageHeader>
      <div className='page__not-found'>
        {t('404page_title')}
        <p>{t('404page_text')}</p>
        <Link href='/'>
          <a>{t('KLIKNI OVDJE')}</a>
        </Link>
      </div>
    </Page>
  )
}
