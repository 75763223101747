import { setFavouriteAds } from '../../actions/setFavouriteAds'
import { ProfileApi } from '../../api'

export const updateFavouriteAds = (options = {}) => {
  const { userId, token, add, status } = options

  return async (dispatch, getState) => {
    const state = getState()
  
    const currentAd = state && state.ads && state.ads.detail && state.ads.detail.currentAd
    const currentFavouriteAds = state && state.user && state.user.profile && state.user.profile.favouriteAds
      && state.user.profile.favouriteAds[status] && Array.isArray(state.user.profile.favouriteAds[status].elements)
      ? state.user.profile.favouriteAds[status].elements : []
  
    if (!currentAd || !currentFavouriteAds) return

    const api = new ProfileApi()

    const result = await api.updateFavouriteAds(userId, token, currentAd.id, add)

    // SET ADS
    let favouriteAds = []

    // REMOVE AD
    if (!add) favouriteAds = currentFavouriteAds.filter(ad => ad.id !== currentAd.id)

    // ADD AN AD
    if (add) {
      favouriteAds = [...currentFavouriteAds]
      const ids = favouriteAds.map(ad => ad.id)
      if (!ids.includes(currentAd.id)) favouriteAds.push(currentAd)
    }
  
    console.log('savead result', result)
    if (result) {
      dispatch(setFavouriteAds({ ads: favouriteAds, status }))
      return result
    }
  }
}
