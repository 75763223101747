import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { CookiesHelper } from 'Core/cookiesHelper/CookiesHelper'
import { ErrorNotification } from 'SRC/ui'
import { connect } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import Router from 'next/router'
import { PageHeader } from 'SRC/ui/PageHeader'
import { PageContent } from 'SRC/ui/PageContent'
import { ContentHeader } from 'SRC/ui/ContentHeader'
import { RegistrationApi } from 'SRC/modules/users/Registration/api'
import { getFormErrors as getLoginFormErrors } from 'SRC/modules/users/Auth/selectors'
import { login } from '../../../actions'

import { Phone } from '../Phone'
import { SmsCode } from '../SmsCode'
import { Password } from '../Password'
import { UpdateForm } from '../UpdateForm'

const statusCodes = {
  WRONG_PHONE_OR_CODE: -1,
  CREATED_NEW_USER: 0,
  FOUND_INCOMPLETE_USER: 1,
  FOUND_FULLY_REGISTERED_USER: 2
}

const Container = ({ login, loginErrors }) => {
  const [smsWasSent, setSmsWasSent] = React.useState(false)
  const [profileStatus, setProfileStatus] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [phone, setPhone] = React.useState('')
  const [country, setCountry] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loginType, setLoginType] = React.useState('smsCode')
  const [businessUserName, setBusinessUserName] = React.useState('')
  const [userInfo, setUserInfo] = React.useState(null)
  const [showQuestion, setShowQuestion] = React.useState(false)

  const { t } = useTranslation('common')

  useEffect(() => {
    if (loginErrors.login) setErrorMessage(loginErrors.login)
  }, [loginErrors?.login])

  const sendSmsCode = async (values, actions, recaptchaCheck = true) => {
    setIsLoading(true)
    setShowQuestion(false)

    const phone = `${values.dial}${values.phone.replace(/ /g, '')}`

    const api = new RegistrationApi()
    const result = await api.sendSmsCodeWithRecaptcha(phone, false, recaptchaCheck)

    console.log('result', result)

    if (result.status === RegistrationApi.responseTypes.OK) {
      if (typeof result.score !== 'undefined' && result.score <= 0.6) {
        setSmsWasSent(false)
        setShowQuestion(true)
      } else if (typeof result.statusCode !== 'undefined' && Number(result.statusCode) === RegistrationApi.statusCodes.TOO_SHORT_PERIOD) {
        setSmsWasSent(false)
        setPhone('')
        setCountry(null)
        setLoginType('smsCode')
        setBusinessUserName('')
      } else if (typeof result.statusCode !== 'undefined' && Number(result.statusCode) === RegistrationApi.statusCodes.FORCE_SENT) {
        setSmsWasSent(true)
        setPhone(phone)
        setCountry(values.country)
        if (result.isBusinessUser) setLoginType('password')
        if (result.name) {
          setBusinessUserName(`${result.name}${result.username ? ` (${result.username})` : ''}`)
        }
      } else {
        setSmsWasSent(true)
        setPhone(phone)
        setCountry(values.country)
        if (result.isBusinessUser) setLoginType('password')
        if (result.name) {
          setBusinessUserName(`${result.name}${result.username ? ` (${result.username})` : ''}`)
        }
      }
    } else {
      if (result.error) actions.setFieldError('phone', result.error)
      setSmsWasSent(false)
      setPhone('')
      setCountry(null)
      setLoginType('smsCode')
      setBusinessUserName('')
    }

    setIsLoading(false)
  }

  const verifySmsCode = async (smsCode) => {
    setErrorMessage('')
    setIsLoading(true)

    const api = new RegistrationApi()
    const result = await api.verifySmsCode(phone, smsCode, country)

    if (result.error) {
      setProfileStatus(null)
      if (typeof result.error === 'string') setErrorMessage(result.error)
    } else {
      const userInfo = result.userInfo

      if (typeof result.status !== 'undefined') {
        if (result.status === statusCodes.FOUND_FULLY_REGISTERED_USER && userInfo?.id && userInfo?.token) {
          const cookie = new CookiesHelper()

          cookie.set('userId', userInfo.id, { maxAge: 315360000 })
          cookie.set('token', userInfo.token, { maxAge: 315360000 })

          return Router.push('/profile', '/profil')
        } else if (result.status === statusCodes.CREATED_NEW_USER || result.status === statusCodes.FOUND_INCOMPLETE_USER) {
          setProfileStatus(result.status)
          setUserInfo(userInfo)
        } else if (result.status === statusCodes.WRONG_PHONE_OR_CODE) {
          setProfileStatus(null)
          setErrorMessage('Pogrešan kod ili telefon')
        } else {
          setProfileStatus(null)
          setErrorMessage('Došlo je do greške')
        }
      } else {
        setProfileStatus(null)
        setErrorMessage('Došlo je do greške')
      }
    }
    setIsLoading(false)
  }

  const loginWithPassword = async (password) => {
    await login({ phone, password })
  }

  const showUpdateForm = smsWasSent && phone && country && userInfo &&
    ([statusCodes.CREATED_NEW_USER, statusCodes.FOUND_INCOMPLETE_USER].includes(profileStatus))

  return (
    <React.Fragment>
      <PageHeader key='header'>{t('login_header')}</PageHeader>
      <PageContent key='content'>
        <p className='heading'>{t('login_heading')}</p>

        {!smsWasSent ? (
          <div className='login-form'>
            <Phone key='phone' isLoading={isLoading} onSubmit={sendSmsCode} showQuestion={showQuestion} />
          </div>
        ) : null}

        {smsWasSent && phone && country && profileStatus === null ? (
          loginType === 'password' ? (
            <Password name={businessUserName} isLoading={isLoading} onSubmit={loginWithPassword} />
          ) : (
            <SmsCode
              isLoading={isLoading}
              setErrorMessage={setErrorMessage}
              onSubmit={verifySmsCode}
              phone={phone}
              wrongNumberClicked={() => { setSmsWasSent(false) }}
            />
          )
        ) : null}

        {showUpdateForm ? <UpdateForm key='user-update-form' userInfo={userInfo} phoneNumber={phone} /> : null}

        <ErrorNotification error={errorMessage} hideNotification={() => { setErrorMessage('') }} />
      </PageContent>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  loginErrors: getLoginFormErrors(state)
})

Container.propTypes = {
  login: PropTypes.func.isRequired,
  loginErrors: PropTypes.shape({
    login: PropTypes.string,
    loginInPopup: PropTypes.string
  }).isRequired
}

export default connect(mapStateToProps, { login })(Container)
