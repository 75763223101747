export const adsStatues = {
  PUBLISHED: {
    title: 'OBJAVLJENI OGLASI',
    code: 'Published'
  },
  UNPUBLISHED: {
    title: 'OGLASI NA ČEKANJU',
    code: 'Unpublished'
  },
  SOLD: {
    title: 'PRODATI OGLASI',
    code: 'Sold'
  },
  REJECTED: {
    title: 'ODBIJENI OGLASI',
    code: 'Rejected'
  },
  EXPIRED: {
    title: 'ISTEKLI OGLASI',
    code: 'Expired'
  },
  PAUSED: {
    title: 'PAUZIRANI OGLASI',
    code: 'Paused'
  }
}

adsStatues[Symbol.iterator] = function * () {
  yield adsStatues.PUBLISHED
  yield adsStatues.UNPUBLISHED
  yield adsStatues.SOLD
  yield adsStatues.REJECTED
  yield adsStatues.EXPIRED
  yield adsStatues.PAUSED
}
