import { ProfileApi } from '../../../users/Profile/api'
import { setUserTypes } from '../setUserTypes'

export const fetchUserTypes = ({ locale }) => {
  return async (dispatch, getState) => {
    let userTypes = getState().common.userTypes

    if (!userTypes || !userTypes.length || (typeof locale === 'string' && locale !== 'me')) {
      const api = new ProfileApi()
      const userTypesResponse = await api.getUserTypes(locale)
      if (userTypesResponse.userTypes) userTypes = userTypesResponse.userTypes
    }

    dispatch(setUserTypes(userTypes))
  }
}
