import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import StpdBannerComponent from 'stpd-ad-component'
import { globalOptions } from 'SRC/core/constants'
import { FloatingBanner } from 'SRC/modules/common/components/FloatingBanner'
import { SocServices } from 'SRC/modules/layout/Sidebar/shared/components'
import { BusinessUsers } from '../BusinessUsers'
import { fetchUsers } from 'SRC/modules/users/listSidebar/actions'
import { getUserType } from 'SRC/modules/users/list/selectors'
import useTranslation from 'next-translate/useTranslation'
import config from 'SRC/config/config.json'

const userTypesSmsAdsConfig = config.sidebar.userTypesSmsAds

const Container = ({ userType, fetchUsers }) => {
  const { t } = useTranslation('common')

  const [usersLoaded, setUsersLoaded] = React.useState(false)

  React.useEffect(() => { if (!usersLoaded) getSidebarUsers() }, [usersLoaded])

  const getSidebarUsers = async () => {
    const fields = { page: 1, limit: 50, types: [2, 3, 4, 5, 6] }

    await fetchUsers(fields)
    setUsersLoaded(true)
  }

  // useDfpSlot({
  //   id: config.banners.sidebar.adsList[0]?.slotId || null,
  //   sizes: config.banners.sidebar.adsList[0]?.sizes || [],
  //   path: config.banners.sidebar.adsList[0]
  //     ? `/${config.banners.dfpNetworkId}/${config.banners.sidebar.adsList[0].adUnit}`
  //     : null
  // })

  // useDfpSlot({
  //   id: config.banners.sidebar.adsList[1]?.slotId || null,
  //   sizes: config.banners.sidebar.adsList[1]?.sizes || [],
  //   path: config.banners.sidebar.adsList[1]
  //     ? `/${config.banners.dfpNetworkId}/${config.banners.sidebar.adsList[1].adUnit}`
  //     : null
  // })

  const items = userType && userTypesSmsAdsConfig.display[userType.seo]
    ? userTypesSmsAdsConfig.display[userType.seo]
    : userTypesSmsAdsConfig.display.default

  return (
    <aside className='sidebar'>
      {items.map(item => {
        if (item.hasOwnProperty('banner')) {
          if (item.banner === 'floating') return <FloatingBanner />

          const banner = config.banners.sidebar.adsList[item.banner]

          if (banner) return (
            <div style={{ width: 300, height: 250 }}>
              <StpdBannerComponent
                refreshOnUrlChange={true}
                adUnitPath={`/${config.banners.dfpNetworkId}/${banner.adUnit}`}
                size={banner.sizes}
                divId={banner.slotId}
              />
            </div>
          )

          return null
        }

        let title = ''
        let info = ''
        if (item.smsAds) {
          title = t('sidebar-sms-title')
        } else {
          if (item.key) title = t(`sidebar-${item.key}-title`)
          if (item.key && item.info) info = t(`sidebar-${item.key}-info`)
        }

        if (item.userTypes) return <BusinessUsers key={item.key} listId={item.key} {...item} title={title} info={info} />
        if (item.social) return <SocServices />
        return null
      })}
    </aside>
  )
}

const mapStateToProps = state => ({
  userType: getUserType(state)
})

Container.propTypes = {
  userType: PropTypes.shape({
    seo: PropTypes.string
  }).isRequired,
  fetchUsers: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { fetchUsers })(Container)
