module.exports = {
  pages: {
    "*": ({ req, query }) => {
      return ['common']
    }
  },
  locales: ["me", "en", "ru", "ua", "tr", "de", "al"],
  defaultLocale: "me",
  loadLocaleFrom: (lng, ns) => {
    console.log('loadLocaleFrom')
    return new Promise((resolve, reject) => {
      import(`public/locales/${lng}/common.json`)
        .then((m) => {
          return resolve(m.default)
        })
        .catch(err => {
          console.log('loadLocaleFrom err', err)
          const url = typeof window === 'undefined' ? `https://autodiler.me/web-translations?locale=${lng}` : `/web-translations?locale=${lng}`

          fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
            .then(response => {
              return resolve(response?.translations || {})
            })
            .catch(err => {
              console.log('err', err)
              return reject(err)
            })
        })

    })
  },
  logBuild: false
}