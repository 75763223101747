import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const editReducer = (state = initialState.ads.edit, action) => {
  switch (action.type) {
    case actionTypes.SET_EDIT_AD:
      return {...state, ad: action.payload}
    case actionTypes.SET_EDIT_AD_ERROR:
      return {...state, error: action.payload}
    case actionTypes.SET_EDIT_AD_SUCCESS:
      return {...state, success: action.payload}
    case actionTypes.RESET_EDIT_AD_STATE:
      return initialState.ads.edit
    default:
      return state
  }
}
