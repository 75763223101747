import PropTypes from 'prop-types'
import React from 'react'

export const WorkingTimeItem = ({from, to, title}) =>
  <div className='sidebar-user-radno-vreme-item'>
    <p>{title}</p>
    <p>{from && to && (from !== ':' || to !== ':') ? `${from}-${to}` : `Zatvoreno`}</p>
  </div>

WorkingTimeItem.propTypes = {
  from: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string
}
