import React from 'react'
import { Item } from '../Item'
import { languageVersions } from '../data/languageVersions'

export const List = () => (
  <ul className='language'>
    {
      languageVersions.map(version => {
        return [
          <Item key={version.id} {...version} />,
          '\n'
        ]
      })
    }
  </ul>
)
