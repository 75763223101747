import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const usersListSidebarReducer = (state = initialState.user.listSidebar, action) => {
  switch (action.type) {
    case actionTypes.SET_SIDEBAR_USERS_LOADING:
      return {...state, isLoading: action.payload}
    case actionTypes.SET_SIDEBAR_USERS:
      const users = action.payload

      if (Array.isArray(users) && users.length) {
        const usersByType = users.reduce((acc, curr) => {
          acc[curr.type] = curr.users
          return acc
        }, {})
        return { ...state, elements: usersByType }
      }

      return { ...state, elements: {} }
    default:
      return state
  }
}
