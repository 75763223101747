import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { AdditionalPreloader } from 'SRC/ui/Preloader'
import { flattenWithChildren } from 'SRC/utils'
import { setSimilarWords } from 'SRC/modules/categories/actions'
import { getCategories, getSimilarWords } from 'SRC/modules/categories/selectors'
import { extractSimilarWords } from 'SRC/modules/categories/functions'
import { getGarageCategories } from 'SRC/modules/garage/shared/selectors'
import { Search, ItemHeader, ItemBody } from '../'

export class List extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filteredCategories: null
    }
  }

  componentDidMount = async () => {
    const { setSimilarWords } = this.props

    const { flatCategories } = this.props

    if (Array.isArray(flatCategories)) {
      const similarWords = extractSimilarWords(flatCategories)
      setSimilarWords(similarWords)
    }
  }

  onChange = (event) => {
    const { similarWords, flatCategories } = this.props
    const value = event.target.value

    let foundCategoriesBySimilarWords = []
    let foundCategoriesByTitle = []

    if (Array.isArray(similarWords)) {
      foundCategoriesBySimilarWords = similarWords.filter(wordCategoryPair => {
        const word = wordCategoryPair[0]
        return word.toLowerCase().indexOf(value.toLowerCase()) > -1
      }).map(wordCategoryPair => wordCategoryPair[1])
    }

    if (Array.isArray(flatCategories)) {
      foundCategoriesByTitle = flatCategories.filter(category => {
        return category.title.toLowerCase().indexOf(value.toLowerCase()) > -1
      }).map(category => category.id)
    }

    const foundCategories = foundCategoriesBySimilarWords.concat(foundCategoriesByTitle)

    const filteredCategories = value === '' ? null : Array.from(new Set(foundCategories))

    this.setState({
      filteredCategories
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
  }

  render () {
    const { categories } = this.props
    const { filteredCategories } = this.state

    return <React.Fragment>
      <Search
        onChange={this.onChange}
        placeholder={this.props.placeholder}
        onSubmit={this.onSubmit}
        key='search'
      />

      <div className='dodavanje-oglasa-kategorije' key='list'>
        {!Array.isArray(categories) || !categories.length
          ? <AdditionalPreloader/>
          : categories.map(item => {
              let items = []

              if (filteredCategories) {
                items = Array.isArray(item.children)
                  ? item.children.filter(category => filteredCategories.includes(category.id))
                  : []
              } else {
                items = Array.isArray(item.children) ? item.children : []
              }

              return (
                items.length
                  ? [
                    <ItemHeader title={item.title} key={`header-${item.id}`} />,
                    '\n',
                    <ItemBody
                      items={items}
                      key={`body-${item.id}`}
                      baseHref={this.props.baseHref}
                      baseAs={this.props.baseAs}
                    />,
                    '\n'
                  ]
                  : null
              )
            })
          }
      </div>
    </React.Fragment>
      
      
  }
}

const mapStateToProps = (state, ownProps) => {
  const categories = !ownProps.isForGarage ? getCategories(state) : getGarageCategories(state)
  const similarWords = getSimilarWords(state)

  return {
    categories,
    flatCategories: flattenWithChildren(categories),
    similarWords
  }
}

List.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired
    }))
  })).isRequired,
  flatCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  similarWords: PropTypes.arrayOf(PropTypes.array).isRequired,
  placeholder: PropTypes.string.isRequired,
  baseHref: PropTypes.string,
  baseAs: PropTypes.string,
  setSimilarWords: PropTypes.func.isRequired
}

List.defaultProps = {
  categories: [],
  flatCategories: [],
  similarWords: []
}

export default connect(mapStateToProps, { setSimilarWords })(List)
