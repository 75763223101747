import { createSelector } from 'reselect'

export const getElements = createSelector(
  state => state && state.user && state.user.list && Array.isArray(state.user.list.elements) ? state.user.list.elements : [],
  elements => {
    return elements.map(user => {
      if (user.isBanner) return user

      const isPremium = user.activeAddons.find(addon => addon.action.label === 'pro') !== undefined
      return {
        ...user,
        premium: isPremium
      }
    })
  }
)
