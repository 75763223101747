import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getGroupCategories, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'

import { Item } from '../Item'

const List = ({
  group,
  items,
  currentSubGroup,
  className
}) => {
  const listRef = React.useRef(null)

  React.useEffect(() => {
    setTimeout(() => {
      if (listRef && listRef.current) listRef.current.scrollTo(0, 0)
    }, 750)
  }, [group])

  if (!Array.isArray(items) || !items.length) return null

  return (
    <div className={className} ref={listRef}>
      {items.filter(item => !item.hideIcon).map(item => {
        return <Item {...item} current={currentSubGroup} key={item.id} />
      })}
    </div>
  )
}

const mapStateToProps = (state) => {
  const currentGroup = getCurrentGroup(state)

  let items = []
    
  if (currentGroup) {
    const categories = getGroupCategories(state)

    if (Array.isArray(categories)) {
      items = categories.filter(item => item.group === currentGroup.id)

      if (items.length === 1 && items[0].seo === currentGroup.seo) items = []
    }
  }
  
  return {
    currentSubGroup: getCurrentSubCategory(state),
    items
  }
}

List.propTypes = {
  group: PropTypes.shape({ id: PropTypes.number }).isRequired,
  items: PropTypes.array.isRequired,
  currentSubGroup: PropTypes.object,
  className: PropTypes.string
}

List.defaultProps = {
  items: []
}

List.displayName = 'SubGroupsList'

export default connect(mapStateToProps)(List)
