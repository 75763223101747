import PropTypes from 'prop-types'
import React from 'react'

export const Banner = ({link, src, alt}) => (
  <div className='sidebar-banner'>
    <a href={link}>
      <img src={src} alt={alt} />
    </a>
  </div>
)

Banner.propTypes = {
  alt: PropTypes.string,
  link: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
}

Banner.displayName = 'SidebarBanner'

export default Banner
