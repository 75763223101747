/* eslint-disable no-tabs */
import React from 'react'
import config from 'SRC/config/config.json'

const AutodilerCKBNotice = () => (
  <div className='novosti-text'>
    <p>Usljed spajanja CKB i Podgoričke banke ONLINE PLACANĆANJE neće funkcionisati u
      periodu od četvrtka 10.12.2020 u 20h do nedjelje 13.12.2020. u 22h.</p>
    <p>Uplate i dalje možete izvrsiti na šalterima poste ili banke po vašem izboru.</p>
    <p>Za sva dodatna pitanja slobodno nas kontaktirajte:</p>
    
    <p><strong>Mobilni:</strong> 067/312-555 : Ponedjeljak - Subota: od 09:00h do 22:00h</p>
    <p><strong>Mobilni:</strong> 067/313-555 : Nedelja od 09:00h do 22:00h</p>
    {/* <p><strong>Fiksni:</strong> 030/55-00-99</p> */}
    <p><strong>Email:</strong> info@autodiler.me</p>
  </div>
)

export const CKBNotice = () => {
  switch (config.siteName) {
    case 'AutoDiler': return <AutodilerCKBNotice />
    default: return null
  }
}
