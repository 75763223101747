import PropTypes from 'prop-types'
import React from 'react'

export const Wrapper = ({children, isFullWidth, isError, className}) =>
  <div className={`${className} ${isFullWidth ? `form-group-fullWidth` : ``} ${isError ? `form-group_error` : ``}`}>
    {children}
  </div>

Wrapper.propTypes = {
  children: PropTypes.any,
  isFullWidth: PropTypes.bool,
  isError: PropTypes.bool,
  className: PropTypes.string
}

Wrapper.defaultProps = {
  isFullWidth: false,
  isError: false,
  className: 'form-group'
}
