import actionTypes from 'SRC/modules/redux/actionTypes'
import initialState from 'SRC/modules/redux/initialState'

export const serviceReducer = (state = initialState.ads.services, action) => {
  switch (action.type) {
    case actionTypes.SET_SERVICES:
      return {
        ...state,
        elements: action.payload
      }
    default:
      return state
  }
}
