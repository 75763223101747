import { createSelector } from 'reselect'

export const getSimilarAds = createSelector(
	(state) => state && state.ads.detail && state.ads.detail.similarAds
		&& state.ads.detail.similarAds.elements
	? state.ads.detail.similarAds.elements : null,
  (ads) => {
		let withSameSimilarSpecs = ads[0]
		let withSameRequiredAdOptions = ads[1]
		let withSameCategory = ads[2]
		if(!withSameCategory)
			withSameCategory = {paidAds: [], freeAds: []}

		if(!withSameRequiredAdOptions)
			withSameRequiredAdOptions = {paidAds: [], freeAds: []}

		if(!withSameSimilarSpecs)
			withSameSimilarSpecs = {paidAds: [], freeAds: []}

		return [
			...withSameSimilarSpecs.paidAds,
			...withSameSimilarSpecs.freeAds,
			...withSameRequiredAdOptions.paidAds,
			...withSameRequiredAdOptions.freeAds,
			...withSameCategory.paidAds,
			...withSameCategory.freeAds
		].map(ad => ({
			...ad,
			isPaid: ad.listingStatus === 'Active',
		}))
	}
)
