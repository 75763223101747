import PropTypes from 'prop-types'
import React from 'react'
import { Place } from '../Place'
import { Time } from '../Time'
import { adsListViews } from 'Core/constants'

export const Location = ({place, time}) => (
  <div className='oglasi-items-tekst-lokacija clearfix'>
    <Place location={place} style={adsListViews.TABLE} />
    <Time time={time} style={adsListViews.TABLE} />
  </div>
)

Location.propTypes = {
  place: PropTypes.string,
  time: PropTypes.string
}
