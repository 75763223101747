import React from 'react'

export const Preloader = () =>
  <div className='preloader'>
    <div className='preloader__icon' />
  </div>

export const AdditionalPreloader = () =>
  <div className='ads-loading'><Preloader /></div>

export const FixedPreloader = () =>
  <div className='preloader preloader-fixed'>
    <div className='preloader__icon' />
  </div>

export const TextPreloader = () =>
  <div className='ads-loading-text'>Učitavanje oglasa...</div>
