import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'

export const SubItem = ({icon, children, seo}) => (
  <li>
    <Link href={`/category?category=${seo}`} as={`/${seo}`}>
      <a className='kategorije-menu-list-item'>
        <i className={icon} />
        {children}
      </a>
    </Link>
  </li>
)

SubItem.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.string,
  seo: PropTypes.string.isRequired
}
