import PropTypes from 'prop-types'
import React from 'react'
import withTranslation from 'next-translate/withTranslation'
import { SocServices, Header, UserCard } from 'SRC/modules/layout/Sidebar/shared/components'
import StpdBannerComponent from 'stpd-ad-component'
import { userStatuses } from 'Core/constants'
import { profileMenu } from 'SRC/modules/users/Profile/constants'
import { getUserTypes } from 'SRC/modules/common/selectors'
import { Menu } from '../Menu'
import { getCurrentProfilePage, getUserInfo, getFavouriteProfiles } from 'SRC/modules/users/Profile/selectors'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { logout } from 'SRC/modules/users/Auth/actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Router from 'next/router'
import { isUserOnline } from 'SRC/utils'
import { getFavouritePublishedAds, getSavedFilters } from '../../../../../users/Profile/selectors'
import { getGarageItems } from 'SRC/modules/garage/list/selectors'
import config from 'SRC/config/config.json'

const userTypesAll = 0

const Banner = () => {
  return (
    <div style={{ width: 300, height: 250 }}>
      <StpdBannerComponent
        refreshOnUrlChange={true}
        adUnitPath={`/${config.banners.dfpNetworkId}/${config.banners.sidebar.profile.adUnit}`}
        size={config.banners.sidebar.profile.sizes}
        divId={config.banners.sidebar.profile.slotId}
      />
    </div>
  )
}

export class Container extends React.Component {
  constructor(props) {
    super(props)

    this.messagesCount = 0
    this.commentsCount = 0
  }

  componentDidUpdate() {
    if (!this.props.isAuthorized) {
      Router.push('/')
    }
  }

  getUserCardInfo = () => {
    const isBusinessUser = this.isBusinessUser()
    const { info } = this.props
    const mainAddress = info && info.userAddresses && this.getMainAddress(info.userAddresses)
    const arLogo = info && Array.isArray(info.media) && info.media.filter(item => item.title === 'profile-picture')
    return info
      ? {
        name: info.name,
        login: info.username,
        status: isUserOnline(info.active) ? userStatuses.online : userStatuses.offline,
        isEmailVerified: Boolean(info.email && info.email.verified),
        isPhoneVerified: true,
        isAddressVerified: false,
        gender: info.gender,
        type: isBusinessUser ? 'business' : 'free',
        image: isBusinessUser && arLogo.length ? arLogo[0] : null,
        addressCount: isBusinessUser ? Array.isArray(info.userAddresses) && info.userAddresses.length : 0,
        city: isBusinessUser ? mainAddress && mainAddress.city ? mainAddress.city.name : null : null,
        isPro: false
      }
      : null
  }

  getUserTypeSeo = () => this.props.info && this.props.info.type && this.props.info.type.seo

  isBusinessUser = () => {
    let isBusiness = false
    const seo = this.getUserTypeSeo()

    const businessUserTypes = [...config.userTypes.businessUsersSeos, config.userTypes.serviceProviderSeo]
    if (businessUserTypes.includes(seo)) isBusiness = true

    return isBusiness
  }

  getSidebarTitle = () => {
    let title = ''

    if (this.props.info && this.props.info.type && this.props.info.type.seo && Array.isArray(this.props.userTypes)) {
      const searchedUserType = this.props.userTypes.filter(item => item.seo === this.props.info.type.seo)
      if (searchedUserType.length) title = searchedUserType[0].name
    }

    return title
  }

  getMainAddress = addresses => {
    if (Array.isArray(addresses)) {
      const searchedAddress = addresses.filter(address => address.main)
      if (searchedAddress.length) {
        return searchedAddress[0]
      } else {
        if (addresses.length) {
          return addresses[0]
        }
        return null
      }
    }
  }

  getMenuItems = () => {
    if (!this.props.info) return []

    const { i18n: { t } } = this.props
    const { publishAdsCount } = this.props.info

    const menuItems = []

    for (let item of profileMenu) {
      let menuItem = {
        ...item,
        url: item.url ? item.url : {
          href: `/profile?page=${item.code}`,
          as: `/profil/${item.code}`
        },
        isActive: this.props.currentPage === item.code || (!this.props.currentPage && item.code === 'moj')
      }
      switch (item.code) {
        case profileMenu.ads.code:
          menuItem.count = `${publishAdsCount || 0} ${t('common:aktivnih')}`
          break
        // case profileMenu.messages.code:
        //   menuItem.count = `${this.messagesCount}`
        //   break
        // case profileMenu.comments.code:
        //   menuItem.count = `${this.commentsCount}`
        //   break
        case profileMenu.savedAds.code:
          menuItem.count = `${this.props.savedAds.length}`
          break
        case profileMenu.savedProfiles.code:
          const { savedProfiles } = this.props
          const savedProfilesCount = savedProfiles && savedProfiles.count ? savedProfiles.count : 0
          menuItem.count = `${savedProfilesCount}`
          break
        case profileMenu.savedFilters.code:
          menuItem.count = `${this.props.savedFilters.length}`
          break
        case 'moja-garaza': // change this
          const { garageItems } = this.props
          menuItem.count = `${garageItems.length}`
          break
        default:
          break
      }

      menuItem.title = t(`common:${menuItem.title}`)
      menuItems.push(menuItem)
    }

    menuItems.push({
      id: 10,
      title: t('common:Izloguj se'),
      iconClass: 'ico-izloguj-se',
      onClick: this.logout,
      wrapperClass: 'logout-button'
    })

    return menuItems
  }

  logout = (e) => {
    e.preventDefault()
    this.props.logout()
  }

  render() {
    const menuItems = this.getMenuItems()
    const user = this.getUserCardInfo()
    const title = this.getSidebarTitle()
    return (
      <aside className='sidebar'>
        <div className='sidebar-prodavac'>
          {title && <Header title={title} />}
          <div className={`sidebar-content ${this.isBusinessUser() ? `sidebar-user-prodavnica` : `sidebar-user-free`}`}>
            <div className='sidebar-user'>
              {user ? <UserCard user={user} /> : null}
              <Menu items={menuItems} />
            </div>
          </div>
        </div>

        <Banner />
        <SocServices />
      </aside>
    )
  }
}

const mapStateToProps = state => {
  const savedProfiles = getFavouriteProfiles(state, userTypesAll)

  return {
    currentPage: getCurrentProfilePage(state),
    isAuthorized: getIsAuthorized(state),
    info: getUserInfo(state),
    userTypes: getUserTypes(state),
    savedAds: getFavouritePublishedAds(state),
    savedFilters: getSavedFilters(state),
    garageItems: getGarageItems(state),
    savedProfiles
  }
}

Container.propTypes = {
  currentPage: PropTypes.string,
  isAuthorized: PropTypes.bool.isRequired,
  userTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    seo: PropTypes.string,
    name: PropTypes.string
  })),
  logout: PropTypes.func.isRequired,
  info: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    gender: PropTypes.string,
    type: PropTypes.shape({
      seo: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    media: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired
    })),
    userAddresses: PropTypes.array,
    email: PropTypes.shape({
      verified: PropTypes.bool
    }),
    publishAdsCount: PropTypes.number,
    active: PropTypes.string
  }),
  savedAds: PropTypes.object,
  savedProfiles: PropTypes.object,
  savedFilters: PropTypes.array,
  garageItems: PropTypes.array
}

Container.displayName = 'ProfileSidebar'

const enhance = compose(
  withTranslation,
  connect(mapStateToProps, { logout })
)

export default enhance(Container)
