import PropTypes from 'prop-types'
import React from 'react'
import { imagesCDN } from 'Core/constants'

export const Logo = ({src, alt, isPro}) =>
  <div className={`sidebar-user-img ${isPro ? `pro` : ``}`}>
    <img src={src ? `${imagesCDN}${src}` : `/icons/no-image-placeholder.svg`} alt={alt} />
  </div>

Logo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  isPro: PropTypes.bool.isRequired
}

Logo.defaultProps = {
  isPro: false
}
