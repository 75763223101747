import PropTypes from 'prop-types'
import Link from 'next/link'
import React from 'react'
import { connect } from 'react-redux'
import { getIsTransitioning } from 'SRC/modules/common/selectors'
import { isObjectContainedAnotherObject } from 'SRC/utils/Utils'

const Item = ({
  id,
  icoClass,
  text,
  ariaLabel,
  children,
  className,
  link,
  targetBlank,
  pages,
  query,
  router,
  is404,
  isTransitioning,
  locale = 'me'
}) => {

  if (!['me', 'en'].includes(locale) && ['discountCard', 'insurance'].includes(id)) return null

  const linkHtml = <a className={`main-menu-btn${isTransitioning ? ' disabled' : ''}`} target={targetBlank ? '_blank' : '_self'} aria-label={ariaLabel || text}>
    {icoClass !== '' ? <i className={`${icoClass} ico`} /> : ''}
    {text}
    {children ? <i className='ico-gavni-meni-dropdown-strelica ico ico-sm' /> : ''}
  </a>

  const activeClass =
    !is404 &&
      router &&
      router.route &&
      pages.includes(router.route) &&
      (!query || isObjectContainedAnotherObject(query, router.query))
      ? `active` : ``

  return (
    <li className={`${className} ${activeClass}`}>
      {
        (link && link !== '' && link !== '#' && !isTransitioning) ? (
          <Link href={link.href} as={link.as}>
            {linkHtml}
          </Link>
        ) : (
          <span className={`main-menu-btn${isTransitioning ? ' disabled' : ''}`}>
            {icoClass !== '' ? <i className={`${icoClass} ico`} /> : ''}
            {text}
            {children ? <i className='ico-gavni-meni-dropdown-strelica ico ico-sm' /> : ''}
          </span>
        )
      }
      {children}
    </li>
  )
}

Item.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  icoClass: PropTypes.string,
  link: PropTypes.string,
  pages: PropTypes.any,
  text: PropTypes.string,
  ariaLabel: PropTypes.string,
  router: PropTypes.object,
  query: PropTypes.shape(),
  is404: PropTypes.bool.isRequired,
  targetBlank: PropTypes.bool,
  isTransitioning: PropTypes.bool.isRequired
}

Item.defaultProps = {
  is404: false
}

const mapStateToProps = state => ({
  isTransitioning: getIsTransitioning(state)
})

export default connect(mapStateToProps)(Item)
