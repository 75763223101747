import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const packageReducer = (state = initialState.user.packages, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_PACKAGES:
      return {
        ...state,
        items: action.payload
      }
    case actionTypes.SET_PACKAGES_DISCOUNTS:
      return {
        ...state,
        discounts: action.payload
      }
    case actionTypes.SET_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: action.payload
      }
    case actionTypes.SET_PACKAGE_CATEGORIES:
      return {
        ...state,
        packageCategories: {
          ...state.packageCategories,
          items: action.payload
        }
      }
    case actionTypes.SET_PACKAGE_CATEGORIES_LOADING:
      return {
        ...state,
        packageCategories: {
          ...state.packageCategories,
          isLoading: action.payload
        }
      }
    default:
      return state
  }
}
