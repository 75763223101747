import React from 'react'
import { Column } from '../Column'
import { columns } from '../data/columns'
import { Apps } from '../Apps'

export const Menu = () => (
  columns.map(column => {
    if (column.apps) return <Apps key='apps' />

    return (
      <Column key={column.id} {...column}>
        {column.child}
      </Column>
    )
  })
)
