import React from 'react'
import PropTypes from 'prop-types'

export const Search = ({onChange, placeholder}) => (
  <div className='dodavanje-oglasa-search'>
    <form className='dodavanje-oglasa-form' onSubmit={e => e.preventDefault()}>
      <input type='text' placeholder={placeholder} onChange={onChange} />
      <button type='submit'><i className='ico-pretraga-text-search-lupa ico' /></button>
    </form>
  </div>
)

Search.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

Search.defaultProps = {
  onChange: () => {}
}
