import PropTypes from 'prop-types'
import React from 'react'
import { ErrorMessage } from 'formik'
import { Wrapper } from '../Wrapper'

export const Password = ({ formikProps, onMouseEnter, name, isAddAdExistingUser, showStar }) => {
  const defaultValue = !isAddAdExistingUser
    ? formikProps.values.password
    : formikProps.values.existingUser
      ? formikProps.values.existingUser.password
      : ''
  
  const passwordError = !isAddAdExistingUser
    ? formikProps.touched.password && formikProps.errors.password
    : formikProps.touched.existingUser && formikProps.errors.existingUser
      ? formikProps.touched.existingUser.password && formikProps.errors.existingUser.password
      : ''

  return (
    <Wrapper isError={passwordError}>
      <label>Lozinka: {showStar ? <span className='required'>*</span> : null}</label>

      <i className='ico-sifra-login ico' />
      <input
        name={name}
        type='password'
        disabled={formikProps.isSubmitting}
        defaultValue={defaultValue}
        onChange={async e => {
          console.log('pwchange', e.target.value)
          if (!isAddAdExistingUser) {
            await formikProps.setFieldValue('password', e.target.value)
            if (!formikProps.touched.password) formikProps.setFieldTouched('password')
          } else {
            await formikProps.setFieldValue('existingUser.password', e.target.value)
            if (formikProps.touched.existingUser && !formikProps.touched.existingUser.password) {
              formikProps.setFieldTouched('existingUser.password')
            }
          }
        }}
        onMouseEnter={onMouseEnter}
      />
      <ErrorMessage
        name={name}
        render={msg => <span style={{ display: 'block', marginTop: 5 }} className='form-group__error-message'>{msg}</span>}
      />
    </Wrapper>
  )
}

Password.defaultProps = {
  name: 'password',
  isAddAdExistingUser: false,
  showStar: true
}

Password.propTypes = {
  formikProps: PropTypes.object.isRequired,
  showStar: PropTypes.bool,
  onMouseEnter: PropTypes.func
}
