import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Header } from '../Header'
import { withRouter } from 'next/router'
import { getCurrentAd } from 'src/modules/ads/detail/selectors'
import { Facebook, Viber, WhatsApp, Twitter, Email, Telegram } from './../index'
import config from 'SRC/config/config.json'

const Container = ({ router, ad }) => {
  const asPath = router.asPath
  let baseUrl = config.siteUrl

  const adLink = ad && ad.link ? ad.link : null

  if (adLink) baseUrl = adLink.startsWith('http') ? '' : 'https://autodiler.me'

  return (
    <div className='kontakt'>
      <Header />
      <div className='sidebar-content clearfix'>
        <Facebook asPath={asPath} adLink={adLink} baseUrl={baseUrl} />
        <Twitter asPath={asPath} adLink={adLink} baseUrl={baseUrl} />
        <WhatsApp asPath={asPath} adLink={adLink} baseUrl={baseUrl} />
        <Viber asPath={asPath} adLink={adLink} baseUrl={baseUrl} />
        <Email asPath={asPath} adLink={adLink} baseUrl={baseUrl} />
        <Telegram asPath={asPath} adLink={adLink} baseUrl={baseUrl} />
      </div>
    </div>
  )
}

Container.propTypes = {
  router: PropTypes.shape({
    asPath: PropTypes.string.isRequired
  }),
  ad: PropTypes.shape({
    link: PropTypes.string.isRequired
  })
}

Container.defaultProps = {
  displayBanner: true
}

Container.displayName = 'SidebarSocServices'
export default compose(connect(state => ({ ad: getCurrentAd(state) })), withRouter)(Container)
