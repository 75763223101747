import React from 'react'
import PropTypes from 'prop-types'

export const AdFormHeader = ({ title }) => (
  <div className='oglas-form-header'>
    <p>{title}</p>
  </div>
)

AdFormHeader.propTypes = {
  title: PropTypes.string.isRequired
}

AdFormHeader.defaultProps = {
  title: ''
}
