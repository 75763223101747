import React from 'react'
import { TypesHelper } from 'src/utils/index'
import PropTypes from 'prop-types'
import { isJsonString } from 'src/utils/Utils'

const renderCompatibilities = compatibilities => {
  const first = compatibilities[0]
  let brand, product, yearMin, yearMax
  if (first.brand) brand = first.brand.name
  if (first.product) product = first.product.model
  for (const comp of compatibilities) {
    if (comp.years && comp.years.length) {
      const years = comp.years.map(y => Number(y))
      const yMin = Math.min(...years)
      const yMax = Math.max(...years)
      if (!yearMin || yMin < yearMin) yearMin = yMin
      if (!yearMax || yMax > yearMax) yearMax = yMax
    }
  }

  const restCount = compatibilities.length - 1
  return <div className='oglasi-item-ikonice-icons'>
    <div className='icons-item' key='brandProduct'>
      <p>{brand ? `${brand} ${product ? ` - ${product} ` : ''}` : ''}</p>
    </div>

    {yearMin || yearMax ? <div className='icons-item'>
      <p>{yearMin && !yearMax ? yearMin : ''}
        {!yearMin && yearMax ? yearMax : ''}
        {yearMin && yearMax && yearMin === yearMax ? yearMin : `${yearMin} - ${yearMax}`}
      </p>
    </div> : null}

    {restCount ? <div className='icons-item' key='restCount'>
      <p>(+{restCount})</p>
    </div> : null}
  </div>
}

const renderServiceAndServiceType = (service, serviceType) => {
  return <div className='oglasi-item-ikonice-icons'>
    <div className='icons-item' key='brandProduct'>
      <p>{service.name}</p>
    </div>

    <div className='icons-item' key='brandProduct'>
      <p>{serviceType.name}</p>
    </div>
  </div>
}

export const CardView = ({specs, compatibilities, service, serviceType}) => {
  return (
    <div className='oglasi-item-ikonice-icons'>
      {
        TypesHelper.isNotEmptyArray(specs)
          ? specs.map((item, index) => {
            if (item) {
              return [
                <div className='icons-item' key={index}>
                  <i className={`${item.icon}`} />&nbsp;
                  <p>{`${isJsonString(item.value) && Array.isArray(JSON.parse(item.value))
                    ? JSON.parse(item.value).join(', ') : item.value}
                    ${item.measure ? item.measure : ''}`}</p>
                </div>,
                '\n'
              ]
            } else {
              return ''
            }
          })
          : compatibilities && compatibilities.length
            ? renderCompatibilities(compatibilities)
            : service && serviceType ? renderServiceAndServiceType(service, serviceType) : ''
      }
    </div>
  )
}

CardView.propTypes = {
  specs: PropTypes.arrayOf(PropTypes.object).isRequired,
  compatibilities: PropTypes.arrayOf(PropTypes.object),
  service: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  serviceType: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
}

CardView.defaultProps = {
  specs: []
}
