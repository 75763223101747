import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withTranslation from 'next-translate/withTranslation'
import { connect as formikConnect } from 'formik'
import { adFiltersLabels } from 'src/core/constants'
import { fetchCountries } from 'SRC/modules/geoObjects/countries/actions'
import { getCountries } from 'SRC/modules/geoObjects/countries/selectors'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { CitiesApi } from 'SRC/modules/geoObjects/cities/api'
import { LocationsApi } from 'SRC/modules/geoObjects/locations/api'
import { SelectDropdown, AdFilterField } from 'SRC/ui/FormElementsNew'
import { Sort } from '../../Sort'

export class SecondRow extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.getDefaultState()
  }

  async componentDidMount() {
    const { fetchCountries, formik, countries } = this.props
    const { cities, isCitiesLoading } = this.state

    if (!Array.isArray(countries) || !countries.length) fetchCountries()

    const country = formik.values.country

    if (country && country !== -1 && !cities[country] && !isCitiesLoading) {
      this.loadCitiesToState(country)
    }
  }

  async componentDidUpdate(prevProps) {
    const { formik } = this.props
    const { locations, isLocationsLoading } = this.state

    const city = formik.values.city
    const prevCity = prevProps.formik.city

    if (city && prevCity !== city && city !== -1 && !locations[city] && !isLocationsLoading) {
      this.loadLocationsToState(city)
    }
  }

  getDefaultState = () => ({
    cities: {},
    citiesFlat: [],
    isCitiesLoading: false,
    locations: {},
    isLocationsLoading: false
  })

  resetState = () => {
    this.setState(this.getDefaultState())
  }

  /**
   * Countries
   */
  getCountryField = () => {
    const { countries, formik, i18n: { t } } = this.props

    return {
      id: 'country',
      isDisabled: !countries || !countries.length,
      title: t('common:Država'),
      options: this.prepareCountries(),
      value: formik.values.country,
      onChange: this.onCountryChange,
      isSearchable: true
    }
  }

  prepareCountries = () => this.props.countries.filter(country => !country.parent).map(country => {
    const isGrouped = Boolean(this.props.countries.filter(item => item.parent === country.id).length)
    return {
      id: `country-${country.id}`,
      label: country.label,
      value: country.id,
      isGrouped,
      options: isGrouped ? this.props.countries.filter(item => item.parent === country.id).map(childCountry => ({
        id: `country-${childCountry.id}`,
        value: childCountry.id,
        label: childCountry.label
      })) : null
    }
  })

  onCountryChange = value => {
    const { formik } = this.props
    const { cities, isCitiesLoading } = this.state
    const country = Number(value)
    formik.setFieldValue('country', country)
    formik.setFieldValue('city', -1)
    formik.setFieldValue('location', -1)
    formik.setFieldValue('cityText', '')
    formik.setFieldValue('cityBc', {})
    formik.setFieldValue('locationText', '')

    if (country && country !== -1) {

      if (!cities[country]) {
        !isCitiesLoading && this.loadCitiesToState(country)
      } else {
        this.updateFlatCities(country)
      }
    }
  }

  /**
   * Cities
   */
  getCityField = () => {
    const { formik, i18n: { t } } = this.props
    const { cities, isCitiesLoading } = this.state

    const country = formik.values.country

    const isDisabled = country === -1 || !cities[country] || !cities[country].length || isCitiesLoading

    return {
      id: 'city',
      isDisabled,
      title: t('common:Grad'),
      options: cities[country] || [],
      onChange: this.onCityChange,
      value: formik.values.city,
      isSearchable: true
    }
  }

  loadCitiesToState = async (country) => {
    this.setState({
      isCitiesLoading: true,
      locations: []
    })

    const citiesApi = new CitiesApi()
    const citiesWithinRegions = await citiesApi.getCitiesByCountryWithinRegions(country)

    const preparedCities = this.prepareCities(citiesWithinRegions)

    let citiesFlat = [];

    (citiesWithinRegions || []).forEach(region => {
      citiesFlat = citiesFlat.concat(region.cities)
    })

    this.setState({
      cities: {
        ...this.state.cities,
        [country]: preparedCities
      },
      citiesFlat,
      isCitiesLoading: false
    })
  }

  updateFlatCities = country => {
    const { cities } = this.state

    let citiesFlat = [];

    (cities[country] || []).forEach(region => {
      citiesFlat = citiesFlat.concat(region.options)
    })

    this.setState({ citiesFlat })
  }

  prepareCities = (citiesWithinRegions = []) => citiesWithinRegions.map(region => ({
    id: `region-${region.id}`,
    label: region.label,
    value: `region-${region.id}`,
    isGrouped: true,
    options: region.cities.map(city => ({
      id: city.id,
      value: city.value,
      seo: city.seo,
      label: city.label
    }))
  }))

  onCityChange = value => {
    const { formik } = this.props
    const { citiesFlat } = this.state

    const city = Number(value)

    const findCity = Array.isArray(citiesFlat) ? citiesFlat.find(c => c.id === city) : null

    if (findCity) {
      formik.setFieldValue('cityText', findCity.label)
      formik.setFieldValue('cityBc', { seo: findCity.seo, text: findCity.label })
    } else {
      formik.setFieldValue('cityText', '')
      formik.setFieldValue('cityBc', {})
    } 

    formik.setFieldValue('city', city)
    formik.setFieldValue('location', -1)
    formik.setFieldValue('locationText', '')
  }

  /**
   * Locations
   */
  getLocationField = () => {
    const { formik, i18n: { t } } = this.props
    const { locations, isLocationsLoading } = this.state

    const city = formik.values.city

    const isDisabled = city === -1 || !locations[city] || !locations[city].length || isLocationsLoading

    return {
      id: 'location',
      isDisabled,
      title: t('common:Lokacija'),
      options: locations[city] || [],
      value: formik.values.location,
      onChange: this.onLocationChange,
      isSearchable: true
    }
  }

  loadLocationsToState = async city => {
    const { currentSubCategory } = this.props

    let categoryId = currentSubCategory ? currentSubCategory.id : null

    this.setState({
      isLocationsLoading: true
    })

    const api = new LocationsApi()

    const locations = await api.getLocationsByCity(Number(city), Number(categoryId))

    this.setState({
      locations: {
        ...this.state.locations,
        [city]: this.prepareLocations(locations)
      },
      isLocationsLoading: false
    })
  }

  prepareLocations = (locations = []) => {
    const sortedLocations = locations.sort((a, b) => {
      if (a.priority === b.priority) return 0
      else if (a.priority === null) return 1
      else if (b.priority === null) return -1
      else return a.priority - b.priority
    })
    return sortedLocations.map(location => ({
      id: location.id,
      label: location.label,
      value: location.value
    }))
  }

  onLocationChange = async value => {
    const { formik } = this.props
    const { locations } = this.state

    const location = Number(value)

    const findLocation = Array.isArray(locations[formik.values.city])
      ? locations[formik.values.city].find(loc => loc.id === location)
      : null

    formik.setFieldValue('location', location)

    if (findLocation) formik.setFieldValue('locationText', findLocation.label)
    else formik.setFieldValue('locationText', '')
  }

  render() {
    const countryField = this.getCountryField()
    const cityField = this.getCityField()
    const locationField = this.getLocationField()
    return (
      <div className='ads-filter__row'>
        <AdFilterField>
          <SelectDropdown {...countryField} />
        </AdFilterField>

        <AdFilterField>
          <SelectDropdown {...cityField} />
        </AdFilterField>

        <AdFilterField>
          <SelectDropdown {...locationField} />
        </AdFilterField>
        <Sort />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    countries: getCountries(state),
    currentSubCategory: getCurrentSubCategory(state)
  }
}

SecondRow.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  })).isRequired,
  currentSubCategory: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  fetchCountries: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func
  }).isRequired
}

SecondRow.defaultProps = {
  countries: []
}

const enhance = compose(
  connect(mapStateToProps, { fetchCountries }),
  formikConnect,
  withTranslation
)

export default enhance(SecondRow)
