import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const sharedReducer = (state = initialState.garage.shared, action) => {
  switch (action.type) {
    case actionTypes.SET_GARAGE_PHOTO:
      return {...state, photo: action.payload}
    case actionTypes.SET_GARAGE_CATEGORIES:
      return {...state, categories: action.payload}
    default:
      return state
  }
}
