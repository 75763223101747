import PropTypes from 'prop-types'
import React from 'react'
import { AdditionalPreloader } from '../Preloader'

export const ConfirmDialog = ({ onConfirm, onCancel, message, isLoading }) => <div className='confirm-dialog'>
  <p>{message}</p>
  {!isLoading ? [<button onClick={onConfirm}>Da</button>,
    <button onClick={onCancel}>Ne</button>] : <AdditionalPreloader />}
</div>

ConfirmDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
}
