import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const Button = ({ onReportClick }) => {
  const { t } = useTranslation('common')

  return (
    <div className='sidebar-user-akcije-item'>
      <a href='javascript:void (0)' onClick={onReportClick}>
        <i className='ico-user-prijavi-oglas ico' />
        <p>{t('Prijavi oglas')}</p>
      </a>
    </div>
  )
}

Button.propTypes = {
  onReportClick: PropTypes.func.isRequired
}
