import React from 'react'
import Link from 'next/link'

export const RegLink = () => (
  <div className='prijavi-se-popup-registracija'>
    <Link href={'/registracija'}>
      <a>
        <p>Nemaš profil? Registruj se.</p>
      </a>
    </Link>
  </div>
)
