import {createStore, applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import initState from './initialState'
import rootReducer from './rootReducer'

const createProdStore = applyMiddleware(thunkMiddleware)
const createDevStore = composeWithDevTools(applyMiddleware(thunkMiddleware))

export const makeStore = (initialState = initState) => {
  return createStore(rootReducer, initialState, process.env.NEXT_PUBLIC_NAME === 'production' ? createProdStore : createDevStore)
}
