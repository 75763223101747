import PropTypes from 'prop-types'
import React from 'react'

export const Name = ({name, username}) =>
  [<p className='sidebar-user-status-name'>{name}</p>,
    <p className='sidebar-user-status-username'>({username})</p>]

Name.propTypes = {
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
}
