import { globalOptions } from 'SRC/core/constants'
import { AdsListApi } from '../../api'
import { setAds } from '../setAds'
import { setLoading } from '../setLoading'
import { setPaidAds } from '../setPaidAds'
import { setPaginationParams } from '../../../pagination/actions'
import config from 'SRC/config/config.json'

export const fetchAds = (requestParams, paginationParams = {}, currentAds = []) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const api = new AdsListApi()

      if (!requestParams.status) requestParams.limit = globalOptions.allProfileAdsLimit
      const {paidAds, freeAds, paidCount, freeCount} = await api.getAds(requestParams)
      const newAds = [...paidAds, ...freeAds]

      const isMobileDevice = getState().common.isMobileDevice

      // Insert banner at random position if exists
      if (config.banners.adsList && config.banners.adsList.betweenAdsMobile && isMobileDevice) {
        if (newAds.length) {
          newAds.push({
            slotId: `div-gpt-ad-1681814473496-${Math.floor((Math.random() * 10000) + 1)}`,
            key: `banner-${Math.floor((Math.random() * 10000) + 1)}`,
            banner: true
          })
        }
      }

      const ads = currentAds.concat([...newAds])
      const totalCount = paidCount + freeCount

      dispatch(setAds(ads))
      dispatch(setPaidAds(paidAds.map(ad => ad.id)))

      const pagination = {
        ...paginationParams,
        paidCount,
        freeCount,
        totalCount,
        currentPage: requestParams.incrementCurrentPage ? paginationParams.currentPage + 1 : paginationParams.currentPage
      }

      dispatch(setPaginationParams(pagination))
      dispatch(setLoading(false))
    } catch (e) {
      dispatch(setAds(currentAds))
      dispatch(setLoading(false))
    }
  }
}
