import PropTypes from 'prop-types'
import React from 'react'
import { Field, reduxForm, getFormSubmitErrors, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { passwordValidator, requiredValidator, emailValidator, phoneValidator } from 'Core/validators'
import { login, setAuthPopupFormErrors } from '../../actions'
import { getLoginInPopupError } from '../../selectors'
import { ErrorNotification } from 'SRC/ui'
import { compose } from 'recompose'

export class LoginInPopupForm extends React.Component {
  onSubmit = async values => {
    this.validate(values)
    this.props.login(this.prepareFields(values), true)
  }

  prepareFields = (fields) => {
    let preparedFields = {}
    preparedFields.password = fields.password
    if (emailValidator(fields.user)) {
      preparedFields.email = fields.user
    } else if (phoneValidator(fields.user)) {
      preparedFields.phone = fields.user
    } else {
      preparedFields.username = fields.user
    }
    return preparedFields
  }

  validate = (values) => {
    const error = {}
    if (!requiredValidator(values.user)) {
      error.user = 'Login is not filled'
    }
    if (!passwordValidator(values.password)) {
      error.password = 'Password is incorrect'
    }
    if (Object.keys(error).length) {
      error._error = 'Login failed'
      throw new SubmissionError(error)
    }
  }

  hideError = () => {
    this.props.setAuthPopupFormErrors('')
  }

  render () {
    const {handleSubmit, formSubmitErrors, errorText} = this.props
    return (
      <div className='prijavi-se-popup-form'>
        <p>Prijavna forma</p>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className={`form-group ${formSubmitErrors.user ? 'form-group_error' : ''}`}>
            <label>Korisničko ime <span>ili</span> broj telefona</label>
            <i className='ico-top-meni-prijavi-se ico' />
            <Field name='user' component='input' type='text' />
          </div>
          <div className={`form-group ${formSubmitErrors.password ? 'form-group_error' : ''}`}>
            <label>Šifra (lozinka)</label>
            <i className='ico-sifra-login ico' />
            <Field name='password' component='input' type='password' />
          </div>
          <ErrorNotification error={errorText} hideNotification={this.hideError} />
          <button type='submit'>Prijavi se</button>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    formSubmitErrors: getFormSubmitErrors('login-popup')(state),
    errorText: getLoginInPopupError(state)
  }
}

LoginInPopupForm.propTypes = {
  handleSubmit: PropTypes.func,
  formSubmitErrors: PropTypes.object,
  errorText: PropTypes.string,
  login: PropTypes.func.isRequired,
  setAuthPopupFormErrors: PropTypes.func.isRequired
}

export default compose(
  connect(mapStateToProps, {login, setAuthPopupFormErrors}),
  reduxForm({form: 'login-popup'})
)(LoginInPopupForm)
