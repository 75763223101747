export const getSpecFilterInputType = filter => {
  let inputType = 'textInput'

  if (filter.input === 'multiselect') inputType = 'multiselect'
  if (filter.input === 'select') inputType = 'singleselect'
  if (filter.input === 'range') {
    if (filter.specification.type === 'select') inputType = 'selectRange'
    else inputType = 'inputRange'
  }

  return inputType
}
