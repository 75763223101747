import PropTypes from 'prop-types'
import React from 'react'
import { getChar, getRegularExpression } from 'SRC/utils'
import { debounce } from 'throttle-debounce'
import { Hint } from '../Hint'

export const RegularTextInput = ({
  id,
  name,
  title,
  isRequired,
  value,
  hint,
  allowedCharacters,
  measure,
  className,
  placeholder,
  isDisabled,
  onChange,
  onMouseEnter,
  onFocus,
  preventUpdate,
  resetPreventUpdate
}) => {
  const onTextInputChange = e => onChange(e.target.value)

  const onKeyPress = e => {
    const chr = getChar(e)
    if (e.ctrlKey || e.altKey || chr == null) return

    if (Array.isArray(allowedCharacters) && allowedCharacters.length) {
      const regexp = getRegularExpression(allowedCharacters)
      const isValid = regexp ? regexp.test(chr) : true
      if (!isValid) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  return (
    <React.Fragment>
      <label htmlFor={id} className='inline-label'>
        {`${title}:`} {isRequired ? <span className='required'>*</span> : null}
      </label>

      {hint ? <Hint hint={hint} /> : null}

      <input
        id={id}
        name={name}
        key={id}
        value={Number(value) !== -1 ? value : ''}
        type='text'
        disabled={isDisabled}
        style={{ opacity: isDisabled ? 0.4 : 1 }}
        placeholder={placeholder}
        className={className}
        onKeyPress={onKeyPress}
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
        onChange={e => { onChange(e.target.value) }}
      />
      {measure ? <span className='input-addon'>{measure}</span> : null}
    </React.Fragment>
  )
}

RegularTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string,
  measure: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  allowedCharacters: PropTypes.array,
  preventUpdate: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  value: PropTypes.any.isRequired,
  resetPreventUpdate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func
}

RegularTextInput.defaultProps = {
  allowedCharacters: [],
  isDisabled: false,
  className: ''
}
