import { AdsDetailApi } from '../../api'
import { setAdsReportTypes } from '../setAdsReportTypes'

export const fetchAdsReportTypes = () => async (dispatch, getState) => {
  const reportTypes = getState().ads.detail.reportTypes

  if (!Array.isArray(reportTypes) || !reportTypes.length) {
    const api = new AdsDetailApi()
    const reportTypesResult = await api.getAdsReportTypes()
    dispatch(setAdsReportTypes(reportTypesResult))
  }
}
