import PropTypes from 'prop-types'
import React from 'react'
import { Address } from '../Address'
import { Phone } from '../../Phone'
import { Buttons } from '../../Buttons'
import { Filters } from '../../Filters'

export const Container = ({ user, adStatus }) => {
  const isUserAddressesExists = Array.isArray(user.addresses) && user.addresses.length
  const visiblePhones = user.phones.filter(phone => {
    if (phone.hasOwnProperty('visible')) return phone.visible
    return true
  })
  return [
    <div className='sidebar-user-contact' key='contacts'>
      {
        isUserAddressesExists
          ? (
            <Address
              country={user.addresses[0].country && user.addresses[0].country.name}
              city={user.addresses[0].city && user.addresses[0].city.name}
              location={user.addresses[0].location && user.addresses[0].location.name}
            />
          ) : null
      }
      {!adStatus || adStatus !== 'Sold' ? visiblePhones.map(phone => <Phone phone={phone} key={phone.phone} />) : null}
    </div>,
    <Buttons key='buttons' />,
    <Filters key='filters' username={user.login} seo={user.seo} />
  ]
}

Container.propTypes = {
  adStatus: PropTypes.string,
  user: PropTypes.shape({
    addresses: PropTypes.arrayOf(PropTypes.shape({
      city: PropTypes.shape({
        name: PropTypes.string.isRequired
      }),
      location: PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    })),
    phones: PropTypes.arrayOf(PropTypes.shape({phone: PropTypes.string.isRequired})).isRequired,
    login: PropTypes.string,
    seo: PropTypes.string
  }).isRequired
}

Container.displayName = 'UserContactsFree'
