import { AdsDetailApi } from '../../api'
import { setSimilarAdsLoading } from '../setSimilarAdsLoading'
import { setSimilarAds } from '../setSimilarAds'

export const fetchSimilarAds = (adId) => async dispatch => {
  dispatch(setSimilarAdsLoading(true))
  const api = new AdsDetailApi()
  const ads = await api.getSimilarAds(adId)
  dispatch(setSimilarAds(ads))
  dispatch(setSimilarAdsLoading(false))
}
