import { createSelector } from 'reselect'
import { getCategories } from '../getCategories'

export const getActiveCategories = createSelector(
  state => state && state.categories && state.categories.activeCategories ? state.categories.activeCategories : null,
  getCategories,
  (activeCategories, categories) => {
    if (Array.isArray(activeCategories)) {
      if (activeCategories.length) {
        const filteredCategories = []
        categories.forEach(item => {
          const visibleChildren = []
          if (Array.isArray(item.children)) {
            item.children.forEach(child => {
              if (activeCategories.includes(child.id)) {
                visibleChildren.push(child)
              }
            })
          }
          if (visibleChildren.length) {
            filteredCategories.push({...item, children: visibleChildren})
          }
        })
        return filteredCategories
      } else {
        return []
      }
    }
    return categories
  }
)
