import React from 'react'
import { Item } from '../Item'
import { projects } from '../data/projects'
import config from 'SRC/config/config.json'
import useTranslation from 'next-translate/useTranslation'

export const List = () => {
  const { t } = useTranslation('common')

  return (
    <div className='footer-projects'>
      <span className='footer-column-title'>{t('footer_projects_list')}</span>
      <ul>
        {
          projects.map(project => [
            <Item key={project.id}{...project} />,
            '\n'
          ])
        }
      </ul>
    </div>
  )
}

