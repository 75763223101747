import { createSelector } from 'reselect'
import { getUserTypes } from 'SRC/modules/common/selectors'

export const getUserType = createSelector(
  state => state && state.user && state.user.list && state.user.list.userType ? state.user.list.userType : null,
  getUserTypes,
  (selectedType, userTypes) => {
    const selectedUserType = userTypes.filter(item => item.seo === selectedType)
    if (selectedUserType.length) {
      return selectedUserType[0]
    } else {
      return null
    }
  }
)
