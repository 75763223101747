import React from 'react'
import { groups as equipmentGroups } from 'SRC/modules/ads/equipment/data'
import { Group as EquipmentGroup } from '../Group'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import { getEquipment } from 'SRC/modules/ads/equipment/selectors'
import { connect } from 'react-redux'

const Container = ({ equipment }) => {
  const { t } = useTranslation('common')

  const getGroupedEquipment = () => {
    const equipmentSorted = equipment.slice().sort((a, b) => a.weight - b.weight)

    return equipmentGroups.map(group => ({
      title: t(group.title),
      groupCode: group.code,
      items: equipmentSorted.filter(item => item.grupa === group.code)
    }))
  }

  const groupedEquipment = getGroupedEquipment()

  return (
    (groupedEquipment || []).map(group => group ? <EquipmentGroup key={`eq-group-${group.groupCode}`} {...group} /> : null)
  )
}

const mapStateToProps = (state) => ({
  equipment: getEquipment(state)
})

Container.propTypes = {
  equipment: PropTypes.array.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
}

Container.defaultProps = {
  equipment: []
}

export default connect(mapStateToProps)(Container)
