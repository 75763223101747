import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'

export const ItemLink = ({url, title, iconClass, iconSvg, count, width}) => (
  <Link
    href={`${url && url.href ? url.href : 'javascript:void(0)'}`}
    as={url && url.as ? url.as : 'javascript:void(0)'}
  >
    <a>
      {iconSvg ? <img src={iconSvg} width={width || 20} /> : <i className={`${iconClass || ''} ico`} />}
      <p>{title}&nbsp;{count ? <span>[&nbsp;{count}&nbsp;]&nbsp;</span> : null}</p>
    </a>
  </Link>
)

ItemLink.propTypes = {
  count: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.shape({
    href: PropTypes.string,
    as: PropTypes.string
  }).isRequired,
  iconSvg: PropTypes.string,
  width: PropTypes.number
}
