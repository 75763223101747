import PropTypes from 'prop-types'
import React from 'react'
import { ItemSpan } from '../ItemSpan'
import { ItemLink } from '../ItemLink'

export const MenuItem = ({iconClass, iconSvg, width, isActive, url, onClick, title, count, wrapperClass}) => (
  <div className={`sidebar-user-ostalo-item ${isActive ? 'active' : ''} ${wrapperClass || ''}`}>
    {
      onClick
        ? <ItemSpan title={title} iconClass={iconClass} count={count} onClick={onClick} iconSvg={iconSvg} width={width} />
        : <ItemLink url={url} title={title} count={count} iconClass={iconClass} iconSvg={iconSvg} width={width} />
    }
  </div>
)

MenuItem.propTypes = {
  count: PropTypes.string,
  iconClass: PropTypes.string,
  iconSvg: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  url: PropTypes.shape({
    href: PropTypes.string,
    as: PropTypes.string
  }),
  width: PropTypes.number,
  wrapperClass: PropTypes.string
}
