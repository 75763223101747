import actionTypes from 'SRC/modules/redux/actionTypes'
import initialState from 'SRC/modules/redux/initialState'

export const serviceTypesReducer = (state = initialState.ads.serviceTypes, action) => {
  switch (action.type) {
    case actionTypes.SET_SERVICE_TYPES:
      return {...state, elements: action.payload}
    case actionTypes.SET_SERVICE_TYPES_LOADING:
      return {...state, isLoading: action.payload}
    default:
      return state
  }
}
