import React from 'react'
import { Item } from '../Item/index'
import { Header } from '../Header/index'
import PropTypes from 'prop-types'

export const List = React.forwardRef((props, ref) => (
  <div className='kategorije-pretraga-mega-menu-content' ref={ref}>
    {
      props.items && Array.isArray(props.items)
        ? props.items.map(item => [
          <Header item={item} key={'header ' + item.id} />,
          '\n',
          <Item
            items={item.children}
            key={'category ' + item.id}
            baseHref={props.baseHref}
            baseAs={props.baseAs}
            hasGetParamsInBaseHref={props.hasGetParamsInBaseHref}
            needScroll={props.needScroll}
          />,
          '\n'
        ])
        : null
    }
  </div>
))

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    children: PropTypes.array.isRequired
  })).isRequired,
  baseHref: PropTypes.string,
  baseAs: PropTypes.string,
  hasGetParamsInBaseHref: PropTypes.bool,
  needScroll: PropTypes.bool
}

List.defaultProps = {
  items: []
}
