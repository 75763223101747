export const getAdThumbnailUrl = item => {
  if (!item) return
  const size = item.size || '236x176'
  if (item && item.thumbnail) {
    return item.thumbnail.fd.replace('/thumbnails', `/size/${size}`)
  } else if (item && item.image) {
    const fd = item.image.fd
    let base = fd.substring(0, fd.indexOf('/', 'https://'.length))
    const path = fd.substring(fd.indexOf('/', 'https://'.length), fd.length)
    base += `/size/${size}`
    return base + path
  } else {
    return 'https://placehold.it/168x126'
  }
}

export const getAdImageUrl = image => {
  if (!image) {
    return 'https://placehold.it/168x126'
  } else {
    return image.fd.replace('fontel', 'autodiler')
  }
}

export const getFdWithoutTimestamp = fd => {
  return fd.split('?')[0]
}

export const getFdWithTimestamp = fd => {
  const fdArr = fd.split('?')
  if (fdArr.length > 1) {
    return `${fdArr[0]}?t=${Date.now()}`
  } else {
    return `${fd}?t=${Date.now()}`
  }
}
