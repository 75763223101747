import React from 'react'
import { connect } from 'react-redux'
import { getIsTransitioning } from 'SRC/modules/common/selectors'
import Link from 'next/link'
import PropTypes from 'prop-types'

export const Item = ({ current, seo, id, title, shortTitle, icon, isTransitioning }) => {
  const isActive = current && id === current.id

  if (!isTransitioning || isActive) {
    return (
      <div className={`kategorije-sub-item ${isActive ? 'active' : ''} `}>
        <Link href={`/category?category=${seo}`} as={`/${seo}`}>
          <a>
            <i className={icon} />
            <p>{shortTitle || title}</p>
          </a>
        </Link>
      </div>
    )
  }

  return (
    <div className={`kategorije-sub-item disabled`}>
      <a href='javascript:void(0)' style={{ opacity: .4, cursor: 'not-allowed' }}>
        <i className={icon} />
        <p>{shortTitle || title}</p>
      </a>
    </div>
  )
}

Item.propTypes = {
  current: PropTypes.object,
  seo: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  shortTitle: PropTypes.string,
  icon: PropTypes.string,
  isTransitioning: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isTransitioning: getIsTransitioning(state)
})

export default connect(mapStateToProps)(Item)
