import { setElements } from '../setElements'
import { setLoading } from '../setLoading'
import { SidebarUsersApi } from '../../api'

export const fetchUsers = ({ userId, token, page, limit, types }) => async dispatch => {
  try {
    dispatch(setLoading(true))
    const api = new SidebarUsersApi()
    const users = await api.getUsers(userId, token, page, limit, types)
    if (!users.error && Array.isArray(users)) {
      dispatch(setElements(users))
    } else {
      dispatch(setElements(null))
    }
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setElements(null))
    dispatch(setLoading(false))
    return { error: e.message }
  }
}
