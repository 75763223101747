import { getCurrentLocale } from 'SRC/modules/common/functions'

import { CategoriesApi } from '../../api'
import { setCategories } from '../setCategories'

import categoriesME from '../../../../../public/data/categoriesME.json'

export const fetchCategories = ({ locale }) => {
  return async (dispatch, getState) => {
    let categories = getState().categories.elements

    if (typeof locale === 'undefined') locale = getCurrentLocale() || 'ME'

    if (locale === 'ME') categories = categoriesME

    if (!Array.isArray(categories) || !categories.length) {
      const api = new CategoriesApi()

      categories = await api.fetchCategories(locale)
    }

    dispatch(setCategories(categories))
  }
}
