import { ProfileApi } from '../../api'
import { setDiscountCard } from '../setDiscountCard'
import { setDiscountCardLoading } from '../setDiscountCardLoading'

export const fetchDiscountCard = (userId, token) => {
  return async (dispatch) => {
    dispatch(setDiscountCardLoading(true))
    const api = new ProfileApi()
    const card = await api.getDiscountCard(userId, token)
    dispatch(setDiscountCard(card))
    dispatch(setDiscountCardLoading(false))
  }
}
