import { createSelector } from 'reselect'
import { defaultDial } from 'Core/constants'

export const getDial = createSelector(
  state => {
    if (state.form && state.form.register && state.form.register.values && state.form.register.values.country) {
      const arSelectedCountry = state.geoObjects.countries.elements.filter(
        country => country.id === Number(state.form.register.values.country)
      )
      if (arSelectedCountry.length) {
        return arSelectedCountry[0].dial ? arSelectedCountry[0].dial : defaultDial
      } else {
        return defaultDial
      }
    } else {
      return defaultDial
    }
  },
  dial => dial
)
