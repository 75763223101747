import React from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export const ForAuthUsers = () => {
  const { t } = useTranslation('common')

  return (
    <div className='header-links__auth-user'>
      <ul className='header-links__list'>
        <li className='active main-nav-actions'>
          <Link href='/profile' as='/profil'>
            <a>
              <i className='ico-top-meni-prijavi-se ico' />
              {t('Moj Profil')}
            </a>
          </Link>
        </li>
        <li className='last-in-nav-second'>
          <Link href={{pathname: '/profile', query: {page: 'oglasi'}}} as='/profil/oglasi'>
            <a>
              <i className='ico-moji-oglasi ico' />
              {t('Moji oglasi')}
            </a>
          </Link>
        </li>
      </ul>
    </div>
  )
}
