import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'

export const detailReducer = (state = initialState.ads.detail, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_AD:
      return {...state, currentAd: action.payload}
    case actionTypes.SET_IS_SERVER_STATE:
      return {...state, isServer: action.payload}
    case actionTypes.SET_ADS_REPORT_TYPES:
      return {...state, reportTypes: action.payload}
    case actionTypes.SET_SIMILAR_ADS:
      return { ...state, similarAds: {...state.similarAds, elements: action.payload} }
    case actionTypes.SET_SIMILAR_ADS_LOADING:
      return { ...state, similarAds: {...state.similarAds, isLoading: action.payload} }
    case actionTypes.SET_REGISTRATION_FORMULAS:
      return { ...state, registrationFormulas: action.payload }
    case actionTypes.SET_REGISTRATION_FORMULA_DETAILS:
      return { ...state, registrationFormulaDetails: action.payload }
    case actionTypes.SET_REGISTRATION_PREMIUM_CLASSES:
      return { ...state, registrationPremiumClasses: action.payload }
    default:
      return state
  }
}
