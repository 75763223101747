import { combineReducers } from 'redux'
import initialState from 'SRC/modules/redux/initialState'
import actionTypes from 'SRC/modules/redux/actionTypes'
import { partnersReducer } from './partners/reducer'

export const discountCardReducer = combineReducers({
  partners: partnersReducer,
  verification: (state = initialState.discountCard, action) => {
    switch (action.type) {
      case actionTypes.SET_DISCOUNT_CARD_VERIFIED:
        return {...state, verified: action.payload.verified, status: action.payload.status}
      default:
        return state
    }
  }
})
