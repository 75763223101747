import { ProfileApi } from '../../api'
import { setAddons } from '../setAddons'
import { setAddonsLoading } from '../setAddonsLoading'

export const fetchAddons = (userId, token) => {
  return async (dispatch) => {
    dispatch(setAddonsLoading(true))
    const api = new ProfileApi()
    const addons = await api.getAddons(userId, token)
    dispatch(setAddons(addons))
    dispatch(setAddonsLoading(false))
  }
}
