import PropTypes from 'prop-types'
import React from 'react'

export const Item = ({link, icoClass, children}) => (
  <div className='prijavi-se-popup-registracija-footer-item'>
    <a href={link}>
      <i className={icoClass + 'ico'} />
      <p>{children}</p>
    </a>
  </div>
)

Item.propTypes = {
  children: PropTypes.any,
  icoClass: PropTypes.string,
  link: PropTypes.string.isRequired
}
