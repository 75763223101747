export const isSpecificationVisible = ({ dependentOn, dependentType, dependentValues, specValues }) => {
  const valueProp = specValues && typeof specValues[dependentOn] === 'object'
    ? specValues[dependentOn].hasOwnProperty('values')
      ? 'values'
      : 'value'
    : null

  let isVisible = true

  let matchDependentValues = false

  if (valueProp === 'value') {
    const value = specValues[dependentOn][valueProp]

    matchDependentValues = value !== -1
      ? Array.isArray(dependentValues) && dependentValues.includes(specValues[dependentOn][valueProp])
      : false
  } else if (valueProp === 'values') {
    const values = specValues[dependentOn][valueProp]

    matchDependentValues = values !== -1
      ? dependentValues.some(dependentValue => values.includes(dependentValue))
      : false
  } else {
    matchDependentValues = false
  }

  if (matchDependentValues) {
    if (dependentType === 'show') {
      isVisible = true
    } else {
      isVisible = false
    }
  } else {
    if (dependentType === 'show') {
      isVisible = false
    } else {
      isVisible = true
    }
  }

  return isVisible
}
