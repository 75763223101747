import PropTypes from 'prop-types'
import React from 'react'

export const Item = ({address, onClick}) =>
  <div
    className='sidebar-user-contact-content sidebar-user-contact-lokacija'
    key={address.id}
    data-id={address.id}
    onClick={onClick}
  >
    <i className='ico-lokacija ico' />
    <p>{address.street}</p>
    <p>{address.city && address.city.name} / {address.location && address.location.name}</p>
  </div>

Item.propTypes = {
  address: PropTypes.shape({
    id: PropTypes.number.isRequired,
    city: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    location: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    street: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired
}
