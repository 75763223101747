import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export const NearAds = () => {
  const { t } = useTranslation('common')

  return (
    <div className='sidebar-user-ostalo-item'>
      <a href='javascript:void (0)'>
        <i className='ico-user-oglasi-u-tvojoj-blizini ico' />
        <p>{t('Pogledaj oglase iz tvoje okoline')}</p>
      </a>
    </div>
  )
}
