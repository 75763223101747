import { getCurrentLocale } from 'SRC/modules/common/functions'

import { CountriesApi } from '../../api'
import { getCountries } from '../../selectors'
import { setCountries, setCountriesLoading } from '../../actions'

export const fetchCountries = (locale = getCurrentLocale()) => {
  return async (dispatch, getState) => {
    dispatch(setCountriesLoading(true))
    const state = getState()
    const countries = getCountries(state)
    if (!countries || (countries && !countries.length) || locale !== 'ME') {
      const api = new CountriesApi()
      const result = await api.getCountries(locale)
      dispatch(setCountries(result))
    }
    dispatch(setCountriesLoading(false))
  }
}
