import { LocationsApi } from '../../api'
import { setLocations, setLocationsLoading } from '../../actions'

export const fetchLocations = (cityId) => {
  return async (dispatch) => {
    if (!cityId) {
      dispatch(setLocations([]))
    } else {
      dispatch(setLocationsLoading(true))
      const api = new LocationsApi()
      const locations = await api.getLocationsByCity(cityId)
      dispatch(setLocations(locations))
      dispatch(setLocationsLoading(false))
    }
  }
}
