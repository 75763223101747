import PropTypes from 'prop-types'
import React from 'react'
import { debounce } from 'throttle-debounce'
import withTranslation from 'next-translate/withTranslation'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { AdsFilterApi } from 'SRC/modules/ads/filter/api'
import { getCities } from 'SRC/modules/geoObjects/cities/selectors'
import { setCurrentSubCategory } from 'SRC/modules/categories/actions'
import { setCurrentGroup } from 'SRC/modules/groups/actions'
import Router from 'next/router'
import { getSearchableArray, searchCities } from 'SRC/modules/categories/search'
import config from 'SRC/config/config.json'

import translations from '../../../../../../../public/data/translations.json'

const getCategoryBCItems = breadCrumb => {
  let masterCat, group, category

  if (Array.isArray(breadCrumb) && breadCrumb[0] && breadCrumb[0].type === 'masterCategory') masterCat = breadCrumb[0]
  if (Array.isArray(breadCrumb) && breadCrumb[1] && breadCrumb[1].type === 'group') group = breadCrumb[1]
  if (Array.isArray(breadCrumb) && breadCrumb[2] && breadCrumb[2].type === 'category') category = breadCrumb[2]

  return { masterCat, group, category }
}

const getBrandProductCBItems = breadCrumb => {
  let brand, product

  if (Array.isArray(breadCrumb) && breadCrumb[3] && breadCrumb[3].type === 'brand') brand = breadCrumb[3]
  if (Array.isArray(breadCrumb) && breadCrumb[4] && breadCrumb[4].type === 'product') product = breadCrumb[4]

  return { brand, product }
}

const getServicesCBItems = breadCrumb => {
  let service, serviceType

  if (Array.isArray(breadCrumb) && breadCrumb[3] && breadCrumb[3].type === 'service') service = breadCrumb[3]
  if (Array.isArray(breadCrumb) && breadCrumb[4] && breadCrumb[4].type === 'serviceType') serviceType = breadCrumb[4]

  return { service, serviceType }
}

const getCompatibilityCBItems = breadCrumb => {
  let compCategory, compBrand, compProduct

  if (Array.isArray(breadCrumb) && breadCrumb[3] && breadCrumb[3].type === 'compatibility_category') compCategory = breadCrumb[3]
  if (Array.isArray(breadCrumb) && breadCrumb[4] && breadCrumb[4].type === 'compatibility_brand') compBrand = breadCrumb[4]
  if (Array.isArray(breadCrumb) && breadCrumb[5] && breadCrumb[5].type === 'compatibility_product') compProduct = breadCrumb[5]

  return { compCategory, compBrand, compProduct }
}

export class SearchInput extends React.Component {
  constructor (props) {
    super(props)

    this.api = new AdsFilterApi()

    this.inputRef = React.createRef()
    this.dropdownRef = React.createRef()

    this.state = {
      textValue: '',
      suggestions: null,
      showSuggestions: false,
      activeSuggestion: null
    }
  }

  componentDidMount () {
    const { cities } = this.props

    this.searchableArray = getSearchableArray([], { cities })

    document.addEventListener('mousedown', this.toggleOptionsVisibility)
    document.addEventListener('keyup', this.onKeyUp)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.toggleOptionsVisibility)
    document.removeEventListener('keyup', this.onKeyUp)
  }

  onKeyUp = event => {
    const { showSuggestions, activeSuggestion, suggestions } = this.state

    const suggestionsCount = Array.isArray(suggestions) ? suggestions.length : 0

    if (event.code === 'Enter' && activeSuggestion === null) { // Enter
      return this.submit({ isFullText: true })
    }

    if (!showSuggestions || !suggestionsCount) return

    let currentActiveSuggestion = activeSuggestion

    if (event.code === 'ArrowUp') { // arrow up
      if (activeSuggestion === null) return

      if (activeSuggestion > 0) currentActiveSuggestion--
    }

    if (event.code === 'ArrowDown') { // arrow down
      if (activeSuggestion === null) currentActiveSuggestion = 0

      if (activeSuggestion !== null && activeSuggestion < suggestionsCount - 1) currentActiveSuggestion++
    }

    if (event.code === 'Enter' && activeSuggestion !== null) { // Enter
      return this.suggestionClicked(suggestions[activeSuggestion])
    }

    this.setState({ activeSuggestion: currentActiveSuggestion })
  }

  toggleOptionsVisibility = (e) => {
    let activeSuggestion = null
    let showSuggestions = false

    if (this.inputRef.current && this.inputRef.current.contains(e.target)) {
      showSuggestions = true
      activeSuggestion = this.state.activeSuggestion
    }

    if (this.dropdownRef.current && this.dropdownRef.current.contains(e.target)) {
      showSuggestions = true
      activeSuggestion = this.state.activeSuggestion
    }

    this.setState({
      showSuggestions,
      activeSuggestion
    })
  }

  submitFullText = () => {
    const { setCurrentSubCategory, setCurrentGroup, i18n } = this.props
    
    const locale = i18n?.lang?.toUpperCase() || 'ME'

    const { textValue } = this.state

    if (!textValue || textValue.length < 3) return

    const as = `/${translations.pretraga[locale]}?fullText=${textValue}&sortBy=dateDesc`
    const href = {
      pathname: '/search',
      query: {
        fullText: textValue,
        sortBy: 'dateDesc'
      }
    }

    setCurrentGroup(null)
    setCurrentSubCategory(null)

    return Router.push(href, as)
  }

  submit = (options) => {
    const { i18n } = this.props

    const locale = i18n?.lang?.toUpperCase() || 'ME'

    let as, href

    if (options && options.isFullText) return this.submitFullText()

    const { category, ...restParams } = options

    const pathname = restParams && Object.keys(restParams).length ? '/search' : '/category'

    const baseAs = '/'
    const baseHref = { pathname }

    href = { ...baseHref, query: { category } }
    as = `${baseAs}${category}`

    if (restParams && Object.keys(restParams).length) {
      href.query = {
        ...href.query,
        pageNumber: 1,
        sortBy: 'dateDesc',
        ...restParams
      }

      as += `/${translations.pretraga[locale]}?pageNumber=1&sortBy=dateDesc`
      Object.keys(restParams).forEach(paramKey => {
        const paramValue = restParams[paramKey]

        as += `&${paramKey}=${paramValue}`
      })
    }

    Router.push(href, as)
  }

  onTextSearch = debounce(250, () => {
    const { textValue } = this.state

    this.getSuggestions(textValue)
  })

  getSuggestions = async textValue => {
    this.setState({ suggestions: [] })

    const suggestions = []

    if (textValue === '' || textValue < 3) { this.setState({ suggestions: [] }); return }

    const searchResult = await this.api.getElasticCBP(textValue)

    if (!searchResult || !Array.isArray(searchResult.result) || !searchResult.result.length) {
      this.setState({ suggestions: [] })
      return
    }

    for (let item of searchResult.result) {
      const breadCrumb = item.breadcrumbs
      const suggestion = {}

      const { masterCat, group, category } = getCategoryBCItems(breadCrumb)
      const { brand, product } = getBrandProductCBItems(breadCrumb)
      const { service, serviceType } = getServicesCBItems(breadCrumb)
      const { compCategory, compBrand, compProduct } = getCompatibilityCBItems(breadCrumb)

      if (!masterCat || !group || !category) continue

      suggestion.masterCategory = masterCat
      suggestion.group = group
      suggestion.category = category

      if (group.seo === category.seo) suggestion.breadCrumbs = [category]
      else suggestion.breadCrumbs = [group, category]

      if (brand) {
        suggestion.breadCrumbs.push(brand)
        suggestion.brand = brand

        if (product) {
          suggestion.breadCrumbs.push(product)
          suggestion.product = product
        }
      }

      if (service) {
        suggestion.breadCrumbs.push(service)
        suggestion.service = service

        if (serviceType) {
          suggestion.breadCrumbs.push(serviceType)
          suggestion.serviceType = serviceType
        }
      }

      if (compCategory) {
        suggestion.breadCrumbs.push(compCategory)
        suggestion.compatibilityCategory = compCategory

        if (compBrand) {
          suggestion.breadCrumbs.push(compBrand)
          suggestion.compatibilityBrand = compBrand

          if (compProduct) {
            suggestion.breadCrumbs.push(compProduct)
            suggestion.compatibilityProduct = compProduct
          }
        }
      }

      const city = searchCities(this.searchableArray, textValue)

      if (city) {
        suggestion.breadCrumbs.push({
          type: 'city',
          id: city.id,
          seo: null,
          title: city.label
        })
        suggestion.city = city
      }

      suggestions.push(suggestion)
    }

    this.setState({ suggestions })
  }

  suggestionClicked = item => {
    const params = {
      category: item.category.seo
    }

    if (item.brand) {
      params.brand = item.brand.id
      params.brands = JSON.stringify([item.brand.id])
      if (item.product) {
        params.product = item.product.id
        params.products = JSON.stringify([item.product.id])
      }
    }

    if (item.service) {
      params.service = item.service.id
      if (item.serviceType) params.serviceType = item.serviceType.id
    }

    if (item.compatibilityCategory) {
      const compatibility = {
        category: item.compatibilityCategory.id
      }

      if (item.compatibilityBrand) compatibility.brand = item.compatibilityBrand.id
      if (item.compatibilityProduct) compatibility.product = item.compatibilityProduct.id

      params.compatibility = JSON.stringify(compatibility)
    }

    if (item.city) {
      params.country = 1
      params.city = item.city.id
      params.cities = JSON.stringify([item.city.id])
    }

    this.submit(params)

    this.setState({ showSuggestions: false })
  }

  renderSuggestions = () => {
    const { suggestions, activeSuggestion } = this.state

    if (Array.isArray(suggestions) && suggestions.length) {
      return (
        <section className='categories-suggestions' ref={this.dropdownRef}>
          {suggestions.map((suggestion, index) => {
            const suggestionCategory = suggestion.category
            const suggestionItems = suggestion.breadCrumbs

            const activeSuggestionClassName = activeSuggestion === index
              ? 'categories-suggestions-container__active'
              : ''

            if (Array.isArray(suggestionItems) && suggestionCategory) {
              return (
                <ul
                  key={`suggestion-${index}`}
                  className={`categories-suggestions-container ${activeSuggestionClassName}`}
                  style={{ margin: '.4rem' }}
                  onClick={() => this.suggestionClicked(suggestion)}
                >
                  {suggestionItems.map((item, index1) => (
                    <React.Fragment key={`suggestion-item-${suggestionCategory.id}-${item.id}-${index1}`}>
                      {
                        <li>
                          {this.renderSuggestionItem(item.title)}
                        </li>
                      }
                      {index1 !== suggestionItems.length - 1 ? <li key={`suggestion-separator-${item.id}`}> > </li> : null}
                    </React.Fragment>
                  ))}
                </ul>
              )
            }

            return null
          })}
        </section>
      )
    }

    return null
  }

  renderSuggestionItem = (title) => {
    return (
      <a href='javascript:void(0)'>
        {title}
      </a>
    )
  }

  render () {
    const { i18n: { t } } = this.props
    const { textValue, showSuggestions } = this.state

    return (
      <form
        onSubmit={e => { e.preventDefault() }}
        className='category-search-form'
      >
        <input
          ref={this.inputRef}
          type='text'
          placeholder={t('common:textSearchPlaceholder')}
          onClick={() => {
            this.setState({ showSuggestions: true })
          }}
          onChange={e => {
            const value = e.target.value

            this.setState({ textValue: value }, this.onTextSearch)
          }}
          defaultValue={textValue}
          value={textValue} maxLength={50}
        />

        {textValue && textValue.length >= 3 && showSuggestions ? this.renderSuggestions() : null}

        <button className='ads-search__button' onClick={() => { this.submit({ isFullText: true }) }}>
          <i className='ogl-header-ico ico-pretraga-text-search-lupa ico' />
        </button>
      </form>
    )
  }
}

SearchInput.propTypes = {
  cities: PropTypes.array.isRequired,
  setCurrentSubCategory: PropTypes.func.isRequired,
  setCurrentGroup: PropTypes.func.isRequired
}

SearchInput.defaultProps = {
  baseAs: '/',
  cities: [],
  baseHrefQuery: {}
}

const mapStateToProps = state => ({
  cities: getCities(state)
})

const enhance = compose(
  connect(mapStateToProps, { setCurrentSubCategory, setCurrentGroup }),
  withTranslation
)

export default enhance(SearchInput)
