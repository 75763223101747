import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { adsStatues } from 'SRC/modules/ads/shared/constants'

const getTextAndColorByStatus = status => {
  const result = {
    text: null,
    color: null
  }
  switch (status) {
    case adsStatues.PUBLISHED.code:
      result.text = 'OBJAVLJEN'
      result.background = '8,132,0'
      result.color = '#ffffff'
      break
    case adsStatues.UNPUBLISHED.code:
      result.text = 'NA ČEKANJU'
      result.background = '255,215,79'
      result.color = '#000000'
      break
    case adsStatues.PAUSED.code:
      result.text = 'PAUZIRAN'
      result.background = '119,119,119'
      result.color = '#ffffff'
      break
    case adsStatues.SOLD.code:
      result.text = 'PRODAT'
      result.background = '3,119,205'
      result.color = '#ffffff'
      break
    case adsStatues.EXPIRED.code:
      result.text = 'ISTEKAO'
      result.background = '130,101,0'
      result.color = '#ffffff'
      break
    case adsStatues.REJECTED.code:
      result.text = 'ODBIJEN'
      result.background = '0,0,0'
      result.color = '#ffffff'
      break
  }
  return result
}

export const AdImage = ({ category, seo, img, paid, className, title, status, isLoading }) => {
  const { t } = useTranslation('common')
  const label = getTextAndColorByStatus(status)

  return (
    <div className={className}>
      {!isLoading ? (
        <Link href={`/ad?category=${category}&ad=${seo}`} as={`/${category}/${seo}`}>
          <a>{'\n'}
            <div
              className={'oglas-item-slika'}
              style={{
                // backgroundImage: `url(${img})`,
                position: 'relative',
                'min-height': '126px'
              }}
              data-tip={title}
            >
              {/* <Image src={img} alt={title} layout='fill' objectFit='cover' /> */}
              <img src={img} alt={title} style={{ objectFit: 'cover' }} />
            </div>
            <div className='ad-status'>
              {
                label.text && label.color
                  ? <div
                    className='ad-status__item'
                    style={{ backgroundColor: `rgba(${label.background}, 0.6)`, color: label.color }}
                  >
                    {t(`${label.text}`)}
                  </div>
                  : null
              }
              {paid ? (
                <div className='ad-status__item ad-status__item_paid'>
                  {t('PLAĆEN OGLAS')}
                </div>
              ) : ''}
            </div>
          </a>
        </Link>
      ) : (
        <div>
          <a style={{ cursor: 'not-allowed' }}>{'\n'}
            <div
              className={'oglas-item-slika'}
              style={{
                // backgroundImage: `url(${img})`,
                position: 'relative',
                'min-height': '126px'
              }}
              data-tip={title}
            >
              {/* <Image src={img} alt={title} layout='fill' objectFit='cover' /> */}
              <img src={img} alt={title} style={{ objectFit: 'cover' }} />
            </div>
            <div className='ad-status'>
              {
                label.text && label.color
                  ? <div
                    className='ad-status__item'
                    style={{ backgroundColor: `rgba(${label.background}, 0.6)`, color: label.color }}
                  >
                    {t(`${label.text}`)}
                  </div>
                  : null
              }
              {paid ? (
                <div className='ad-status__item ad-status__item_paid'>
                  {t('PLAĆEN OGLAS')}
                </div>
              ) : ''}
            </div>
          </a>
        </div>
      )}
    </div>
  )
}

AdImage.propTypes = {
  category: PropTypes.string.isRequired,
  img: PropTypes.string,
  paid: PropTypes.bool,
  seo: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    adsStatues.PUBLISHED.code,
    adsStatues.UNPUBLISHED.code,
    adsStatues.PAUSED.code,
    adsStatues.SOLD.code,
    adsStatues.EXPIRED.code,
    adsStatues.REJECTED.code
  ]),
  isLoading: PropTypes.bool.isRequired
}

AdImage.defaultProps = {
  paid: false
}
