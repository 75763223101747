import PropTypes from 'prop-types'
import Link from 'next/link'
import React from 'react'
import ReactSVG from 'react-svg'
import useTranslation from 'next-translate/useTranslation'
import { isObjectContainedAnotherObject } from 'SRC/utils/Utils'

const renderIcon = icoClass => {
  return <i className={`${icoClass} ico`} />
}

const renderSvg = iconSvg => {
  return (
    <ReactSVG src={iconSvg} beforeInjection={svg => {
      svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 40px;')
    }} wrapper='span' />
  )
}

export const ItemTop = ({
  id,
  icoClass,
  text,
  children,
  className,
  link,
  targetBlank,
  pages,
  query,
  isActive,
  is404,
  iconSvg,
  closeIcon
}) => {
  const { t } = useTranslation('common')
  const activeClass = !is404 && isActive ? `active` : ``

  const itemClassName = `${className}${activeClass ? ` ${activeClass}` : ''}${id ? ` ${id}` : ''}`

  return (
    <li className={itemClassName.trim()}>
      {link ? (
        <Link href={link.href} as={link.as}>
          <a target={targetBlank ? '_blank' : '_self'} onClick={closeIcon}>
            {iconSvg
              ? renderSvg(iconSvg)
              : icoClass
                ? renderIcon(icoClass)
                : null
            }
            <p className='bm-item__text'>{t(text)}</p>
          </a>
        </Link>
      ) : null}
    </li>
  )
}

ItemTop.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  icoClass: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.any,
    as: PropTypes.string
  }),
  pages: PropTypes.any,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  query: PropTypes.shape(),
  is404: PropTypes.bool.isRequired,
  targetBlank: PropTypes.bool,
  closeIcon: PropTypes.func.isRequired
}

ItemTop.defaultProps = {
  is404: false
}
