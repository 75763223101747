import React from 'react'
import ReactSVG from 'react-svg'
import { useRouter } from 'next/router'
import { debounce } from 'throttle-debounce'

export const ScrollToTopButton = () => {
	const [offsetY, setOffsetY] = React.useState(0)

	const updateOffsetY = () => {
		setOffsetY(window.pageYOffset)
	}

	React.useEffect(() => {
    // Update the document title using the browser API
		document.addEventListener('scroll', debounce(150, updateOffsetY))
  })
  
	const router = useRouter()
	let className = 'scroll-to-top-btn'
	if (['/', '/category', '/search', '/ad'].includes(router.pathname)) className += ' scroll-to-top-btn-elevated'

	return offsetY > 50 ? (
		<div className={className} onClick={() => { window.scrollTo(0, 0) }}>
			<ReactSVG src='/icons/016.gavni-meni-dropdown-strelica.svg' beforeInjection={svg => {
				svg.setAttribute('style', 'width: 25px; height: 25px; margin-top: 8px;')
			}} wrapper='span' />
		</div>
	) : null
}
