import PropTypes from 'prop-types'
import React from 'react'

export const Field = ({children, className}) => (
  <div className={`polja-pretrage-item ${className}`}>{children}</div>
)

Field.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
}

Field.defaultProps = {
  className: ''
}
