import PropTypes from 'prop-types'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Notification } from 'react-notification'

export const ErrorNotification = ({ error, hideNotification }) => {
  const { t } = useTranslation('common')

  return (
    <Notification
      isActive={Boolean(error)}
      message={error || ''}
      action={t('Sakrij')}
      onClick={hideNotification}
    />
  )
}

ErrorNotification.propTypes = {
  error: PropTypes.string,
  hideNotification: PropTypes.func.isRequired
}

ErrorNotification.defaultProps = {
  error: ''
}
