import { BaseApi } from 'Core/api/BaseApi'

export class Api extends BaseApi {
  getElasticCBP = async text => {
    const params = { search: text, size: 10 }
    try {
      const {body} = await Api.post(`/elastic/searchCBP`, params)
      return body
    } catch (error) {
      return { error }
    }
  }

  getElasticTextSearch = async text => {
    try {
      const {body} = await Api.post(`/ads/searchText`, { text })

      if (body && body.hits && Array.isArray(body.hits.hits) && body.hits.hits.length) {
        return body.hits.hits.map(hit => hit._source)
      }

      return []
    } catch (error) {
      return { error }
    }
  }
}
