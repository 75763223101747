import PropTypes from 'prop-types'
import React from 'react'

export const Name = ({name, login}) =>
  <p className='sidebar-user-status-name'>{name} ({login})</p>

Name.propTypes = {
  login: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}
