import PropTypes from 'prop-types'
import React from 'react'

export const EmailInput = ({
  id,
  title,
  value,
  className,
  placeholder,
  isDisabled,
  onMouseEnter,
  onFocus,
  onChange
}) => {
  return (
    <React.Fragment>
      <label htmlFor={id} className='inline-label'>{`${title}:`}</label>
      <input
        id={id}
        key={id}
        value={Number(value) !== -1 ? value : ''}
        type='email'
        disabled={isDisabled}
        style={{ opacity: isDisabled ? 0.4 : 1 }}
        placeholder={placeholder}
        className={className}
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
        onChange={(e) => onChange(e.target.value)}
      />
    </React.Fragment>
  )
}

EmailInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func
}
